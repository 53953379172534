import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';

import { MeasurementSection } from './sections/MeasurementSection';
import theme from '../theme';

export const Measurement = ({ imageSets, defaultStructureSetUID }) => {
  const [selectedStructureSetUID] = useState(defaultStructureSetUID);
  const [selectedROINumber, setSelectedROINumber] = useState(-1);
  const [filteredInterpretedType, setFilteredInterpretedType] = useState('all');

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: '70vh', minHeight: '300px', maxHeight: '70vh' }}>
        <MeasurementSection
          imageSets={imageSets}
          selectedStructureSetUID={selectedStructureSetUID}
          selectedROINumber={selectedROINumber}
          setSelectedROINumber={setSelectedROINumber}
          filteredInterpretedType={filteredInterpretedType}
          setFilteredInterpretedType={setFilteredInterpretedType}
        />
      </div>
    </ThemeProvider>
  );
};
Measurement.propTypes = {
  imageSets: PropTypes.arrayOf(
    PropTypes.shape({ SeriesInstanceUID: PropTypes.string })
  ),
  defaultStructureSetUID: PropTypes.string,
};
