import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

export default function LesionSnapshot({
  id,
  imageId,
  scale,
  translation,
  width,
  height,
}) {
  useEffect(() => {
    const element = document.getElementById(id);
    cornerstone.loadImage(imageId).then(image => {
      cornerstone.enable(element);
      const viewport = cornerstone.getViewport(element);
      cornerstone.setViewport(element, { ...viewport, scale, translation });
      cornerstone.displayImage(element, image);
    });
    return () => {
      cornerstoneTools.clearToolState(element, 'stackPrefetch');
      cornerstoneTools.stopClip(element);
      cornerstone.disable(element);
    };
  }, []);
  return (
    <div
      id={id}
      style={{
        background: 'var(--ui-gray)',
        width: `${width}px`,
        height: `${height}px`,
      }}
    />
  );
}
LesionSnapshot.propTypes = {
  id: PropTypes.string,
  imageId: PropTypes.string,
  scale: PropTypes.number,
  translation: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  width: PropTypes.number,
  height: PropTypes.number,
};
