import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { redux, user as platformUser } from '@platform/core';
import store from '../../store';

const { actions } = redux;

export function Auth({ children, config }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenExpiredAt, setTokenExpiredAt] = useState(null);
  const [isAuthStateChanged, setIsAuthStateChanged] = useState(false);

  useEffect(() => {
    (async function() {
      firebase.auth().onAuthStateChanged(async user => {
        setIsAuthStateChanged(false);
        try {
          setUser(user);
          if (user?.email) {
            const now = new Date().getTime();
            if (config.autoLogout) {
              /** 2 weeks */
              const result = await user.getIdTokenResult();
              const auth_time = result.claims.auth_time * 1000;
              if (now - auth_time > 2 * 7 * 24 * 60 * 60 * 1000) {
                window.alert('Session expired.');
                firebase.auth().signOut();
              }
            }
            const token = await user.getIdToken(true);
            setToken(token);
            setTokenExpiredAt(now + 60 * 60 * 1000);
            /** set user email */
            store.dispatch(actions.setEmail(user.email));
            /** set token for platform user */
            platformUser.getAccessToken = () => token;
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        } finally {
          setIsAuthStateChanged(true);
        }
      });
    })();
  }, []);

  useEffect(() => {
    if (!user) {
      return () => {};
    }
    const interval = setInterval(async () => {
      const now = new Date().getTime();
      const token = await user.getIdToken(true);
      setToken(token);
      setTokenExpiredAt(now + 60 * 60 * 1000);
      /** refresh token for platform user every 30 min */
      platformUser.getAccessToken = () => token;
      // eslint-disable-next-line no-console
      console.log(`refresh token at ${now}`);
    }, 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, [user]);

  return children({
    firebaseUser: user,
    firebaseToken: token,
    firebaseTokenExpiredAt: tokenExpiredAt,
    isAuthStateChanged,
  });
}
