import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cornerstoneTools from 'cornerstone-tools';

import {
  Grid,
  Body,
  Container,
  InputBox,
  Select,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LoadingSpinner,
} from '@platform/ui';
import ExportAlert from '../../common/ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

export const FindingSection = ({
  imageSets,
  selectedLinkID,
  setSelectedLinkID,
  getLinkROINames,
  measurementTech,
  setMeasurementTech,
}) => {
  const [stats, setStats] = useState([]);
  const { seriesStructureMap } = useSelector(state => state.extensions.dicomRT);
  const {
    Links: links,
    PrimaryRTSS: primary,
    ReferencedRTSS: references,
  } = useSelector(state => state.linkage);

  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const structureSets = editModule.getters.structureSets();
      const referencedImageSets = models.getImageSetsFromReferences(
        imageSets,
        references,
        seriesStructureMap
      );
      const timepointManager = new models.TimepointManager();
      timepointManager.addTimepointsByImageSets(
        referencedImageSets,
        structureSets,
        seriesStructureMap,
        false
      );
      timepointManager.addLinks(links);
      const stats = timepointManager.getStats();
      setStats(stats);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const techs = models.getTechs();
  const tech = techs[measurementTech];
  const sumValues = stats.map(stat => stat.sumOfLinks[tech.measurement]);
  const sumChanges = stats.map(stat => stat.sumOfLinks[tech.change]);

  return (
    <Body>
      <Container>
        {stats.length > 0 ? (
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow index={-1}>
                {[
                  ...references.map(s => s.ReferencedSeriesDate),
                  'change in last 2 TPs',
                ].map((text, i) => (
                  <TableCell key={i} index={i} header={1}>
                    <H6>{text}</H6>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={'sum'} index={-1}>
                {sumValues.map((text, i) => (
                  <TableCell key={i} index={i}>
                    <div style={{ display: 'flex' }}>
                      <H6>{text}</H6>
                      <H6
                        style={{
                          marginLeft: '10px',
                          color: 'var(--active-color)',
                        }}
                      >{`(SUM)`}</H6>
                    </div>
                  </TableCell>
                ))}
                <TableCell>
                  <H6>{models.getSign(sumChanges[sumChanges.length - 1])}</H6>
                </TableCell>
              </TableRow>
              {links
                .filter(link => [-1, link.LinkID].includes(selectedLinkID))
                .map((link, index) => {
                  const values = stats.map(stat =>
                    stat.links.find(l => l.id === link.LinkID)
                  );
                  const value_names = stats.map(stat => {
                    const uid = seriesStructureMap[stat.SeriesInstanceUID];
                    const names = getLinkROINames(link, uid);
                    return names ? ` (${names})` : '';
                  });
                  return (
                    <TableRow key={link.LinkID} index={index}>
                      {values.map((value, i) => (
                        <TableCell key={i} index={i}>
                          <div style={{ display: 'flex' }}>
                            <H6>{value[tech.measurement]}</H6>
                            <H6
                              style={{
                                marginLeft: '10px',
                                color: 'var(--active-color)',
                              }}
                            >
                              {value_names[i]}
                            </H6>
                          </div>
                        </TableCell>
                      ))}
                      <TableCell>
                        <H6>
                          {models.getSign(
                            values[values.length - 1][tech.change]
                          )}
                        </H6>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        ) : (
          <div style={{ margin: '15px 0' }}>
            <LoadingSpinner />
          </div>
        )}
      </Container>
      <div>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputBox label={'ROI Name(s):'} ratio={0.5}>
              <Select
                value={selectedLinkID}
                onChange={evt => setSelectedLinkID(Number(evt.target.value))}
              >
                <option value={-1}>------</option>
                {links
                  .map(link => ({
                    value: link.LinkID,
                    label: getLinkROINames(link, primary.SeriesInstanceUID),
                  }))
                  .filter(({ label }) => !!label)
                  .map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={6}>
            <InputBox label={'Measurement Tech:'} ratio={1}>
              <Select
                value={measurementTech}
                onChange={evt => setMeasurementTech(evt.target.value)}
              >
                {Object.entries(techs).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value.label}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
        </Grid>
      </div>
    </Body>
  );
};
FindingSection.propTypes = {
  imageSets: PropTypes.array,
  selectedLinkID: PropTypes.number,
  setSelectedLinkID: PropTypes.func,
  getLinkROINames: PropTypes.func,
  measurementTech: PropTypes.string,
  setMeasurementTech: PropTypes.func,
  onExportClick: PropTypes.func,
  isExporting: PropTypes.bool,
};
