export default function getWeightedCentroid(points) {
  /**
   * use shoelace formula: https://en.wikipedia.org/wiki/Shoelace_formula
   */
  const _points = [...points, points[0]];
  let area = 0;
  for (let i = 0; i < _points.length - 1; i++) {
    area =
      area +
      (_points[i].x * _points[i + 1].y - _points[i + 1].x * _points[i].y) / 2;
  }
  let centroidX = 0;
  let centroidY = 0;
  for (let i = 0; i < _points.length - 1; i++) {
    centroidX =
      centroidX +
      ((_points[i].x + _points[i + 1].x) *
        (_points[i].x * _points[i + 1].y - _points[i + 1].x * _points[i].y)) /
        (6 * area);
  }
  for (let i = 0; i < _points.length - 1; i++) {
    centroidY =
      centroidY +
      ((_points[i].y + _points[i + 1].y) *
        (_points[i].x * _points[i + 1].y - _points[i + 1].x * _points[i].y)) /
        (6 * area);
  }
  return { x: centroidX, y: centroidY };
}
