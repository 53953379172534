export default function getMatchings(stats, links) {
  const dates = stats.map(stat => stat.SeriesDate);

  const matchings = [];
  links.forEach(link => {
    const cells = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.names}`;
    });
    const m = { ID: `${link.LinkID}`, color: 'black' };
    dates.forEach((date, index) => {
      m[date] = cells[index] || ' ';
    });
    matchings.push(m);
  });

  return matchings;
}
