import React, { useEffect, useState } from 'react';

import { LayoutChooser } from './LayoutChooser.js';
import PropTypes from 'prop-types';
import ToolbarButton from '../../containers/ToolbarButton';

export function LayoutButton(props) {
  const [dropdownVisible, setDropdownVisible] = useState(props.dropdownVisible);

  useEffect(() => {
    setDropdownVisible(props.dropdownVisible);
  }, [props.dropdownVisible]);

  const onClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const onChange = selectedCell => {
    if (props.onChange) {
      props.onChange(selectedCell);
    }
  };

  return (
    <div className="btn-group">
      <ToolbarButton
        isActive={dropdownVisible}
        label={'Layout'}
        icon="th"
        onClick={onClick}
      />
      <LayoutChooser
        visible={dropdownVisible}
        onChange={onChange}
        onClick={onClick}
        selectedCell={props.selectedCell}
      />
    </div>
  );
}
LayoutButton.propTypes = {
  dropdownVisible: PropTypes.bool.isRequired,
  /** Called with the selectedCell number when grid sell is selected */
  onChange: PropTypes.func,
  /** The cell to show as selected */
  selectedCell: PropTypes.object,
};
LayoutButton.defaultProps = {
  dropdownVisible: false,
};

export default LayoutButton;
