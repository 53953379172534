import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Select, Input, P, Alert } from '@platform/ui';

export const ExpansionForm = ({
  ROIContours,
  onPreview,
  onSubmit,
  onDelete,
  errorMessage = null,
}) => {
  const [targetROIContour, setTargetROIContour] = useState(null);
  const [avoidROIContour, setAvoidROIContour] = useState(null);
  const [type, setType] = useState('outer');
  const [name, setName] = useState('');
  const [offset, setOffset] = useState(0);
  const [px, setPx] = useState(0);
  const [nx, setNx] = useState(0);
  const [py, setPy] = useState(0);
  const [ny, setNy] = useState(0);
  const [pz, setPz] = useState(0);
  const [nz, setNz] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isPreviewed, setIsPreviewed] = useState(false);
  const isPreviewable = !!targetROIContour;
  const onPreviewClick = async () => {
    try {
      setIsProcessing(true);
      await new Promise(resolve => setTimeout(resolve, 100));
      const { ROINumber } = targetROIContour;
      const { ROINumber: avoidNumber } = avoidROIContour || {};
      setIsPreviewed(true);
      await onPreview(ROINumber, { px, nx, py, ny, pz, nz }, type, avoidNumber);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div
      style={{
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      <div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>New ROI Name:</P>
          <Input
            value={name}
            onChange={evt => setName(evt.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Target ROI Name:</P>
          <Select
            onChange={evt => {
              const targetROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setTargetROIContour(targetROIContour);
              setIsPreviewed(false);
            }}
          >
            <option value={-1}>------</option>
            {ROIContours.map(c => (
              <option key={c.ROINumber} value={c.ROINumber}>
                {c.ROIName}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0', display: 'flex', gap: '10px' }}>
          <div style={{ width: '100%', flex: 2 }}>
            <P style={{ color: 'var(--default-color)' }}>All Direction:</P>
            <Input
              value={offset}
              onChange={evt => {
                const offset = Number(evt.target.value);
                const newName =
                  `${targetROIContour?.ROIName || ''}` +
                  `(${type === 'inner' ? '-' : ''}${offset}mm)`;
                setIsPreviewed(false);
                setOffset(offset);
                setPx(offset);
                setNx(offset);
                setPy(offset);
                setNy(offset);
                setPz(offset);
                setNz(offset);
                setName(newName);
              }}
            />
          </div>
          <div style={{ width: '100%', flex: 1 }}>
            <P style={{ color: 'var(--default-color)' }}>Type:</P>
            <Select
              onChange={evt => {
                const newName =
                  `${targetROIContour?.ROIName || ''}` +
                  `(${type !== 'inner' ? '-' : ''}${offset}mm)`;
                setIsPreviewed(false);
                setType(evt.target.value);
                setName(newName);
              }}
            >
              <option value="outer">Outer</option>
              <option value="inner">Inner</option>
            </Select>
          </div>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(+x) L:</P>
              <Input
                value={px}
                onChange={evt => {
                  setIsPreviewed(false);
                  setPx(Number(evt.target.value));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(+y) P:</P>
              <Input
                value={py}
                onChange={evt => {
                  setIsPreviewed(false);
                  setPy(Number(evt.target.value));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(+z) H:</P>
              <Input
                value={pz}
                onChange={evt => {
                  setIsPreviewed(false);
                  setPz(Number(evt.target.value));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(-x) R:</P>
              <Input
                value={nx}
                onChange={evt => {
                  setIsPreviewed(false);
                  setNx(Number(evt.target.value));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(-y) A:</P>
              <Input
                value={ny}
                onChange={evt => {
                  setIsPreviewed(false);
                  setNy(Number(evt.target.value));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <P style={{ color: 'var(--default-color)' }}>(-z) F:</P>
              <Input
                value={nz}
                onChange={evt => {
                  setIsPreviewed(false);
                  setNz(Number(evt.target.value));
                }}
              />
            </Grid>
          </Grid>
          <div style={{ margin: '10px 0 0' }}>
            <P style={{ color: 'var(--default-color)' }}>Avoid ROI Name:</P>
            <Select
              onChange={evt => {
                const roi = ROIContours.find(
                  c => c.ROINumber === Number(evt.target.value)
                );
                setAvoidROIContour(roi);
                setIsPreviewed(false);
              }}
            >
              <option value={-1}>------</option>
              {ROIContours.map(c => (
                <option key={c.ROINumber} value={c.ROINumber}>
                  {c.ROIName}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </div>
      </div>
      <div>
        <div>
          <Button
            onClick={onPreviewClick}
            loading={isProcessing ? 'true' : undefined}
            disabled={!isPreviewable || isPreviewed}
            color="secondary"
            variant="contained"
            medium
            fullWidth
          >
            Preview
          </Button>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                onClick={onDelete}
                color="secondary"
                variant="contained"
                medium
                fullWidth
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => onSubmit({ name })}
                disabled={!isPreviewed}
                color="secondary"
                variant="contained"
                medium
                fullWidth
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
ExpansionForm.propTypes = {
  ROIContours: PropTypes.arrayOf(
    PropTypes.shape({ ROINumber: PropTypes.number })
  ),
  onPreview: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};
