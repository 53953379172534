export default function rotate({ x: cx, y: cy }, { x: tx, y: ty }, angle) {
  /**
   * rotate center { x: cx, y: cy }
   * rotate target point { x: tx, y: ty }
   */
  const radians = (Math.PI / 180) * angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = cos * (tx - cx) + sin * (ty - cy) + cx,
    ny = cos * (ty - cy) - sin * (tx - cx) + cy;
  return { x: nx, y: ny };
}
