import styled from 'styled-components';

const H6 = styled.h6`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.75;
`;

export default H6;
