import cornerstone from 'cornerstone-core';

import { getDistance3D } from '../../../modules/dicom-measurement/src/utils/math';
import polygonToMask from '../../../tools/operations/polygonToMask';
import convolution3D from '../../../tools/operations/convolution3D';
import findContoursOfImage from '../../../tools/operations/findContoursOfImage';

export default async function getExpandedStackData(
  api,
  activePainters,
  ROINumber,
  { px, nx, py, ny, pz, nz },
  type
) {
  const id0 = activePainters[0].imageId;
  const id1 = activePainters[1].imageId;
  const plane0 = cornerstone.metaData.get('imagePlaneModule', id0) || {};
  const plane1 = cornerstone.metaData.get('imagePlaneModule', id1) || {};
  const depth = activePainters.length;
  const {
    rows: width,
    columns: height,
    rowPixelSpacing: ySpacing,
    columnPixelSpacing: xSpacing,
  } = plane0;
  const p0 = plane0.imagePositionPatient;
  const p1 = plane1.imagePositionPatient;
  const thickness = getDistance3D(
    { x: p0[0], y: p0[1], z: p0[2] },
    { x: p1[0], y: p1[1], z: p1[2] }
  );

  let slices = [];
  if (api?.isEnabled()) {
    const inputSlices = [];
    for (let k = 0; k < depth; k++) {
      const state = activePainters[k].getState();
      const data = state.data.filter(d => d.ROINumber === ROINumber);
      const polygons = [];
      data.forEach(d => polygons.push(d.handles.points));
      inputSlices.push({ instance_number: k, polygons: polygons });
    }

    const input = {
      slices: inputSlices,
      margin: {
        ipx: Math.floor(px / xSpacing),
        inx: Math.floor(nx / xSpacing),
        ipy: Math.floor(py / ySpacing),
        iny: Math.floor(ny / ySpacing),
        ipz: Math.floor(pz / thickness),
        inz: Math.floor(nz / thickness),
      },
      image: { width, height },
      type,
    };
    try {
      const res = await api.computeMargin(input);
      const output = await res.json();
      slices = output.slices;
    } catch {
      slices = [];
    }
  } else {
    if (type === 'outer') {
      /** polygon to mask */
      let minX = width;
      let maxX = 0;
      let minY = height;
      let maxY = 0;
      let minZ = depth;
      let maxZ = 0;
      const input = [];
      for (let k = 0; k < depth; k++) {
        input[k] = [];
        const state = activePainters[k].getState();
        const data = state.data.filter(d => d.ROINumber === ROINumber);
        if (data.length > 0) {
          if (k <= minZ) minZ = k;
          if (k >= maxZ) maxZ = k;
        }
        let mask = Array.from({ length: height }, () => Array(width).fill(0));
        data.forEach(d => {
          const polygon = d.handles.points.map(({ x, y }) => {
            if (x <= minX) minX = x;
            if (x >= maxX) maxX = x;
            if (y <= minY) minY = y;
            if (y >= maxY) maxY = y;
            return [x, y];
          });
          mask = polygonToMask(mask, polygon);
        });
        input[k] = mask;
      }
      minX = minX - (2 * nx) / xSpacing;
      maxX = maxX + (2 * px) / xSpacing;
      minY = minY - (2 * ny) / ySpacing;
      maxY = maxY + (2 * py) / ySpacing;
      minZ = minZ - (2 * nz) / thickness;
      maxZ = maxZ + (2 * pz) / thickness;

      /** convolution */
      let output = input;
      const kernel = getKernel({
        ipx: Math.floor(px / xSpacing),
        inx: Math.floor(nx / xSpacing),
        ipy: Math.floor(py / ySpacing),
        iny: Math.floor(ny / ySpacing),
        ipz: Math.floor(pz / thickness),
        inz: Math.floor(nz / thickness),
      });
      const boundary = { minX, maxX, minY, maxY, minZ, maxZ };
      output = convolution3D(output, kernel, boundary);

      /** find contour */
      for (let k = 0; k < output.length; k++) {
        const contours = findContoursOfImage(output[k]);
        slices.push({ polygons: contours });
      }
    }
  }

  const stackData = [];
  for (let k = 0; k < activePainters.length; k++) {
    if (slices.length === 0) {
      stackData.push([]);
    } else {
      const contours = slices[k].polygons;
      const data = contours.reduce((data, contour) => {
        return [...data, { handles: { points: contour } }];
      }, []);
      stackData.push(data);
    }
  }
  return stackData;
}

function getKernel({ ipx, inx, ipy, iny, ipz, inz }) {
  const half = Math.max(ipx, inx, ipy, iny, ipz, inz);
  const size = half * 2 + 1;
  const kernel = [];
  for (let k = 0; k < size; k++) {
    kernel[k] = [];
    for (let j = 0; j < size; j++) {
      kernel[k][j] = [];
      for (let i = 0; i < size; i++) {
        const a = i - half > 0 ? inx : ipx;
        const b = j - half > 0 ? iny : ipy;
        const c = k - half > 0 ? inz : ipz;
        if (
          (a > 0 ? Math.pow(i - half, 2) / Math.pow(a, 2) : 0) +
            (b > 0 ? Math.pow(j - half, 2) / Math.pow(b, 2) : 0) +
            (c > 0 ? Math.pow(k - half, 2) / Math.pow(c, 2) : 0) <=
          1
        ) {
          kernel[k][j][i] = 1;
        } else {
          kernel[k][j][i] = 0;
        }
        if (a === 0 && i !== half) kernel[k][j][i] = 0;
        if (b === 0 && j !== half) kernel[k][j][i] = 0;
        if (c === 0 && k !== half) kernel[k][j][i] = 0;
      }
    }
  }
  return kernel;
}
