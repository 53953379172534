import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

// Cornerstone 3rd party dev kit imports
const draw = importInternal('drawing/draw');

export function deletePainter(toolState, structureSetSeriesInstanceUid) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  const state = _.cloneDeep(toolState);
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  let initialPoint = null;
  let currentPoint = null;
  if (!_.isNumber(ROINumber)) return;
  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      const element = evt.detail.element;
      const initialPointPixel = cornerstone.canvasToPixel(
        element,
        initialPoint
      );
      const currentPointPixel = cornerstone.canvasToPixel(
        element,
        currentPoint
      );
      const originalCount = state.data.length;
      state.data = getDeleteData(
        state.data,
        ROINumber,
        initialPointPixel,
        currentPointPixel
      );
      return state.data.length !== originalCount;
    },
    update: function(evt) {
      const eventData = evt.detail;
      const newPoint = eventData.currentPoints.canvas;
      if (!initialPoint) initialPoint = newPoint;
      currentPoint = newPoint;
      return true;
    },
    cursor: function(evt, context, cursorCanvasPosition, isDrawing) {
      if (!isDrawing) return;
      draw(context, context => {
        context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
        context.fillStyle = `rgba(${colorArray.join(',')}, 0.1)`;
        context.strokeRect(
          initialPoint.x,
          initialPoint.y,
          currentPoint.x - initialPoint.x,
          currentPoint.y - initialPoint.y
        );
        context.fillRect(
          initialPoint.x,
          initialPoint.y,
          currentPoint.x - initialPoint.x,
          currentPoint.y - initialPoint.y
        );
      });
      return true;
    },
  };
}

export function getDeleteData(
  data,
  ROINumber,
  initialPointPixel,
  currentPointPixel
) {
  const maxX = Math.max(initialPointPixel.x, currentPointPixel.x);
  const minX = Math.min(initialPointPixel.x, currentPointPixel.x);
  const maxY = Math.max(initialPointPixel.y, currentPointPixel.y);
  const minY = Math.min(initialPointPixel.y, currentPointPixel.y);
  return data.filter(
    x =>
      x.ROINumber !== ROINumber ||
      !_.every(
        x.handles.points,
        point =>
          maxX >= point.x &&
          minX <= point.x &&
          maxY >= point.y &&
          minY <= point.y
      )
  );
}
