import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve Study metadata from a DICOM server
 * @param server
 * @param StudyInstanceUID
 * @throws ECONNREFUSED
 * @returns {Array} The list of instance object
 */
export default async function retrieveStudyMetadata(server, StudyInstanceUID) {
  const dicomWebClient = getDICOMwebClient(server.wadoRoot, server);
  const instances = await dicomWebClient.retrieveStudyMetadata({
    studyInstanceUID: StudyInstanceUID,
  });
  return instances.map(instance => {
    return dcmjs.data.DicomMetaDictionary.naturalizeDataset(instance);
  });
}
