// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dcmseg-segmentation-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin: 0;
  width: 100%;
}

.dcmseg-segmentation-item .segmentation-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: hidden;
}

.dcmseg-segmentation-item .segmentation-meta-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: white;
  max-width: calc(100% - 30px);
}

.dcmseg-segmentation-item .segmentation-meta-description {
  font-size: 12px;
  color: var(--text-secondary-color);
}
`, "",{"version":3,"sources":["webpack://./../../../extensions/dicom-tag-browser/src/components/DicomBrowserSelectItem.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,uBAAuB;MACnB,oBAAoB;UAChB,2BAA2B;EACnC,SAAS;EACT,WAAW;AACb;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;MACzB,0BAA0B;UACtB,sBAAsB;EAC9B,mBAAmB;MACf,oBAAoB;UAChB,YAAY;EACpB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,kCAAkC;AACpC","sourcesContent":[".dcmseg-segmentation-item {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  margin: 0;\n  width: 100%;\n}\n\n.dcmseg-segmentation-item .segmentation-meta {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  overflow: hidden;\n}\n\n.dcmseg-segmentation-item .segmentation-meta-title {\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n  color: white;\n  max-width: calc(100% - 30px);\n}\n\n.dcmseg-segmentation-item .segmentation-meta-description {\n  font-size: 12px;\n  color: var(--text-secondary-color);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
