import React, { useState } from 'react';
import styled from 'styled-components';
import cornerstoneTools from 'cornerstone-tools';

import { ToggleSwitch } from '@platform/ui';
import * as commands from '../../../tools/commands';

export const RTDisplaySettings = () => {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const [isInformationToggled, setIsInformationToggled] = useState(
    editModule.getters.displayConfig('isInfoRendering')
  );
  const [isSelectedInfoToggled, setIsSelectedInfoToggled] = useState(
    editModule.getters.displayConfig('isSelectedInfoRendering')
  );
  const [isAxisToggled, setIsAxisToggled] = useState(
    editModule.getters.displayConfig('isAxesRendering')
  );
  const [isFillingToggled, setIsFillingToggled] = useState(
    editModule.getters.displayConfig('isContoursFilling')
  );
  const [isRefToggled, setIsRefToggled] = useState(
    editModule.getters.displayConfig('isInterViewRefRendering')
  );
  function onInformationToggle() {
    setIsInformationToggled(!isInformationToggled);
    editModule.setters.displayConfig('isInfoRendering', !isInformationToggled);
    commands.refreshViewport();
  }
  function onSelectedInfoToggle() {
    setIsSelectedInfoToggled(!isSelectedInfoToggled);
    editModule.setters.displayConfig(
      'isSelectedInfoRendering',
      !isSelectedInfoToggled
    );
    commands.refreshViewport();
  }
  function onAxisToggle() {
    setIsAxisToggled(!isAxisToggled);
    editModule.setters.displayConfig('isAxesRendering', !isAxisToggled);
    commands.refreshViewport();
  }
  function onFillingToggle() {
    setIsFillingToggled(!isFillingToggled);
    editModule.setters.displayConfig('isContoursFilling', !isFillingToggled);
    commands.refreshViewport();
  }
  function onRefToggle() {
    setIsRefToggled(!isRefToggled);
    editModule.setters.displayConfig('isInterViewRefRendering', !isRefToggled);
    commands.refreshViewport();
  }

  return (
    <div style={{ margin: '10px 0 0' }}>
      <FlexBox>
        <P>ROI Contours Information:</P>
        <ToggleSwitch
          id={'roi-info'}
          isToggled={isInformationToggled}
          onToggle={onInformationToggle}
        />
      </FlexBox>
      <FlexBox>
        <P>ROI Contours Information(selected):</P>
        <ToggleSwitch
          id={'roi-info-selected'}
          isToggled={isSelectedInfoToggled}
          onToggle={onSelectedInfoToggle}
        />
      </FlexBox>
      <FlexBox>
        <P>ROI Contours Axes:</P>
        <ToggleSwitch isToggled={isAxisToggled} onToggle={onAxisToggle} />
      </FlexBox>
      <FlexBox>
        <P>Fill Selected ROI Contours:</P>
        <ToggleSwitch isToggled={isFillingToggled} onToggle={onFillingToggle} />
      </FlexBox>
      <FlexBox>
        <P>(MPR) Reference Line:</P>
        <ToggleSwitch isToggled={isRefToggled} onToggle={onRefToggle} />
      </FlexBox>
    </div>
  );
};

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const P = styled.p`
  color: var(--default-color);
`;
