import { createContext } from 'react';
import { Firebase } from '@platform/core';

class TokenError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'TokenError';
    this.statusCode = statusCode;
  }
}

export class ServiceAPI {
  constructor(config = {}) {
    this.urlBase = config?.service?.endpoint['default'] || '';
    this.projectId = config?.firebaseConfig?.projectId || '';
    this.storageBucket = config?.firebaseConfig?.storageBucket || '';
    this.healthcareProjectId = config?.dicomWebProxy?.projectId || '';
  }

  async getIdToken() {
    const user = Firebase.getCurrentUser();
    const token = await user.getIdToken();
    return token;
  }

  handleError(res) {
    if (res.status >= 400 && res.status <= 500) {
      if (res.status === 401) {
        throw new TokenError(401, 'Unauthorized');
      } else if (res.status === 403) {
        throw new TokenError(403, 'Forbidden');
      } else {
        throw new Error(res.statusText);
      }
    }
  }

  async getResponseData(response) {
    const resp_text = await response.text();
    const contentType = response.headers.get('Content-Type');
    if (
      resp_text &&
      contentType &&
      contentType.indexOf('application/json') !== -1
    ) {
      return JSON.parse(resp_text);
    } else {
      return resp_text;
    }
  }

  /** upload */
  async uploadPostProcess(data) {
    const url = `${this.urlBase}/api/v1/data/download`;
    const body = JSON.stringify({
      ...data,
      project_id: this.projectId,
      input_bucket_name: this.storageBucket,
    });
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    this.handleError(res);
    return true;
  }
  /** end upload */

  /** workflow */
  async getWorkflows(page, siteId, trialId) {
    const url = `${this.urlBase}/api/v1/workflow?cursor=${page}&site=${siteId}&trial=${trialId}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    return resData;
  }

  async getWorkflow(workflowId, siteId, trialId) {
    const url = `${this.urlBase}/api/v1/workflow/${workflowId}?site=${siteId}&trial=${trialId}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    return resData;
  }

  async createWorkflow(data) {
    const url = `${this.urlBase}/api/v1/workflow`;
    const body = JSON.stringify({
      ...data,
      healthcare_info: {
        ...data.healthcare_info,
        project_id: this.healthcareProjectId,
      },
    });
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    return resData;
  }

  async deleteWorkflow(workflowId, siteId, trialId) {
    const url = `${this.urlBase}/api/v1/workflow/${workflowId}?site=${siteId}&trial=${trialId}`;
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    this.handleError(res);
    return true;
  }

  async getWorkflowOutputData(sandboxId, data) {
    const url = `${this.urlBase}/workflow/v1/sandbox/${sandboxId}/output/data`;
    const body = JSON.stringify(data);
    const token = await this.getIdToken();
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: body,
    });
    this.handleError(res);
    const resData = await this.getResponseData(res);
    return resData;
  }
  /** end workflow */
}

export const ServiceAPIContext = createContext();
