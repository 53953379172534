// import tf from '@tensorflow/tfjs';

export default function convolution3D(volume, kernel, boundary = {}) {
  const { minX, maxX, minY, maxY, minZ, maxZ } = boundary;
  const depth = volume.length;
  const height = volume[0].length;
  const width = volume[0][0].length;
  const size = kernel.length;
  const result = [];

  const isOutside = (x, y, z) => {
    if (minX && maxX && minY && maxY && minZ && maxZ) {
      return !(
        minX <= x &&
        x <= maxX &&
        minY <= y &&
        y <= maxY &&
        minZ <= z &&
        z <= maxZ
      );
    }
    return false;
  };

  const isInside = (x, y, z) => {
    return x >= 0 && x < width && y >= 0 && y < height && z >= 0 && z < depth;
  };

  for (let k = 0; k < depth; k++) {
    result[k] = [];
    for (let j = 0; j < height; j++) {
      result[k][j] = [];
      for (let i = 0; i < width; i++) {
        let sum = 0;
        if (isOutside(i, j, k)) {
          result[k][j][i] = 0;
          continue;
        }
        for (let n = 0; n < size; n++) {
          for (let m = 0; m < size; m++) {
            for (let l = 0; l < size; l++) {
              const x = i + l - Math.floor(size / 2);
              const y = j + m - Math.floor(size / 2);
              const z = k + n - Math.floor(size / 2);
              if (isInside(x, y, z)) {
                sum += volume[z][y][x] * kernel[n][m][l];
              }
            }
          }
        }
        result[k][j][i] = sum;
      }
    }
  }

  return result;
}

// export function _convolution3D(v, k) {
//   const depth = v.length;
//   const height = v[0].length;
//   const width = v[0][0].length;
//   const size = k.length;
//   const volume = tf.tensor(v, [depth, height, width, 1]);
//   const kernel = tf.tensor(k, [size, size, size, 1, 1]);
//   const convolved = tf.conv3d(volume, kernel, [1, 1, 1], 'same');
//   const reshaped = tf.reshape(convolved, [depth, height, width]);
//   const convolvedImageData = reshaped.arraySync();
//   return convolvedImageData;
// }
