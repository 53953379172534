export default function multiply(mat1, mat2) {
  const N = 3;
  let mat = [[], [], []];
  let i, j, k;
  for (i = 0; i < N; i++) {
    for (j = 0; j < N; j++) {
      mat[i][j] = 0;
      for (k = 0; k < N; k++) mat[i][j] += mat1[i][k] * mat2[k][j];
    }
  }
  return mat;
}
