const languagesMap = {
  en: 'English',
  'en-GB': 'English (Great Britain)',
  'en-US': 'English (USA)',
  ja: 'Japanese',
  'ja-JP': 'Japanese (Japan)',
  zh: 'Chinese',
  'zh-TW': 'Chinese (Taiwan)',
};

export default function getAvailableLanguagesInfo(locales) {
  const availableLanguagesInfo = [];

  Object.keys(locales).forEach(key => {
    availableLanguagesInfo.push({
      value: key,
      label: languagesMap[key] || key,
    });
  });

  return availableLanguagesInfo;
}
