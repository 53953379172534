// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-pulse {
  fa-spin: 1s infinite steps(8);
}
.icon-spin {
  animation: spin 2s linear infinite;
}
@-moz-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@-o-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/elements/Icon.styl"],"names":[],"mappings":"AAAA;EACE,6BAAQ;AACV;AACA;EACE,kCAAW;AACb;AAEA;EACE;IAAI,uBAAU;EACd;EAAA;IAAI,wBAAU;EAGd;AACF;AANA;EACE;IAAI,uBAAU;EASd;EARA;IAAI,wBAAU;EAWd;AACF;AAdA;EACE;IAAI,uBAAU;EAiBd;EAhBA;IAAI,wBAAU;EAmBd;AACF;AAtBA;EACE;IAAI,uBAAU;EAyBd;EAxBA;IAAI,wBAAU;EA2Bd;AACF","sourcesContent":[".icon-pulse\n  fa-spin 1s infinite steps(8)\n\n.icon-spin {\n  animation: spin 2s linear infinite;\n}\n\n@keyframes fa-spin{\n  0%{ transform:rotate(0deg) }\n  to{ transform:rotate(1turn) }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
