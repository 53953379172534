import { OVERALL_RESPONSE } from '../../constants/guideline.js';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @param {string} nameOfTimepoint
 * @returns {string}
 */
function findTimepointIndex(reports, nameOfTimepoint) {
  switch (nameOfTimepoint) {
    case 'baseline':
      return 0;
    case 'latest':
      return reports.length - 1;
    case 'responsive':
      return reports.findIndex(report =>
        [
          OVERALL_RESPONSE.COMPLETE_RESPONSE,
          OVERALL_RESPONSE.PARTIAL_RESPONSE,
        ].includes(report.result)
      );
    case 'progressive':
      return reports.findIndex(
        report => report.result === OVERALL_RESPONSE.PROGRESSIVE_DISEASE
      );
    default:
      return -1;
  }
}

export default findTimepointIndex;
