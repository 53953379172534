import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';

import { DialogBase } from '@platform/ui';
import theme from '../../theme';

export const ConfirmDialog = ({ title, content, onClose, onSubmit }) => {
  return (
    <ThemeProvider theme={theme}>
      <DialogBase
        open={true}
        onClose={onClose}
        onConfirm={onSubmit}
        title={title}
        content={content}
      />
    </ThemeProvider>
  );
};
ConfirmDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};
