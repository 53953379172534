export default function polygonArea(vertexes) {
  const len = vertexes.length;
  let sum = 0;
  for (let idx = 0; idx < vertexes.length; idx++) {
    const vertex = vertexes[idx];
    const last = vertexes[(idx || len) - 1];
    const next = vertexes[(idx === len - 1 ? -1 : idx) + 1];
    sum += 0.5 * vertex.y * (next.x - last.x);
  }
  return Math.abs(sum);
}
