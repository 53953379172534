export default function(array, value) {
  let low = 0;
  let high = array.length - 1;

  let result;
  while (low <= high) {
    let mid = low + (((high - low) / 2) | 0);
    result = compareReals(array[mid], value);
    if (result < 0) {
      low = mid + 1;
    } else if (result > 0) {
      high = mid - 1;
    } else {
      return mid;
    }
  }
}

function compareReals(a, b) {
  let eq = realsApproximatelyEqual(a, b);
  if (eq === true) return 0;

  if (a < b) {
    return -1;
  }
  return 1;
}

function realsApproximatelyEqual(a, b, eps = 0.00001) {
  return Math.abs(a - b) < eps;
}
