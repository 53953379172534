import { createContext } from 'react';
import _ from 'lodash';

class TokenError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'TokenError';
    this.statusCode = statusCode;
  }
}

export class GoogleHealthcareAPI {
  constructor({ token, tokenExpiredAt }, config) {
    this.config = config;
    this.token = token;
    this.tokenExpiredAt = tokenExpiredAt;
    this.project = config.dicomWebProxy.projectId;
    this.urlBase = config.dicomWebProxy.endpoint['default'];
    this.topic = 'dicomweb';
  }

  getRegions() {
    return [
      'asia-east1',
      'asia-northeast1',
      'us-west1',
      'us-central1',
      'us-east1',
      'europe-west4',
    ];
  }

  getTopics() {
    return ['dicomweb', 'dicomweb-backup-nosync'];
  }

  getUrlBase(location) {
    if (location) {
      return this.config.dicomWebProxy.endpoint[location] || this.urlBase;
    }
    return this.urlBase;
  }

  async fetchData(dicomwebUrl, props) {
    const { token, tokenExpiredAt } = this;
    if (tokenExpiredAt && tokenExpiredAt < new Date().getTime()) {
      throw new TokenError(401, 'Token Expired');
    }
    const res = await fetch(dicomwebUrl, {
      ...props,
      headers: {
        ...props.headers,
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status >= 400 && res.status <= 500) {
      if (res.status === 401) {
        throw new TokenError(401, 'Unauthorized');
      } else if (res.status === 403) {
        throw new TokenError(403, 'Forbidden');
      } else {
        throw new Error(res.statusText);
      }
    }
    const contentType = res.headers.get('Content-Type');
    if (contentType.includes('json')) {
      return await res.json();
    } else {
      return await res.text();
    }
  }

  async createDataset({ location, dataset }) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets?datasetId=${dataset}`;
    return await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      method: 'POST',
      mode: 'cors',
    });
  }

  async createDicomstore({ location, dataset, store, topic }) {
    const urlBase = this.getUrlBase(location, dataset);
    const { project, topic: defaultTopic } = this;
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores?dicomStoreId=${store}`;
    return await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      body: JSON.stringify({
        notificationConfig: {
          pubsubTopic: `projects/${project}/topics/${topic || defaultTopic}`,
        },
      }),
      method: 'POST',
      mode: 'cors',
    });
  }

  async deleteDicomstore({ location, dataset, store }) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${store}`;
    return await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      method: 'DELETE',
      mode: 'cors',
    });
  }

  async importDicomObjects({ location, dataset, store, filePath }) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${store}:import`;
    const uri = `gs://vysioneer-dicom-pool/contouring_project_dicom/${filePath}`;
    return await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      body: JSON.stringify({ gcsSource: { uri } }),
      method: 'POST',
      mode: 'cors',
    });
  }

  async getDatasetOperation({ location, dataset, operation }) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/operations/${operation}`;
    return await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      method: 'GET',
      mode: 'cors',
    });
  }

  async getStudies({ location, dataset, datastore }, naturalized = true) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${datastore}/dicomWeb/studies?limit=1000&offset=0&fuzzymatching=false&includefield=all`;
    const studies = await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      method: 'GET',
      mode: 'cors',
    });
    if (!studies) return [];
    if (!naturalized) return studies;
    const naturalizedStudies = studies.map(study => ({
      StudyInstanceUID: _.get(study, ['0020000D', 'Value', 0], ''),
      SpecificCharacterSet: _.get(study, ['00080005', 'Value', 0], ''),
      StudyDate: _.get(study, ['00080020', 'Value', 0], ''),
      StudyTime: _.get(study, ['00080030', 'Value', 0], '').split('.')[0],
      AccessionNumber: _.get(study, ['00080050', 'Value', 0], ''),
      StudyDescription: _.get(study, ['00081030', 'Value', 0], ''),
      PatientName: _.get(study, ['00100010', 'Value', 0, 'Alphabetic'], ''),
      PatientID: _.get(study, ['00100020', 'Value', 0], ''),
      PatientBirthDate: _.get(study, ['00100030', 'Value', 0], ''),
      PatientSex: _.get(study, ['00100040', 'Value', 0], ''),
      StudyID: _.get(study, ['00200010', 'Value', 0], ''),
    }));
    return naturalizedStudies;
  }

  async deleteStudy({ location, dataset, datastore, studyInstanceUID }) {
    const urlBase = this.getUrlBase(location, dataset);
    const parent = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${datastore}`;
    const dicomWebPath = `/dicomWeb/studies/${studyInstanceUID}`;
    await this.fetchData(parent + dicomWebPath, {
      headers: { accept: 'application/dicom+json' },
      method: 'DELETE',
      mode: 'cors',
    });
    return true;
  }

  async getSeries({ location, dataset, datastore }, naturalized = true) {
    const urlBase = this.getUrlBase(location, dataset);
    const dicomwebUrl = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${datastore}/dicomWeb/series?limit=3000&offset=0&fuzzymatching=false&includefield=all`;
    const series = await this.fetchData(dicomwebUrl, {
      headers: { accept: 'application/dicom+json' },
      method: 'GET',
      mode: 'cors',
    });
    if (!series) return [];
    if (!naturalized) return series;
    const naturalizedSeries = series.map(s => ({
      StudyDescription: _.get(s, ['00081030', 'Value', 0], ''),
      SeriesDescription: _.get(s, ['0008103E', 'Value', 0], ''),
      StudyInstanceUID: _.get(s, ['0020000D', 'Value', 0], ''),
      SeriesInstanceUID: _.get(s, ['0020000E', 'Value', 0], ''),
      SpecificCharacterSet: _.get(s, ['00080005', 'Value', 0], ''),
      StudyDate: _.get(s, ['00080020', 'Value', 0], ''),
      SeriesDate: _.get(s, ['00080021', 'Value', 0], ''),
      StudyTime: _.get(s, ['00080030', 'Value', 0], '').split('.')[0],
      SeriesTime: _.get(s, ['00080031', 'Value', 0], '').split('.')[0],
      TimezoneOffsetFromUTC: _.get(s, ['00080201', 'Value', 0], ''),
      AccessionNumber: _.get(s, ['00080050', 'Value', 0], ''),
      Modality: _.get(s, ['00080060', 'Value', 0], ''),
      PatientName: _.get(s, ['00100010', 'Value', 0, 'Alphabetic'], ''),
      PatientID: _.get(s, ['00100020', 'Value', 0], ''),
      PatientBirthDate: _.get(s, ['00100030', 'Value', 0], ''),
      PatientSex: _.get(s, ['00100040', 'Value', 0], ''),
      StudyID: _.get(s, ['00200010', 'Value', 0], ''),
      SeriesNumber: _.get(s, ['00200011', 'Value', 0], ''),
    }));
    return naturalizedSeries;
  }

  async deleteSeries({
    location,
    dataset,
    datastore,
    studyInstanceUID,
    seriesInstanceUID,
  }) {
    const urlBase = this.getUrlBase(location, dataset);
    const parent = `${urlBase}/locations/${location}/datasets/${dataset}/dicomStores/${datastore}`;
    const dicomWebPath = `/dicomWeb/studies/${studyInstanceUID}/series/${seriesInstanceUID}`;
    await this.fetchData(parent + dicomWebPath, {
      headers: { accept: 'application/dicom+json' },
      method: 'DELETE',
      mode: 'cors',
    });
    return true;
  }

  getToken() {
    return this.token;
  }

  setToken({ token, tokenExpiredAt }) {
    this.token = token;
    this.tokenExpiredAt = tokenExpiredAt;
  }
}

export const APIContext = createContext();
