import polygonClipping from 'polygon-clipping';

export function booleanOperation(method, poly1, poly2) {
  const methodMapping = {
    union: 'union',
    intersect: 'intersection',
    subtract: 'difference',
    exclude: 'xor',
  };
  return polygonClipping[methodMapping[method]](poly1, poly2);
}
