import polygonSmooth, { downsize } from './polygonSmooth';
import { math } from '../../modules/dicom-measurement/src';
const { makeHull } = math;

/**
 * @param {Int16Array} image.pixelData
 * @param {Number} image.width
 * @returns {Array} vertices of the concave hull
 */
export default function findConcaveHull(image) {
  const { pixelData, width } = image;
  const pointCloud = [];
  for (let i = 0; i < pixelData.length; i++) {
    if (pixelData[i] === 1) {
      pointCloud.push({
        x: (i % width) + 0.5,
        y: Math.floor(i / width) + 0.5,
      });
    }
  }

  /** make concave hull */
  const hull = [];
  if (pointCloud.length > 3) {
    hull.push(...makeHull(pointCloud));
  }

  /** post processing */
  let smoothened = [...hull];
  if (smoothened.length >= 10) {
    smoothened = downsize(smoothened, 3);
  }
  smoothened = polygonSmooth(smoothened);
  return smoothened;
}
