import on_premise from './on_premise';
import cloud_prod from './cloud_prod';
import cloud_dev from './cloud_dev';

export default function config() {
  const mode = process.env.VIEWER_APP_CONFIG;
  switch (mode) {
    case 'cloud-prod':
      return cloud_prod();
    case 'cloud-dev':
      return cloud_dev();
    case 'on-premise':
    default:
      return on_premise();
  }
}
