import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve a list of series using a QIDO call
 * @param server
 * @param StudyInstanceUID
 * @throws ECONNREFUSED
 * @returns {Array} The list of series object
 */
export default async function searchForSeries(server, StudyInstanceUID) {
  const dicomWebClient = getDICOMwebClient(server.qidoRoot, server);
  const requiredTags = ['00080021', '00080031', '00080201'];
  const includefield = requiredTags.join(',');
  const series = await dicomWebClient.searchForSeries({
    studyInstanceUID: StudyInstanceUID,
    queryParams: { includefield },
  });
  return series.map(s => {
    return {
      ...dcmjs.data.DicomMetaDictionary.naturalizeDataset(s),
      StudyInstanceUID,
    };
  });
}
