import React from 'react';
import PropTypes from 'prop-types';

export default function EmptySquare({ width, height }) {
  return <div style={{ width: `${width}px`, height: `${height}px` }} />;
}
EmptySquare.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};
