import styled, { css } from 'styled-components';
import theme from './theme';

const IconButton = styled.button`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  margin: 0;
  padding: 3px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.03em;
  text-decoration: none;
  outline: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: ${theme.primary.outlined.color};
  background-color: ${theme.primary.outlined.background};
  cursor: pointer;

  &:hover {
    background-color: ${theme.primary.outlined.hover};
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: transparent;
    cursor: not-allowed;
    :hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }

  ${props =>
    props.color &&
    props.variant &&
    css`
      color: ${theme[props.color][props.variant].color};
      background-color: ${theme[props.color][props.variant].background};
      :hover {
        background-color: ${theme[props.color][props.variant].hover};
      }
    `}
`;

export default IconButton;
