import scale from './scale.js';
import rotate from './rotate.js';
import getDistance from './getDistance.js';
import getDistance3D from './getDistance3D.js';
import getIntersectionPoint from './getIntersectionPoint.js';
import { round } from '../math/round.js';
import { makeHull } from '../math/convexHull.js';

export default function polygonAxis(vertices) {
  try {
    if (!vertices || vertices.length < 2) {
      throw new Error('Polygon does not exist');
    }
    const { value: longAxisValue, points: longAxisPoints } = polygonLongAxis(
      makeHull(vertices)
    );
    if (longAxisPoints.length < 2) {
      throw new Error('Long Axis does not exist');
    }
    const longAxisCenter = {
      x: (longAxisPoints[0].x + longAxisPoints[1].x) / 2,
      y: (longAxisPoints[0].y + longAxisPoints[1].y) / 2,
    };
    const longAxisInversePoints = [
      scale(longAxisCenter, rotate(longAxisCenter, longAxisPoints[0], 90), 2),
      scale(longAxisCenter, rotate(longAxisCenter, longAxisPoints[1], 90), 2),
    ];
    const longAxisVector = {
      x: longAxisPoints[0].x - longAxisPoints[1].x,
      y: longAxisPoints[0].y - longAxisPoints[1].y,
    };

    let shortAxisValue = 0;
    let shortAxisPoints = [];
    const lowerbound = 100;
    const n = Math.max(lowerbound, longAxisValue);
    for (let i = 0; i < n; i++) {
      const delta = (i - n / 2 + 1 / 2) / n;
      const sweepLine = [
        {
          x: longAxisInversePoints[0].x + longAxisVector.x * delta,
          y: longAxisInversePoints[0].y + longAxisVector.y * delta,
        },
        {
          x: longAxisInversePoints[1].x + longAxisVector.x * delta,
          y: longAxisInversePoints[1].y + longAxisVector.y * delta,
        },
      ];

      const intersects = [];
      [...vertices, vertices[0]].forEach((_, idx, _vertices) => {
        if (idx === 0) {
          return;
        }
        const intersect = getIntersectionPoint(
          sweepLine[0],
          sweepLine[1],
          _vertices[idx - 1],
          _vertices[idx]
        );
        if (!intersect) return;
        intersects.push(intersect);
      });
      if (intersects.length > 1) {
        intersects.sort((a, b) => a.x - b.x);
        const distance = getDistance(
          intersects[0],
          intersects[intersects.length - 1]
        );
        if (distance > shortAxisValue) {
          shortAxisValue = distance;
          shortAxisPoints = [intersects[0], intersects[intersects.length - 1]];
        }
      }
    }
    return {
      longAxis: { points: longAxisPoints, value: round(longAxisValue, -4) },
      shortAxis: { points: shortAxisPoints, value: round(shortAxisValue, -4) },
    };
  } catch (error) {
    return {
      longAxis: { points: [], value: 0 },
      shortAxis: { points: [], value: 0 },
    };
  }
}

export function polygonLongAxis(vertices) {
  let longAxisValue = 0;
  let longAxisPoints = [];
  for (let i = 0; i < vertices.length; i++) {
    for (let j = 0; j < vertices.length; j++) {
      const distance = getDistance(vertices[i], vertices[j]);
      if (distance > longAxisValue) {
        longAxisValue = distance;
        longAxisPoints = [vertices[i], vertices[j]];
      }
    }
  }

  return { points: longAxisPoints, value: longAxisValue };
}

export function polygonLongAxis3D(_points) {
  const points = downSample(_points, 1000);
  let longAxisValue = 0;
  let longAxisPoints = [];
  for (let i = 0; i < points.length; i++) {
    for (let j = 0; j < points.length; j++) {
      const distance = getDistance3D(points[i], points[j]);
      if (distance > longAxisValue) {
        longAxisValue = distance;
        longAxisPoints = [points[i], points[j]];
      }
    }
  }
  return { points: longAxisPoints, value: longAxisValue };
}

function downSample(points, size) {
  const ratio = Math.floor(points.length / size);
  return points.length > size
    ? points
        .map((p, index) => {
          return index % ratio === 0 ? p : null;
        })
        .filter(p => p)
    : points;
}
