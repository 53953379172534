import getDistance3D from './getDistance3D';

/**
 * Given the orientation, determine the coordinates of the z axis
 * i.e. the z axis per the DICOM xray or other device relative to the
 * patient. Also, determine the average spacing along that axis, and
 * return the index (0,1,2) of the z axis.
 */
export default function(orientation, metaData) {
  const ippArray = [];
  const xyzIndex = determineOrientationIndex(orientation);
  for (var value of metaData.values()) {
    let ipp = value.imagePositionPatient;
    if (xyzIndex === 0) {
      ippArray.push(ipp.x || ipp[0]);
    } else if (xyzIndex === 1) {
      ippArray.push(ipp.y || ipp[1]);
    } else {
      ippArray.push(ipp.z || ipp[2]);
    }
  }
  ippArray.sort(function(a, b) {
    return a - b;
  });

  const meanSpacing = getSampleSpacing(metaData);

  /** Find origin from positions */
  const originPositionAlongAcqAxis = ippArray[0];
  const originImagePlane = Array.from(metaData.values()).find(meta => {
    return meta.imagePositionPatient[xyzIndex] === originPositionAlongAcqAxis;
  });

  var obj = {
    spacing: meanSpacing,
    positions: ippArray,
    origin: originImagePlane.imagePositionPatient,
    xyzIndex,
  };
  return obj;
}

/**
 * given the text orientation, determine the index (0,1,2) of the z axis
 * @param {String} orientation
 * @returns {Number} index
 */
function determineOrientationIndex(orientation) {
  var o = orientation;
  var index;
  switch (o) {
    case 'A':
    case 'P':
      index = 1;
      break;
    case 'L':
    case 'R':
      index = 0;
      break;
    case 'S':
    case 'I':
      index = 2;
      break;
    default:
      console.assert(false, ' OBLIQUE NOT SUPPORTED');
      break;
  }
  return index;
}

function getSampleSpacing(metaData) {
  const ippArray = [];
  for (var value of metaData.values()) {
    let ipp = value.imagePositionPatient;
    if (ipp.x && ipp.y && ipp.z) {
      ippArray.push(ipp);
    } else {
      ippArray.push({ x: ipp[0], y: ipp[1], z: ipp[2] });
    }
  }
  let sum = 0;
  for (let i = 0; i < ippArray.length - 1; i++) {
    sum = sum + getDistance3D(ippArray[i], ippArray[i + 1]);
  }
  return sum / (ippArray.length - 1);
}
