/**
 * @param {Int16Array} image.pixelData
 * @param {Number} image.width
 * @param {Number} image.height
 * @param {Array} kernal
 * @returns {Object} processed image
 */

export default function convolution(image, kernal) {
  const { pixelData, width, height } = image;
  const outputData = new Int16Array(pixelData.length);

  /** kernal 3x3, 5x5, 7x7... */
  const side = Math.round(Math.sqrt(kernal.length));
  const halfSide = Math.floor(side / 2);

  /** process */
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      let sum = 0;
      for (let ky = 0; ky < side; ky++) {
        for (let kx = 0; kx < side; kx++) {
          const currentKx = x + kx - halfSide;
          const currentKy = y + ky - halfSide;
          if (
            currentKx >= 0 &&
            currentKx < width &&
            currentKy >= 0 &&
            currentKy < height
          ) {
            const offset = currentKx + currentKy * width;
            const weight = kernal[kx + ky * side];
            sum += pixelData[offset] * weight;
          }
        }
      }
      outputData[x + width * y] = sum;
    }
  }

  return { ...image, pixelData: outputData };
}
