import determineOrientation from './determineOrientation.js';
import computeZAxis from './computeZAxis.js';
import cross from '../math/cross.js';

/**
 * @param {Object} metaDataMap
 * @returns {Object} {dimensions, orientation, spacing, zAxis}
 */
export default function calculateDimensions(metaDataMap) {
  const imagePlaneModule = metaDataMap.values().next().value;
  const isMultiComponent = imagePlaneModule.numberOfComponents > 1;
  if (isMultiComponent) {
    throw new Error('Multi component image not supported by this plugin.');
  }

  const { rowCosines, columnCosines } = imagePlaneModule;
  const zedCosines = cross(columnCosines, rowCosines);
  const orientation = determineOrientation(zedCosines);
  const zAxis = computeZAxis(orientation, metaDataMap);

  const xVoxels = imagePlaneModule.columns;
  const yVoxels = imagePlaneModule.rows;
  const zVoxels = metaDataMap.size;
  const xSpacing = imagePlaneModule.columnPixelSpacing;
  const ySpacing = imagePlaneModule.rowPixelSpacing;
  const zSpacing = zAxis.spacing;

  return {
    dimensions: [xVoxels, yVoxels, zVoxels],
    spacing: [xSpacing, ySpacing, zSpacing],
    orientation,
    zAxis,
  };
}
