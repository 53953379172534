/**
 * @param {Date} start
 * @param {Date} end
 */
function getNumberOfDays(start, end) {
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = end.getTime() - start.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;
}

export default getNumberOfDays;
