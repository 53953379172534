import getROIMeasurement from './getROIMeasurement';

/**
 * @param {Object[]} instances
 * @param {Object[]} ROIContours
 * @param {Object[]} ROIContours[].data
 */
function getROIMeasurements(instances, ROIContours) {
  instances.sort((a, b) => Number(a.InstanceNumber) - Number(b.InstanceNumber));
  const ROIMeasurements = ROIContours.map(roi =>
    getROIMeasurement(instances, roi)
  );
  return ROIMeasurements;
}

export default getROIMeasurements;
