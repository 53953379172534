import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal, EVENTS } from 'cornerstone-tools';
import _ from 'lodash';

import TOOL_NAMES from './constants/toolNames';
import { refreshViewport } from './commands';

const BaseTool = importInternal('base/BaseTool');
const getNewContext = importInternal('drawing/getNewContext');

/**
 * @extends cornerstoneTools.BaseTool
 */
export default class PointTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      supportedInteractionTypes: ['Mouse'],
      mixins: ['renderBrushMixin'],
      configuration: {},
      name: TOOL_NAMES.POINT_TOOL,
    };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps);

    this._module = cornerstoneTools.getModule('rtstruct-edit');
    this._module.addEventListener('ROISelected', () => refreshViewport());
    this._module.addEventListener('Highlighted', () => refreshViewport());
    this._isDrawing = false;
    this._isPreviewing = false;
    this._currentImageId = '';
    this._onDoubleClick = this._onDoubleClick.bind(this);
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const painter = this._getPainter(eventData.element);
    const context = getNewContext(eventData.canvasContext.canvas);
    if (!painter || painter._mode !== 'point') return;
    if (!this._isDrawing || !this._isPreviewing) return;
    if (painter.imageId !== this._currentImageId) painter.clear();
    painter.cursor(evt, context, this._cursorCanvasPosition, this._isDrawing);
  }

  preMouseDownCallback(evt) {
    const { element } = evt.detail;
    this._startListeningForMouseDoubleClick(element);

    let painter = this._getPainter(element);
    if (painter.imageId !== this._currentImageId) {
      this._isDrawing = false;
      this._isPreviewing = false;
    }

    if (!this._isDrawing) {
      if (process.env.READONLY) return;
      const isEditable = this._module.getters.isEditable();
      if (!isEditable) return;

      const selectedROINumber = this._module.getters.selectedROINumber();
      if (!_.isNumber(selectedROINumber)) return;

      const eventData = evt.detail;
      const { element } = eventData;
      painter = this._switchPainterMode('point', element);
      if (!painter) return;

      const uid = this._module.getters.selectedStructureSetUID();
      if (uid !== painter._structureSetSeriesInstanceUid) return;

      this._isDrawing = true;
      this._isPreviewing = true;
      this._currentImageId = painter.imageId;
    }
    painter.update(evt);
    refreshViewport();
  }

  disabledCallback(element) {
    const painters = this._getPainters(element);
    for(const p of painters){
       if (p?._mode === 'point') p.clear();
    }
    if (this._isDrawing && !this._isPreviewing) {
      const imageId = cornerstone.getEnabledElement(element).image.imageId;
      this._module.setters.popPainter(imageId);
    }
    this._isDrawing = false;
    this._isPreviewing = false;
    refreshViewport();
  }

  _switchPainterMode(painterMode, element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    const painter = this._module.getters.peekPainter(imageId);
    const newPainter = this._module.setters.createPainter(
      painterMode,
      imageId,
      painter._structureSetSeriesInstanceUid
    );
    return newPainter;
  }

  _getPainter(element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    return this._module.getters.peekPainter(imageId);
  }

  _getPainters(element) {
    const toolState = cornerstoneTools.getToolState(element, 'stack');
    if (!toolState) return [];
    const { imageIds } = toolState.data[0];
    const painters = imageIds.map(id => this._module.getters.peekPainter(id));
    return painters;
  }

  async _onDoubleClick(evt) {
    const eventData = evt.detail;
    const { element } = eventData;
    const painter = this._getPainter(element);
    await painter.commit(evt);
    refreshViewport();
  }

  _startListeningForMouseDoubleClick(element) {
    this._stopListeningForMouseDoubleClick(element);
    try {
      element.addEventListener(EVENTS.MOUSE_DOUBLE_CLICK, this._onDoubleClick);
    } catch (error) {
      this._stopListeningForMouseDoubleClick(element);
      throw error;
    }
  }

  _stopListeningForMouseDoubleClick(element) {
    element.removeEventListener(EVENTS.MOUSE_DOUBLE_CLICK, this._onDoubleClick);
  }
}
