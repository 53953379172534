import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

/**
 * data: [
 *  {
 *    name: '20200101', sumDiameter: 5, sumVolume: 60,
 *    comparedSumDiameter: 3, comparedSumVolume: 20,
 *  },
 *  {
 *    name: '20200201', sumDiameter: 10, sumVolume: 200,
 *    comparedSumDiameter: 6, comparedSumVolume: 60,
 *  },
 * ]
 */
export const LinePlot = ({ data, xAxisKey, yAxisKey, ySumAxisKey }) => {
  data.forEach(d => {
    d.name = d.date;
  });
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey={xAxisKey} />
          <YAxis>
            <Label
              style={{ fill: 'lightgrey' }}
              value={getLabel(ySumAxisKey)}
              offset={20}
              position="insideTopLeft"
            />
          </YAxis>
          <Legend verticalAlign="top" height={36} />
          <Tooltip
            itemStyle={{ color: 'grey' }}
            contentStyle={{ color: 'grey' }}
          />
          <Line
            name="Lesions"
            type="monotone"
            dataKey={ySumAxisKey}
            stroke="var(--active-color)"
            activeDot={{ r: 8 }}
          />
          <Line
            name="Selected Lesion"
            type="monotone"
            dataKey={yAxisKey}
            stroke="var(--default-color)"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
LinePlot.propTypes = {
  data: PropTypes.any,
  xAxisKey: PropTypes.string,
  yAxisKey: PropTypes.string,
  ySumAxisKey: PropTypes.string,
};

const getLabel = dataKey => {
  const labelMapping = {
    sumVolume: 'mm^3',
    sumDiameter: 'mm',
  };
  return labelMapping[dataKey];
};

/**
 * data: [
 *  { name: '20200101', 0: 20, 1: 30, 2: 40 }
 *  { name: '20200201', 0: 10, 1: 20, 2: 10 }
 * ]
 */
export const AllLesionsLinePlot = ({
  data,
  names,
  legends,
  targets,
  others,
  brains,
  livers,
  lungs,
  lymphnodes,
  xAxisKey,
  ySumAxisKey,
}) => {
  const [idx, setIdx] = useState('');

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <Legend
            layout="horizontal"
            verticalAlign="top"
            align="center"
            content={<CustomLegend legends={legends} />}
          />
          <Tooltip content={<CustomTooltip idx={idx} name={names[idx]} />} />
          <XAxis dataKey={xAxisKey} />
          <YAxis>
            <Label
              style={{ fill: 'lightgrey' }}
              value={getLabel(ySumAxisKey)}
              offset={20}
              position="insideTopLeft"
            />
          </YAxis>
          {targets.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke="var(--active-color)"
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
          {others.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke={getStroke(a)}
              strokeWidth={getStrokeWidth(a)}
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
          {brains.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke={getStroke('brain')}
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
          {livers.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke={getStroke('liver')}
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
          {lungs.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke={getStroke('lung')}
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
          {lymphnodes.map(a => (
            <Line
              name={a}
              key={a}
              dataKey={a}
              type="monotone"
              stroke={getStroke('lymph-node')}
              activeDot={{ r: 8, onMouseOver: () => setIdx(a) }}
              isAnimationActive={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
AllLesionsLinePlot.propTypes = {
  data: PropTypes.any,
  names: PropTypes.object,
  legends: PropTypes.array,
  targets: PropTypes.array,
  others: PropTypes.array,
  brains: PropTypes.array,
  livers: PropTypes.array,
  lungs: PropTypes.array,
  lymphnodes: PropTypes.array,
  xAxisKey: PropTypes.string,
  ySumAxisKey: PropTypes.string,
};

const getStroke = a => {
  const strokeColorMap = {
    brain: '#f6d55c',
    liver: '#ed553b',
    lung: '#3caea3',
    'lymph-node': '#e4f1ee',
    default: '#bfcbed',
  };
  return strokeColorMap[a] || strokeColorMap['default'];
};

const getStrokeWidth = a => {
  if (['brain', 'liver', 'lung', 'lymph-node'].includes(a)) return 2;
  return 1;
};

const CustomTooltip = ({ active, payload, label, idx, name }) => {
  if (!(active && payload && payload.length)) return null;
  const data = payload[0].payload;
  const value = data[idx];
  const tooltipStyle = {
    color: 'grey',
    background: '#FFFFFF',
    padding: '10px',
    boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)',
  };
  const pStyle = { margin: '3px 0' };
  return (
    <div style={tooltipStyle}>
      <p style={pStyle}>
        <strong>{label}</strong>
      </p>
      <p style={pStyle}>
        {name}: {value}
      </p>
    </div>
  );
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.any,
  label: PropTypes.string,
  idx: PropTypes.any,
  name: PropTypes.string,
};

const CustomLegend = ({ legends }) => {
  const _legends = legends || [
    ['Target lesions', 'var(--active-color)'],
    ['Others', 'var(--default-color)'],
  ];
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {_legends.map(([text, color]) => (
        <LegendItem key={text} text={text} backgroundColor={color} />
      ))}
    </div>
  );
};
CustomLegend.propTypes = {
  legends: PropTypes.array,
};

const LegendItem = ({ backgroundColor, text }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          margin: '10px',
          width: 10,
          height: 10,
          backgroundColor: backgroundColor,
        }}
      />
      <p variant="body1">{text}</p>
    </div>
  );
};
LegendItem.propTypes = {
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
};
