import React from 'react';
import PropTypes from 'prop-types';
import './TableList.styl';

export function TableList(props) {
  const getHeader = () => {
    if (props.customHeader) {
      return props.customHeader;
    } else {
      return (
        <>
          <div className="tableListHeaderTitle">{props.headerTitle}</div>
          <div className="numberOfItems">{props.children.length}</div>
        </>
      );
    }
  };
  return (
    <div className="tableList">
      {!props.headless && <div className="tableListHeader">{getHeader()}</div>}
      <div className="tableListContent">{props.children}</div>
    </div>
  );
}
TableList.propTypes = {
  customHeader: PropTypes.node,
  defaultItems: PropTypes.object,
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string,
  headless: PropTypes.bool,
};
TableList.defaultProps = {
  headless: false,
};
