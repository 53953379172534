import styled from 'styled-components';

const P = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.2;
`;

export default P;
