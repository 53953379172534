import BasePainterTool from './BasePainterTool';
import TOOL_NAMES from './constants/toolNames';

export default class ExpansionTool extends BasePainterTool {
  constructor(props = {}) {
    const defaultProps = { name: TOOL_NAMES.EXPANSION_TOOL };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps, 'expansion');
  }
}
