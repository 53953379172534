import {
  TRACKING_TYPES,
  MEASUREMENT_TECHNIQUE,
  OVERALL_RESPONSE,
} from '../constants/guideline';
import {
  getTimepointStats,
  getTargetResponses,
  getNonTargetResponses,
  getNewLesionResponses,
} from '../models/responseAssessments';
import { getTimepoints, getAssessedTimepoints } from '../models/getTimepoints';
import {
  getTimeToProgression,
  getTimeToResponse,
  getDurationOfResponse,
  getBestOverallResponse,
  getBestPercentageChange,
} from '../models/clinicalEndpoints';

export function getEndpoints(
  axialImageSets,
  ROIContours,
  criteria,
  assessments
) {
  const timepoints = getTimepoints(axialImageSets, ROIContours);

  /** target lesion responses */
  const targetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.TARGET
  );
  const targetStats = getTimepointStats(
    timepoints,
    targetROIContours,
    [],
    criteria,
    assessments
  );
  const targetResponses = getTargetResponses(targetStats, criteria);

  /** non-target lesion responses */
  const nonTargetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NONTARGET
  );
  const nonTargetStats = getTimepointStats(
    timepoints,
    nonTargetROIContours,
    [],
    criteria,
    assessments
  );
  const nonTargetResponses = getNonTargetResponses(nonTargetStats, criteria);

  /** new lesion responses */
  const newLesionROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NEW
  );
  const newLesionResponses = getNewLesionResponses(
    timepoints,
    newLesionROIContours,
    criteria
  );

  /** overall responses */
  const assessedTimepoints = getAssessedTimepoints(
    timepoints,
    targetResponses,
    nonTargetResponses,
    newLesionResponses,
    assessments
  );

  /** endpoints */
  const labelMap = {
    [OVERALL_RESPONSE.COMPLETE_RESPONSE]: 'CR',
    [OVERALL_RESPONSE.PARTIAL_RESPONSE]: 'PR',
    [OVERALL_RESPONSE.STABLE_DISEASE]: 'SD',
    [OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE]: 'SD',
    [OVERALL_RESPONSE.PROGRESSIVE_DISEASE]: 'PD',
    [OVERALL_RESPONSE.NOT_EVALUABLE]: 'NE',
    [OVERALL_RESPONSE.NONE]: 'N/A',
  };
  const keyMap = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: 'sumDiameter',
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: 'sumVolume',
  };
  const reports = assessedTimepoints.map((timepoint, index) => ({
    date: timepoint.SeriesDate,
    changeFromBaseline:
      targetStats[index].fromBaseline[keyMap[criteria.measurementTechnique]],
    result: timepoint.overallResponse.result,
  }));
  const timeToProgression = getTimeToProgression(reports);
  const timeToResponse = getTimeToResponse(reports);
  const durationOfResponse = getDurationOfResponse(reports);
  const bestOverallResponse = getBestOverallResponse(
    reports,
    criteria.completeResponseConfirmation === 'REQUIRED',
    criteria.minStableDiseaseDuration || 0
  );
  const bestPercentageChange = getBestPercentageChange(reports);

  return {
    changeFromBaseline: bestPercentageChange.changeFromBaseline,
    bestOverallResponse: labelMap[bestOverallResponse.result],
    timeToProgression,
    timeToResponse,
    durationOfResponse,
  };
}
