import getCommandsModule from './modules/commandsModule/getCommandsModule';
import toolbarModule from './modules/toolbarModule/toolbarModule';

export default {
  id: 'dicom-tag-browser',
  getCommandsModule({ servicesManager }) {
    return getCommandsModule(servicesManager);
  },
  getToolbarModule() {
    return toolbarModule;
  },
};
