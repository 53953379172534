import React from 'react';
import MuiTableCell from '@material-ui/core/TableCell';

const TableCell = props => {
  const { children, header } = props;
  const style = {
    padding: '5px',
    borderBottom: 'none',
  };
  if (header === 1) {
    style.color = 'var(--default-color)';
    style.backgroundColor = '#151a1f';
  }
  return (
    <MuiTableCell {...props} style={style}>
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
