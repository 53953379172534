import convolution from '../operations/convolution';

/**
 * @param {Int16Array} image.pixelData
 * @param {Number} image.width
 * @param {Number} image.height
 * @returns {Object} processed image
 */
const bigGaussianBlur = function(image) {
  const operator = [
    2, 4, 5, 4, 2,
    4, 9, 12, 9, 4,
    5, 12, 15, 12, 5,
    4, 9, 12, 9, 4,
    2, 4, 5, 4, 2,
  ];
  const divider = operator.reduce((acc, cur) => acc + cur);
  const kernal = operator.map(i => i / divider);

  return convolution(image, kernal);
};

export default bigGaussianBlur;
