import concaveman from 'concaveman';

function makeHull(vertices) {
  const points = vertices.map(v => [v.x, v.y]);
  const polygon = concaveman(points);
  const hull = polygon.map(p => ({ x: p[0], y: p[1] }));
  return hull;
}

export { makeHull };
