import styled, { css } from 'styled-components';

const Container = styled.div`
  overflow-y: scroll;
  height: 100%;
  margin: 0 10px 0 5px;
  ${props =>
    props.mode === 'dark' &&
    css`
      ::-webkit-scrollbar {
        width: 6px;
        background-color: #151a1f;
      }
      ::-webkit-scrollbar-thumb {
        background-color: var(--ui-gray-light);
        border-radius: 5px;
      }
    `}
`;

export default Container;
