import React, { useState } from 'react';
import cornerstoneTools from 'cornerstone-tools';

import { Range, Input, P } from '@platform/ui';
import * as commands from '../../../tools/commands';

export const RegionGrowingToolSettings = () => {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const [radius, setRadius] = useState(
    editModule.getters.painterConfig('region-growing').radius
  );
  const [regionRadius, setRegionRadius] = useState(
    editModule.getters.painterConfig('region-growing').regionRadius
  );
  const [sigma, setSigma] = useState(
    editModule.getters.painterConfig('region-growing').sigma
  );
  const unit = 0.5; // 0.5mm per step
  const maxRadius = 5; // 5 cm
  const minRadius = 0.5;
  const maxSigma = 5;
  const minSigma = 0.5;

  function onPainterConfigChange(key, value) {
    switch (key) {
      case 'radius':
        setRadius(value);
        editModule.setters.painterConfig('region-growing', {
          radius: value,
          regionRadius,
          sigma,
        });
        break;
      case 'regionRadius':
        setRegionRadius(value);
        editModule.setters.painterConfig('region-growing', {
          radius,
          regionRadius: value,
          sigma,
        });
        break;
      case 'sigma':
        setSigma(value);
        editModule.setters.painterConfig('region-growing', {
          radius,
          regionRadius,
          sigma: value,
        });
        break;
      default:
    }
    commands.refreshViewport();
  }

  return (
    <div>
      <div
        className="range"
        style={{ color: 'var(--default-color)', margin: '20px 10px 0 0' }}
      >
        <div htmlFor="range" style={{ margin: '5px 0 15px' }}>
          Smart Brush Radius ({radius} mm)
        </div>
        <Range
          step={1}
          min={minRadius / unit}
          max={maxRadius / unit}
          value={radius / unit}
          onChange={evt => {
            const _radius = parseInt(evt.target.value, 10) * unit;
            onPainterConfigChange('radius', _radius);
            evt.target.blur();
          }}
        />
      </div>
      <div
        className="range"
        style={{ color: 'var(--default-color)', margin: '20px 0' }}
      >
        <div htmlFor="range" style={{ margin: '5px 0 15px' }}>
          Smart Brush Sigma ({sigma})
        </div>
        <Range
          step={1}
          min={minSigma / unit}
          max={maxSigma / unit}
          value={sigma / unit}
          onChange={evt => {
            const _sigma = parseInt(evt.target.value, 10) * unit;
            onPainterConfigChange('sigma', _sigma);
            evt.target.blur();
          }}
        />
      </div>
      <div style={{ margin: '5px 0' }}>
        <P style={{ color: 'var(--default-color)' }}>Smart Brush Region:</P>
        <Input
          value={regionRadius}
          placeholder={'Radius'}
          onChange={evt => {
            const _regionRadius = Number(evt.target.value) || null;
            onPainterConfigChange('regionRadius', _regionRadius);
          }}
          size="small"
          variant="outlined"
          fullWidth
        ></Input>
      </div>
    </div>
  );
};
