import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '@platform/viewer';
import { Firebase, utils } from '@platform/core';
import {
  Body,
  Container,
  Grid,
  Button,
  IconButton,
  Icon,
  Select,
  Input,
  H6,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@platform/ui';
import {
  TRACKING_TYPES,
  MEASUREMENT_TECHNIQUE,
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  OVERALL_RESPONSE,
  STEROIDS_USE,
  CLINICAL_STATUS,
} from '../constants/guideline';
import {
  getTimepointStats,
  getTargetResponses,
  getNonTargetResponses,
  getNewLesionResponses,
} from '../models/responseAssessments';
import { getTimepoints, getAssessedTimepoints } from '../models/getTimepoints';
import {
  getTimeToProgression,
  getTimeToResponse,
  getDurationOfResponse,
  getBestOverallResponse,
} from '../models/clinicalEndpoints';
import {
  getResponseAlerts,
  getAllNewLesionAlert,
} from '../models/responseAlerts';

const { round } = utils;
const responseMapping = {
  [OVERALL_RESPONSE.COMPLETE_RESPONSE]: 'Complete Response',
  [OVERALL_RESPONSE.PARTIAL_RESPONSE]: 'Partial Response',
  [OVERALL_RESPONSE.STABLE_DISEASE]: 'Stable Disease',
  [OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE]: 'Stable Disease',
  [OVERALL_RESPONSE.PROGRESSIVE_DISEASE]: 'Progressive Disease',
  [OVERALL_RESPONSE.NONE]: 'None',
};

const idMap = {
  complete: 'Complete',
  partial: 'Partial',
  stable: 'Stable',
  'non-progressive': 'Stable',
  'pseudo-progressive': 'Pseudo-Progressive',
  progressive: 'Progressive',
  'not-evaluable': 'Not Evaluable',
  'not-applicable': 'N/A',
  none: 'None',
  improved: 'Improved',
  worse: 'Worse',
  increased: 'Increased',
  decreased: 'Decreased',
  present: 'Present',
  absent: 'Absent',
  unconfirmed: 'Unconfirmed',
};

export const ResponseSection = ({
  imageSets,
  ROIContours,
  criteria,
  assessments,
  onAssessmentsChange,
  resetAssessments,
  onExportReportClick,
  onExportLogsClick,
  onResponseConfirm,
}) => {
  const timepoints = getTimepoints(imageSets, ROIContours);

  /** target lesion responses */
  const targetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.TARGET
  );
  const targetStats = getTimepointStats(
    timepoints,
    targetROIContours,
    [],
    criteria,
    assessments
  );
  const targetResponses = getTargetResponses(targetStats, criteria);

  /** non-target lesion responses */
  const nonTargetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NONTARGET
  );
  const nonTargetStats = getTimepointStats(
    timepoints,
    nonTargetROIContours,
    [],
    criteria,
    assessments
  );
  const nonTargetResponses = getNonTargetResponses(nonTargetStats, criteria);

  /** new lesion responses */
  const newLesionROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NEW
  );
  const newLesionResponses = getNewLesionResponses(
    timepoints,
    newLesionROIContours,
    criteria
  );

  /** overall responses */
  const assessedTimepoints = getAssessedTimepoints(
    timepoints,
    targetResponses,
    nonTargetResponses,
    newLesionResponses,
    assessments
  );

  /** endpoints */
  const keyMap = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: 'sumDiameter',
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: 'sumVolume',
  };
  const reports = assessedTimepoints.map((timepoint, index) => ({
    date: timepoint.SeriesDate,
    changeFromBaseline:
      targetStats[index].fromBaseline[keyMap[criteria.measurementTechnique]],
    result: timepoint.overallResponse.result,
  }));
  const timeToProgression = getTimeToProgression(reports);
  const timeToResponse = getTimeToResponse(reports);
  const durationOfResponse = getDurationOfResponse(reports);
  const bestOverallResponse = getBestOverallResponse(
    reports,
    criteria.completeResponseConfirmation === 'REQUIRED',
    criteria.minStableDiseaseDuration || 0
  );

  /** alerts */
  const responseAlerts = getResponseAlerts(assessedTimepoints);
  const existingAlert =
    criteria.overallResponseAlert === 'ENABLED' &&
    responseAlerts.find(alert => alert.isWarning);
  const existingNewLesionAlert =
    criteria.newLesionAlert === 'ENABLED' &&
    getAllNewLesionAlert(assessedTimepoints);
  const [isAlertDisplayed, setAlertDisplayed] = useState(true);

  /** password */
  const { appConfig: config } = useContext(AppContext);
  const isPasswordInputDisplayed =
    config.firebaseConfig.apiKey &&
    Firebase.getCurrentUser().providerData[0].providerId === 'password';

  return (
    <Body>
      <Container>
        <Table stickyHeader size="small">
          <TableHead>
            <HeadRow
              isAlertDisplayed={isAlertDisplayed}
              setAlertDisplayed={setAlertDisplayed}
            />
          </TableHead>
          <TableBody>
            {assessedTimepoints.map((timepoint, index) => {
              return (
                <Row
                  key={timepoint.SeriesInstanceUID + index}
                  index={index}
                  timepoint={timepoint}
                  onAssessmentsChange={onAssessmentsChange}
                  onResponseConfirm={onResponseConfirm}
                  existingAlert={existingAlert}
                  existingNewLesionAlert={existingNewLesionAlert}
                  isAlertDisplayed={isAlertDisplayed}
                  isPasswordInputDisplayed={isPasswordInputDisplayed}
                />
              );
            })}
          </TableBody>
        </Table>
      </Container>
      <Divider />
      {isAlertDisplayed && existingAlert?.isWarning && (
        <div style={{ display: 'flex' }}>
          <IconButton disabled color="warning">
            <Icon
              name="warning"
              fill="var(--ui-yellow)"
              widht="24px"
              height="24px"
            ></Icon>
          </IconButton>
          <H6 style={{ color: 'var(--ui-yellow)' }}>
            Warning: {existingAlert.message}
          </H6>
        </div>
      )}
      {isAlertDisplayed && existingNewLesionAlert?.isWarning && (
        <div style={{ display: 'flex' }}>
          <IconButton disabled color="warning">
            <Icon
              name="warning"
              fill="var(--ui-yellow)"
              widht="24px"
              height="24px"
            ></Icon>
          </IconButton>
          <H6 style={{ color: 'var(--ui-yellow)' }}>
            Warning: {existingNewLesionAlert.message}
          </H6>
        </div>
      )}
      <div style={{ margin: '5px 0' }}>
        <Grid container spacing={2}>
          {[
            `Time to response: ${timeToResponse} days`,
            `Duration of response: ${durationOfResponse} days`,
            timeToProgression
              ? `Time to progression: ${timeToProgression} days`
              : `Time to progression: N/A`,
            `Best overall response: ${
              responseMapping[bestOverallResponse.result]
            }`,
          ].map((text, i) => (
            <Grid key={i} item xs={3}>
              <H6>{text}</H6>
            </Grid>
          ))}
        </Grid>
      </div>
      <div style={{ margin: '5px 0' }}>
        <Grid container spacing={2}>
          {[
            ['Reset Assessments', () => resetAssessments(), false],
            ['Export Logs', () => onExportLogsClick(), false],
            ['Export Reports', () => onExportReportClick(), false],
          ].map(([text, handleClick, disabled], i) => (
            <Grid key={i} item xs={4}>
              <Button
                disabled={disabled}
                onClick={handleClick}
                color="secondary"
                variant="contained"
                medium
                fullWidth
              >
                {text}
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
    </Body>
  );
};
ResponseSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  criteria: PropTypes.object,
  assessments: PropTypes.array,
  onAssessmentsChange: PropTypes.func,
  resetAssessments: PropTypes.func,
  onExportReportClick: PropTypes.func,
  onExportLogsClick: PropTypes.func,
  onResponseConfirm: PropTypes.func,
};

const HeadRow = ({ isAlertDisplayed, setAlertDisplayed }) => {
  return (
    <TableRow>
      <TableCell width={'6%'} header={1}>
        <IconButton
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setAlertDisplayed(!isAlertDisplayed);
          }}
          color="primary"
        >
          <Icon
            name={isAlertDisplayed ? 'alert-triangle' : 'triangle'}
            width="24px"
            height="24px"
          ></Icon>
        </IconButton>
      </TableCell>
      {[
        ['Date', '10%'],
        ['ST', '6%'],
        ['Targets', '12%'],
        ['Non-targets', '12%'],
        ['New Lesion', '12%'],
        ['Use of Steroids', '12%'],
        ['Clinical Status', '12%'],
        ['Overall', '12%'],
        ['', '6%'],
      ].map(([text, width], i) => (
        <TableCell key={i} width={width} header={1}>
          <H6>{text}</H6>
        </TableCell>
      ))}
    </TableRow>
  );
};
HeadRow.propTypes = {
  isAlertDisplayed: PropTypes.bool,
  setAlertDisplayed: PropTypes.func,
};

const Row = ({
  index,
  timepoint,
  onAssessmentsChange,
  onResponseConfirm,
  existingAlert,
  isAlertDisplayed,
  isPasswordInputDisplayed,
}) => {
  const { isResponseConfirmed } = timepoint;
  const [isOverlayDisplayed, setOverlayDisplayed] = useState(false);
  const [isResponseConfirming, setIsResponseConfirming] = useState(false);
  const [reason, setReason] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      <TableRow index={index}>
        <TableCell>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setOverlayDisplayed(!isOverlayDisplayed);
            }}
            color="primary"
          >
            <Icon
              name={
                isOverlayDisplayed
                  ? 'feather-chevron-down'
                  : 'feather-chevron-right'
              }
              width="24px"
              height="24px"
            />
          </IconButton>
        </TableCell>
        <TableCell>
          <H6
            style={{
              color:
                isAlertDisplayed && timepoint.SeriesDate === existingAlert?.date
                  ? 'var(--ui-yellow)'
                  : '',
            }}
          >
            {timepoint.SeriesDate}
          </H6>
        </TableCell>
        <TableCell>
          <H6>{round(timepoint.SliceThickness, -1)}</H6>
        </TableCell>
        {[
          ['targetResponse', TARGET_RESPONSE],
          ['nonTargetResponse', NON_TARGET_RESPONSE],
          ['newLesionResponse', NEW_LESION_RESPONSE],
          ['steroidsUse', STEROIDS_USE],
          ['clinicalStatus', CLINICAL_STATUS],
          ['overallResponse', OVERALL_RESPONSE],
        ].map(([key, options]) => (
          <TableCell key={key}>
            {isResponseConfirmed ? (
              <H6>{idMap[timepoint[key].result]}</H6>
            ) : (
              <Select
                value={timepoint[key].result}
                onChange={evt => {
                  onAssessmentsChange(
                    timepoint.SeriesInstanceUID,
                    key,
                    evt.target.value
                  );
                }}
                color="primary"
                highlight={timepoint[key].isOverridden}
              >
                {Object.values(options).map(id => (
                  <option key={id} value={id}>
                    {idMap[id]}
                  </option>
                ))}
              </Select>
            )}
          </TableCell>
        ))}
        <TableCell>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if (!isResponseConfirming) {
                setIsResponseConfirming(true);
              }
            }}
            size="small"
            color="primary"
          >
            <Icon
              name={
                isResponseConfirming
                  ? 'feather-user-check'
                  : isResponseConfirmed
                  ? 'feather-check-square'
                  : 'feather-square'
              }
              width="24px"
              height="24px"
            ></Icon>
          </IconButton>
        </TableCell>
      </TableRow>
      {isOverlayDisplayed &&
        ['targetResponse', 'nonTargetResponse', 'newLesionResponse'].map(
          key => (
            <TableRow key={key} index={index}>
              <TableCell colSpan={3} />
              <TableCell colSpan={6}>
                <H6>{timepoint[key]?.message}</H6>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )
        )}
      {isResponseConfirming && (
        <>
          <TableRow index={index}>
            <TableCell colSpan={2} />
            <TableCell>
              <H6>Reason:</H6>
            </TableCell>
            <TableCell colSpan={6}>
              <Input
                value={reason}
                onChange={e => setReason(e.target.value)}
              ></Input>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
          {isPasswordInputDisplayed && (
            <TableRow index={index}>
              <TableCell colSpan={2} />
              <TableCell>
                <H6>Password:</H6>
              </TableCell>
              <TableCell colSpan={6}>
                <Input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                ></Input>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          <TableRow index={index}>
            <TableCell colSpan={7} />
            <TableCell>
              <Button
                onClick={() => {
                  setIsResponseConfirming(false);
                  setReason('');
                  setPassword('');
                }}
                color="secondary"
                variant="contained"
                fullWidth
              >
                Cancel
              </Button>
            </TableCell>
            <TableCell>
              <Button
                onClick={async () => {
                  /**
                   * if is confirmed, update to empty string
                   * if is not confirmed, update to confirmed
                   */
                  const responseAssessmentStatus = isResponseConfirmed
                    ? ''
                    : 'confirmed';
                  try {
                    const success = await onResponseConfirm(
                      timepoint,
                      responseAssessmentStatus,
                      reason,
                      password
                    );
                    if (success) {
                      setIsResponseConfirming(false);
                      setReason('');
                    }
                  } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                  } finally {
                    setPassword('');
                  }
                }}
                color="secondary"
                variant="contained"
                fullWidth
              >
                {isResponseConfirmed ? 'Unapprove' : 'Approve'}
              </Button>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
Row.propTypes = {
  index: PropTypes.number,
  timepoint: PropTypes.object,
  onAssessmentsChange: PropTypes.func,
  onResponseConfirm: PropTypes.func,
  existingAlert: PropTypes.bool,
  isAlertDisplayed: PropTypes.bool,
  isPasswordInputDisplayed: PropTypes.bool,
};
