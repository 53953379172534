import {
  getTimeToProgression,
  getTimeToResponse,
  getDurationOfResponse,
  getBestOverallResponse,
} from '../models/clinicalEndpoints';

export function getEndpointSource(assessmentSource, criteria) {
  if (assessmentSource.data.length === 0) {
    return;
  }
  const dataSource = {
    data: [],
    columns: [
      {
        dataField: 'patientId',
        displayName: 'Patient ID',
      },
      {
        dataField: 'timeToResponse',
        displayName: 'Time To Response',
      },
      {
        dataField: 'durationOfResponse',
        displayName: 'Duration Of Response',
      },
      {
        dataField: 'timeToProgression',
        displayName: 'Time To Progression',
      },
      {
        dataField: 'bestOverallResponse',
        displayName: 'Best Overall Response',
      },
    ],
  };

  const reports = assessmentSource.data.map(d => ({
    date: d.date,
    changeFromBaseline: d.targetChangeFromBaseline,
    result: d.overallResponse,
  }));
  dataSource.data.push({
    patientId: assessmentSource.data[0].patientId,
    timeToProgression: getTimeToProgression(reports),
    timeToResponse: getTimeToResponse(reports),
    durationOfResponse: getDurationOfResponse(reports),
    bestOverallResponse: getBestOverallResponse(
      reports,
      criteria.completeResponseConfirmation === 'REQUIRED',
      criteria.minStableDiseaseDuration || 0
    ).result,
  });

  return dataSource;
}
