export function generateUuid(name) {
  return `${standardizeName(name)}-${_uuid()}`;
}

export function standardizeName(name) {
  let id = name.toLowerCase();
  id = id.replaceAll(' ', '-');
  return id;
}

export function _uuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4();
}
