export default function getMatchingContent(stats, links) {
  let content = '';
  const newLine = '\r\n';

  /** header */
  const dates = stats.map(stat => stat.SeriesDate).join(',');
  content += 'ID,' + dates + newLine;

  /** rows */
  links.forEach(link => {
    const cells = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.names}`;
    });
    content += `${link.LinkID},` + cells.join(',') + newLine;
  });

  return content;
}
