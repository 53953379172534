// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DraggableItem.draggable div {
  cursor: grab !important;
}
.DraggableItem.draggable.dragging div {
  cursor: grabbing !important;
}
.DraggableArea {
  width: 100%;
  height: 100%;
  position: absolute;
}
.DraggableArea .Overlay {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0,0,0,0.1);
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/context/DialogProvider.styl"],"names":[],"mappings":"AACE;EACE,uBAAQ;AAAZ;AAGE;EACE,2BAAQ;AADZ;AAGA;EACE,WAAO;EACP,YAAQ;EACR,kBAAU;AADZ;AAEE;EACE,eAAU;EACV,YAAS;EACT,OAAM;EACN,MAAK;EACL,WAAO;EACP,YAAQ;EACR,cAAU;EACV,2BAAY;AAAhB","sourcesContent":[".DraggableItem.draggable\n  div\n    cursor: grab !important\n\n.DraggableItem.draggable.dragging\n  div\n    cursor: grabbing !important\n\n.DraggableArea\n  width: 100%\n  height: 100%\n  position: absolute\n  .Overlay\n    position: fixed\n    z-index: 999\n    left: 0\n    top: 0\n    width: 100%\n    height: 100%\n    overflow: auto\n    background: rgba(0,0,0,.1)\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
