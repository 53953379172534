import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cornerstoneTools from 'cornerstone-tools';

import { Body, Grid, Select, InputBox, LoadingSpinner } from '@platform/ui';
import { LinePlot } from '../plots/LinePlot';
import ExportAlert from '../../common/ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

export const AnalysisSection = ({
  imageSets,
  selectedLinkID,
  setSelectedLinkID,
  getLinkROINames,
  measurementTech,
  setMeasurementTech,
}) => {
  const [stats, setStats] = useState([]);
  const { seriesStructureMap } = useSelector(state => state.extensions.dicomRT);
  const {
    Links: links,
    PrimaryRTSS: primary,
    ReferencedRTSS: references,
  } = useSelector(state => state.linkage);

  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const structureSets = editModule.getters.structureSets();
      const referencedImageSets = models.getImageSetsFromReferences(
        imageSets,
        references,
        seriesStructureMap
      );
      const timepointManager = new models.TimepointManager();
      timepointManager.addTimepointsByImageSets(
        referencedImageSets,
        structureSets,
        seriesStructureMap,
        false
      );
      timepointManager.addLinks(links);
      const stats = timepointManager.getStats();
      setStats(stats);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const techs = models.getTechs();
  const tech = techs[measurementTech];
  const { label } = tech;
  const measurements =
    selectedLinkID === -1
      ? stats.map(stat => {
          return { ...stat.sumOfLinks, id: 'sum', name: 'SUM', label };
        })
      : stats.map(stat => {
          const link = stat.links.find(link => link.id === selectedLinkID);
          const _link = links.find(link => link.LinkID === selectedLinkID);
          const names = getLinkROINames(
            _link,
            seriesStructureMap[stat.SeriesInstanceUID]
          );
          return { ...link, name: names || 'NONE', label };
        });

  return (
    <Body>
      {measurements.length > 0 ? (
        <LinePlot data={measurements} xKey={'date'} yKey={tech.measurement} />
      ) : (
        <div style={{ margin: '15px 0', height: '100%' }}>
          <LoadingSpinner />
        </div>
      )}
      <div>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputBox label={'ROI Name(s):'} ratio={0.5}>
              <Select
                value={selectedLinkID}
                onChange={evt => setSelectedLinkID(Number(evt.target.value))}
              >
                <option value={-1}>SUM</option>
                {links
                  .map(link => ({
                    value: link.LinkID,
                    label: getLinkROINames(link, primary.SeriesInstanceUID),
                  }))
                  .filter(({ label }) => !!label)
                  .map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={6}>
            <InputBox label={'Measurement Tech:'} ratio={1}>
              <Select
                value={measurementTech}
                onChange={evt => setMeasurementTech(evt.target.value)}
              >
                {Object.entries(techs).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value.label}
                  </option>
                ))}
              </Select>
            </InputBox>
          </Grid>
        </Grid>
      </div>
    </Body>
  );
};
AnalysisSection.propTypes = {
  imageSets: PropTypes.array,
  selectedLinkID: PropTypes.number,
  setSelectedLinkID: PropTypes.func,
  getLinkROINames: PropTypes.func,
  measurementTech: PropTypes.string,
  setMeasurementTech: PropTypes.func,
  onExportClick: PropTypes.func,
  isExporting: PropTypes.bool,
};
