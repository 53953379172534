import findTimepointIndex from './findTimepointIndex';
import { OVERALL_RESPONSE } from '../../constants/guideline';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @param {string[]} reports[].changeFromBaseline
 * @returns {string}
 */
function getBestPercentageChange(reports) {
  const progressiveIndex = findTimepointIndex(reports, 'progressive');

  let overallResponse = OVERALL_RESPONSE.NONE;
  let responseIndex = 0;
  let changeFromBaseline = 100;
  reports.every((report, index) => {
    if (index === 0) return true;
    if (report.changeFromBaseline < changeFromBaseline) {
      overallResponse = report.result;
      changeFromBaseline = report.changeFromBaseline;
      responseIndex = index;
    }
    return progressiveIndex < 0 || index <= progressiveIndex - 1;
  });

  return {
    result: overallResponse,
    changeFromBaseline: changeFromBaseline,
    date: reports[responseIndex]?.date,
    index: responseIndex,
  };
}

export default getBestPercentageChange;
