export const defaultState = {
  PrimaryRTSS: {
    StudyInstanceUID: '',
    SeriesInstanceUID: '',
    SOPInstanceUID: '',
    ReferencedSeriesDate: '',
  },
  ReferencedRTSS: [],
  Links: [],
};

const linkage = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_LINKAGE': {
      const { linkage } = action.payload;
      return { ...state, ...linkage };
    }
    case 'ADD_LINK': {
      const { linkID } = action.payload;
      const { Links } = state;
      return {
        ...state,
        Links: [...Links, { LinkID: linkID, RTMappings: [] }],
      };
    }
    case 'ADD_ROI_LINK': {
      const { SeriesInstanceUID, ROINumber, linkID } = action.payload;
      const { ReferencedRTSS, Links } = state;
      const referenced = ReferencedRTSS.find(
        rtss => rtss.SeriesInstanceUID === SeriesInstanceUID
      );
      if (!referenced) return state;
      return {
        ...state,
        Links: [
          ...Links,
          {
            LinkID: linkID,
            RTMappings: [
              {
                StudyInstanceUID: referenced.StudyInstanceUID,
                SeriesInstanceUID: referenced.SeriesInstanceUID,
                SOPInstanceUID: referenced.SOPInstanceUID,
                ROINumbers: [ROINumber],
              },
            ],
          },
        ],
      };
    }
    case 'UPDATE_ROI_LINK': {
      const { SeriesInstanceUID, ROINumber, prevID, nextID } = action.payload;
      const { ReferencedRTSS, Links } = state;
      const referenced = ReferencedRTSS.find(
        rtss => rtss.SeriesInstanceUID === SeriesInstanceUID
      );
      if (!referenced) return state;
      const newLinks = Links.map(link => {
        if (link.LinkID === prevID) {
          return {
            ...link,
            RTMappings: link.RTMappings.map(mapping => {
              if (mapping.SeriesInstanceUID === SeriesInstanceUID) {
                return {
                  ...mapping,
                  ROINumbers: mapping.ROINumbers.filter(
                    number => number !== ROINumber
                  ),
                };
              }
              return mapping;
            }).filter(mapping => mapping.ROINumbers.length !== 0),
          };
        }
        if (link.LinkID === nextID) {
          const targetMapping = link.RTMappings.find(
            mapping => mapping.SeriesInstanceUID === SeriesInstanceUID
          );
          return targetMapping
            ? {
                ...link,
                RTMappings: link.RTMappings.map(mapping => {
                  if (mapping.SeriesInstanceUID === SeriesInstanceUID) {
                    return {
                      ...mapping,
                      ROINumbers: [
                        ...mapping.ROINumbers.filter(
                          number => number !== ROINumber
                        ),
                        ROINumber,
                      ],
                    };
                  }
                  return mapping;
                }),
              }
            : {
                ...link,
                RTMappings: [
                  ...link.RTMappings,
                  {
                    StudyInstanceUID: referenced.StudyInstanceUID,
                    SeriesInstanceUID: referenced.SeriesInstanceUID,
                    SOPInstanceUID: referenced.SOPInstanceUID,
                    ROINumbers: [ROINumber],
                  },
                ],
              };
        }
        return link;
      });
      return {
        ...state,
        Links: newLinks,
      };
    }
    case 'REMOVE_ROI_LINK': {
      const { SeriesInstanceUID, ROINumber } = action.payload;
      const { ReferencedRTSS, Links } = state;
      const referenced = ReferencedRTSS.find(
        rtss => rtss.SeriesInstanceUID === SeriesInstanceUID
      );
      if (!referenced) return state;
      const newLinks = Links.map(link => {
        const targetMapping = link.RTMappings.find(
          mapping => mapping.SeriesInstanceUID === SeriesInstanceUID
        );
        return targetMapping
          ? {
              ...link,
              RTMappings: link.RTMappings.map(mapping => {
                if (mapping.SeriesInstanceUID === SeriesInstanceUID) {
                  return {
                    ...mapping,
                    ROINumbers: mapping.ROINumbers.filter(
                      number => number !== ROINumber
                    ),
                  };
                }
                return mapping;
              }).filter(mapping => mapping.ROINumbers.length > 0),
            }
          : link;
      });
      return { ...state, Links: newLinks };
    }
    case 'MERGE_LINKS': {
      const { ids } = action.payload;
      const { Links, ReferencedRTSS } = state;
      const _links = Links.filter(link => ids.includes(link.LinkID));
      const newLink = {
        LinkID: _links[0].LinkID,
        RTMappings: ReferencedRTSS.map(referenced => {
          const ROINumbers = _links.reduce((numbers, link) => {
            const _referenced = link.RTMappings.find(
              ref => ref.SeriesInstanceUID === referenced.SeriesInstanceUID
            );
            if (!_referenced) return [...numbers];
            return [...numbers, ..._referenced.ROINumbers];
          }, []);
          return {
            StudyInstanceUID: referenced.StudyInstanceUID,
            SeriesInstanceUID: referenced.SeriesInstanceUID,
            SOPInstanceUID: referenced.SOPInstanceUID,
            ROINumbers: ROINumbers,
          };
        }).filter(referenced => referenced.ROINumbers.length !== 0),
      };
      const newLinks = Links.filter(link => !ids.includes(link.LinkID));
      newLinks.push(newLink);
      newLinks.sort((a, b) => a.LinkID - b.LinkID);
      return { ...state, Links: newLinks };
    }
    case 'REMOVE_LINKS': {
      const { ids } = action.payload;
      const { Links } = state;
      const newLinks = Links.filter(link => !ids.includes(link.LinkID));
      return { ...state, Links: newLinks };
    }
    default: {
      return state;
    }
  }
};

export default linkage;
