import { math } from '../../modules/dicom-measurement/src';
const { getDistance } = math;

/**
 * @param {Int16Array} image.pixelData
 * @param {Number} image.width
 * @param {Number} image.height
 * @param {Int16Array} refImage.pixelData
 * @param {Number} seedPixel.x
 * @param {Number} seedPixel.y
 * @param {Number} upper
 * @param {Number} lower
 * @returns {Object} processed image
 */

export default function growRegion(
  image,
  refImage,
  upper,
  lower,
  seedPixel,
  maxPixelRadius
) {
  const { pixelData, width } = image;

  const activeFront = [];
  const region = new Int16Array(pixelData.length);
  if (refImage) {
    const refRegion = refImage.pixelData;
    for (let i = 0; i < refRegion.length; i++) {
      if (refRegion[i] === 1) {
        if (pixelData[i] > lower && pixelData[i] < upper) {
          region[i] = 1;
          activeFront.push(i);
        } else {
          region[i] = -1;
        }
      }
    }
  } else if (seedPixel) {
    const { x, y } = seedPixel;
    const c = x + width * y;
    if (pixelData[c] > lower && pixelData[c] < upper) {
      region[c] = 1;
      activeFront.push(c);
    }
  }

  while (activeFront.length > 0) {
    const d = activeFront.shift();
    const left = d - 1;
    const right = d + 1;
    const top = d - width;
    const bottom = d + width;
    [left, right, top, bottom].forEach(i => {
      if (region[i] !== 0) {
        return;
      }
      if (
        seedPixel &&
        maxPixelRadius &&
        getDistance(seedPixel, {
          x: i % width,
          y: Math.floor(i / width),
        }) > maxPixelRadius
      ) {
        region[i] = -1;
        return;
      }
      if (pixelData[i] > lower && pixelData[i] < upper) {
        region[i] = 1;
        activeFront.push(i);
      } else {
        region[i] = -1;
      }
    });
  }
  return { ...image, pixelData: region };
}
