import user from './user';
import extensions from './extensions';
import linkage from './linkage';
import backend from './backend';
import studies from './studies';
import viewports from './viewports';

const reducers = {
  user,
  extensions,
  linkage,
  backend,
  studies,
  viewports,
};

export default reducers;
