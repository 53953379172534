import cornerstone from 'cornerstone-core';
import cornerstoneTools, {
  importInternal,
  getToolState,
} from 'cornerstone-tools';
import _ from 'lodash';

import { store } from '@platform/viewer';
import { redux } from '@platform/core';
import TOOL_NAMES from './constants/toolNames';
import { refreshViewport } from './commands';
import {
  math,
  getVolumeFromSlices,
  transformPointsToImagePlaneById,
  transformPointsToPhysicalById,
} from '../modules/dicom-measurement/src';

const {
  formatNumber,
  pointToCoorArray,
  innerProduct,
  crossProduct,
  vector,
} = math;
const { setDICOMRTImageData } = redux.actions;

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');
export const getNewContext = importInternal('drawing/getNewContext');
const BaseTool = importInternal('base/BaseTool');

/**
 * @class RTStructDisplayTool - Renders RTSTRUCT data in a read only manner (i.e. as an overlay).
 * @extends cornerstoneTools.BaseTool
 */
export default class RTStructDisplayTool extends BaseTool {
  constructor(props = {}) {
    const defaultProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      mixins: ['renderBrushMixin'],
      configuration: {},
      name: TOOL_NAMES.RTSTRUCT_DISPLAY_TOOL,
    };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps);

    this._module = cornerstoneTools.getModule('rtstruct-edit');
    this._module.addEventListener('ROISelected', () => refreshViewport());
    this._module.addEventListener('Highlighted', () => refreshViewport());
    this._isDrawing = false;
    this._cursorCanvasPosition = null;
    this._SeriesInstanceUID = null;
    this._isAxialViewSeries = false;
    this._viewtype = 'unknown';
    this._csToolsHandlers = {};
  }

  renderToolData(evt) {
    const { enabledElement } = evt.detail;
    const imageId = enabledElement.image.imageId;
    this.updateSeriesInfo(imageId);
    const mode = this._module.getters.mode();

    let toolState;
    let module;
    switch (mode) {
      case 'edit':
        if (this._viewtype === 'axial') {
          toolState = this._module.getters.toolState(imageId);
          module = this._module;
        } else {
          this._renderContourIntersection(evt);
        }
        break;
      default:
        toolState = getToolState(evt.currentTarget, this.name);
        module = { getters: {}, setters: {} };
        break;
    }
    if (toolState) {
      const roiNumbers = {};
      const painter = this._module.getters.peekPainter(imageId);
      const getDisplayConfig = this._module.getters.displayConfig;
      const isInfoRendering = getDisplayConfig('isInfoRendering');
      const isSelectedInfoRendering = getDisplayConfig(
        'isSelectedInfoRendering'
      );
      const isAxesRendering = getDisplayConfig('isAxesRendering');
      const isContoursFilling = getDisplayConfig('isContoursFilling');
      const stack = cornerstoneTools.getToolState(
        enabledElement.element,
        'stack'
      );

      const roiInformations = [];
      this._iterateContour(evt, toolState, module, contour => {
        this._drawContour(contour, isContoursFilling);
        if (!roiNumbers[contour.ROINumber]) {
          roiNumbers[contour.ROINumber] = true;
          if (isAxesRendering) this._renderAxes(painter, contour);
          if (
            isInfoRendering ||
            (isSelectedInfoRendering &&
              (contour.isSelected || contour.isHighlighted))
          ) {
            const renderInfo = this._getRenderInfo(stack, painter, contour);
            if (renderInfo) roiInformations.push(renderInfo);
          }
        }
      });
      /** render ROI information */
      store.dispatch(setDICOMRTImageData(imageId, { roiInformations }));
    }

    this._renderInterViewRef(evt, imageId);
  }

  _getRenderInfo(stack, painter, contour) {
    if (!stack) return;
    const info = painter.getInfo().find(i => i.ROINumber === contour.ROINumber);
    const area = info?.area || 0;
    const mergedLongAxisValue = info?.mergedLongAxisValue || 0;
    const mergedShortAxisValue = info?.mergedShortAxisValue || 0;
    const slices = stack.data[0].imageIds.map(id => {
      const ImagePositionPatient =
        cornerstone.metaData.get('ImagePositionPatient', id) || {};
      const generalImageModule =
        cornerstone.metaData.get('generalImageModule', id) || {};
      const { instanceNumber: InstanceNumber } = generalImageModule;
      const painter = this._module.getters.peekPainter(id);
      const painterInfo = painter
        .getInfo()
        .find(i => i.ROINumber === contour.ROINumber);
      const area = painterInfo?.area || 0;
      return { area, InstanceNumber, ImagePositionPatient };
    });
    const volume = getVolumeFromSlices(slices);
    return {
      ROIName: contour.ROIContourData.ROIName,
      ROINumber: contour.ROINumber,
      isSelected: contour.isSelected,
      isHighlighted: contour.isHighlighted,
      area: formatNumber(area / 100, 0.1),
      longAxis: formatNumber(mergedLongAxisValue, 0.1),
      shortAxis: formatNumber(mergedShortAxisValue, 0.1),
      volume: formatNumber(volume / 1000, 0.1),
      color: contour.ROIContourData.colorArray,
    };
  }

  _renderAxes(painter, { ROINumber, ROIContourData, context, eventData }) {
    const painterInfo = painter.getInfo().find(a => a.ROINumber === ROINumber);
    const longAxes = painterInfo?.longAxes || [];
    const shortAxes = painterInfo?.shortAxes || [];
    if (longAxes.length) {
      longAxes.forEach(longAxis => {
        if (longAxis && longAxis.value !== 0) {
          const canvasPoints = longAxis.points.map(point =>
            cornerstone.pixelToCanvas(eventData.enabledElement.element, point)
          );
          draw(context, context => {
            const { colorArray } = ROIContourData;
            context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
            context.lineWidth = 0.5;
            context.beginPath();
            context.moveTo(canvasPoints[0].x, canvasPoints[0].y);
            context.lineTo(canvasPoints[1].x, canvasPoints[1].y);
            context.stroke();
          });
        }
      });
    }
    if (shortAxes.length) {
      shortAxes.forEach(shortAxis => {
        if (shortAxis && shortAxis.value !== 0) {
          const canvasPoints = shortAxis.points.map(point =>
            cornerstone.pixelToCanvas(eventData.enabledElement.element, point)
          );
          draw(context, context => {
            const { colorArray } = ROIContourData;
            context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
            context.lineWidth = 0.5;
            context.beginPath();
            context.moveTo(canvasPoints[0].x, canvasPoints[0].y);
            context.lineTo(canvasPoints[1].x, canvasPoints[1].y);
            context.stroke();
          });
        }
      });
    }
  }

  _renderContourIntersection(evt) {
    const { enabledElement } = evt.detail;
    const imageId = enabledElement.image.imageId;

    let axialElement;
    for (const enabledElement of cornerstone.getEnabledElements()) {
      const tool = cornerstoneTools.getToolForElement(
        enabledElement.element,
        TOOL_NAMES.RTSTRUCT_DISPLAY_TOOL
      );
      if (tool._viewtype === 'axial') {
        axialElement = enabledElement;
        break;
      }
    }
    if (!axialElement || axialElement === enabledElement) return;

    const { element } = axialElement;
    const axialStack = cornerstoneTools.getToolState(element, 'stack');
    if (!axialStack) return;

    const ROI = {};
    const axialImageIds = axialStack.data[0].imageIds;
    axialImageIds.forEach(id => {
      const toolState = this._module.getters.toolState(id);
      this._iterateContour(
        evt,
        toolState,
        this._module,
        ({ points, ROIContourData }) => {
          const segments = this._sampleContourSegment(id, imageId, points);
          const { ROINumber } = ROIContourData;
          ROI[ROINumber] = ROI[ROINumber] || {
            ROIContourData,
            points: [],
            count: 0,
          };
          ROI[ROINumber].count++;
          for (const { top, bottom, point } of this._enumerateSegmentPoints(
            segments,
            imageId
          )) {
            ROI[ROINumber].points.push({ point: top, source: point });
            ROI[ROINumber].points.push({ point: bottom, source: point });
          }
        }
      );
    });
    _.forEach(ROI, function({ ROIContourData, points }, ROINumber) {
      const eventData = evt.detail;
      const context = getNewContext(eventData.canvasContext.canvas);
      const colorArray = ROIContourData.colorArray;
      const color = `rgba(${colorArray.join(',')},1)`;

      const originY = points.length && points[0].point.y;
      _.chain(points)
        .groupBy(function({ point }) {
          let key = _.round(point.y - originY);
          return key;
        })
        .map(points =>
          points
            .map(({ point, source }) => ({
              point: cornerstone.pixelToCanvas(eventData.element, point),
              source: cornerstone.pixelToCanvas(eventData.element, source),
            }))
            .sort((a, b) => a.point.x - b.point.x)
        )
        .flatten()
        .chunk(2)
        .forEach(([p1, p2]) => {
          draw(context, context => {
            context.strokeStyle = color;
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(p1.source.x, p1.source.y);
            context.lineTo(p2.source.x, p2.source.y);
            context.stroke();
          });
        })
        .value();
    });
  }

  _sampleContourSegment(id, imageId, points) {
    const {
      imagePositionPatient,
      rowCosines,
      columnCosines,
    } = cornerstone.metaData.get('imagePlaneModule', imageId);

    const normalVec = crossProduct(columnCosines, rowCosines);
    const imageOffset = innerProduct(normalVec, imagePositionPatient);

    const physicalCoorPoints = transformPointsToPhysicalById(points, id).map(
      physicalCoor => {
        const coor = pointToCoorArray(physicalCoor);
        return {
          coor,
          offset: innerProduct(coor, normalVec) - imageOffset,
        };
      }
    );

    if (!_.some(physicalCoorPoints, x => x.offset != 0)) {
      return [];
    }

    const segments = [];
    for (let idx = 0; idx < physicalCoorPoints.length; idx++) {
      const current = physicalCoorPoints[idx];
      let jdx = (idx + 1) % physicalCoorPoints.length;
      let next = physicalCoorPoints[jdx];
      if (next.offset === 0 && current.offset === 0) {
        continue;
      } else if (next.offset !== 0 && current.offset !== 0) {
        if (next.offset / current.offset > 0) {
          continue;
        }
        const denominator = Math.abs(current.offset - next.offset);
        const intersection = vector.add(
          vector.multiply(
            current.coor,
            1 - Math.abs(current.offset) / denominator
          ),
          vector.multiply(next.coor, 1 - Math.abs(next.offset) / denominator)
        );
        segments.push(intersection);
      } else if (next.offset === 0) {
        segments.push(next.coor);
      } else if (current.offset === 0) {
        segments.push(current.coor);
      }
    }
    segments.sort((a, b) => a[0] - b[0] || a[1] - b[1] || a[2] - b[2]);
    return segments;
  }

  *_enumerateSegmentPoints(segments, imageId) {
    for (let idx = 0; idx < segments.length; idx += 2) {
      const [start, end] = transformPointsToImagePlaneById(
        segments.slice(idx, idx + 2).map(p => ({ x: p[0], y: p[1], z: p[2] })),
        imageId
      );
      if (!start?.x || !start?.y || !end?.x || !end?.y) continue;
      for (const point of [start, end]) {
        yield {
          top: {
            ...point,
            y: point.y - 0.5,
          },
          bottom: {
            ...point,
            y: point.y + 0.5,
          },
          point: point,
        };
      }
    }
  }

  updateSeriesInfo(imageId) {
    const seriesInstanceUID = cornerstone.metaData.get(
      'SeriesInstanceUID',
      imageId
    );
    if (this._SeriesInstanceUID != seriesInstanceUID) {
      this._SeriesInstanceUID = seriesInstanceUID;
      this._viewtype = getImageViewType(imageId);
    }
  }

  enabledCallback(element) {
    this.csToolsHandlers = {
      [cornerstoneTools.EVENTS.STACK_SCROLL]: evt => {
        const { newImageIdIndex } = evt.detail;

        const toolState = cornerstoneTools.getToolState(element, 'stack');
        if (!toolState) {
          return;
        }

        const imageId = toolState.data[0].imageIds[newImageIdIndex];
        this._module.setters.scrollingImageId(imageId);
        const enabledElements = cornerstone.getEnabledElements();
        for (const enabledElement of enabledElements) {
          if (enabledElement.image) {
            cornerstone.updateImage(enabledElement.element);
          }
        }
      },
    };

    _.forEach(this.csToolsHandlers, function(handler, eventName) {
      element.addEventListener(eventName, handler);
    });
  }

  disabledCallback(element) {
    _.forEach(this.csToolsHandlers, function(handler, eventName) {
      element.removeEventListener(eventName, handler);
    });
  }

  _renderInterViewRef(evt, imageId) {
    const getDisplayConfig = this._module.getters.displayConfig;
    const isInterViewRefRendering = getDisplayConfig('isInterViewRefRendering');
    if (!isInterViewRefRendering) return;

    const scrollingImageId = this._module.getters.scrollingImageId();
    if (!scrollingImageId || imageId === scrollingImageId) return;

    const scrollingViewType = getImageViewType(scrollingImageId);
    if (this._viewtype === 'unknown') {
      this._viewtype = getImageViewType(imageId);
    }
    if (this._viewtype === scrollingViewType) return;

    const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
    const {
      imagePositionPatient,
      rowCosines,
      columnCosines,
      rows,
      columns,
      columnPixelSpacing,
      rowPixelSpacing,
    } = imagePlane;
    const {
      normalVector,
      offset,
    } = this._module.getters.scrollingImageConfig();

    let origins;
    let delta;
    switch (`${this._viewtype}+${scrollingViewType}`) {
      case 'axial+coronal':
      case 'sagittal+axial':
      case 'coronal+axial':
        delta = columnCosines;
        origins = [
          imagePositionPatient,
          _.zipWith(imagePositionPatient, rowCosines, function(p, o) {
            return p + columns * o * columnPixelSpacing;
          }),
        ];
        break;
      case 'sagittal+coronal':
      case 'coronal+sagittal':
      case 'axial+sagittal':
        delta = rowCosines;
        origins = [
          imagePositionPatient,
          _.zipWith(imagePositionPatient, columnCosines, function(p, o) {
            return p + rows * o * rowPixelSpacing;
          }),
        ];
        break;
      default:
        break;
    }
    if (!delta) return;
    const imageOffset = innerProduct(normalVector, delta);
    const points = origins.map(origin => {
      const alpha =
        -(innerProduct(normalVector, origin) + offset) / imageOffset;
      return _.zipWith(origin, delta, function(o, d) {
        return o + alpha * d;
      });
    });

    const eventData = evt.detail;
    const context = getNewContext(eventData.canvasContext.canvas);

    draw(context, context => {
      const ppp = transformPointsToImagePlaneById(
        points.map(point => ({ x: point[0], y: point[1], z: point[2] })),
        imageId
      );
      context.strokeStyle = 'rgba(255, 0, 255, 1)';
      context.lineWidth = 1;
      context.beginPath();
      const origin = cornerstone.pixelToCanvas(eventData.element, ppp[0]);
      context.moveTo(origin.x, origin.y);
      for (const p of ppp.slice(1)) {
        const point = cornerstone.pixelToCanvas(eventData.element, p);
        context.lineTo(point.x, point.y);
      }
      context.closePath();
      context.stroke();
    });
  }

  _iterateContour(evt, toolState, module, onContour) {
    if (!toolState) return;
    const eventData = evt.detail;
    const context = getNewContext(eventData.canvasContext.canvas);

    const selectedStructureSetUID = this._module.getters.selectedStructureSetUID();
    const selectedROINumber = this._module.getters.selectedROINumber();
    const highlights = this._module.getters.highlights();
    for (let i = 0; i < toolState.data.length; i++) {
      const data = toolState.data[i];
      const ROIContourData = module.getters.ROIContour(
        data.structureSetSeriesInstanceUid,
        data.ROINumber
      );

      // Don't render if ROIContour is hidden.
      // Do render when the ROIContour is selected
      const isSelected =
        data.structureSetSeriesInstanceUid === selectedStructureSetUID &&
        data.ROINumber === selectedROINumber;
      if (
        !ROIContourData ||
        (ROIContourData.visible === false && !isSelected)
      ) {
        continue;
      }

      const isHighlighted = !!highlights.find(
        i =>
          i.SeriesInstanceUID === data.structureSetSeriesInstanceUid &&
          i.ROINumber === data.ROINumber
      );

      const points = data.handles.points;
      if (!points.length) continue;

      onContour({
        ROIContourData,
        context,
        eventData,
        points,
        ROINumber: data.ROINumber,
        isSelected,
        isHighlighted,
      });
    }
  }

  _drawContour(
    { ROIContourData, context, eventData, points, isHighlighted },
    isContoursFilling
  ) {
    const colorArray = ROIContourData.colorArray;
    const color = `rgba(${colorArray.join(',')},1)`;
    const fillColor = `rgba(${colorArray.join(',')},0.6)`;

    draw(context, context => {
      context.strokeStyle = color;
      context.lineWidth = 2;
      if (isHighlighted && isContoursFilling) {
        context.lineWidth = 3;
      }
      context.beginPath();
      const origin = cornerstone.pixelToCanvas(eventData.element, points[0]);
      context.moveTo(origin.x, origin.y);
      for (const p of points.slice(1)) {
        const point = cornerstone.pixelToCanvas(eventData.element, p);
        context.lineTo(point.x, point.y);
      }
      context.closePath();
      context.stroke();
      if (isHighlighted && isContoursFilling) {
        context.fillStyle = fillColor;
        context.fill();
      }
    });
  }
}

function getImageViewType(imageId) {
  const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
  if (!imagePlane) return 'unknown';
  const orientation = imagePlane.imageOrientationPatient.map(Math.round);
  const axials = [
    [0, 4],
    [1, 3],
  ];
  const coronals = [
    [0, 5],
    [2, 3],
  ];
  const sagittals = [
    [1, 5],
    [2, 4],
  ];
  const isOrientationFit = ([i, j]) =>
    Math.abs(orientation[i]) > 0.9 && Math.abs(orientation[j]) > 0.9;
  if (axials.some(isOrientationFit)) return 'axial';
  if (coronals.some(isOrientationFit)) return 'coronal';
  if (sagittals.some(isOrientationFit)) return 'sagittal';
  return 'unknown';
}
