import styled, { css } from 'styled-components';

const Select = styled.select`
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #ffffff;
  background: none;
  margin: 0;
  padding: 8px 4px;
  width: 100%;
  height: auto;
  min-height: 1.2em;
  font: inherit;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: inherit;
  outline: 0;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.23);
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  cursor: pointer;

  &:hover {
    border-color: white;
  }

  ${props =>
    props.highlight &&
    css`
      background-color: rgba(102, 175, 233, 0.3);
      box-shadow: 0 0 10px #44626f;
    `}

  option {
    color: #ffffff !important;
    background-color: #424242 !important;
  }
`;

export default Select;
