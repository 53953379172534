import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const defaultColor = '#BFCBED';
const activeColor = '#8784EE';
const uiGrayDark = '#16202B';
const uiGrayDarker = '#151A1F';

const dicomBrowserSelectStyles = {
  singleValue: base => ({
    ...base,
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    cursor: 'pointer',
    background: uiGrayDarker,
    borderRadius: state.isFocused ? '5px 5px 5px 5px' : 5,
    borderColor: state.isFocused ? activeColor : defaultColor,
    minHeight: '50px',
    '&:hover': {
      borderColor: activeColor,
    },
  }),
  menu: base => ({
    ...base,
    borderRadius: 5,
    background: uiGrayDarker,
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    cursor: 'pointer',
    '&:first-of-type': {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
    background: state.isSelected ? uiGrayDark : uiGrayDarker,
    '&:hover': {
      background: uiGrayDark,
    },
  }),
};

const DicomBrowserSelect = ({ value, formatOptionLabel, options }) => (
  <Select
    value={value}
    formatOptionLabel={formatOptionLabel}
    styles={dicomBrowserSelectStyles}
    options={options}
  />
);
DicomBrowserSelect.propTypes = {
  value: PropTypes.any,
  formatOptionLabel: PropTypes.any,
  options: PropTypes.array,
};

export default DicomBrowserSelect;
