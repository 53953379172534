import * as dcmjs from 'dcmjs';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { meta } from './getRTStructDataset';

const { datasetToBlob } = dcmjs.data;

export default async function(filename, dataset) {
  const reportBlob = datasetToBlob({ ...dataset, _meta: meta });
  exportDCMfile(filename, reportBlob);
}

function exportDCMfile(filename, data) {
  const zip = new JSZip();
  zip.file(`${filename}.dcm`, data);
  zip.generateAsync({ type: 'blob' }).then(function(blob) {
    saveAs(blob, `${filename}.zip`);
  });
}
