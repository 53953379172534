/* eslint-disable no-console */
const name = 'LoggerService';
const publicAPI = {
  name,
  info: _info,
  warn: _warn,
  error: _error,
  setServiceImplementation,
};
const serviceImplementation = {
  _info: () => console.warn('info() NOT IMPLEMENTED'),
  _warn: () => console.warn('warn() NOT IMPLEMENTED'),
  _error: () => console.warn('error() NOT IMPLEMENTED'),
};

function _info(log) {
  return serviceImplementation._info(log);
}

function _warn(log) {
  return serviceImplementation._warn(log);
}

function _error(log) {
  return serviceImplementation._error(log);
}

function setServiceImplementation({
  info: infoImplementation,
  warn: warnImplementation,
  error: errorImplementation,
}) {
  if (infoImplementation) {
    serviceImplementation._info = infoImplementation;
  }
  if (warnImplementation) {
    serviceImplementation._warn = warnImplementation;
  }
  if (errorImplementation) {
    serviceImplementation._error = errorImplementation;
  }
}

export default {
  name,
  create: ({ configuration = {} }) => {
    return publicAPI;
  },
};
