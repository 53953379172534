import React from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { math } from '../../../modules/dicom-measurement/src';
const { ceil } = math;

export const LinePlot = ({ data, xKey, yKey }) => {
  const max = Math.max(...data.map(d => (d ? Number(d[yKey]) : 0)));
  const yMax = max > 10 ? ceil(max, 1) : max > 1 ? ceil(max, 0) : ceil(max, -1);
  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  };

  return (
    <div style={style}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey={xKey} />
          <YAxis dataKey={yKey} domain={[0, yMax]} />
          <Legend verticalAlign="top" height={36} />
          <Tooltip content={<CustomTooltip />} />
          <Line
            name={`${data[0]?.label || ''}`}
            type="monotone"
            dataKey={yKey}
            stroke="var(--active-color)"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
LinePlot.propTypes = {
  data: PropTypes.array,
  xKey: PropTypes.string,
  yKey: PropTypes.string,
};

const CustomTooltip = ({ active, payload }) => {
  if (!(active && payload && payload.length)) return null;
  const data = payload[0].payload;
  const tooltipStyle = {
    color: 'grey',
    background: '#FFFFFF',
    padding: '10px',
    boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.2)',
  };
  const pStyle = { margin: '3px 0' };
  return (
    <div style={tooltipStyle}>
      <p style={pStyle}>
        <strong>{data.date}</strong>
      </p>
      <p style={pStyle}>{data.name}</p>
      <p style={pStyle}>
        {payload[0].name}: {payload[0].value}
      </p>
    </div>
  );
};
CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  cohorts: PropTypes.array,
};
