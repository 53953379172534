import { vec3 } from 'gl-matrix';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';

import getTypedPixelArray from '../lib/getTypedPixelArray.js';
import insertSlice from '../lib/insertSlice.js';
import getSliceIndex from '../lib/getSliceIndex.js';

/**
 * @param {Array} imageIds
 * @returns {Object} vtk volume
 */
export default function(images, meta) {
  const origin = [0, 0, 0];
  const { orientation, zAxis } = meta;

  const imageData = _createVtkImageData(
    images,
    origin,
    meta.dimensions,
    meta.spacing,
    meta.zAxis,
    meta.bitsAllocated,
    meta.pixelRepresentation
  );
  const center = _getVolumeCenter(imageData);

  const image = images[0];
  const { windowWidth, windowCenter, intercept, slope } = image;
  const vtkVolume = {
    vtkImageData: imageData,
    center,
    windowWidth,
    windowCenter,
    intercept,
    slope,
    orientation,
    zAxis,
  };
  return vtkVolume;
}

/**
 * @param {Array} imageIds
 * @param {Array} origin
 * @param {Array} dimensions
 * @param {Array} spacing
 * @param {Object} zAxis
 * @returns {*} vtk image data
 */
function _createVtkImageData(
  images,
  origin,
  dimensions,
  spacing,
  zAxis,
  bitsAllocated,
  pixelRepresentation
) {
  const imageData = vtkImageData.newInstance();
  imageData.setOrigin(origin);
  imageData.setDimensions(dimensions);
  imageData.setSpacing(spacing);
  const extent = imageData.getExtent();

  const typedPixelArray = getTypedPixelArray(
    bitsAllocated,
    pixelRepresentation,
    dimensions
  );
  for (const image of images) {
    const { imagePositionPatient } = image;
    const pixelData = image.getPixelData();
    const sliceIndex = getSliceIndex(zAxis, imagePositionPatient);
    insertSlice(typedPixelArray, pixelData, extent, sliceIndex);
  }

  const scalarArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: typedPixelArray,
  });
  imageData.getPointData().setScalars(scalarArray);
  return imageData;
}

/**
 * @param {*} vtkImageData
 * @returns {vec3} Float32Array contain the volume's center IPP
 */
function _getVolumeCenter(vtkImageData) {
  const [x0, y0, z0] = vtkImageData.getOrigin();
  const [xSpacing, ySpacing, zSpacing] = vtkImageData.getSpacing();
  const [xMin, xMax, yMin, yMax, zMin, zMax] = vtkImageData.getExtent();

  const centerOfVolume = vec3.fromValues(
    x0 + xSpacing * 0.5 * (xMin + xMax),
    y0 + ySpacing * 0.5 * (yMin + yMax),
    z0 + zSpacing * 0.5 * (zMin + zMax)
  );
  return centerOfVolume;
}
