export default function getAxes(stats, links) {
  const dates = stats.map(stat => stat.SeriesDate);
  const sumValues = stats.map(stat => stat.sumOfLinks.maxLongAxisValue);
  const sumChanges = stats.map(stat => stat.sumOfLinks.axisChange);
  let latestChange = sumChanges[sumChanges.length - 1];

  const axes = [];
  const sumAxis = {
    ID: 'sum',
    Last_2_TPs: `${latestChange}%`,
    ROI_Name: ' ',
    color: 'black',
  };
  dates.forEach((date, index) => {
    sumAxis[date] = sumValues[index];
  });
  axes.push(sumAxis);

  links.forEach(link => {
    const names = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.names}`;
    });
    const latestName = names[names.length - 1];
    const nameCell = latestName.includes(',')
      ? JSON.stringify(latestName[latestName.length - 1]).replaceAll('"', "'")
      : latestName;
    const values = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.maxLongAxisValue}`;
    });
    let _latestChange = 0;
    const changes = stats.map((stat, index) => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      if (index === stats.length - 1) _latestChange = _link.axisChange || 0;
      if (!_link.axisChange) return ' ';
      return `${_link.axisChange}%`;
    });
    const a = {
      ID: `${link.LinkID}`,
      Last_2_TPs: changes[changes.length - 1],
      ROI_Name: nameCell || ' ',
      color: _latestChange > 0 ? 'red' : _latestChange < 0 ? 'green' : 'black',
    };
    dates.forEach((date, index) => {
      a[date] = values[index];
    });
    axes.push(a);
  });

  return axes;
}
