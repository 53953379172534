import { generateUuid } from '../utils/generator';
import { getCurrentUser } from '../auth';
import firebase from 'firebase/app';
import 'firebase/firestore';

export async function writeLog(db, id, subjectName, logParam) {
  /**
   * db: firebase -> firestore() -> ... -> collection
   * id: document id
   */
  const user = getCurrentUser().email;
  const action = logParam.action;
  const logId = generateUuid(action);
  const logData = {
    user: user,
    time: firebase.firestore.FieldValue.serverTimestamp(),
    level: logParam.level || 'INFO',
    component: logParam.component || 'Platform',
    action: action,
    message:
      logParam.message || `${user} ${action} ${subjectName} successfully`,
    details: logParam.details || {},
  };
  await db.doc(`${id}/logs/${logId}`).set(logData);
}

export async function writeLogs(siteId, trialId, subjectId, logs) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('logs');
  for (let i = 0; i < logs.length; i += 100) {
    const batch = firebase.firestore().batch();
    logs
      .slice(i, i + 100)
      .map(log => ({
        ...log,
        time: new firebase.firestore.Timestamp(
          Math.floor(log.time / 1000),
          (log.time % 1000) * 1000000
        ),
      }))
      .forEach(log => {
        const logRef = db.doc(generateUuid(log.action));
        batch.set(logRef, log);
      });
    await batch.commit();
  }
  return true;
}

export async function getSubjectLogs(siteId, trialId, subjectId) {
  const logs = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('logs')
    .orderBy('time')
    .get();
  querySnapshot.forEach(doc => {
    logs.push({ id: doc.id, ...doc.data() });
  });
  return logs;
}

export async function checkAndSubmitLogs(
  logs,
  { site, trial, subject },
  config
) {
  if (!config.firebaseConfig?.enableSubmitLogs) return false;
  if (!site || !trial || !subject) return false;
  const submittingLogs = logs.filter(log => !log.details.saved);
  const submitted = await writeLogs(site, trial, subject, submittingLogs);
  return !!submitted;
}
