export const defaultState = {
  email: '',
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_EMAIL': {
      const { email } = action.payload;
      return { ...state, email };
    }
    default: {
      return state;
    }
  }
};

export default user;
