export function getAverage(sample) {
  return sample.reduce((acc, cur) => acc + cur, 0) / sample.length;
}

export function getStandardDeviation(sample) {
  const mean = getAverage(sample);
  const sum = sample.reduce(
    (sum, i) => sum + Math.pow(Math.abs(i - mean), 2),
    0
  );
  return Math.sqrt(sum / (sample.length - 1));
}
