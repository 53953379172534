import firebase from 'firebase/app';
import 'firebase/firestore';

export async function getUser(email) {
  const doc = await firebase
    .firestore()
    .collection('users')
    .doc(email)
    .get();
  return { id: doc.id, email: doc.id, ...doc.data() };
}

export async function getUserRole(email) {
  const doc = await firebase
    .firestore()
    .collection('users')
    .doc(email)
    .get();
  if (!doc.exists) {
    return '';
  } else {
    const { role } = doc.data();
    return role;
  }
}

export async function createUser(email) {
  await firebase
    .firestore()
    .collection('users')
    .doc(email)
    .set({ email }, { merge: true });
  return true;
}
