import getNumberOfDays from './getNumberOfDays.js';
import transformStudyDateToDate from './transformStudyDateToDate.js';
import findTimepointIndex from './findTimepointIndex.js';
import { OVERALL_RESPONSE } from '../../constants/guideline.js';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @param {string[]} reports[].changeFromBaseline
 * @returns {string}
 */
function getBestOverallResponse(
  reports,
  isConfirmationRequired = true,
  minDuration = 0
) {
  const progressiveIndex = findTimepointIndex(reports, 'progressive');

  /** min duration */
  const baselineDate = transformStudyDateToDate(reports[0].date);
  const progressiveDate = transformStudyDateToDate(
    reports[progressiveIndex]?.date || '99991230'
  );
  const days = getNumberOfDays(baselineDate, progressiveDate);
  const existMinDuration = days > minDuration;

  const precedence = {
    [OVERALL_RESPONSE.COMPLETE_RESPONSE]: 5,
    [OVERALL_RESPONSE.PARTIAL_RESPONSE]: 4,
    [OVERALL_RESPONSE.STABLE_DISEASE]: 3,
    [OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE]: 2,
    [OVERALL_RESPONSE.PROGRESSIVE_DISEASE]: 1,
    [OVERALL_RESPONSE.NONE]: 0,
  };
  let bestResponse = OVERALL_RESPONSE.NONE;
  let responseIndex = 0;
  let changeFromBaseline = 100;
  reports.every((report, index) => {
    if (index === 0) return true;
    let currentResponse = report.result;
    const subsequentResponse = reports[index + 1]?.result || null;
    const isResponsive = [
      OVERALL_RESPONSE.COMPLETE_RESPONSE,
      OVERALL_RESPONSE.PARTIAL_RESPONSE,
    ].includes(currentResponse);
    if (isConfirmationRequired && isResponsive) {
      /** add default BOR: stable disease */
      const stable = OVERALL_RESPONSE.STABLE_DISEASE;
      if (precedence[stable] > precedence[bestResponse]) {
        bestResponse = stable;
        changeFromBaseline = report.changeFromBaseline;
        responseIndex = index;
      }
      /** skip when no subsequent response */
      if (!subsequentResponse) {
        return progressiveIndex < 0 || index <= progressiveIndex - 1;
      }
      /** get confirmed response according to RECIST 1.1 guideline */
      currentResponse = getConfirmedResponse(
        currentResponse,
        subsequentResponse,
        existMinDuration
      );
    }
    if (precedence[currentResponse] > precedence[bestResponse]) {
      bestResponse = report.result;
      changeFromBaseline = report.changeFromBaseline;
      responseIndex = index;
    }
    return progressiveIndex < 0 || index <= progressiveIndex - 1;
  });
  return {
    result: bestResponse,
    changeFromBaseline: changeFromBaseline,
    date: reports[responseIndex]?.date,
    index: responseIndex,
  };
}

/**
 * @param {string} firstResponse
 * @param {string} subsequentResponse
 * @param {boolean} existMinDuration
 * @returns {string}
 */
function getConfirmedResponse(
  firstResponse,
  subsequentResponse,
  existMinDuration
) {
  let bestResponse = firstResponse;
  switch (firstResponse) {
    case OVERALL_RESPONSE.COMPLETE_RESPONSE:
      switch (subsequentResponse) {
        case OVERALL_RESPONSE.COMPLETE_RESPONSE:
          bestResponse = OVERALL_RESPONSE.COMPLETE_RESPONSE;
          break;
        case OVERALL_RESPONSE.PARTIAL_RESPONSE:
        case OVERALL_RESPONSE.STABLE_DISEASE:
        case OVERALL_RESPONSE.PROGRESSIVE_DISEASE:
          bestResponse = existMinDuration
            ? OVERALL_RESPONSE.STABLE_DISEASE
            : OVERALL_RESPONSE.PROGRESSIVE_DISEASE;
          break;
        case OVERALL_RESPONSE.NOT_EVALUABLE:
          bestResponse = existMinDuration
            ? OVERALL_RESPONSE.STABLE_DISEASE
            : OVERALL_RESPONSE.NOT_EVALUABLE;
          break;
        default:
      }
      break;
    case OVERALL_RESPONSE.PARTIAL_RESPONSE:
      switch (subsequentResponse) {
        case OVERALL_RESPONSE.COMPLETE_RESPONSE:
        case OVERALL_RESPONSE.PARTIAL_RESPONSE:
          bestResponse = OVERALL_RESPONSE.PARTIAL_RESPONSE;
          break;
        case OVERALL_RESPONSE.STABLE_DISEASE:
          bestResponse = OVERALL_RESPONSE.STABLE_DISEASE;
          break;
        case OVERALL_RESPONSE.PROGRESSIVE_DISEASE:
        case OVERALL_RESPONSE.NOT_EVALUABLE:
          bestResponse = existMinDuration
            ? OVERALL_RESPONSE.STABLE_DISEASE
            : subsequentResponse;
          break;
      }
      break;
    default:
  }
  return bestResponse;
}

export default getBestOverallResponse;
