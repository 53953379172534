import * as dcmjs from 'dcmjs';

import { DICOMWeb } from '@platform/core';
import { meta } from './getRTStructDataset';
import { multipartEncode } from './message';

const { DicomMetaDictionary, DicomDict } = dcmjs.data;

class TokenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'TokenError';
    this.stack = new Error().stack;
  }
}

function handleError(res) {
  if (res.redirected) throw new Error('Session expired.');
  if (!res.ok) {
    if (res.status === 401) {
      // unauthorized requests
      throw new TokenError(res.statusText);
    } else {
      throw new Error(res.statusText);
    }
  }
}

export default async function(url, dataset) {
  await new Promise(resolve => setTimeout(resolve, 0));
  const denaturalized = DicomMetaDictionary.denaturalizeDataset(meta);
  const dicomDict = new DicomDict(denaturalized);
  dicomDict.dict = DicomMetaDictionary.denaturalizeDataset(dataset);
  const arrayBuffer = dicomDict.write();
  const encode = multipartEncode([arrayBuffer]);
  const headers = {
    ...DICOMWeb.getAuthorizationHeader(),
    'Content-Type': `multipart/related; type=application/dicom; boundary=${encode.boundary}`,
  };
  const res = await storeInstance(url, headers, encode.data);
  handleError(res);
  return dataset;
}

const storeInstance = (url, headers, body) => {
  const resource = `${url}/studies`;
  const options = { method: 'POST', headers, body, mode: 'cors' };
  return fetch(resource, options);
};
