import React, { lazy, Suspense, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, matchPath } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { ViewerbaseDragDropContext } from '@platform/ui';
import AppContext from '../context/AppContext';
import RedirectPage from './RedirectPage';

const Routes = ({ location }) => {
  const { appConfig = {} } = useContext(AppContext);
  const { pathname } = location;
  const routes = getRoutes(appConfig);
  const matchingRoutes = routes.find(r =>
    matchPath(pathname, { path: r.path, exact: true })
  );

  return (
    <Switch>
      <Route path="/redirect">
        <RedirectPage />
      </Route>
      {matchingRoutes ? (
        routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            {({ match }) =>
              match && (
                <Suspense fallback={<div>Loading...</div>}>
                  <Component match={match} location={location} />
                </Suspense>
              )
            }
          </Route>
        ))
      ) : (
        <RedirectPage />
      )}
    </Switch>
  );
};
Routes.propTypes = {
  location: PropTypes.object,
};

// Dynamic Import Routes (CodeSplitting)
const Routing = lazy(() =>
  import(/* webpackChunkName: "Routing" */ './Routing.js')
);
const ViewerRouting = lazy(() =>
  import(/* webpackChunkName: "ViewerRouting" */ './ViewerRouting.js')
);
const RtstructRouting = lazy(() =>
  import(/* webpackChunkName: "RtstructRouting" */ './RtstructRouting.js')
);
const TrackingRouting = lazy(() =>
  import(/* webpackChunkName: "TrackingRouting" */ './TrackingRouting.js')
);
const ReadRouting = lazy(() =>
  import(/* webpackChunkName: "ReadRouting" */ './ReadRouting.js')
);
const PatientListRouting = lazy(() =>
  import(
    /* webpackChunkName: "PatientListRouting" */ '../on_premise/PatientListRouting.js'
  )
);
const PlatformRouting = lazy(() =>
  import(
    /* webpackChunkName: "PlatformRouting" */ '../cloud/PlatformRouting.js'
  )
);
const ViewerLocalFileData = lazy(() =>
  import(
    /* webpackChunkName: "ViewerLocalFileData" */ './ViewerLocalFileData.js'
  )
);

const ROUTES_DEF = {
  default: {
    all: {
      path: ['/viewer'],
      component: Routing,
    },
    viewer: {
      path: [
        '/viewer/:studyInstanceUID/:seriesInstanceUID',
        '/viewer/:studyInstanceUID',
      ],
      component: ViewerRouting,
    },
    rtstructViewer: {
      path: ['/rtstruct/:studyInstanceUID/:seriesInstanceUID'],
      component: RtstructRouting,
    },
    trackingViewer: {
      path: ['/tracking/:studyInstanceUID/:seriesInstanceUID'],
      component: TrackingRouting,
      condition: appConfig => appConfig.enableTrackingExtension,
    },
    readViewer: {
      path: [
        '/sites/:site/trials/:trial/cohorts/:cohort/subjects/:subject/reads/:read',
      ],
      component: ReadRouting,
      condition: appConfig => appConfig.firebaseConfig.apiKey,
    },
    patientList: {
      path: [
        '/patients/studies/:studyInstanceUID/series/:seriesInstanceUID',
        '/patients/studies/:studyInstanceUID/series',
        '/patients/studies/:studyInstanceUID',
        '/patients/studies',
        '/patients',
        '/',
      ],
      component: PatientListRouting,
      condition: appConfig => appConfig.showPatientList,
    },
    /** Firebase: platform paths */
    platform: {
      path: [
        '/projects/:project/stores/:store/edit',
        '/projects/:project/stores/:store',
        '/projects/:project/createStore',
        '/projects/:project/edit',
        '/projects/:project',
        '/projects',
        '/createProject',
        '/trials/:trial/cohorts/:cohort/subjects/:subject',
        '/trials/:trial/cohorts/:cohort/edit',
        '/trials/:trial/cohorts/:cohort',
        '/trials/:trial/createCohort',
        '/trials/:trial/review',
        '/trials/:trial/edit',
        '/trials/:trial',
        '/trials',
        '/demo',
        '/createTrial',
        '/upload',
        '/workflows',
        '/tutorial/projects',
        '/tutorial/platform',
        '/tutorial/viewer',
        '/tutorial/longitudinal',
        '/faq',
        '/',
      ],
      component: PlatformRouting,
      condition: appConfig => appConfig.firebaseConfig.apiKey,
    },
    local: {
      path: '/local',
      component: ViewerLocalFileData,
      condition: appConfig => appConfig.enableLocal,
    },
  },
};

const getRoutes = appConfig => {
  const routes = [];
  for (let keyConfig in ROUTES_DEF) {
    const routesConfig = ROUTES_DEF[keyConfig];
    for (let routeKey in routesConfig) {
      const route = routesConfig[routeKey];
      const validRoute =
        typeof route.condition === 'function'
          ? route.condition(appConfig)
          : true;
      if (validRoute) {
        routes.push({ path: route.path, Component: route.component });
      }
    }
  }
  return routes;
};

export default ViewerbaseDragDropContext(withRouter(Routes));
