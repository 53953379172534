import { events, EVENTS } from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

import TOOL_NAMES from '../tools/constants/toolNames';
import RTStructDisplayTool from '../tools/RTStructDisplayTool';
import RepulserTool from '../tools/RepulserTool';
import SphereThresholdTool from '../tools/SphereThresholdTool';
import RegionGrowingTool from '../tools/RegionGrowingTool';
import EdgeTool from '../tools/EdgeTool';
import PolygonTool from '../tools/PolygonTool';
import DeleteTool from '../tools/DeleteTool';
import BooleanTool from '../tools/BooleanTool';
import MergeTool from '../tools/MergeTool';
import InterpolationTool from '../tools/InterpolationTool';
import ExpansionTool from '../tools/ExpansionTool';
import PointTool from '../tools/PointTool';
import BBoxTool from '../tools/BBoxTool';
import BBox3DTool from '../tools/BBox3DTool';
import editRTStructModule from '../tools/modules/editRTStructModule';

/**
 *
 * @param {object} configuration
 * @param {Object|Array} configuration.csToolsConfig
 */
export default function init({ servicesManager }) {
  cornerstoneTools.register(
    'module',
    'rtstruct-edit',
    editRTStructModule(servicesManager)
  );
  cornerstoneTools.addTool(RTStructDisplayTool);
  cornerstoneTools.addTool(RepulserTool);
  cornerstoneTools.addTool(SphereThresholdTool);
  cornerstoneTools.addTool(RegionGrowingTool);
  cornerstoneTools.addTool(EdgeTool);
  cornerstoneTools.addTool(PolygonTool);
  cornerstoneTools.addTool(BooleanTool);
  cornerstoneTools.addTool(MergeTool);
  cornerstoneTools.addTool(InterpolationTool);
  cornerstoneTools.addTool(ExpansionTool);
  cornerstoneTools.addTool(DeleteTool);
  cornerstoneTools.addTool(PointTool);
  cornerstoneTools.addTool(BBoxTool);
  cornerstoneTools.addTool(BBox3DTool);
  events.addEventListener(EVENTS.ELEMENT_ENABLED, function(evt) {
    const { element } = evt.detail;
    setToolPassive(element, TOOL_NAMES.RTSTRUCT_DISPLAY_TOOL);
  });
}
function setToolPassive(element, toolName) {
  cornerstoneTools.setToolEnabledForElement(element, toolName);
  cornerstoneTools.setToolPassiveForElement(element, toolName, {
    mouseButtonMask: [1],
    hideDefaultCursor: true,
  });
}
