import {
  polygonArea,
  polygonAxis,
  polygonLongAxis3D,
  getDistance3D,
  getWeightedCentroid,
} from '../utils/math';
import { makeHull } from '../utils/math/convexHull';
import getVolumeFromSlices from './getVolumeFromSlices';
import transformPointsToImagePlane from '../utils/transformPointsToImagePlane';
import transformPointsToPhysical from '../utils/transformPointsToPhysical';

/**
 * @param {Object[]} instances[].points
 * @param {String} instances[].ReferencedSOPInstanceUID
 * @param {Number[]} instances[].PixelSpacing
 * @param {Number[]} instances[].ImagePositionPatient
 * @param {Number[]} instances[].ImageOrientationPatient
 * @param {Number} instances[].SliceThickness
 * @param {Number} instances[].InstanceNumber
 * @param {String} roi.ROIName
 * @param {Number} roi.ROINumber
 * @param {Array} roi.data
 */
function getROIMeasurement(instances, roi) {
  // find instances of roi in specific series
  const contours = instances.reduce((contours, i) => {
    const data = roi.data.filter(
      d => d.ReferencedSOPInstanceUID === i.SOPInstanceUID
    );
    if (data.length === 0) data.push({ points: [] });
    const currentContours = data.map(d => ({
      ...d,
      PixelSpacing: i.PixelSpacing,
      ImagePositionPatient: i.ImagePositionPatient,
      ImageOrientationPatient: i.ImageOrientationPatient,
      SliceThickness: i.SliceThickness,
      SliceLocation: i.SliceLocation,
      InstanceNumber: i.InstanceNumber,
    }));
    return [...contours, ...currentContours];
  }, []);

  const mergedContours = [];
  contours.forEach(i => {
    const imagePlane = {
      RowPixelSpacing: i.PixelSpacing[0],
      ColumnPixelSpacing: i.PixelSpacing[1],
      ImagePositionPatient: i.ImagePositionPatient,
      RowCosines: i.ImageOrientationPatient.slice(0, 3),
      ColumnCosines: i.ImageOrientationPatient.slice(3, 6),
      ImageOrientationPatient: i.ImageOrientationPatient,
      SliceThickness: i.SliceThickness,
    };
    const imagePoints = transformPointsToImagePlane(i.points, imagePlane);

    /** axes */
    const axes = polygonAxis(imagePoints);
    const longAxisPoints = transformPointsToPhysical(
      axes.longAxis.points,
      imagePlane
    );
    const longAxisValue =
      longAxisPoints.length >= 2
        ? getDistance3D(longAxisPoints[0], longAxisPoints[1])
        : 0;
    const shortAxisPoints = transformPointsToPhysical(
      axes.shortAxis.points,
      imagePlane
    );
    const shortAxisValue =
      shortAxisPoints.length >= 2
        ? getDistance3D(shortAxisPoints[0], shortAxisPoints[1])
        : 0;

    /** area */
    const area =
      polygonArea(imagePoints) * i.PixelSpacing[0] * i.PixelSpacing[1];
    const centroid = getWeightedCentroid(imagePoints);
    const contour = {
      InstanceNumber: i.InstanceNumber,
      SliceLocation: i.SliceLocation,
      SliceThickness: i.SliceThickness,
      ImagePositionPatient: i.ImagePositionPatient,
      longAxis: longAxisValue,
      shortAxis: shortAxisValue,
      product: longAxisValue * shortAxisValue,
      mainArea: {
        value: area,
        centroid: centroid,
        pCentroid: transformPointsToPhysical([centroid], imagePlane)[0],
      },
      area: area,
    };

    // sum up/merge if more than one contour on an image
    const existContourOnImage = mergedContours.find(
      c => c.InstanceNumber === contour.InstanceNumber
    );
    if (existContourOnImage) {
      existContourOnImage.longAxis += contour.longAxis;
      existContourOnImage.shortAxis += contour.shortAxis;
      existContourOnImage.product += contour.product;
      existContourOnImage.area += contour.area;
      if (existContourOnImage.mainArea < contour.mainArea) {
        existContourOnImage.mainArea = contour.mainArea;
      }
    } else {
      mergedContours.push(contour);
    }
  });

  // get measurement of max area
  const measurement = mergedContours.reduce(
    (acc, cur) => {
      return cur.area > acc.area ? cur : acc;
    },
    {
      InstanceNumber: -1,
      SliceLocation: null,
      longAxis: 0,
      shortAxis: 0,
      product: 0,
      area: 0,
      mainArea: {
        value: 0,
        centroid: { x: null, y: null },
        pCentroid: { x: null, y: null, z: null },
      },
    }
  );

  /** 3D long axis */
  const allPoints = contours.reduce(
    (pts, c) => [...pts, ...makeHull(c.points)],
    []
  );
  const longAxis3D = polygonLongAxis3D(allPoints);

  /** volume */
  const volume = getVolumeFromSlices(mergedContours);

  return {
    ROIName: roi.ROIName,
    ROINumber: roi.ROINumber,
    InstanceNumber: measurement.InstanceNumber,
    longAxis3DValue: longAxis3D.value,
    maxLongAxisValue: measurement.longAxis,
    maxShortAxisValue: measurement.shortAxis,
    maxProductValue: measurement.product,
    maxAreaValue: measurement.area,
    maxAreaCentroid: measurement.mainArea.centroid,
    maxAreaPhysicalCentroid: measurement.mainArea.pCentroid,
    volume: volume,
  };
}

export default getROIMeasurement;
