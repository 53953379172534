// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-ohif-container {
  font-size: 1em;
  color: var(--text-primary-color);
  font-weight: 400;
}

.input-ohif-container .input-ohif {
  display: inline-block;
  height: 40px;
  margin: 0 10px 20px;
  padding: 0 20px;
  cursor: pointer;
  border: none;
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
  font-size: 10pt;
  font-weight: normal;
  width: auto;
  border-radius: 4px;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.input-ohif-container .input-ohif-label {
  padding: 5px;
}

.input-ohif-container .input-ohif:active,
.input-ohif-container .input-ohif:focus {
  background-color: var(--input-background-color);
}

.input-ohif-container .input-ohif.invisible {
  visibility: hidden;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/textInput/TextInput.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,YAAY;EACZ,+CAA+C;EAC/C,qCAAqC;EACrC,eAAe;EACf,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,kCAAkC;EAClC,0BAA0B;AAC5B;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,+CAA+C;AACjD;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".input-ohif-container {\n  font-size: 1em;\n  color: var(--text-primary-color);\n  font-weight: 400;\n}\n\n.input-ohif-container .input-ohif {\n  display: inline-block;\n  height: 40px;\n  margin: 0 10px 20px;\n  padding: 0 20px;\n  cursor: pointer;\n  border: none;\n  background-color: var(--input-background-color);\n  color: var(--input-placeholder-color);\n  font-size: 10pt;\n  font-weight: normal;\n  width: auto;\n  border-radius: 4px;\n  -webkit-transition: all 0.15s ease;\n  transition: all 0.15s ease;\n}\n\n.input-ohif-container .input-ohif-label {\n  padding: 5px;\n}\n\n.input-ohif-container .input-ohif:active,\n.input-ohif-container .input-ohif:focus {\n  background-color: var(--input-background-color);\n}\n\n.input-ohif-container .input-ohif.invisible {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
