import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { redux } from '@platform/core';
import { Tooltip, ToolbarButton, OverlayTrigger } from '@platform/ui';

const { setScrollSync, setPanZoomSync, setWwwcSync } = redux.actions;

export default function SyncButton({ button }) {
  const { isScrollSync, isPanZoomSync, isWwwcSync } = useSelector(
    state => state.extensions.cornerstone
  );
  const dispatch = useDispatch();

  const onScrollSyncClick = () => {
    dispatch(setScrollSync(!isScrollSync));
    button.commandsManager.runCommand('toggleScrollSynchronizer', {
      toggledState: !isScrollSync,
    });
  };
  const onPanZoomSyncClick = () => {
    dispatch(setPanZoomSync(!isPanZoomSync));
    button.commandsManager.runCommand('togglePanZoomSynchronizer', {
      toggledState: !isPanZoomSync,
    });
  };
  const onWwwcSyncClick = () => {
    dispatch(setWwwcSync(!isWwwcSync));
    button.commandsManager.runCommand('toggleWwwcSynchronizer', {
      toggledState: !isWwwcSync,
    });
  };

  const getToolBarButtonComponent = () => {
    return (
      <ToolbarButton
        key={button.id}
        label={button.label}
        icon={button.icon}
        isActive={!!(isScrollSync || isPanZoomSync || isWwwcSync)}
      />
    );
  };

  const getOverlayComponent = () => {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-toolbar-overlay"
        id={`${Math.random()}_tooltip-toolbar-overlay}`}
      >
        <ToolbarButton
          key={'bars'}
          label={'Scroll Sync'}
          icon={'bars'}
          onClick={onScrollSyncClick}
          isActive={isScrollSync}
        />
        <ToolbarButton
          key={'search-plus'}
          label={'Pan Zoom Sync'}
          icon={'search-plus'}
          onClick={onPanZoomSyncClick}
          isActive={isPanZoomSync}
        />
        <ToolbarButton
          key={'level'}
          label={'Level Sync'}
          icon={'level'}
          onClick={onWwwcSyncClick}
          isActive={isWwwcSync}
        />
      </Tooltip>
    );
  };

  const toolbarComponent = getToolBarButtonComponent();
  const overlayComponent = getOverlayComponent();

  return (
    <OverlayTrigger
      key="menu-button"
      trigger="click"
      placement="bottom"
      rootClose={true}
      handleHide={null}
      onClick={event => event.stopPropagation()}
      overlay={overlayComponent}
    >
      {toolbarComponent}
    </OverlayTrigger>
  );
}
SyncButton.propTypes = {
  button: PropTypes.object,
};
