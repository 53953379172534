import cornerstone from 'cornerstone-core';
import transformPointsToImagePlane from './transformPointsToImagePlane';

export default function transformPointsToImagePlaneById(points, imageId) {
  const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
  return transformPointsToImagePlane(points, {
    RowPixelSpacing: imagePlane.rowPixelSpacing,
    ColumnPixelSpacing: imagePlane.columnPixelSpacing,
    ImagePositionPatient: imagePlane.imagePositionPatient,
    ImageOrientationPatient: imagePlane.imageOrientationPatient,
    SliceThickness: imagePlane.sliceThickness,
  });
}
