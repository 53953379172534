export default function checkNumberOfTargets(ROIContours, criteria) {
  if (
    !ROIContours ||
    !criteria?.maxNumberOfTargets ||
    !criteria?.maxNumberOfTargetsPerOrgan
  ) {
    return { result: false, message: 'Invalid input.' };
  }
  const targets = ROIContours.filter(roi => roi.trackingType === 'target');
  const number = targets.length;
  if (number > criteria.maxNumberOfTargets) {
    return {
      result: false,
      message: `The number of target lesion is more than ${criteria.maxNumberOfTargets}.`,
    };
  }
  const organNumbers = Object.values(
    targets.reduce((bodyParts, target) => {
      bodyParts[target.bodyPart] = bodyParts[target.bodyPart] || 0;
      bodyParts[target.bodyPart] += 1;
      return bodyParts;
    }, {})
  );
  if (
    organNumbers.find(number => number > criteria.maxNumberOfTargetsPerOrgan)
  ) {
    return {
      result: false,
      message: `The number of target lesion per organ is more than ${criteria.maxNumberOfTargetsPerOrgan}.`,
    };
  }
  return { result: true, message: '' };
}
