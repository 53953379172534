import BasePainterTool from './BasePainterTool';
import TOOL_NAMES from './constants/toolNames';

export default class BooleanTool extends BasePainterTool {
  constructor(props = {}) {
    const defaultProps = { name: TOOL_NAMES.BOOLEAN_TOOL };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps, 'boolean-operation');
  }
}
