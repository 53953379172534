import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';

import { Range, Tooltip, ToolbarButton, OverlayTrigger } from '@platform/ui';

const Button = ({ button, onClick, isActive }) => {
  const unit = 0.5; // 0.5mm per step
  const maxRadius = 30; // 3cm
  const minRadius = 1; // 1mm

  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const repulserConfig = editModule.getters.painterConfig('repulser');
  const [radius, setRadius] = useState(repulserConfig.radius);

  useEffect(() => {
    editModule.setters.painterConfig('repulser', { radius: radius });
  }, [radius]);

  const getOverlay = () => {
    return (
      <Tooltip
        placement="bottom"
        className="tooltip-toolbar-overlay"
        id={`${Math.random()}_tooltip-toolbar-overlay}`}
      >
        <div
          className="range"
          style={{
            color: 'var(--default-color)',
            margin: '0 10px 10px',
            width: '240px',
          }}
        >
          <div htmlFor="range" style={{ margin: '0 0 15px' }}>
            Radius({radius.toFixed(1)} mm)
          </div>
          <Range
            step={1}
            min={minRadius / unit}
            max={maxRadius / unit}
            value={radius / unit}
            onChange={event => {
              setRadius(parseInt(event.target.value, 10) * unit);
              event.target.blur();
            }}
          />
        </div>
      </Tooltip>
    );
  };

  return (
    <OverlayTrigger
      key="menu-button"
      trigger="click"
      placement="bottom"
      rootClose={true}
      handleHide={null}
      onClick={evt => evt.stopPropagation()}
      overlay={getOverlay()}
    >
      <ToolbarButton
        key={button.id}
        label={button.label}
        icon={button.icon}
        onClick={onClick}
        isActive={isActive}
        disabled={button.disabled}
      />
    </OverlayTrigger>
  );
};
Button.propTypes = {
  button: PropTypes.any,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
};

export default Button;
