import getNewLesionResponse from './getNewLesionResponse.js';

export function confirmNewLesionsOfTimepoints(
  timepoints,
  newLesionROIContours,
  criteria
) {
  const needConfirmation =
    criteria.newLesionConfirmation === 'REQUIRED' ||
    criteria.newLesionDateOfProgression === 'USE_INITIAL_DATE';
  if (!needConfirmation) return;

  newLesionROIContours.forEach(roi => {
    let confirmingLesions = [];
    let confirmedLesions = [];
    timepoints.forEach((timepoint, index) => {
      const measurableLongAxisOfNewLesion =
        timepoint?.SliceThickness >= criteria?.highSliceThickness
          ? criteria.measurableLongAxisOfNewLesionHST
          : criteria.measurableLongAxisOfNewLesion;
      const lesion = timepoint.Lesions.find(
        lesion => lesion.ROINumber === roi.ROINumber
      );
      lesion.isUnequivocal =
        lesion.maxLongAxisValue >= measurableLongAxisOfNewLesion;
      lesion.isConfirmed =
        criteria.newLesionConfirmation === 'REQUIRED'
          ? false
          : lesion.isUnequivocal;

      const isBaseline = index === 0;
      if (isBaseline) return;

      let requireNextTimepoint = false;
      if (lesion.maxAreaValue > 0) {
        confirmingLesions.push({
          index,
          isUnequivocal: lesion.isUnequivocal,
        });
        requireNextTimepoint = index < timepoints.length - 1;
      }
      if (requireNextTimepoint) return;

      const isConfirmed =
        confirmingLesions.length > 1 &&
        confirmingLesions.some(lesion => lesion.isUnequivocal);
      if (isConfirmed) {
        confirmedLesions.push(...confirmingLesions);
      }
      confirmingLesions = [];

      const isLastTimepoint = index === timepoints.length - 1;
      if (isLastTimepoint && lesion.isUnequivocal) {
        confirmedLesions.push({
          index,
          maxLongAxisValue: lesion.maxLongAxisValue,
        });
      }
    });
    confirmedLesions.reduce((existProgression, l) => {
      const lesion = timepoints[l.index].Lesions.find(
        lesion => lesion.ROINumber === roi.ROINumber
      );
      lesion.isConfirmed = existProgression || lesion.isUnequivocal;
      return lesion.isConfirmed;
    }, criteria.newLesionDateOfProgression === 'USE_INITIAL_DATE');
  });
}

/**
 * @param {Object[]} timepoints
 * @param {Object[]} newLesionROIContours
 * @param {Number} criteria.measurableLongAxisOfNewLesion
 */
function getNewLesionResponses(timepoints, newLesionROIContours, criteria) {
  confirmNewLesionsOfTimepoints(timepoints, newLesionROIContours, criteria);
  return timepoints.map(timepoint =>
    getNewLesionResponse(timepoint, newLesionROIContours, criteria)
  );
}

export default getNewLesionResponses;
