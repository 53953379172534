import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

import { redux } from '@platform/core';
import { Grid, Body, Container, InputBox, Input, Button } from '@platform/ui';
import LesionItem from './report/LesionItem';
import exportReport from './report/exportReport';
import ExportAlert from '../../common/ExportAlert';
import * as models from '../../../modules/dicom-measurement/src';

const { actions } = redux;

function setViewports({ scale, windowWidth, windowLevel }) {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    try {
      let viewport = cornerstone.getViewport(enabledElement.element);
      viewport.scale = Number(scale) || viewport.scale;
      viewport.voi = {
        windowWidth: Number(windowWidth) || viewport.voi.windowWidth,
        windowCenter: Number(windowLevel) || viewport.voi.windowCenter,
      };
      cornerstone.setViewport(enabledElement.element, viewport);
    } catch (err) {
      return;
    }
  });
}

export const ReportSection = ({ imageSets, PatientID }) => {
  const dispatch = useDispatch();
  const { seriesStructureMap } = useSelector(state => state.extensions.dicomRT);
  const { notes, scale, windowWidth, windowLevel } = useSelector(
    state => state.extensions.tracking
  );
  const { Links: links, ReferencedRTSS: references } = useSelector(
    state => state.linkage
  );
  const [data, setData] = useState(null);
  const newData = {
    ...data,
    Lesions: data?.Lesions.map(l => {
      const { ROINumber } = l.currentTimepoint;
      const note = notes[ROINumber];
      return {
        ...l,
        currentTimepoint: { ...l.currentTimepoint, note: note || '' },
      };
    }),
  };

  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      const editModule = cornerstoneTools.getModule('rtstruct-edit');
      const structureSets = editModule.getters.structureSets();
      const referencedImageSets = models.getImageSetsFromReferences(
        imageSets,
        references,
        seriesStructureMap
      );
      const timepointManager = new models.TimepointManager();
      timepointManager.addTimepointsByImageSets(
        referencedImageSets,
        structureSets,
        seriesStructureMap,
        false
      );
      timepointManager.addLinks(links);
      const report = timepointManager.getTimepointsReport();
      setData(report);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scale !== 0 || windowWidth !== 0 || windowLevel !== 0) {
      setViewports({ scale, windowWidth, windowLevel });
    }
  }, [scale, windowWidth, windowLevel]);

  function updateLesionNote(id, note) {
    const newNotes = { ...notes, [id]: note };
    dispatch(actions.setTrackingNotes(newNotes));
  }

  async function onExportClick() {
    newData.Lesions.forEach(l => {
      if (l.displayImage) {
        const element = document.getElementById(l.currentTimepoint.id);
        const canvas = cornerstone.getEnabledElement(element).canvas;
        const dataUrl = canvas.toDataURL('image/jpeg');
        l.currentTimepoint.imageURL = dataUrl;
        l.previousTimepoints.forEach(timepoint => {
          const element = document.getElementById(timepoint.id);
          const canvas = cornerstone.getEnabledElement(element).canvas;
          const dataUrl = canvas.toDataURL('image/jpeg');
          timepoint.imageURL = dataUrl;
        });
      }
    });
    exportReport(`report-${PatientID}.pdf`, newData);
  }

  if (!data) return <div />;

  return (
    <Body>
      <Container>
        {newData.Lesions.map(lesion => {
          const { id } = lesion.currentTimepoint;
          return (
            <LesionItem
              key={id}
              lesion={lesion}
              updateLesionNote={updateLesionNote}
            />
          );
        })}
      </Container>
      <div style={{ marginTop: '15px' }}>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputBox label={'Scale:'} ratio={1}>
              <Input
                value={scale}
                onChange={evt => {
                  const value = Number(evt.target.value);
                  dispatch(actions.setTrackingScale(value));
                }}
              />
            </InputBox>
          </Grid>
          <Grid item xs={3}>
            <InputBox label={'Window Width:'} ratio={1}>
              <Input
                value={windowWidth}
                onChange={evt => {
                  const value = Number(evt.target.value);
                  dispatch(actions.setTrackingWindowWidth(value));
                }}
              />
            </InputBox>
          </Grid>
          <Grid item xs={3}>
            <InputBox label={'Window Level:'} ratio={1}>
              <Input
                value={windowLevel}
                onChange={evt => {
                  const value = Number(evt.target.value);
                  dispatch(actions.setTrackingWindowLevel(value));
                }}
              />
            </InputBox>
          </Grid>
          <Grid item xs={3}>
            <InputBox label={''}>
              <Button
                onClick={onExportClick}
                color="secondary"
                variant="contained"
                medium
                fullWidth
              >
                Export
              </Button>
            </InputBox>
          </Grid>
        </Grid>
      </div>
    </Body>
  );
};
ReportSection.propTypes = {
  imageSets: PropTypes.array,
  PatientID: PropTypes.string,
};
