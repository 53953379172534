import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../../elements';
import './TableListItem.styl';

export function TableListItem(props) {
  const onItemClick = event => {
    if (props.onItemClick) {
      event.preventDefault();
      event.stopPropagation();
      props.onItemClick(event, props.itemKey);
    }
  };

  return (
    <div
      data-cy={props['data-cy']}
      className={`tableListItem ${props.itemClass} ${props.itemSelected &&
        'selected'}`}
      onClick={onItemClick}
    >
      <div className={`itemIndex ${props.itemMetaClass}`}>
        {props.itemIndex}
        {props.itemMeta}
        <span className="warning-icon">
          <Icon name="exclamation-triangle" />
        </span>
      </div>
      <div className="itemContent">{props.children}</div>
    </div>
  );
}
TableListItem.propTypes = {
  'data-cy': PropTypes.string,
  children: PropTypes.node,
  itemClass: PropTypes.string,
  itemIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemMeta: PropTypes.node,
  itemMetaClass: PropTypes.string,
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemSelected: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
};
TableListItem.defaultProps = {
  itemMeta: null,
  itemMetaClass: '',
  itemSelected: false,
};
