import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

import theme from './theme';

const Component = props => {
  const { loading } = props;
  return loading === 'true' ? (
    <Button {...props}>
      <LoadingSpinner width={'25px'} height={'25px'} />
    </Button>
  ) : (
    <Button {...props} />
  );
};
Component.propTypes = {
  loading: PropTypes.string,
};

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 10px;
  border: 0;
  border-radius: 4px;
  outline: 0;
  user-select: none;
  -moz-appearance: none;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.03em;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  color: ${theme.primary.contained.color};
  background-color: ${theme.primary.contained.background};
  cursor: pointer;

  &:hover {
    background-color: ${theme.primary.contained.hover}
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.12);
    cursor: not-allowed;
    :hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }

  ${props =>
    props.medium &&
    css`
      padding: 6px 10px;
    `}

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  ${props =>
    props.color &&
    props.variant &&
    css`
      color: ${theme[props.color][props.variant].color};
      background-color: ${theme[props.color][props.variant].background};
      :hover {
        background-color: ${theme[props.color][props.variant].hover};
      }
    `}
`;

export default Component;
