import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal, EVENTS } from 'cornerstone-tools';
import _ from 'lodash';
import { refreshViewport } from './commands';

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');
export const getNewContext = importInternal('drawing/getNewContext');
const BaseTool = importInternal('base/BaseTool');

/**
 * @class RTStructDisplayTool - Renders RTSTRUCT data in a read only manner (i.e. as an overlay).
 * @extends cornerstoneTools.BaseTool
 */
export default class BasePainterTool extends BaseTool {
  constructor(props = {}, painterMode) {
    const defaultProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      mixins: ['renderBrushMixin'],
      configuration: {},
    };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps);

    this._module = cornerstoneTools.getModule('rtstruct-edit');
    this._module.addEventListener('ROISelected', () => refreshViewport());
    this._module.addEventListener('Highlighted', () => refreshViewport());
    this._isDrawing = false;
    this._cursorCanvasPosition = null;
    this._painterMode = painterMode;
    this._imageId = null;
    const computationalModes = [
      'boolean-operation',
      'interpolation',
      'merge',
      'expansion',
    ];
    if (computationalModes.includes(painterMode)) return;
    for (const mixin of [touchMixin, mouseMixin]) {
      _.assign(this, mixin);
      mixin.init.apply(this, arguments);
    }
  }

  renderToolData(evt) {
    const eventData = evt.detail;
    const painter = this._getPainter(eventData.element);
    const context = getNewContext(eventData.canvasContext.canvas);
    if (!painter) return;
    painter.cursor(evt, context, this._cursorCanvasPosition, this._isDrawing);
  }

  _switchPainterMode(painterMode, element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    const painter = this._module.getters.peekPainter(imageId);
    const newPainter = this._module.setters.createPainter(
      painterMode,
      imageId,
      painter._structureSetSeriesInstanceUid
    );
    return newPainter;
  }

  _preInteractionCallback(evt) {
    if (process.env.READONLY) return;
    const selectedROINumber = this._module.getters.selectedROINumber();
    if (!_.isNumber(selectedROINumber)) return;

    const eventData = evt.detail;
    const { element } = eventData;

    const painter = this._switchPainterMode(this._painterMode, element);
    if (!painter) return;
    const uid = this._module.getters.selectedStructureSetUID();
    if (uid !== painter._structureSetSeriesInstanceUid) return;
    const isEditable = this._module.getters.isEditable();
    if (!isEditable) return;
    this._imageId = cornerstone.getEnabledElement(
      evt.detail.element
    ).image.imageId;

    this._isDrawing = true;
    this._paint(evt);
  }

  _paint(evt) {
    const painter = this._getPainter(evt.detail.element);

    if (painter && painter.update(evt, this._imageId)) {
      cornerstone.updateImage(evt.detail.element);
    }
  }

  _getPainter(element) {
    const imageId = cornerstone.getEnabledElement(element).image.imageId;
    return this._module.getters.peekPainter(imageId);
  }

  _getPainters(element) {
    const toolState = cornerstoneTools.getToolState(element, 'stack');
    if (!toolState) return [];
    const { imageIds } = toolState.data[0];
    const painters = imageIds.map(id => this._module.getters.peekPainter(id));
    return painters;
  }

  _updateCursorPosition(evt) {
    this._cursorCanvasPosition = evt.detail.currentPoints.canvas;
  }

  _drawingUpCallback(evt, shouldUpdate = true) {
    const eventData = evt.detail;
    const element = eventData.element;

    this._isDrawing = false;
    delete this._imageId;
    delete this._cursorCanvasPosition;

    if (shouldUpdate) {
      const painter = this._getPainter(element);
      painter.commit(evt);
      refreshViewport();
    }
  }
}

const mouseMixin = {
  init: function() {
    this._drawingMouseUpCallback = this._drawingMouseUpCallback.bind(this);
  },
  preMouseDownCallback(evt) {
    this._updateCursorPosition(evt);
    try {
      this._preInteractionCallback(evt);
    } catch (error) {
      this._drawingUpCallback(evt, false);
      throw error;
    }

    const eventData = evt.detail;
    const { element } = eventData;
    this._startListeningForMouseUp(element);
  },

  mouseMoveCallback(evt) {
    this._updateCursorPosition(evt);
  },

  _drawingMouseUpCallback(evt) {
    this._drawingUpCallback(evt);
    const eventData = evt.detail;
    const element = eventData.element;

    this._stopListeningForMouseUp(element);
  },

  _startListeningForMouseUp(element) {
    this._stopListeningForMouseUp(element);

    try {
      element.addEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);
      element.addEventListener(
        EVENTS.MOUSE_CLICK,
        this._drawingMouseUpCallback
      );
    } catch (error) {
      this._stopListeningForMouseUp(element);
      throw error;
    }
  },

  _stopListeningForMouseUp(element) {
    element.removeEventListener(EVENTS.MOUSE_UP, this._drawingMouseUpCallback);
    element.removeEventListener(
      EVENTS.MOUSE_CLICK,
      this._drawingMouseUpCallback
    );
  },
  mouseDragCallback: function(evt) {
    this._updateCursorPosition(evt);
    if (this._isDrawing) {
      this._paint(evt);
    }
  },
};

const touchMixin = {
  init: function() {
    this._dragDelta = null;
    this._dragLastPoint = null;
  },
  touchDragCallback: function(evt) {
    if (!this._isDrawing) {
      this._preTouch(evt);
    } else {
      if (this._dragDelta) {
        this._fixDragPointDelta(evt);
      }
      this._updateCursorPosition(evt);
      this._paint(evt);
    }
    this._dragLastPoint = evt.detail.currentPoints;
  },
  _fixDragPointDelta(evt) {
    evt.detail.currentPoints = _.reduce(
      this._dragDelta,
      (currentPoints, delta, key) => {
        return {
          ...currentPoints,
          [key]: {
            x: evt.detail.currentPoints[key].x + delta.x,
            y: evt.detail.currentPoints[key].y + delta.y,
          },
        };
      },
      {}
    );
    evt.detail.lastPoints = _.reduce(
      evt.detail.currentPoints,
      function(lastPoints, currentPoint, key) {
        return {
          ...lastPoints,
          [key]: {
            x: currentPoint.x - evt.detail.deltaPoints[key].x,
            y: currentPoint.y - evt.detail.deltaPoints[key].y,
          },
        };
      },
      {}
    );
  },
  _preTouch: function(evt) {
    this._updateCursorPosition(evt);
    try {
      this._preInteractionCallback(evt);
    } catch (error) {
      this.touchEndCallback(evt);
      throw error;
    }
  },

  touchEndCallback: function(evt) {
    this._drawingUpCallback(evt);
    this._dragDelta = null;
    this._dragLastPoint = null;
  },

  preTouchStartCallback: function(evt) {
    if (!this._isDrawing) {
      this._preTouch(evt);
    }

    if (this._dragLastPoint) {
      const lastPoints = this._dragLastPoint;
      const currentPoints = evt.detail.currentPoints;
      this._dragDelta = _.reduce(
        currentPoints,
        (dragDelta, currentPoint, key) => {
          return {
            ...dragDelta,
            [key]: {
              x: lastPoints[key].x - currentPoint.x,
              y: lastPoints[key].y - currentPoint.y,
            },
          };
        },
        {}
      );
    }
  },
};
