import RECIST11 from '../guidelines/RECIST11';
import MRECIST11 from '../guidelines/MRECIST11';
import RANOBM from '../guidelines/RANOBM';
import RANOBM5MM from '../guidelines/RANOBM5MM';
import CUSTOM from '../guidelines/CUSTOM';
import CUSTOM1 from '../guidelines/CUSTOM1';
import CUSTOM4 from '../guidelines/CUSTOM4';

const guidelines = {
  RECIST11,
  MRECIST11,
  RANOBM,
  RANOBM5MM,
  CUSTOM,
  CUSTOM1,
  CUSTOM4,
};

export default guidelines;
