/* eslint-disable no-console */
import cornerstoneTools from 'cornerstone-tools';
import * as dcmjs from 'dcmjs';
import _ from 'lodash';

import { DICOMWeb, utils } from '@platform/core';
import { multipartDecode } from './message';
import TOOL_NAMES from '../../tools/constants/toolNames';
import {
  math,
  transformPointsToImagePlaneById,
} from '../dicom-measurement/src';
const { calibrate } = math;

const { DicomMessage, DicomMetaDictionary, Colors } = dcmjs.data;
const { dicomlab2RGB } = Colors;
const globalImageIdSpecificToolStateManager =
  cornerstoneTools.globalImageIdSpecificToolStateManager;
const { getArray, isHighPriorityModality } = utils;

async function fetchDICOMdata(dicomWebUrl, config) {
  let dicomData = {};
  if (config.dicomWebServer === 'orthanc') {
    const rt_res = await fetch(dicomWebUrl, {
      headers: {
        ...DICOMWeb.getAuthorizationHeader(),
        accept:
          'multipart/related; type=application/dicom; transfer-syntax=1.2.840.10008.1.2',
      },
      responseType: 'arraybuffer',
    });
    const arrayBuffer = await rt_res.arrayBuffer();
    const file = multipartDecode(arrayBuffer)[0];
    dicomData = DicomMessage.readFile(file);
  } else if (config.dicomWebServer === 'healthcareApi') {
    const rt_res = await fetch(dicomWebUrl, {
      headers: {
        ...DICOMWeb.getAuthorizationHeader(),
        accept: 'application/dicom; transfer-syntax=*',
      },
    });
    const segArrayBuffer = await rt_res.arrayBuffer();
    dicomData = DicomMessage.readFile(segArrayBuffer);
  }
  return dicomData;
}

export default async function loadRTStruct(studies, displaySet, config) {
  if (displaySet.isLoaded) return;
  const module = cornerstoneTools.getModule('rtstruct-edit');
  let dataset;
  if (window.location.pathname.split('/')[1] === 'local') {
    dataset = displaySet.metadata;
  } else if (
    config.dicomWebServer === 'orthanc' &&
    displaySet.metadata.SOPInstanceUID &&
    displaySet.metadata.StructureSetROISequence &&
    displaySet.metadata.ROIContourSequence &&
    displaySet.metadata.RTROIObservationsSequence &&
    displaySet.metadata.ReferencedFrameOfReferenceSequence
  ) {
    dataset = displaySet.metadata;
  } else {
    const {
      wadoRoot: root,
      StudyInstanceUID: study_uid,
      SeriesInstanceUID: series_uid,
      SOPInstanceUID: instance_uid,
    } = displaySet;
    const url = `${root}/studies/${study_uid}/series/${series_uid}/instances/${instance_uid}`;
    const data = await fetchDICOMdata(url, config);
    dataset = DicomMetaDictionary.naturalizeDataset(data.dict);
    dataset._meta = DicomMetaDictionary.namifyDataset(data.meta);
  }
  displaySet.isLoaded = true;

  /** structure set entry --> rtstruct module state */
  const { ReferencedSeriesSequence } = displaySet.metadata;
  const structureSet = {
    StructureSetLabel: dataset.StructureSetLabel,
    StudyInstanceUID: dataset.StudyInstanceUID,
    SeriesInstanceUID: dataset.SeriesInstanceUID,
    referencedFrameOfReferenceSequence: getArray(
      dataset.ReferencedFrameOfReferenceSequence
    ),
    referencedSeriesSequence: ReferencedSeriesSequence,
    ROIContours: [],
    visible: true,
  };
  module.setters.rawStructureSet(structureSet);

  /** referenced series image ids */
  const referencedSeries = studies
    .reduce((displaySets, study) => [...displaySets, ...study.displaySets], [])
    .filter(d => isHighPriorityModality(d.Modality));
  let imageIdSopInstanceUidPairs = [];
  for (let i = 0; i < referencedSeries.length; i++) {
    const ImageIdSopInstanceUidPairsForDisplaySet = _getImageIdSopInstanceUidPairsForDisplaySet(
      studies,
      referencedSeries[i].StudyInstanceUID,
      referencedSeries[i].SeriesInstanceUID
    );
    imageIdSopInstanceUidPairs = [
      ...imageIdSopInstanceUidPairs,
      ...ImageIdSopInstanceUidPairsForDisplaySet,
    ];
  }

  /** measurements --> global cornerstone tools state */
  const toolState = globalImageIdSpecificToolStateManager.saveToolState();
  const rtStructDisplayToolName = TOOL_NAMES.RTSTRUCT_DISPLAY_TOOL;
  const rois = _loadSequence(dataset.StructureSetROISequence);
  const observations = _loadSequence(dataset.RTROIObservationsSequence);
  const contours = _loadSequence(dataset.ROIContourSequence);
  for (let i = 0; i < contours.length; i++) {
    const ROIContour = contours[i];
    _setROIContourMetadata(structureSet, rois, observations, ROIContour);
    let { ReferencedROINumber, ContourSequence } = ROIContour;
    if (!ContourSequence) continue;
    if (!_.isArray(ContourSequence)) ContourSequence = [ContourSequence];
    for (let c = 0; c < ContourSequence.length; c++) {
      const {
        ContourImageSequence,
        ContourData,
        NumberOfContourPoints,
        ContourGeometricType,
      } = ContourSequence[c];
      if (ContourGeometricType !== 'CLOSED_PLANAR') continue;
      const sopInstanceUID = ContourImageSequence.ReferencedSOPInstanceUID;
      const imageId = _getImageId(imageIdSopInstanceUidPairs, sopInstanceUID);
      if (!imageId) continue;
      const imageIdSpecificToolData = _getOrCreateImageIdSpecificToolData(
        toolState,
        imageId,
        rtStructDisplayToolName
      );
      const points = [];
      for (let p = 0; p < NumberOfContourPoints * 3; p += 3) {
        const [x, y, z] = ContourData.slice(p, p + 3);
        points.push({ x, y, z });
      }
      const imagePoints = transformPointsToImagePlaneById(points, imageId);
      const measurementData = {
        handles: {
          points: config.contour_calibration
            ? calibrate(imagePoints, { x: 0.5, y: 0.5 })
            : imagePoints,
        },
        structureSetSeriesInstanceUid: dataset.SeriesInstanceUID,
        ROINumber: ReferencedROINumber,
      };
      imageIdSpecificToolData.push(measurementData);
    }
  }
}

function _loadSequence(sequence) {
  let sq = sequence;
  if (!_.isArray(sequence)) sq = sequence ? [sequence] : [];
  return sq;
}

function _setROIContourMetadata(
  structureSet,
  StructureSetROISequence,
  RTROIObservationsSequence,
  ROIContour
) {
  const StructureSetROI = StructureSetROISequence.find(
    ({ ROINumber }) => ROINumber === ROIContour.ReferencedROINumber
  );
  const ROIContourData = {
    ROINumber: StructureSetROI.ROINumber,
    ROIName: StructureSetROI.ROIName,
    ROIGenerationAlgorithm: StructureSetROI.ROIGenerationAlgorithm,
    ROIDescription: StructureSetROI.ROIDescription,
    visible: true,
  };
  _setROIContourDataColor(ROIContour, ROIContourData);

  if (RTROIObservationsSequence) {
    // If present, add additional RTROIObservations metadata.
    _setROIContourRTROIObservations(
      ROIContourData,
      RTROIObservationsSequence,
      ROIContour.ReferencedROINumber
    );
  }

  structureSet.ROIContours.push(ROIContourData);
}

function _setROIContourDataColor(ROIContour, ROIContourData) {
  let { ROIDisplayColor, RecommendedDisplayCIELabValue } = ROIContour;
  if (!ROIDisplayColor && RecommendedDisplayCIELabValue) {
    // If ROIDisplayColor is absent, try using the RecommendedDisplayCIELabValue color.
    ROIDisplayColor = dicomlab2RGB(RecommendedDisplayCIELabValue);
  }
  if (ROIDisplayColor) {
    ROIContourData.colorArray = [...ROIDisplayColor];
  } else {
    //Choose a color from the cornerstoneTools colorLUT
    // We sample from the default color LUT here (i.e. 0), as we have nothing else to go on.
    const { getters } = cornerstoneTools.getModule('segmentation');
    const { ROINumber } = ROIContourData;
    const color = getters.colorForSegmentIndexColorLUT(0, ROINumber);
    ROIContourData.colorArray = [...color];
  }
}

function _setROIContourRTROIObservations(
  ROIContourData,
  RTROIObservationsSequence,
  ROINumber
) {
  const RTROIObservations = RTROIObservationsSequence.find(
    ({ ReferencedROINumber }) => ReferencedROINumber === ROINumber
  );
  if (RTROIObservations) {
    // Deep copy so we don't keep the reference to the dcmjs dataset entry.
    const {
      ObservationNumber,
      ROIObservationDescription,
      RTROIInterpretedType,
      ROIInterpreter,
      ROIObservationLabel,
    } = RTROIObservations;
    ROIContourData.RTROIObservations = {
      ObservationNumber,
      ROIObservationDescription,
      RTROIInterpretedType,
      ROIInterpreter,
      ROIObservationLabel,
    };
  }
}

function _getOrCreateImageIdSpecificToolData(toolState, imageId, toolName) {
  if (toolState.hasOwnProperty(imageId) === false) toolState[imageId] = {};
  const imageIdToolState = toolState[imageId];
  // If we don't have tool state for this type of tool, add an empty object
  if (imageIdToolState.hasOwnProperty(toolName) === false) {
    imageIdToolState[toolName] = { data: [] };
  }
  return imageIdToolState[toolName].data;
}

const _getImageId = (imageIdSopInstanceUidPairs, sopInstanceUID) => {
  const imageIdSopInstanceUidPairsEntry = imageIdSopInstanceUidPairs.find(
    ({ sopInstanceUID: uid }) => uid === sopInstanceUID
  );
  // To Fix: can not find imageIdSopInstanceUidPairsEntry
  if (!imageIdSopInstanceUidPairsEntry) return '';
  return imageIdSopInstanceUidPairsEntry.imageId;
};

function _getImageIdSopInstanceUidPairsForDisplaySet(
  studies,
  StudyInstanceUID,
  SeriesInstanceUID
) {
  const study = studies.find(
    ({ StudyInstanceUID: uid }) => uid === StudyInstanceUID
  );
  const displaySets = study.displaySets.filter(
    ({ SeriesInstanceUID: uid }) => uid === SeriesInstanceUID
  );
  const images = displaySets.reduce(
    (images, displaySet) => [...images, ...displaySet.images],
    []
  );
  if (!images || images.length === 0) return [];
  return images.map(image => ({
    imageId: image.getImageId(),
    sopInstanceUID: image.getSOPInstanceUID(),
  }));
}
