import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';

export default function InputBox({ label, children, ratio = 0.5 }) {
  return (
    <Box sx={{ margin: '5px 0' }} alignItems="center">
      <Grid container spacing={2}>
        <Grid
          item
          xs={(12 * ratio) / (1 + ratio)}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography color="primary">{label}</Typography>
        </Grid>
        <Grid item xs={(12 * 1) / (1 + ratio)}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}
InputBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  ratio: PropTypes.number,
};
