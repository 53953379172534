import firebase from 'firebase/app';
import 'firebase/firestore';

export async function getProjects(email, type) {
  const projects = [];

  /** get projects of owners and reviewers */
  const getQuerySnapshotByRole = async role => {
    return await firebase
      .firestore()
      .collection('projects')
      .where(role, 'array-contains', email)
      .get();
  };
  const ownersQuerySnapshot = await getQuerySnapshotByRole('owners');
  ownersQuerySnapshot.forEach(doc => {
    projects.push({ id: doc.id, ...doc.data(), role: 'owner' });
  });
  const reviewersQuerySnapshot = await getQuerySnapshotByRole('reviewers');
  reviewersQuerySnapshot.forEach(doc => {
    projects.push({ id: doc.id, ...doc.data(), role: 'reviewer' });
  });

  /** get projects of editors */
  const editorsQuerySnapshot = await firebase
    .firestore()
    .collectionGroup('stores')
    .where('editors', 'array-contains', email)
    .get();
  for (let i = 0; i < editorsQuerySnapshot.docs.length; i++) {
    const project = await getProject(
      editorsQuerySnapshot.docs[i].ref.parent.parent.id
    );
    if (!projects.find(p => p.id === project.id)) {
      projects.push({ ...project, role: 'editor' });
    }
  }

  return projects.filter(p => (type === 'all' && !!p.type) || p.type === type);
}

export async function getProject(projectId) {
  const doc = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .get();
  return { id: doc.id, ...doc.data() };
}

export async function createProject(data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(data.id)
    .set({
      name: data.name,
      location: data.location,
      dataset: data.dataset,
      type: data.type,
      description: data.description,
      owners: [data.email],
      reviewers: [],
    });
  return true;
}

export async function updateProject(projectId, data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .update(data);
  return true;
}

export async function updateProjectMembers(projectId, email, role, method) {
  const data = {
    owner: {
      add: { owners: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { owners: firebase.firestore.FieldValue.arrayRemove(email) },
    },
    reviewer: {
      add: { reviewers: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { reviewers: firebase.firestore.FieldValue.arrayRemove(email) },
    },
  };
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .update(data[role][method]);
  return true;
}

export async function deleteProject(projectId) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .delete();
  return true;
}

export async function getDicomstores(projectId) {
  const stores = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .get();
  querySnapshot.forEach(doc => {
    stores.push({ id: doc.id, ...doc.data() });
  });
  return stores;
}

export async function getDicomstoresAsEditor(projectId, email) {
  const stores = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .where('editors', 'array-contains', email)
    .get();
  querySnapshot.forEach(doc => {
    stores.push({ id: doc.id, ...doc.data() });
  });
  return stores;
}

export async function getDicomstore(projectId, storeId) {
  const doc = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(storeId)
    .get();
  return { id: doc.id, ...doc.data() };
}

export async function createDicomstore(projectId, data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(data.id)
    .set({
      name: data.name,
      dataset: data.dataset,
      datastore: data.id,
      location: data.location,
      topic: data.topic,
      editors: [],
      feedbacks: [],
    });
  return true;
}

export async function updateDicomstore(projectId, dicomstoreId, data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .update(data);
  return true;
}

export async function updateDicomstoreMembers(
  projectId,
  dicomstoreId,
  email,
  role,
  method
) {
  const data = {
    editor: {
      add: { editors: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { editors: firebase.firestore.FieldValue.arrayRemove(email) },
    },
  };
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .update(data[role][method]);
  return true;
}

export async function deleteDicomstore(projectId, dicomstoreId) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .delete();
  return true;
}

export async function getStudies(projectId, dicomstoreId) {
  const studies = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('studies')
    .get();
  querySnapshot.forEach(doc => {
    studies.push({
      id: doc.id,
      project_id: projectId,
      dicomstore_id: dicomstoreId,
      ...doc.data(),
    });
  });
  return studies;
}

export async function getStudy(projectId, dicomstoreId, studyId) {
  const doc = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('studies')
    .doc(studyId)
    .get();
  return { id: doc.id, ...doc.data() };
}

export async function createStudy(projectId, dicomstoreId, studyId, data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('studies')
    .doc(studyId)
    .set({
      state: data.state,
      time: '',
      feedback: '',
    });
  return true;
}

export async function updateStudy(projectId, dicomstoreId, studyId, data) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('studies')
    .doc(studyId)
    .set({ ...data, time: new Date().getTime() });
  return true;
}

export async function getStudyState(studyInstanceUID) {
  const search = window.location.search
    ? new URLSearchParams(window.location.search)
    : null;
  const project = search && search.get('project');
  const store = search && search.get('store');

  const doc = await firebase
    .firestore()
    .collection('projects')
    .doc(project)
    .collection('stores')
    .doc(store)
    .collection('studies')
    .doc(studyInstanceUID)
    .get();
  const studyState = doc.exists ? doc.data().state : 'todo';
  return studyState;
}

export async function updateStudyState(studyInstanceUID, newStatus) {
  const search = window.location.search
    ? new URLSearchParams(window.location.search)
    : null;
  const project = search && search.get('project');
  const store = search && search.get('store');

  await firebase
    .firestore()
    .collection('projects')
    .doc(project)
    .collection('stores')
    .doc(store)
    .collection('studies')
    .doc(studyInstanceUID)
    .set({
      state: newStatus,
      time: new Date().getTime(),
    });
}

export function getAvailableStudyState() {
  const search = window.location.search
    ? new URLSearchParams(window.location.search)
    : null;
  const role = search && search.get('role');

  return [
    {
      key: 'todo',
      name: 'Todo',
      disabled: false,
    },
    {
      key: 'ongoing',
      name: 'Ongoing',
      disabled: false,
    },
    {
      key: 'finished',
      name: 'Finished',
      disabled: false,
    },
    {
      key: 'reviewed',
      name: 'Reviewed',
      disabled: role in ['reviewer', 'owner'],
    },
  ];
}

export async function getOperations(projectId, dicomstoreId) {
  const operations = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('operations')
    .get();
  querySnapshot.forEach(doc => {
    operations.push({ id: doc.id, ...doc.data() });
  });
  return operations;
}

export async function getOperation(projectId, dicomstoreId, operationId) {
  const doc = await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('operations')
    .doc(operationId)
    .get();
  return { id: doc.id, ...doc.data() };
}

export async function createOperation(
  projectId,
  dicomstoreId,
  operationId,
  data
) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('operations')
    .doc(operationId)
    .set({
      createTime: data.createTime,
      endTime: data.endTime,
      path: data.path,
      status: data.status,
    });
  return true;
}

export async function updateOperation(
  projectId,
  dicomstoreId,
  operationId,
  data
) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('operations')
    .doc(operationId)
    .update(data);
  return true;
}

export async function deleteOperation(projectId, dicomstoreId, operationId) {
  await firebase
    .firestore()
    .collection('projects')
    .doc(projectId)
    .collection('stores')
    .doc(dicomstoreId)
    .collection('operations')
    .doc(operationId)
    .delete();
  return true;
}
