import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

import { store } from '@platform/viewer';
import { redux } from '@platform/core';
import TOOL_NAMES from '../../tools/constants/toolNames';
import * as commands from '../../tools/commands';

const { actions } = redux;

function _setToolActive(toolName) {
  cornerstoneTools.setToolActive(toolName, { mouseButtonMask: 1 });
}

export function getCommandsModule() {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  return {
    definitions: {
      setRepulserActive: {
        commandFn: () => _setToolActive(TOOL_NAMES.REPULSER_TOOL),
        storeContexts: ['viewports', 'studies'],
        options: {},
      },
      setSketchActive: {
        commandFn: () => _setToolActive(TOOL_NAMES.EDGE_TOOL),
        storeContexts: ['viewports', 'studies'],
        options: {},
      },
      setPolygonActive: {
        commandFn: () => _setToolActive(TOOL_NAMES.POLYGON_TOOL),
        storeContexts: ['viewports', 'studies'],
        options: {},
      },
      setDeleteActive: {
        commandFn: () => _setToolActive(TOOL_NAMES.DELETE_TOOL),
        storeContexts: ['viewports', 'studies'],
        options: {},
      },
      interpolateContour: {
        commandFn: ({ viewports }) => {
          editModule.setters.interpolateContour({ viewports });
        },
        storeContexts: ['viewports'],
        options: {},
      },
      clearActiveViewportContours: {
        commandFn: ({ viewports }) => {
          editModule.setters.clearActiveViewportContours({ viewports });
        },
        storeContexts: ['viewports'],
        options: {},
      },
      undoRT: {
        commandFn: () => {
          let changed = false;
          for (const element of cornerstone.getEnabledElements()) {
            const imageId = element.image.imageId;
            if (changed || editModule.setters.popPainter(imageId)) {
              changed = true;
              cornerstone.updateImage(element.element);
            }
          }
        },
        storeContexts: ['viewports'],
        options: {},
      },
      redoRT: {
        commandFn: () => {
          let changed = false;
          for (const element of cornerstone.getEnabledElements()) {
            const imageId = element.image.imageId;
            if (changed || editModule.setters.restorePainter(imageId)) {
              changed = true;
              cornerstone.updateImage(element.element);
            }
          }
        },
        storeContexts: ['viewports'],
        options: {},
      },
      clearRefLine: {
        commandFn: () => {
          cornerstoneTools
            .getModule('rtstruct-edit')
            .setters.scrollingImageId('');
          commands.refreshViewport();
        },
        storeContexts: ['viewports'],
        options: {},
      },
      commitPoint: {
        commandFn: async ({ viewports }) => {
          await editModule.setters.commitPoint({ viewports });
        },
        storeContexts: ['viewports'],
        options: {},
      },
      switchPointLabel: {
        commandFn: () => {
          const editModule = cornerstoneTools.getModule('rtstruct-edit');
          const pointConfig = editModule.getters.painterConfig('point');
          const { label } = pointConfig;
          const newLabel = label === 1 ? -1 : 1;
          editModule.setters.painterConfig('point', {label: newLabel });
          store.dispatch(actions.setPointLabel(label !== 1));
        },
        storeContexts: ['viewports'],
        options: {},
      },
    },
    defaultContext: 'ACTIVE_VIEWPORT::CORNERSTONE',
  };
}
