// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  margin: 0 -20px;
  border-top: 3px solid var(--primary-background-color);
}
.footer div button:last-child {
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/tabComponents/TabFooter.styl"],"names":[],"mappings":"AAAA;EACE,aAAS;EACT,mBAAgB;EAChB,8BAAiB;EACjB,yBAAS;EACT,eAAQ;EACR,qDAAY;AACd;AAEI;EACE,iBAAa;AAAnB","sourcesContent":[".footer\n  display: flex\n  flex-direction: row\n  justify-content: space-between\n  padding: 20px 20px 0 20px\n  margin: 0 -20px\n  border-top: 3px solid var(--primary-background-color)\n\n  div\n    button:last-child\n      margin-left: 10px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
