import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './LayoutChooser.styl';

/**
 * Adds the 'hover' class to cells above and to the left of the current cell
 * This is used to "fill in" the grid that the user will change the layout to,
 * if they click on a specific table cell.
 **/

function LayoutChooser(props) {
  let emptyCell = { row: -1, column: -1 };
  const [table, setTable] = useState([[]]);
  const [selectedCell, setSelectedCell] = useState(props.selectedCell);

  useEffect(() => {
    highlightCells(emptyCell);
  }, []);

  function onClick(currentCell) {
    setSelectedCell(currentCell);
    highlightCells(currentCell);
    if (props.onClick) {
      props.onClick(currentCell);
    }
    if (props.onChange) {
      props.onChange(currentCell);
    }
  }
  const isRange = (cell, parentCell) => {
    return cell.row <= parentCell.row && cell.col <= parentCell.col;
  };
  const highlightCells = currentCell => {
    let table = [];
    for (let row = 0; row < props.Rows; row++) {
      let newRow = [];
      for (let col = 0; col < props.Columns; col++) {
        let cell = { row: row, col: col };
        if (isRange(cell, currentCell)) {
          cell.className = 'hover';
        } else if (
          isRange(currentCell, emptyCell) &&
          isRange(cell, selectedCell)
        ) {
          cell.className = 'selectedBefore';
        }
        newRow.push(cell);
      }
      table.push(newRow);
    }
    setTable(table);
  };

  let Columns = props.Columns;
  const style = {
    display: props.visible ? 'block' : 'none',
    minWidth: Columns * props.boxSize + (Columns + 5) * props.cellBorder,
  };
  return (
    <div
      className="layoutChooser layoutChooser-dropdown-menu"
      role="menu"
      style={style}
    >
      <table>
        <tbody>
          {table.map((row, i) => {
            return (
              <tr key={i}>
                {row.map((cell, j) => {
                  return (
                    <td
                      className={cell.className}
                      style={{
                        width: props.boxSize,
                        height: props.boxSize,
                        border: 'solid 1px black',
                      }}
                      key={j}
                      onMouseEnter={() => highlightCells(cell)}
                      onMouseLeave={() => highlightCells(emptyCell)}
                      onClick={() => onClick(cell)}
                    />
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
LayoutChooser.propTypes = {
  Rows: PropTypes.number.isRequired,
  Columns: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedCell: PropTypes.object,
  boxSize: PropTypes.number.isRequired,
  cellBorder: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};
LayoutChooser.defaultProps = {
  Rows: 3,
  Columns: 3,
  visible: true,
  boxSize: 20,
  cellBorder: 1,
  selectedCell: {
    row: -1,
    col: -1,
  },
};

export { LayoutChooser };
