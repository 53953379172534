import firebase from 'firebase/app';
import 'firebase/auth';
import { getUser } from './firestore/users';

export async function signInWithEmailAndPassword(email, password) {
  await firebase.auth().signInWithEmailAndPassword(email, password);

  const user = await getUser(email);
  const lastPasswordResetEmailTime = user.lastPasswordResetEmailTime;
  const now = new Date().getTime();
  if (
    !lastPasswordResetEmailTime ||
    now - lastPasswordResetEmailTime > 1000 * 86400 * 180 // 6 months
  ) {
    const remindMsg =
      '\nPlease change your password every few months to be safe';
    await resetPassword(email, remindMsg);
  }
}

export async function resetPassword(email, remindMsg = '') {
  if (!email) {
    window.alert('Please enter your email');
    return;
  }

  await firebase.auth().sendPasswordResetEmail(email);
  const now = new Date().getTime();
  await firebase
    .firestore()
    .collection('users')
    .doc(email)
    .update({ lastPasswordResetEmailTime: now });

  window.alert(`Password reset email is sent${remindMsg}`);
}

export async function signInWithGooglePopup() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'consent',
  });
  await firebase.auth().signInWithPopup(provider);
}

export function signOut() {
  return firebase.auth().signOut();
}

export function getCurrentUser() {
  return firebase.auth().currentUser;
}

export async function reauthenticateUser(password) {
  try {
    const user = firebase.auth().currentUser;
    if (user.providerData[0].providerId === 'google.com') {
      return await reauthenticateWithGooglePopup(user);
    }
    return await reauthenticateWithEmailAndPassword(user, password);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return false;
  }
}

export async function reauthenticateWithEmailAndPassword(user, password) {
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password
  );
  await user.reauthenticateWithCredential(credential);
  return true;
}

export async function reauthenticateWithGooglePopup(user) {
  const provider = new firebase.auth.GoogleAuthProvider();
  await user.reauthenticateWithPopup(provider);
  return true;
}
