import _ from 'lodash';

export default function getViewTypeFromOrientation(orientation) {
  if (!orientation) {
    return 'axial';
  }
  if (
    _.some(
      [
        [0, 4],
        [1, 3],
      ],
      ([i, j]) =>
        Math.abs(orientation[i]) > 0.9 && Math.abs(orientation[j]) > 0.9
    )
  ) {
    return 'axial';
  }
  if (
    _.some(
      [
        [0, 5],
        [2, 3],
      ],
      ([i, j]) =>
        Math.abs(orientation[i]) > 0.9 && Math.abs(orientation[j]) > 0.9
    )
  ) {
    return 'coronal';
  }
  if (
    _.some(
      [
        [1, 5],
        [2, 4],
      ],
      ([i, j]) =>
        Math.abs(orientation[i]) > 0.9 && Math.abs(orientation[j]) > 0.9
    )
  ) {
    return 'sagittal';
  }
  return 'axial';
}
