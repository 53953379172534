import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Modal,
  InputBox,
  TextField,
  MenuItem,
  Typography,
  Divider,
  Icon,
} from '@platform/ui';

const interpretedTypes = [
  { key: 'GTV', label: 'GTV' },
  { key: 'CTV', label: 'CTV' },
  { key: 'PTV', label: 'PTV' },
  { key: 'ORGAN', label: 'Organ' },
];

const tagsMapping = {
  contouring: {
    interpretedType: 'GTV',
    bodyPart: null,
    measurable: null,
    trackingType: null,
  },
  tracking: {
    interpretedType: 'GTV',
    bodyPart: null,
    measurable: null,
    trackingType: 'new',
  },
  longitudinal: {
    interpretedType: 'GTV',
    bodyPart: 'brain',
    measurable: true,
    trackingType: 'new',
  },
};

const optionsMapping = {
  contouring: {
    trackingTypes: [],
    bodyParts: [],
  },
  tracking: {
    trackingTypes: [
      { key: 'none', label: '------' },
      { key: 'tracked', label: 'Tracked' },
      { key: 'new', label: 'New' },
    ],
    bodyParts: [],
  },
  longitudinal: {
    trackingTypes: [
      { key: 'target', label: 'Target Lesion' },
      { key: 'non-target', label: 'Non-target Lesion' },
      { key: 'new', label: 'New Lesion' },
      { key: 'not-included', label: 'Not Included' },
    ],
    bodyParts: [
      { key: 'brain', label: 'Brain' },
      { key: 'bone', label: 'Bone' },
      { key: 'lung', label: 'Lung' },
      { key: 'liver', label: 'Liver' },
      { key: 'lymph-node', label: 'Lymph Node' },
      { key: 'pleura', label: 'Pleura' },
      { key: 'pleural-effusion', label: 'Pleural Effusion' },
      { key: 'others', label: 'Others' },
    ],
  },
};

export function NewROIForm({ mode, onFinished, defaultName, disabled }) {
  const tags = tagsMapping[mode] || tagsMapping['contouring'];
  const defaultMeasurable = tags.measurable;
  const defaultBodyPart = tags.bodyPart;
  const defaultTrackingType = tags.trackingType;
  const defaultInterpretedType = tags.interpretedType;
  const [name, setName] = useState(defaultName);
  const [measurable, setMeasurable] = useState(defaultMeasurable);
  const [bodyPart, setBodyPart] = useState(defaultBodyPart);
  const [trackingType, setTrackingType] = useState(defaultTrackingType);
  const [interpretedType, setInterpretedType] = useState(
    defaultInterpretedType
  );
  const [open, setOpen] = useState(false);

  const reset = () => {
    setName(defaultName);
    setMeasurable(defaultMeasurable);
    setBodyPart(defaultBodyPart);
    setTrackingType(defaultTrackingType);
    setInterpretedType(defaultInterpretedType);
  };

  const handleCreateClick = () => {
    const rois = [
      {
        ROIName: name,
        RTROIObservations: { RTROIInterpretedType: interpretedType },
        bodyPart,
        measurable,
        trackingType,
      },
    ].map(roi => {
      Object.keys(roi).forEach(key => {
        if (roi[key] == null) delete roi[key];
      });
      return roi;
    });
    onFinished(rois);
    reset();
    setOpen(false);
  };

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  useEffect(() => {
    if (interpretedType === 'ORGAN') {
      setTrackingType('none');
    } else {
      setTrackingType('new');
    }
  }, [interpretedType]);

  return (
    <>
      <Button
        data-cy="create-roi-button"
        disabled={disabled}
        onClick={() => setOpen(true)}
        color="secondary"
        variant="contained"
        fullWidth
      >
        <Icon
          className="plus-icon"
          name="plus"
          width="20px"
          height="20px"
          style={{ color: disabled ? '#5A5A5A' : 'black' }}
        />
        ROI
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div>
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            {'New ROI'}
          </Typography>
          <Divider />
          <InputBox label={'Name:'}>
            <TextField
              value={name}
              onChange={e => setName(e.target.value)}
              fullWidth
            />
          </InputBox>
          <InputBox label={'Interpreted Type:'}>
            <TextField
              select
              value={interpretedType}
              onChange={e => setInterpretedType(e.target.value)}
              fullWidth
            >
              {interpretedTypes.map(({ key, label }) => (
                <MenuItem key={key} value={key}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </InputBox>
          {mode === 'longitudinal' && (
            <>
              <InputBox label={'Body Part:'}>
                <TextField
                  select
                  value={bodyPart}
                  onChange={e => setBodyPart(e.target.value)}
                  fullWidth
                >
                  {optionsMapping.longitudinal.bodyParts.map(
                    ({ key, label }) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </InputBox>
              <InputBox label={'Measurability:'}>
                <TextField
                  select
                  value={measurable}
                  onChange={e => setMeasurable(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={true}>Measurable</MenuItem>
                  <MenuItem value={false}>Non-measurable</MenuItem>
                </TextField>
              </InputBox>
              <InputBox label={'Tracking Type:'}>
                <TextField
                  select
                  value={trackingType}
                  onChange={e => setTrackingType(e.target.value)}
                  fullWidth
                >
                  {optionsMapping.longitudinal.trackingTypes.map(
                    ({ key, label }) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </InputBox>
            </>
          )}
          {mode === 'tracking' && (
            <InputBox label={'Tracking Type:'}>
              <TextField
                select
                value={trackingType}
                onChange={e => setTrackingType(e.target.value)}
                fullWidth
              >
                {optionsMapping.tracking.trackingTypes.map(({ key, label }) => (
                  <MenuItem key={key} value={key}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>
            </InputBox>
          )}
        </div>
        <div style={{ marginTop: 'auto' }}>
          <Button
            data-cy="submit-new-roi-button"
            onClick={handleCreateClick}
            color="primary"
            variant="outlined"
            medium
            fullWidth
          >
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
}
NewROIForm.propTypes = {
  mode: PropTypes.string,
  onFinished: PropTypes.func,
  defaultName: PropTypes.string,
  disabled: PropTypes.bool,
};
