export default function getSeriesUIDByStructureSetUID(
  structureSetUID,
  seriesStructureMap
) {
  let uid = '';
  Object.entries(seriesStructureMap).forEach(([key, value]) => {
    if (value === structureSetUID) uid = key;
  });
  return uid;
}
