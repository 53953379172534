import _ from 'lodash';

export function dummyPainter(toolState, structureSetSeriesInstanceUid) {
  let state = _.cloneDeep(toolState);
  return {
    getState: function() {
      return state;
    },
    commit: function() {},
    update: function() {},
    cursor: function() {},
  };
}
