import { mat4, vec3 } from 'gl-matrix';
import vtkImageReslice from 'vtk.js/Sources/Imaging/Core/ImageReslice';
import * as macro from 'vtk.js/Sources/macros';

import cross from '../math/cross';
import multiply from '../math/multiply';

/**
 * @function createImageSlice
 * @param {Object} vtkVolume
 * @param {*} vtkVolume.vtkImageData
 * @param {vec3} vtkVolume.center
 * @param {Object} [options={}]
 * @param {String} [options.orientation] image orientation
 * @param {String} [options.origin] image origin
 * @param {String} [options.rotation] vtk orientation
 * @param {String} [options.position] vtk position
 * @returns {Object} {slice, metaData}
 */
export default function(vtkVolume, options = {}) {
  const vtkImageData = vtkVolume.vtkImageData;
  const orientationAsString = options.orientation || '1,0,0,0,1,0';
  const originAsString = options.origin || '0,0,0';
  const rotationAsString = options.rotation || '1,0,0,0,1,0';
  const positionAsString = options.position || 'center';

  const orientation = orientationAsString.split(',').map(parseFloat);
  const origin = originAsString.split(',').map(parseFloat);
  const rotation = rotationAsString.split(',').map(parseFloat);
  const rowCosines = vec3.fromValues(rotation[0], rotation[1], rotation[2]);
  const colCosines = vec3.fromValues(rotation[3], rotation[4], rotation[5]);
  let zedCosines = vec3.create();
  vec3.cross(zedCosines, rowCosines, colCosines);

  /** calculate position and axes in vtk coordinate */
  const [x0, y0, z0] = vtkImageData.getOrigin();
  const [, , zSpacing] = vtkImageData.getSpacing();
  const [, , , , zMin, zMax] = vtkImageData.getExtent();
  const zStart = z0 + zSpacing * (zMax - zMin);
  const position =
    positionAsString === 'center'
      ? [
          zedCosines[0] * -1 * (vtkVolume.center[0] - x0) + x0,
          zedCosines[1] * (vtkVolume.center[1] - y0) + y0,
          zedCosines[2] * (vtkVolume.center[2] - zStart) + zStart,
        ]
      : positionAsString.split(',').map(parseFloat);
  const axes = _calculateRotationAxes(rowCosines, colCosines, position);

  /** set up vtkImageReslice */
  const imageReslice = vtkImageReslice.newInstance();
  imageReslice.setInputData(vtkImageData); // Our volume
  imageReslice.setOutputDimensionality(2); // We want a "slice", not a volume
  imageReslice.setBackgroundColor(255, 255, 255, 255); // Black background
  imageReslice.setResliceAxes(axes);

  /** get output data */
  const outputSlice = imageReslice.getOutputData();
  const spacing = outputSlice.getSpacing();
  const dimensions = outputSlice.getDimensions();

  /** transformation */
  const imageOrientationPatient = _transformImageOrientationPatient(
    orientation,
    rotation
  );
  const imagePositionPatient = _transformImagePositionPatient(
    orientation,
    origin,
    [x0, y0, z0],
    position
  );

  const result = {
    slice: _mapMprSliceToCornerstoneImage(outputSlice),
    metaData: {
      SeriesInstanceUID: options.SeriesInstanceUID,
      imagePlaneModule: {
        imageOrientationPatient: imageOrientationPatient,
        imagePositionPatient: imagePositionPatient,
        centerPositionPatient: position,
        rowCosines: imageOrientationPatient.slice(0, 3),
        columnCosines: imageOrientationPatient.slice(3),
        rowPixelSpacing: spacing[1],
        columnPixelSpacing: spacing[0],
        frameOfReferenceUID: '',
        columns: dimensions[0],
        rows: dimensions[1],
        sliceThickness: spacing[2],
      },
    },
  };
  return result;
}

/**
 * @param {Float32Array} rowCosines
 * @param {Float32Array} colCosines
 * @param {Float32Array} position
 * @returns {mat4} 4x4 rotation matrix
 */
function _calculateRotationAxes(rowCosines, colCosines, position) {
  let zedCosines = vec3.create();
  vec3.cross(zedCosines, rowCosines, colCosines);

  const axes = mat4.fromValues(
    rowCosines[0],
    rowCosines[1],
    rowCosines[2],
    0,
    colCosines[0],
    colCosines[1],
    colCosines[2],
    0,
    zedCosines[0],
    zedCosines[1],
    zedCosines[2],
    0,
    position[0],
    position[1],
    position[2],
    1
  );
  return axes;
}

/**
 * @param {Array} orientation image orientation
 * @param {Array} vtkOrientation vtk orientation
 * @returns {Array} orientation of the new image
 */
function _transformImageOrientationPatient(orientation, vtkOrientation) {
  const orientationMatrix = [orientation.slice(0, 3), orientation.slice(3)];
  orientationMatrix.push(cross(orientationMatrix[0], orientationMatrix[1]));
  const rotationMatrix = [vtkOrientation.slice(0, 3), vtkOrientation.slice(3)];
  rotationMatrix.push(cross(rotationMatrix[0], rotationMatrix[1]));
  const matrix = multiply(rotationMatrix, orientationMatrix);
  return [...matrix[0], ...matrix[1]];
}

/**
 * @param {*} orientation image orientation;
 * @param {*} origin image origin
 * @param {*} vtkOrigin vtk origin
 * @param {*} vtkPosition vtk position
 * @returns
 */
function _transformImagePositionPatient(
  orientation,
  origin,
  vtkOrigin,
  vtkPosition
) {
  const orientationMatrix = [orientation.slice(0, 3), orientation.slice(3)];
  orientationMatrix.push(cross(orientationMatrix[0], orientationMatrix[1]));
  const offset = multiply(
    [
      [
        vtkPosition[0] - vtkOrigin[0],
        vtkPosition[1] - vtkOrigin[1],
        vtkPosition[2] - vtkOrigin[2],
      ],
      [0, 0, 0],
      [0, 0, 0],
    ],
    orientationMatrix
  )[0];
  return [origin[0] + offset[0], origin[1] + offset[1], origin[2] + offset[2]];
}

/**
 * @param {Object} mprSlice
 * @returns {Object} image
 */
function _mapMprSliceToCornerstoneImage(mprSlice) {
  const spacing = mprSlice.getSpacing();
  const dimensions = mprSlice.getDimensions();
  const scalars = mprSlice.getPointData().getScalars();
  const dataRange = scalars.getRange(0);
  const rawData = scalars.getData();

  let pixelData;
  if (dimensions[2] === 1) {
    const scalarsData = scalars.getData();
    pixelData = scalarsData;
  } else {
    const offset =
      mprSlice.sliceIndex *
      dimensions[0] *
      dimensions[1] *
      rawData.BYTES_PER_ELEMENT;
    pixelData = new macro.TYPED_ARRAYS[scalars.getDataType()](
      rawData.buffer,
      offset,
      dimensions[0] * dimensions[1]
    );
  }

  return {
    pixelData,
    columnPixelSpacing: spacing[0],
    rows: dimensions[1],
    height: dimensions[1],
    rowPixelSpacing: spacing[1],
    columns: dimensions[0],
    width: dimensions[0],
    depth: dimensions[2],
    intercept: 0,
    invert: false,
    minPixelValue: dataRange[0],
    maxPixelValue: dataRange[1],
    sizeInBytes: pixelData.length * pixelData.BYTES_PER_ELEMENT,
    slope: 1,
    windowCenter: Math.round((dataRange[0] + dataRange[1]) / 2),
    windowWidth: dataRange[1] - dataRange[0],
    decodeTimeInMS: 0,
    getPixelData: () => pixelData,
  };
}
