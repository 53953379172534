import _ from 'lodash';

export function copyPainter(
  toolState,
  structureSetSeriesInstanceUid,
  config,
  [sourceROINumber, targetROINumber]
) {
  let state = _.cloneDeep(toolState);
  return {
    getState: function() {
      return state;
    },
    commit: function() {
      state.data = [
        ...state.data,
        ...state.data
          .filter(d => d.ROINumber == sourceROINumber)
          .map(d => ({
            ...d,
            ROINumber: targetROINumber,
          })),
      ];
    },
    update: function() {},
    cursor: function() {},
  };
}
