import React from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import './StudyBrowser.styl';

function StudyBrowser(props) {
  const {
    studies,
    displayedSeries,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
    isReaderMode,
  } = props;

  return (
    <div className="study-browser">
      <div className="scrollable-study-thumbnails">
        {studies
          .map((study, studyIndex) =>
            study.thumbnails
              .filter(thumb =>
                isReaderMode
                  ? displayedSeries
                      .map(s => s.SeriesInstanceUID)
                      .includes(thumb.SeriesInstanceUID)
                  : true
              )
              .map((thumb, thumbIndex) => {
                const {
                  active,
                  altImageText,
                  displaySetInstanceUID,
                  imageId,
                  numImageFrames,
                  SeriesDescription,
                  SeriesNumber,
                  SeriesDate,
                  ContentDate,
                  AcquisitionDate,
                } = thumb;

                return (
                  <div
                    key={thumb.displaySetInstanceUID}
                    className="thumbnail-container"
                    data-cy="thumbnail-list"
                  >
                    <Thumbnail
                      active={active}
                      supportsDrag={supportsDrag}
                      key={`${studyIndex}_${thumbIndex}`}
                      id={`${studyIndex}_${thumbIndex}`}
                      /** study */
                      StudyInstanceUID={study.StudyInstanceUID}
                      /** thumb */
                      altImageText={altImageText}
                      imageId={imageId}
                      displaySetInstanceUID={displaySetInstanceUID}
                      numImageFrames={numImageFrames}
                      SeriesDescription={SeriesDescription}
                      SeriesNumber={SeriesNumber}
                      /** define series date */
                      SeriesDate={
                        SeriesDate || ContentDate || AcquisitionDate || ''
                      }
                      /** events */
                      onClick={() => onThumbnailClick(displaySetInstanceUID)}
                      onDoubleClick={onThumbnailDoubleClick}
                      showProgressBar={showThumbnailProgressBar}
                    />
                  </div>
                );
              })
          )
          .flat()}
      </div>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.number,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  displayedSeries: PropTypes.array,
  supportsDrag: PropTypes.bool,
  onThumbnailClick: PropTypes.func,
  onThumbnailDoubleClick: PropTypes.func,
  showThumbnailProgressBar: PropTypes.bool,
  isReaderMode: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  displayedSeries: [],
  supportsDrag: true,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: true,
  isReaderMode: false,
};

export { StudyBrowser };
