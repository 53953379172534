import React from 'react';
import { ExtendedRTPanel } from '../../components/ExtendedRTPanel';

export const getPanelModule = function({ commandsManager, servicesManager }) {
  const Panel = props => {
    return (
      <ExtendedRTPanel
        {...props}
        commandsManager={commandsManager}
        servicesManager={servicesManager}
      />
    );
  };
  const isPanelDisabled = studies => {
    if (!studies) return true;
    for (const study of studies) {
      if (study?.series) {
        for (const s of study.series) {
          const modalities = ['MR', 'CT', 'PT', 'RTSTRUCT'];
          const isPrimaryModality = modalities.includes(s.Modality);
          if (isPrimaryModality) return false;
        }
      }
    }
    return true;
  };

  return {
    menuOptions: [
      {
        icon: 'list',
        label: 'RTSTRUCT',
        target: 'rt-panel',
        isDisabled: isPanelDisabled,
      },
    ],
    components: [{ id: 'rt-panel', component: Panel }],
    defaultContext: ['VIEWER'],
  };
};
