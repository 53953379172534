export const BODY_PARTS = {
  BRAIN: 'brain',
  BONE: 'bone',
  LUNG: 'lung',
  LIVER: 'liver',
  LYMPHNODE: 'lymph-node',
  PLEURA: 'pleura',
  PLEURALEFFUSION: 'pleural-effusion',
  OTHERS: 'others',
};

export const TRACKING_TYPES = {
  TARGET: 'target',
  NONTARGET: 'non-target',
  NEW: 'new',
  NOTINCLUDED: 'not-included',
  NONE: 'none',
  ISOLATED: 'isolated',
};

export const OVERALL_RESPONSE = {
  COMPLETE_RESPONSE: 'complete',
  PARTIAL_RESPONSE: 'partial',
  PSEUDO_PROGRESSIVE_DISEASE: 'pseudo-progressive',
  PROGRESSIVE_DISEASE: 'progressive',
  STABLE_DISEASE: 'stable',
  NOT_EVALUABLE: 'not-evaluable',
  NONE: 'none',
};

export const TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'complete',
  PARTIAL_RESPONSE: 'partial',
  PROGRESSIVE_DISEASE: 'progressive',
  STABLE_DISEASE: 'stable',
  NOT_EVALUABLE: 'not-evaluable',
  NONE: 'none',
};

export const NON_TARGET_RESPONSE = {
  COMPLETE_RESPONSE: 'complete',
  NON_PROGRESSIVE_RESPONSE: 'non-progressive',
  PROGRESSIVE_DISEASE: 'progressive',
  NOT_EVALUABLE: 'not-evaluable',
  NONE: 'none',
};

export const NEW_LESION_RESPONSE = {
  PRESENT: 'present',
  ABSENT: 'absent',
  UNCONFIRMED: 'unconfirmed',
  NOT_EVALUABLE: 'not-evaluable',
  NONE: 'none',
};

export const MEASUREMENT_TECHNIQUE = {
  UNIDIMENSIONAL: 'UNIDIMENSIONAL',
  BIDIMENSIONAL: 'BIDIMENSIONAL',
  VOLUMETRIC: 'VOLUMETRIC',
};

export const STEROIDS_USE = {
  NONE: 'none',
  DECREASED: 'decreased',
  STABLE: 'stable',
  INCREASED: 'increased',
  NOT_APPLICABLE: 'not-applicable',
};

export const CLINICAL_STATUS = {
  WORSE: 'worse',
  STABLE: 'stable',
  IMPROVED: 'improved',
  NOT_APPLICABLE: 'not-applicable',
};
