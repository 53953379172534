import styled from 'styled-components';

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  font: inherit;
  font-size: 16px;
  color: #ffffff;
  background: none;
  width: 100%;
  height: auto;
  min-height: 1.2em;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: inherit;
  outline: 0;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.23);
  border-radius: 4px;
  margin: 0;
  padding: 8px 8px;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    border-color: white;
  }
`;

export default Input;
