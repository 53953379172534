import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { getOverallResponse } from './responseAssessments';
import { STEROIDS_USE, CLINICAL_STATUS } from '../constants/guideline';
import { getVolumeFromSlices } from '../../../modules/dicom-measurement/src';

export function getTimepoints(axialImageSets, ROIContours) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const timepoints = [];
  axialImageSets.forEach(imageSet => {
    const { SeriesInstanceUID } = imageSet;
    const images = imageSet.images.map(image => {
      const id = image.getImageId();
      const ImagePositionPatient =
        cornerstone.metaData.get('ImagePositionPatient', id) || {};
      const generalImageModule =
        cornerstone.metaData.get('generalImageModule', id) || {};
      const { instanceNumber: InstanceNumber } = generalImageModule;
      return { id, InstanceNumber, ImagePositionPatient };
    });
    const meta = cornerstone.metaData.get('instance', images[0].id) || {};
    const Lesions = [];
    for (let i = 0; i < ROIContours.length; i++) {
      const slices = images
        .map(image => {
          const painter = editModule.getters.peekPainter(image.id);
          if (!painter) return null;
          const painterInfo = painter
            .getInfo()
            .find(c => c.ROINumber === ROIContours[i].ROINumber);
          return { ...image, ...painterInfo };
        })
        .filter(i => i !== null);

      /** volume */
      const volume = getVolumeFromSlices(slices);
      const maxAreaContours = slices.reduce(
        (acc, cur, index) => {
          return cur.area > acc.area
            ? {
                area: cur.area,
                maxProductValue: cur.product,
                maxLongAxisValue: cur.mergedLongAxisValue,
                maxShortAxisValue: cur.mergedShortAxisValue,
                mainAreaCentroid: cur.mainAreaCentroid,
                mainAreaPhysicalCentroid: cur.mainAreaPhysicalCentroid,
                imageIdx: index,
              }
            : acc;
        },
        {
          area: 0,
          maxProductValue: 0,
          maxLongAxisValue: 0,
          maxShortAxisValue: 0,
          mainAreaCentroid: { x: null, y: null },
          mainAreaPhysicalCentroid: { x: null, y: null, z: null },
          imageIdx: null,
        }
      );

      Lesions.push({
        ROIName: ROIContours[i].ROIName,
        ROINumber: ROIContours[i].ROINumber,
        bodyPart: ROIContours[i].bodyPart,
        maxLongAxisValue: Number(maxAreaContours.maxLongAxisValue),
        maxShortAxisValue: Number(maxAreaContours.maxShortAxisValue),
        maxDiameterValue:
          ROIContours[i].bodyPart === 'lymph-node'
            ? Number(maxAreaContours.maxShortAxisValue)
            : Number(maxAreaContours.maxLongAxisValue),
        maxProductValue: Number(maxAreaContours.maxProductValue),
        maxAreaValue: Number(maxAreaContours.area),
        maxAreaCentroid: maxAreaContours.mainAreaCentroid,
        maxAreaPhysicalCentroid: maxAreaContours.mainAreaPhysicalCentroid,
        InstanceNumber: maxAreaContours.imageIdx
          ? maxAreaContours.imageIdx + 1
          : null,
        volume,
      });
    }

    const currentTimepoint = {
      ClinicalTrialSiteID: meta.ClinicalTrialSiteID,
      ClinicalTrialSiteName: meta.ClinicalTrialSiteName,
      ClinicalTrialTimePointID: meta.ClinicalTrialTimePointID,
      ClinicalTrialSubjectID: meta.ClinicalTrialSubjectID,
      PatientID: meta.PatientID || '',
      StudyDate: meta.StudyDate || '',
      StudyDescription: meta.StudyDescription || '',
      StudyInstanceUID: meta.StudyInstanceUID || '',
      /** define series date */
      SeriesDate:
        meta.SeriesDate || meta.ContentDate || meta.AcquisitionDate || '',
      SeriesInstanceUID: SeriesInstanceUID,
      SeriesDescription: meta.SeriesDescription || '',
      Modality: meta.Modality || '',
      SliceThickness: meta.SliceThickness || null,
      SpacingBetweenSlices: meta.SpacingBetweenSlices || null,
      Lesions: Lesions,
    };

    /** check if exist same timepoint */
    const sameTimepoint = findSameTimepoint(timepoints, currentTimepoint);
    if (sameTimepoint) {
      /** redefine measurements of lesions */
      currentTimepoint.Lesions.forEach(lesion => {
        /** if contours exist, override the previous lesion measurements */
        if (!lesion.maxAreaValue || lesion.maxAreaValue <= 0) return;
        sameTimepoint.Lesions.forEach((prevLesion, index) => {
          if (lesion.ROINumber === prevLesion.ROINumber) {
            sameTimepoint.Lesions[index] = lesion;
          }
        });
      });
    } else {
      timepoints.push(currentTimepoint);
    }
  });

  const Timepoints = timepoints.filter(
    timepoint => timepoint.SeriesDate !== '99999999'
  );
  Timepoints.sort(function(a, b) {
    return Number(a.SeriesDate) - Number(b.SeriesDate);
  });
  return Timepoints;
}

function findSameTimepoint(timepoints, currentTimepoint) {
  let sameTimepoint = null;
  const { ClinicalTrialTimePointID: currentTimePointID } = currentTimepoint;
  if (!currentTimePointID) return sameTimepoint;
  timepoints.forEach(timepoint => {
    const { ClinicalTrialTimePointID: prevTimePointID } = timepoint;
    if (prevTimePointID && prevTimePointID === currentTimePointID) {
      sameTimepoint = timepoint;
    }
  });
  return sameTimepoint;
}

export function getAssessedTimepoints(
  timepoints,
  targetResponses,
  nonTargetResponses,
  newLesionResponses,
  assessments
) {
  return timepoints.map((timepoint, index) => {
    const targetResponse = targetResponses[index];
    const nonTargetResponse = nonTargetResponses[index];
    const newLesionResponse = newLesionResponses[index];
    const steroidsUse = {
      result: STEROIDS_USE.NOT_APPLICABLE,
      isOverridden: false,
    };
    const clinicalStatus = {
      result: CLINICAL_STATUS.NOT_APPLICABLE,
      isOverridden: false,
    };

    /** override AI response assessments with user assessments */
    const { SeriesInstanceUID } = timepoint;
    const assessment =
      assessments.find(a => a.SeriesInstanceUID === SeriesInstanceUID) || {};
    const { customBaseline } = assessment;
    if (assessment.targetResponse) {
      targetResponse.result = assessment.targetResponse;
      targetResponse.isOverridden = true;
    }
    if (assessment.nonTargetResponse) {
      nonTargetResponse.result = assessment.nonTargetResponse;
      nonTargetResponse.isOverridden = true;
    }
    if (assessment.newLesionResponse) {
      newLesionResponse.result = assessment.newLesionResponse;
      newLesionResponse.isOverridden = true;
    }
    if (assessment.steroidsUse) {
      steroidsUse.result = assessment.steroidsUse;
      steroidsUse.isOverridden = true;
    }
    if (assessment.clinicalStatus) {
      clinicalStatus.result = assessment.clinicalStatus;
      clinicalStatus.isOverridden = true;
    }

    /** recalculate overall responses */
    const overallResponse = getOverallResponse({
      targetResponse,
      nonTargetResponse,
      newLesionResponse,
      steroidsUse,
      clinicalStatus,
    });
    if (assessment.overallResponse) {
      overallResponse.result = assessment.overallResponse;
      overallResponse.isOverridden = true;
    }

    /** check if overall response is confirmed */
    const isResponseConfirmed =
      assessment.responseAssessmentStatus === 'confirmed';

    return {
      ...timepoint,
      customBaseline,
      targetResponse,
      nonTargetResponse,
      newLesionResponse,
      steroidsUse,
      clinicalStatus,
      overallResponse,
      isResponseConfirmed,
    };
  });
}
