import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import Logo from '../../static/images/Theme.png';
import Brain from '../../static/images/Brain_base.png';
import BrainCere from '../../static/images/Brain_cere.png';
import BrainFrn from '../../static/images/Brain_frn.png';
import BrainOcc from '../../static/images/Brain_occ.png';
import BrainPar from '../../static/images/Brain_par.png';
import BrainTemp from '../../static/images/Brain_temp.png';
import BrainStem from '../../static/images/Brain_stem.png';

export default function exportReport(filename, data) {
  let page = 0;
  const doc = new jsPDF({ format: 'a4', unit: 'px' });

  const addNewPage = () => {
    const title = 'VBrain Tracking Report';
    const header = `Patient ID: ${data.PatientID} / Study Date: ${data.StudyDate}`;
    const footer = 'The information generated by VBrain is for reference only';
    if (page !== 0) doc.addPage('a4');
    doc.addImage(Logo, 'JPEG', 340, 15, 84, 14);
    doc.setFontSize(18);
    doc.text(title, 20, 50);
    doc.setFontSize(14);
    doc.text(header, 20, 70);
    doc.text(footer, 220, 610, { align: 'center' });
    page += 1;
  };

  const addSubtitle = (text, x, y) => {
    doc.setFillColor(217, 217, 217);
    doc.rect(x, y - 10, 405, 15, 'F');
    doc.setFontSize(14);
    doc.text(text, x, y);
  };

  const ss = data.distribution;
  const lr = (l, r) => {
    if (l > 0 && r > 0) {
      return `${l}(L)/${r}(R)`;
    } else if (l > 0) {
      return `${l}(L)`;
    } else if (r > 0) {
      return `${r}(R)`;
    } else {
      return 0;
    }
  };

  const createOverviewSection = (x, y) => {
    addSubtitle('Disease Overview', x, y);
    doc.text(`Body Part: Brain`, x, y + 30);
    doc.text(`Overall: ${ss.overall}`, x, y + 55);
    doc.text(`Frontal: ${lr(ss.L_Frn, ss.R_Frn)}`, x, y + 70);
    doc.text(`Temporal: ${lr(ss.L_Temp, ss.R_Temp)}`, x, y + 85);
    doc.text(`Parietal: ${lr(ss.L_Par, ss.R_Par)}`, x, y + 100);
    doc.text(`Occipital: ${lr(ss.L_Occ, ss.R_Occ)}`, x, y + 115);
    doc.text(`Cerebellum: ${lr(ss.L_Cere, ss.R_Cere)}`, x, y + 130);
    doc.text(`Brainstem: ${ss.BS}`, x + 140, y + 70);
    doc.text(`Caudate: ${lr(ss.L_Caud, ss.R_Caud)}`, x + 140, y + 85);
    doc.text(`Putamen: ${lr(ss.L_Puta, ss.R_Puta)}`, x + 140, y + 100);
    doc.text(`Thalamus: ${lr(ss.L_Thal, ss.R_Thal)}`, x + 140, y + 115);
    doc.text(`Globus Pallidus: ${lr(ss.L_GP, ss.R_GP)}`, x + 140, y + 130);
    doc.addImage(Brain, 'JPEG', x + 270, y + 30, 132, 104);

    [
      [ss.L_Cere + ss.R_Cere, BrainCere],
      [ss.L_Frn + ss.R_Frn, BrainFrn],
      [ss.L_Par + ss.R_Par, BrainPar],
      [ss.L_Occ + ss.R_Occ, BrainOcc],
      [ss.L_Temp + ss.R_Temp, BrainTemp],
      [ss.BS, BrainStem],
    ].forEach(([value, image]) => {
      doc.saveGraphicsState();
      doc.setGState(doc.GState({ opacity: Math.min(8, value * 2) * 0.1 }));
      doc.addImage(image, 'JPEG', x + 270, y + 30, 132, 104);
      doc.restoreGraphicsState();
    });
  };

  const createDiagramSection = (x, y, data) => {
    addSubtitle('Tumor Volume Tracking', x, y);
    const docWidth = doc.internal.pageSize.width;
    addChart(data, x + 30, y + 30, docWidth - 100, 200);
  };

  const addChart = (chartData, startX, startY, chartWidth, chartHeight) => {
    const maxX = startX + chartWidth;
    const maxY = startY + chartHeight;
    const ratio = 1 / Math.ceil(Math.max(...chartData.map(d => d.value)));

    doc.line(startX, startY, startX, maxY);
    doc.line(startX, maxY, maxX, maxY);

    doc.text('volume(cc)', startX + 160, startY);

    doc.setFontSize(10);
    // Add X-axis ticks and labels
    for (let i = 0; i < chartData.length; i++) {
      const data = chartData[i];
      const x = startX + (i / (chartData.length - 1)) * chartWidth;
      const tickHeight = maxY + 3;
      doc.line(x, maxY - 2, x, maxY + 2); // X-axis tick
      doc.text(data.label, x - 7, tickHeight + 5);
    }
    // Add Y-axis ticks and labels
    for (let i = 0; i <= 100; i += 25) {
      const j = (1 / ratio) * (i / 100);
      const y = maxY - (i / 100) * chartHeight;
      const tickWidth = startX - 2;
      doc.line(tickWidth - 2, y, tickWidth + 2, y); // Y-axis tick
      doc.text(j.toString(), tickWidth - 20, y + 2);
    }

    const dataPointRadius = 2;
    for (let i = 0; i < chartData.length; i++) {
      const data = chartData[i];
      const x = startX + (i / (chartData.length - 1)) * chartWidth;
      const y = maxY - data.value * ratio * chartHeight;
      if (i > 0) {
        const prevData = chartData[i - 1];
        const prevX = startX + ((i - 1) / (chartData.length - 1)) * chartWidth;
        const prevY = maxY - prevData.value * ratio * chartHeight;
        doc.setDrawColor(132, 134, 231);
        doc.setLineWidth(1);
        doc.line(prevX, prevY, x, y);
      }
    }
    for (let i = 0; i < chartData.length; i++) {
      const data = chartData[i];
      const x = startX + (i / (chartData.length - 1)) * chartWidth;
      const y = maxY - data.value * ratio * chartHeight;
      doc.setFillColor(70, 48, 171);
      doc.circle(x, y, dataPointRadius, 'F');
    }
  };

  const createLesionItem = (lesion, x, y) => {
    const t = lesion?.currentTimepoint || {};
    if (t.imageURL) {
      doc.addImage(t.imageURL, 'JPEG', x, y, 100, 100);
    }
    doc.text(`Name: ${t.ROIName}`, x + 110, y + 10);
    doc.text(`Long Axis: ${t.longAxis} mm`, x + 110, y + 25);
    doc.text(`Short Axis: ${t.shortAxis} mm`, x + 110, y + 40);
    doc.text(`Volume: ${t.volume} cc`, x + 110, y + 55);
    doc.text(`Date: ${t.dateRange}`, x + 270, y + 10);
    doc.text(`Status: ${t.status}`, x + 270, y + 25);
    doc.text(`Location: ${t.location}`, x + 270, y + 40);
    if (t.imageURL) {
      doc.text(`Note: ${t.note}`, x + 110, y + 70, { maxWidth: 290 });
    }
  };

  const createLesionPages = () => {
    if (data.Lesions.length > 0) {
      let count;
      const lesionsWithImages = [];
      const lesionsWithoutImages = [];
      data.Lesions.forEach(l => {
        if (l.displayImage) {
          lesionsWithImages.push(l);
        } else {
          lesionsWithoutImages.push(l);
        }
      });
      count = -1;
      lesionsWithImages.forEach(lesion => {
        count += 1;
        if (count % 4 === 0) {
          addNewPage();
          addSubtitle('Primary Tumors', 20, 100);
        }
        const x = 20;
        const y = ((count % 4) + 1) * 120;
        createLesionItem(lesion, x, y);
        if (lesion.previousTimepoints.length > 0) {
          lesion.previousTimepoints.forEach((timepoint, index) => {
            if (index % 4 === 0) {
              count += 1;
              if (count % 4 === 0) {
                addNewPage();
                addSubtitle('Primary Tumors', 20, 100);
              }
            }
            const x = 20;
            const y = ((count % 4) + 1) * 120;
            const ix = x + (index % 4) * 100;
            const iy = y;
            if (timepoint.imageURL) {
              doc.addImage(timepoint.imageURL, 'JPEG', ix, iy, 90, 90);
            } else {
              doc.setDrawColor(217, 217, 217);
              doc.rect(ix, iy, 90, 90);
            }
            doc.text(timepoint.date, ix + 45, iy + 100, { align: 'center' });
          });
        }
        const ly = 110 + ((count % 4) + 1) * 120;
        doc.setDrawColor(217, 217, 217);
        doc.line(x, ly, 425, ly);
      });
      count = -1;
      lesionsWithoutImages.forEach(lesion => {
        count += 1;
        if (count % 6 === 0) {
          addNewPage();
          addSubtitle('Primary Tumors', 20, 100);
        }
        const x = 20;
        const y = 120 + (count % 6) * 80;
        createLesionItem(lesion, x, y);
        const ly = 110 + ((count % 6) + 1) * 80;
        doc.setDrawColor(217, 217, 217);
        doc.line(x, ly, 425, ly);
      });
    }
  };

  const createTablePages = () => {
    doc.setDrawColor(217, 217, 217);
    const generalSettings = {
      startY: 115,
      margin: 20,
      theme: 'grid',
      headStyles: { fillColor: 'gray' },
    };

    if (data.matchings.length > 0) {
      const titles = [
        'ID',
        ...Object.keys(data.matchings[0]).filter(n => !isNaN(Number(n))),
      ];
      addNewPage();
      addSubtitle('Tumor Matching', 20, 95);
      autoTable(doc, {
        head: [titles],
        body: data.matchings.map(o => {
          const t = {};
          Object.entries(o).forEach(([key, value]) => {
            t[key] = { content: value, styles: { textColor: o.color } };
          });
          return t;
        }),
        columns: titles.map(i => ({ header: i, dataKey: i })),
        ...generalSettings,
      });
    }

    if (data.volumes.length > 0) {
      const titles = [
        'ID',
        ...Object.keys(data.volumes[0]).filter(n => !isNaN(Number(n))),
        'Last_2_TPs',
        'ROI_Name',
      ];
      addNewPage();
      addSubtitle('Tumor Volume (cc)', 20, 95);
      autoTable(doc, {
        head: [titles],
        body: data.volumes.map(o => {
          const t = {};
          Object.entries(o).forEach(([key, value]) => {
            t[key] = { content: value, styles: { textColor: o.color } };
          });
          return t;
        }),
        columns: titles.map(i => ({ header: i, dataKey: i })),
        ...generalSettings,
      });
    }

    if (data.diameters.length > 0) {
      const titles = [
        'ID',
        ...Object.keys(data.diameters[0]).filter(n => !isNaN(Number(n))),
        'Last_2_TPs',
        'ROI_Name',
      ];
      addNewPage();
      addSubtitle('Tumor Diameter (mm)', 20, 95);
      autoTable(doc, {
        head: [titles],
        body: data.diameters.map(o => {
          const t = {};
          Object.entries(o).forEach(([key, value]) => {
            t[key] = { content: value, styles: { textColor: o.color } };
          });
          return t;
        }),
        columns: titles.map(i => ({ header: i, dataKey: i })),
        ...generalSettings,
      });
    }
  };

  addNewPage();
  createOverviewSection(20, 95);
  createDiagramSection(20, 260, data.sumOfVolumes);
  createLesionPages();
  createTablePages();

  doc.save(filename);
}
