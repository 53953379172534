import React, { useState } from 'react';
import { Typography, Box, Grid, Divider, Button } from '@material-ui/core';
import _ from 'lodash';

import { guidelines } from '@platform/core';
import { Body, Container } from '@platform/ui';
import CriterionBox from './CriterionBox';

const CriteriaForm = ({
  defaultCriteria,
  onUpdateCriteriaClick,
  timepoint = {},
  component = 'Platform',
  readOnly = false,
}) => {
  const [criteria, setCriteria] = useState(defaultCriteria);

  const getCriterionValue = (key, value) => {
    switch (key) {
      case 'guidelineId':
      case 'measurementTechnique':
      case 'completeResponseConfirmation':
      case 'newLesionConfirmation':
      case 'newLesionDateOfProgression':
      case 'newLesionAlert':
      case 'overallResponseAlert':
        return value || '';
      case 'minDiameterIncrease':
      case 'minDiameterDecrease':
      case 'minVolumeIncrease':
      case 'minVolumeDecrease':
        return Math.round(100 * Number(value || 0));
      default:
        return Number(value || 0);
    }
  };

  const onCriterionChange = (key, value) => {
    let newCriteria = _.cloneDeep(criteria);
    switch (key) {
      case 'guidelineId':
        if (value === 'CUSTOM') {
          newCriteria = _.cloneDeep(defaultCriteria);
          newCriteria['guidelineId'] = value;
          newCriteria['guidelineName'] = guidelines[value].guidelineName;
        } else {
          newCriteria = guidelines[value];
        }
        break;
      case 'measurementTechnique':
      case 'completeResponseConfirmation':
      case 'newLesionConfirmation':
      case 'newLesionDateOfProgression':
      case 'newLesionAlert':
      case 'overallResponseAlert':
        newCriteria[key] = value;
        break;
      case 'minDiameterIncrease':
      case 'minDiameterDecrease':
      case 'minVolumeIncrease':
      case 'minVolumeDecrease':
        newCriteria[key] = Number(value) / 100;
        break;
      default:
        newCriteria[key] = Number(value);
    }
    setCriteria(newCriteria);
  };

  return (
    <Body>
      <Container mode={component === 'Viewer' ? 'dark' : 'light'}>
        {component === 'Platform' && (
          <Typography component="h2" variant="h6" color="primary" gutterBottom>
            Criteria
          </Typography>
        )}
        {component === 'Viewer' && (
          <>
            <Grid container spacing={0} sx={{ padding: '15px 0' }}>
              {[
                'ClinicalTrialSiteName',
                'ClinicalTrialSubjectID',
                'SliceThickness',
                'SpacingBetweenSlices',
              ].map(key => (
                <Grid item xs={6} key={key}>
                  <CriterionBox
                    criterionKey={key}
                    criterionValue={timepoint[key]}
                    onCriterionChange={() => {}}
                    readOnly={true}
                  ></CriterionBox>
                </Grid>
              ))}
            </Grid>
            <Divider></Divider>
          </>
        )}
        <Grid container spacing={0} sx={{ padding: '15px 0' }}>
          {['guidelineId', 'measurementTechnique'].map(key => (
            <Grid item xs={6} key={key}>
              <CriterionBox
                criterionKey={key}
                criterionValue={getCriterionValue(key, criteria[key])}
                onCriterionChange={onCriterionChange}
                readOnly={readOnly}
              ></CriterionBox>
            </Grid>
          ))}
        </Grid>
        <Divider></Divider>
        <Grid container spacing={0} sx={{ padding: '15px 0' }}>
          {['maxNumberOfTargets', 'maxNumberOfTargetsPerOrgan'].map(key => (
            <Grid item xs={6} key={key}>
              <CriterionBox
                criterionKey={key}
                criterionValue={getCriterionValue(key, criteria[key])}
                onCriterionChange={onCriterionChange}
                readOnly={readOnly || criteria.guidelineId !== 'CUSTOM'}
              ></CriterionBox>
            </Grid>
          ))}
        </Grid>
        <Divider></Divider>
        {criteria?.measurementTechnique === 'BIDIMENSIONAL' && (
          <Grid container spacing={0} sx={{ padding: '15px 0' }}>
            {[
              'measurableLongAxis',
              'measurableShortAxis',
              //'measurableShortAxisOfLymphNode',
              'measurableLongAxisOfNewLesion',
              'smallDiameterChange',
              'measurableLongAxisOfNewLesionHST',
              'highSliceThickness',
              'minDiameterIncrease',
              'absoluteDiameterChange',
              'minDiameterDecrease',
              'absoluteSumOfDiameterChange',
            ].map(key => (
              <Grid item xs={6} key={key}>
                <CriterionBox
                  criterionKey={key}
                  criterionValue={getCriterionValue(key, criteria[key])}
                  onCriterionChange={onCriterionChange}
                  readOnly={readOnly || criteria.guidelineId !== 'CUSTOM'}
                ></CriterionBox>
              </Grid>
            ))}
          </Grid>
        )}
        {criteria?.measurementTechnique === 'VOLUMETRIC' && (
          <Grid container spacing={0} sx={{ padding: '15px 0' }}>
            {[
              'measurableVolume',
              //'measurableVolumeOfLymphNode',
              //'measurableVolumeOfNewLesion',
              //'smallVolumeChange',
              '',
              'minVolumeIncrease',
              //'absoluteVolumeChange',
              '',
              'minVolumeDecrease',
              //'absoluteSumOfVolumeChange',
            ].map(key => (
              <Grid item xs={6} key={key}>
                <CriterionBox
                  criterionKey={key}
                  criterionValue={getCriterionValue(key, criteria[key])}
                  onCriterionChange={onCriterionChange}
                  readOnly={readOnly || criteria.guidelineId !== 'CUSTOM'}
                ></CriterionBox>
              </Grid>
            ))}
          </Grid>
        )}
        <Divider></Divider>
        <Grid container spacing={0} sx={{ padding: '15px 0' }}>
          {[
            'completeResponseConfirmation',
            'minStableDiseaseDuration',
            'newLesionConfirmation',
            'newLesionDateOfProgression',
          ].map(key => (
            <Grid item xs={6} key={key}>
              <CriterionBox
                criterionKey={key}
                criterionValue={getCriterionValue(key, criteria[key])}
                onCriterionChange={onCriterionChange}
                readOnly={readOnly || criteria.guidelineId !== 'CUSTOM'}
              ></CriterionBox>
            </Grid>
          ))}
        </Grid>
        <Divider></Divider>
        <Grid container spacing={0} sx={{ padding: '15px 0' }}>
          {['newLesionAlert', 'overallResponseAlert'].map(key => (
            <Grid item xs={6} key={key}>
              <CriterionBox
                criterionKey={key}
                criterionValue={getCriterionValue(key, criteria[key])}
                onCriterionChange={onCriterionChange}
              ></CriterionBox>
            </Grid>
          ))}
        </Grid>
        <Divider></Divider>
      </Container>
      <div>
        {!readOnly && component === 'Platform' && (
          <Box sx={{ margin: '15px 0 0 0' }}>
            <Button onClick={() => onUpdateCriteriaClick(criteria)} fullWidth>
              Update
            </Button>
          </Box>
        )}
        {!readOnly && component === 'Viewer' && (
          <Box sx={{ margin: '15px 0 0 0' }}>
            <Grid container spacing={0}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button
                  onClick={() => onUpdateCriteriaClick(criteria)}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </Body>
  );
};

export default CriteriaForm;
