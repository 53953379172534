import mprMetaDataStore from './mprMetaDataStore.js';

export default function provider(moduleName, imageId) {
  const meta = mprMetaDataStore.get(imageId);

  if (!meta) {
    return;
  }
  if (moduleName === 'imagePlaneModule') {
    const imagePlaneModule = meta.imagePlaneModule;
    return imagePlaneModule;
  }
  if (moduleName === 'instance') {
    const instance = { SeriesInstanceUID: meta.SeriesInstanceUID };
    return instance;
  }
  return;
}
