import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');
export const getNewContext = importInternal('drawing/getNewContext');

export function edgePainter(toolState, structureSetSeriesInstanceUid) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  const state = _.cloneDeep(toolState);
  let points = [];
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  if (!_.isNumber(ROINumber)) {
    return;
  }
  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      const newData = {
        ROINumber,
        handles: {
          points,
        },
        structureSetSeriesInstanceUid,
      };
      state.data = [...state.data, newData];
      points = [];
      return newData;
    },
    update: function(evt) {
      const element = evt.detail.element;
      const eventData = evt.detail;
      if (!points.length && eventData.lastPoints) {
        points.push(eventData.lastPoints.image);
      }
      points.push(eventData.currentPoints.image);
      if (eventData.lastPoints && eventData.currentPoints) {
        const canvas = cornerstone.getEnabledElement(element).canvas;
        const context = getNewContext(canvas);
        draw(context, context => {
          context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
          context.beginPath();
          context.moveTo(
            eventData.lastPoints.canvas.x,
            eventData.lastPoints.canvas.y
          );
          context.lineTo(
            eventData.currentPoints.canvas.x,
            eventData.currentPoints.canvas.y
          );
          context.stroke();
        });
      }
      return false;
    },
    cursor: function() {},
  };
}
