const guideline = {
  guidelineId: 'CUSTOM',
  guidelineName: 'Custom',
  measurementTechnique: 'BIDIMENSIONAL',
  maxNumberOfTargets: 0,
  maxNumberOfTargetsPerOrgan: 0,
  measurableLongAxis: 0,
  measurableShortAxis: 0,
  measurableVolume: 0,
  measurableLongAxisOfLymphNode: 15,
  measurableShortAxisOfLymphNode: 15,
  measurableVolumeOfLymphNode: 0,
  measurableLongAxisOfNewLesion: 0,
  measurableLongAxisOfNewLesionHST: 10,
  highSliceThickness: 2,
  measurableShortAxisOfNewLesion: 0,
  measurableVolumeOfNewLesion: 0,
  newLesionConfirmation: 'NOT_APPLICABLE',
  newLesionDateOfProgression: 'USE_DETERIORATION_DATE',
  newLesionAlert: 'ENABLED',
  overallResponseAlert: 'ENABLED',
  minDiameterIncrease: 0,
  minDiameterDecrease: 0,
  minVolumeIncrease: 0,
  minVolumeDecrease: 0,
  smallDiameterChange: 0,
  smallVolumeChange: 0,
  absoluteDiameterChange: 0,
  absoluteSumOfDiameterChange: 0,
  absoluteVolumeChange: 0,
  absoluteSumOfVolumeChange: 0,
  completeResponseConfirmation: 'NOT_APPLICABLE',
  minStableDiseaseDuration: 0,
};

export default guideline;
