import isHighPriorityModality from './isHighPriorityModality';

/**
 * Get sorted image sets
 *
 * @param {array} displaySets
 * @return {Array} Array of imageSet object
 */
export default function getSortedImageSetsFromDisplaySets(displaySets) {
  const imageSets = displaySets.filter(displaySet => {
    return isHighPriorityModality(displaySet.Modality);
  });
  sortDisplaySetsByDate(imageSets);
  return imageSets;
}

/**
 * Sort display sets by series date or content date.
 *
 * @param {array} displaySets
 * @returns
 */
export function sortDisplaySetsByDate(displaySets) {
  if (displaySets.length === 0) {
    return;
  }
  displaySets.sort((a, b) => {
    if (!isHighPriorityModality(a.Modality)) {
      return 1;
    } else if (!isHighPriorityModality(b.Modality)) {
      return -1;
    }
    const aSeriesDate =
      a.SeriesDate || a.ContentDate || a.AcquisitionDate || '99999999';
    const bSeriesDate =
      b.SeriesDate || b.ContentDate || b.AcquisitionDate || '99999999';
    return Number(aSeriesDate) - Number(bSeriesDate);
  });
}

/**
 * Sort display sets by series description.
 *
 * @param {array} displaySets
 * @returns
 */
export function sortDisplaySetsByDescription(displaySets) {
  if (displaySets.length === 0) return;
  const isAxialDescription = d => {
    const sd = (d.SeriesDescription || '').toLowerCase();
    return sd.indexOf('coronal') === -1 && sd.indexOf('sagittal') === -1;
  };
  displaySets.sort((a, b) => {
    if (isAxialDescription(a) && isAxialDescription(b)) {
      return 0;
    } else if (isAxialDescription(a)) {
      return -1;
    } else if (isAxialDescription(b)) {
      return 1;
    }
  });
}
