import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

// Cornerstone 3rd party dev kit imports
const draw = importInternal('drawing/draw');
const getNewContext = importInternal('drawing/getNewContext');

const radius = 5;

export function polygonPainter(toolState, structureSetSeriesInstanceUid) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  const state = _.cloneDeep(toolState);
  let points = [];
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  if (!_.isNumber(ROINumber)) {
    return;
  }
  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      const element = evt.detail.element;
      const newData = {
        ROINumber,
        handles: {
          points: points.map(p => cornerstone.canvasToPixel(element, p)),
        },
        structureSetSeriesInstanceUid,
      };
      state.data = [...state.data, newData];
      points = [];
      return newData;
    },
    update: function(evt) {
      const element = evt.detail.element;
      const eventData = evt.detail;
      const newPoint = eventData.currentPoints.canvas;
      if (points.length > 2 && distance(points[0], newPoint) < radius) {
        return false;
      }

      const lastPoint = points[points.length - 1];
      points.push(newPoint);
      const canvas = cornerstone.getEnabledElement(element).canvas;
      const context = getNewContext(canvas);

      draw(context, context => {
        context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
        context.fillStyle = `rgba(${colorArray.join(',')}, 1)`;

        context.beginPath();
        context.arc(newPoint.x, newPoint.y, radius, 0, 2 * Math.PI);
        context.fill();

        if (lastPoint) {
          context.beginPath();
          context.moveTo(lastPoint.x, lastPoint.y);
          context.lineTo(newPoint.x, newPoint.y);
          context.stroke();
        }
      });
      return true;
    },
    cursor: function() {},
  };
}

function distance(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}
