export default function getHotkeys() {
  return [
    { commandName: 'scaleUpViewport', label: 'Zoom In', keys: ['+'] },
    { commandName: 'scaleDownViewport', label: 'Zoom Out', keys: ['-'] },
    { commandName: 'nextImage', label: 'Next Image', keys: ['down'] },
    { commandName: 'previousImage', label: 'Previous Image', keys: ['up'] },
    {
      commandName: 'setRepulserActive',
      label: 'Set Repulser Active',
      keys: ['ctrl+r'],
    },
    {
      commandName: 'setSketchActive',
      label: 'Set Sketch Active',
      keys: ['ctrl+s'],
    },
    {
      commandName: 'setPolygonActive',
      label: 'Set Polygon Active',
      keys: ['ctrl+p'],
    },
    {
      commandName: 'setDeleteActive',
      label: 'Set Delete Active',
      keys: ['ctrl+d'],
    },
    {
      commandName: 'interpolateContour',
      label: 'Copy Contour',
      keys: ['ctrl+v'],
    },
    {
      commandName: 'clearActiveViewportContours',
      label: 'Delete Contours',
      keys: ['ctrl+x'],
    },
    {
      commandName: 'undoRT',
      label: 'Undo RT',
      keys: ['ctrl+z'],
    },
    {
      commandName: 'redoRT',
      label: 'Redo RT',
      keys: ['ctrl+shift+z'],
    },
    {
      commandName: 'clearRefLine',
      label: 'Clear Reference Line',
      keys: ['esc'],
    },
    {
      commandName: 'switchPointLabel',
      label: 'Switch Point Label',
      keys: ['space'],
    },
  ];
}
