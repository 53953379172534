import getTimepointWithTool from './getTimepointWithTool';
import getSeriesUIDByStructureSetUID from './getSeriesUIDByStructureSetUID';

export default function getTimepointsByImageSets(
  axialImageSets,
  structureSets,
  seriesStructureMap,
  calculate3D = true
) {
  const timepoints = structureSets
    .map(set => {
      const uid = getSeriesUIDByStructureSetUID(
        set.SeriesInstanceUID,
        seriesStructureMap
      );
      const s = axialImageSets.find(set => set.SeriesInstanceUID === uid);
      return getTimepointWithTool(s, set.ROIContours, calculate3D);
    })
    .filter(t => !!t.SeriesDate);
  timepoints.sort((a, b) => Number(a.SeriesDate) - Number(b.SeriesDate));
  return timepoints;
}

export function getImageSetsFromReferences(
  axialImageSets,
  references,
  seriesStructureMap
) {
  const referencedImageSets = references.map(ref => {
    const uid = getSeriesUIDByStructureSetUID(
      ref.SeriesInstanceUID,
      seriesStructureMap
    );
    const set = axialImageSets.find(set => set.SeriesInstanceUID === uid);
    return set || {};
  });
  return referencedImageSets;
}
