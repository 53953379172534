import getNumberOfDays from './getNumberOfDays.js';
import transformStudyDateToDate from './transformStudyDateToDate.js';
import findTimepointIndex from './findTimepointIndex.js';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @returns {string}
 */
function getDurationOfResponse(reports) {
  const responsiveIndex = findTimepointIndex(reports, 'responsive');
  const progressiveIndex = findTimepointIndex(reports, 'progressive');
  const latestIndex = findTimepointIndex(reports, 'latest');
  if (responsiveIndex > 0) {
    const responsiveDate = transformStudyDateToDate(
      reports[responsiveIndex].date
    );
    if (progressiveIndex === -1) {
      const latestDate = transformStudyDateToDate(reports[latestIndex].date);
      return getNumberOfDays(responsiveDate, latestDate);
    } else if (progressiveIndex > responsiveIndex) {
      const progressiveDate = transformStudyDateToDate(
        reports[progressiveIndex].date
      );
      return getNumberOfDays(responsiveDate, progressiveDate);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export default getDurationOfResponse;
