import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ToolbarButton from './ToolbarButton.js';
import { OverlayTrigger } from '../components/overlayTrigger/OverlayTrigger.js';
import { Tooltip } from '../components/tooltip/Tooltip.js';
import './ExpandableToolMenu.styl';

function ExpandableToolMenu(props) {
  const [isExpanded, setIsExpanded] = useState(false);

  const getButtons = () => {
    return props.buttons.map((button, index) => {
      return (
        <ToolbarButton
          key={index}
          {...button}
          isActive={button.id === props.activeCommand}
        />
      );
    });
  };

  const toolbarMenuOverlay = () => (
    <Tooltip
      placement="bottom"
      className="tooltip-toolbar-overlay"
      id={`${Math.random()}_tooltip-toolbar-overlay}`}
    >
      {getButtons()}
    </Tooltip>
  );

  const isActive = () => {
    let isActive = false;
    if (props.activeCommand) {
      props.buttons.forEach(button => {
        if (props.activeCommand === button.id) {
          isActive = true;
        }
      });
    }

    return isActive;
  };

  const activeIcon = () => {
    if (props.activeCommand) {
      return (
        props.buttons.find(btn => props.activeCommand === btn.id).icon ||
        props.icon
      );
    }

    return props.icon;
  };

  const onExpandableToolClick = () => {
    if (props.onGroupMenuClick) {
      props.onGroupMenuClick();
    }
    setIsExpanded(!isExpanded);
  };

  const onOverlayHide = () => {
    setIsExpanded(false);
  };
  const getToolBarButtonComponent = () => {
    return (
      <ToolbarButton
        key="menu-button"
        type="tool"
        label={props.label}
        icon={activeIcon()}
        className={'toolbar-button expandableToolMenu'}
        isActive={isActive()}
        isExpandable={true}
        isExpanded={isExpanded}
        disabled={props.disabled}
      />
    );
  };

  const toolbarComponent = getToolBarButtonComponent();

  return (
    <OverlayTrigger
      key="menu-button"
      trigger="click"
      placement="bottom"
      rootClose={true}
      handleHide={onOverlayHide}
      onClick={event => {
        event.stopPropagation();
        onExpandableToolClick();
      }}
      overlay={toolbarMenuOverlay()}
    >
      {toolbarComponent}
    </OverlayTrigger>
  );
}
ExpandableToolMenu.propTypes = {
  /** Button label */
  label: PropTypes.string.isRequired,
  /** Array of buttons to render when expanded */
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string.isRequired,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ]),
    })
  ).isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ]),
  onGroupMenuClick: PropTypes.func,
  activeCommand: PropTypes.string,
  disabled: PropTypes.bool,
};
ExpandableToolMenu.defaultProps = {
  buttons: [],
  icon: 'ellipse-circle',
  label: 'More',
};

export default ExpandableToolMenu;
