import { create, all } from 'mathjs';

const config = {};
const math = create(all, config);

export default function transformPointsToImagePlane(
  points,
  {
    RowPixelSpacing,
    ColumnPixelSpacing,
    ImagePositionPatient,
    ImageOrientationPatient,
    SliceThickness,
  }
) {
  // See Equation C.7.6.2.1-1 of the DICOM standard
  const inverse = math.inv(
    math.transpose(
      math.matrix([
        math.multiply(ImageOrientationPatient.slice(0, 3), ColumnPixelSpacing),
        math.multiply(ImageOrientationPatient.slice(3), RowPixelSpacing),
        math.multiply(
          math.cross(
            ImageOrientationPatient.slice(3),
            ImageOrientationPatient.slice(0, 3)
          ),
          SliceThickness || 1
        ),
      ])
    )
  );

  return points.map(({ x, y, z }) => {
    const index = math
      .multiply(inverse, math.subtract([x, y, z], ImagePositionPatient))
      .toArray();
    return { x: index[0], y: index[1] };
  });
}
