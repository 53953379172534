export default function getIntersectionPoint(
  { x: x1, y: y1 },
  { x: x2, y: y2 },
  { x: x3, y: y3 },
  { x: x4, y: y4 }
) {
  /** https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection */

  /** Check if none of the lines are of length 0 */
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  /** Lines are parallel */
  if (denominator === 0) {
    return false;
  }

  /** Check intersection */
  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;
  if (ua <= 0 || ua > 1) {
    return false;
  }
  if (ub <= 0 || ub > 1) {
    return false;
  }

  /** Return a object with the x and y coordinates of the intersection */
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);
  return { x, y };
}
