function createExportHeader(dataSource, separator) {
  let headerRow = '';
  const columns = dataSource.columns;
  const newLine = '\r\n';

  for (let i = 0; i < columns.length; i++) {
    headerRow += (i > 0 ? separator : '') + columns[i].displayName;
  }
  return headerRow + newLine;
}

function createExportRows(dataSource, separator) {
  let content = '';
  let dataField;
  const columns = dataSource.columns;
  const data = dataSource.data;
  const newLine = '\r\n';

  for (let j = 0; j < data.length; j++) {
    for (let i = 0; i < columns.length; i++) {
      dataField = columns[i].dataField;
      content += (i > 0 ? separator : '') + data[j][dataField];
    }
    content += newLine;
  }
  return content;
}

export function createCsvContent(dataSource) {
  const separator = ',';
  let content = createExportHeader(dataSource, separator);
  content += createExportRows(dataSource, separator);
  return content;
}

export function exportCsv(fileName, dataSource) {
  const content = createCsvContent(dataSource);

  //an anchor html element on the page (or create dynamically one)
  //to use its download attribute to set filename
  const a = document.createElement('a');
  a.textContent = 'download';
  a.download = `${fileName}.csv`;
  a.href =
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(content);
  a.click();
}
