import { createContext } from 'react';

export class PACS {
  constructor(host, config = {}) {
    this.host = host;
    this.enabled = config.dicomWebServer === 'orthanc';
    this.orthanc = config.orthancUrl;
    this.orthancConfig = { Synchronous: true, Timeout: 10 };
  }

  /** ORTHANC REST API */
  isEnabled() {
    return this.enabled;
  }
  async findSeriesID({ StudyInstanceUID, SeriesInstanceUID }) {
    const url = `${this.orthanc}/tools/find`;
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/dicom+json' },
      body: JSON.stringify({
        Level: 'Series',
        Query: { StudyInstanceUID, SeriesInstanceUID },
        Expand: true,
      }),
    });
    const data = await res.json();
    const { ID } = data[0];
    return ID;
  }
  async listDICOMmodalities() {
    const url = `${this.orthanc}/modalities`;
    const res = await fetch(url, { method: 'GET' });
    const data = await res.json();
    /** not displaying vysioneer's AE */
    const _data = data.filter(i => i !== 'vysioneer_ae');
    return _data;
  }
  async forwardSeries(id, { StudyInstanceUID, SeriesInstanceUID }) {
    const series = { StudyInstanceUID, SeriesInstanceUID };
    const seriesID = await this.findSeriesID(series);
    const url = `${this.orthanc}/modalities/${id}/store`;
    const forwarded = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...this.orthancConfig,
        Resources: [seriesID],
      }),
    });
    if (forwarded.status !== 200) return false;
    return true;
  }
  async getModalityConfig(id) {
    const url = `${this.orthanc}/modalities/${id}/configuration`;
    const res = await fetch(url, { method: 'GET' });
    const data = await res.json();
    return data;
  }
}

export const PACSContext = createContext();
