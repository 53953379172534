import { useEffect, useContext } from 'react';

import { redux } from '@platform/core';
import { useSnackbar } from '@platform/ui';
import store from '../store';
import { APIContext } from '../context/APIContext';

const { actions } = redux;

const Auth = ({ children }) => {
  const api = useContext(APIContext);
  const snackbar = useSnackbar();
  let lastUpdateTime = new Date().getTime();

  const checkUpdateTime = () => {
    const newTime = new Date().getTime();
    if (newTime - lastUpdateTime > 60 * 1000) {
      lastUpdateTime = newTime;
      try {
        if (api?.isEnabled()) api.me();
      } catch (err) {
        snackbar.show({
          title: 'AUTHENTICATION',
          message: 'Internal server error',
          type: 'error',
          autoClose: false,
        });
      }
    }
  };
  const onMouseMove = () => checkUpdateTime();

  useEffect(() => {
    (async () => {
      if (api?.isEnabled()) {
        const user = await api.me();
        store.dispatch(actions.setEmail(user.preferred_username));
      } else {
        store.dispatch(actions.setEmail('dev@vysioneer.com'));
      }
    })();
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => window.removeEventListener('mousemove', onMouseMove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default Auth;
