import cornerstone from 'cornerstone-core';
import transformPointsToPhysical from './transformPointsToPhysical';

export default function transformPointsToPhysicalById(points, imageId) {
  const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
  return transformPointsToPhysical(points, {
    RowCosines: imagePlane.rowCosines,
    ColumnCosines: imagePlane.columnCosines,
    RowPixelSpacing: imagePlane.rowPixelSpacing,
    ColumnPixelSpacing: imagePlane.columnPixelSpacing,
    ImagePositionPatient: imagePlane.imagePositionPatient,
  });
}
