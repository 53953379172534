import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import getTimepoints from './measurements/getTimepoints';
import getTimepointsByImageSets from './measurements/getTimepointsByImageSets';
import getStats from './stats/getStats';
import getVolumeContent from './reports/tracking/getVolumeContent';
import getAxisContent from './reports/tracking/getAxisContent';
import getAxis3DContent from './reports/tracking/getAxis3DContent';
import getMatchingContent from './reports/tracking/getMatchingContent';
import getAxes from './reports/tracking/getAxes';
import getVolumes from './reports/tracking/getVolumes';
import getMatchings from './reports/tracking/getMatchings';
import { round } from './utils/math';

const locationMap = {
  L_Frn: 'Left Frontal',
  R_Frn: 'Right Frontal',
  L_Temp: 'Left Temporal',
  R_Temp: 'Right Temporal',
  L_Par: 'Left Parietal',
  R_Par: 'Right Parietal',
  L_Occ: 'Left Occipital',
  R_Occ: 'Right Occipital',
  L_Cere: 'Left Cerebellum',
  R_Cere: 'Right Cerebellum',
  BS: 'Brainstem',
  L_Caud: 'Left Caudate',
  R_Caud: 'Right Caudate',
  L_Puta: 'Left Putamen',
  R_Puta: 'Right Putamen',
  L_Thal: 'Left Thalamus',
  R_Thal: 'Right Thalamus',
  L_GP: 'Left Globus Pallidus',
  R_GP: 'Right Globus Pallidus',
};

export default class TimepointManager {
  constructor() {
    this.timepoints = [];
    this.links = [];
  }
  addTimepointsByDICOMfiles(series, structureSets, seriesStructureMap) {
    this.timepoints = getTimepoints(series, structureSets, seriesStructureMap);
    this.seriesStructureMap = seriesStructureMap;
  }
  addTimepointsByImageSets(
    imageSets,
    structureSets,
    seriesStructureMap,
    calculate3D
  ) {
    this.timepoints = getTimepointsByImageSets(
      imageSets,
      structureSets,
      seriesStructureMap,
      calculate3D
    );
    this.seriesStructureMap = seriesStructureMap;
  }
  addLinks(links) {
    this.links = links;
  }
  getTimepoints() {
    return this.timepoints;
  }
  getTimepointsReport() {
    const timepointStats = this.getStats();
    const measurements = timepointStats
      .map(stat => ({ ...stat.sumOfLinks }))
      .map(item => ({ label: item.date, value: Number(item.volume) }));
    const baseline = this.timepoints[0];
    const planning = this.timepoints[this.timepoints.length - 1];
    const reportLesions = this.links
      .map(link => {
        const linkedRois = link.RTMappings.reduce((acc, cur) => {
          const timepoint = this.timepoints.find(
            t =>
              this.seriesStructureMap[t.SeriesInstanceUID] ===
              cur.SeriesInstanceUID
          );
          const rois = cur.ROINumbers.map(number => {
            const roi = timepoint.ROIMeasurements.find(
              roi => roi.ROINumber === number
            );
            return {
              ...roi,
              StudyDate: timepoint.StudyDate,
              SeriesInstanceUID: cur.SeriesInstanceUID,
              Rows: timepoint.Rows,
              Columns: timepoint.Columns,
            };
          });
          return [...acc, ...rois];
        }, []);
        linkedRois.sort(function(a, b) {
          return Number(a.StudyDate) - Number(b.StudyDate);
        });
        const roi = linkedRois.find(
          l =>
            this.seriesStructureMap[planning.SeriesInstanceUID] ===
            l.SeriesInstanceUID
        );
        if (!roi) return null;
        const currentId = `${planning.StudyDate}-${roi.ROINumber}`;
        const defaultScale = 2;
        return {
          id: roi.ROINumber,
          currentTimepoint: {
            id: currentId,
            imageId: roi.imageId,
            ROIName: roi.ROIName,
            ROINumber: roi.ROINumber,
            longAxis: round(roi.maxLongAxisValue, -2),
            shortAxis: round(roi.maxShortAxisValue, -2),
            volume: round(roi.volume / 1000, -2),
            dateRange:
              linkedRois.length > 1
                ? `${baseline.StudyDate} - ${planning.StudyDate}`
                : planning.StudyDate,
            status: linkedRois.length > 1 ? 'Tracked' : 'New',
            location: this.getLocation(roi.ROIName),
            note: '',
            scale: defaultScale,
            translation: {
              x: planning.Rows / 2 - roi.maxAreaCentroid.x,
              y: planning.Columns / 2 - roi.maxAreaCentroid.y,
            },
          },
          previousTimepoints: linkedRois
            .map(roi => ({
              id: `${roi.StudyDate}-${roi.ROINumber}`,
              imageId: roi.imageId,
              date: roi.StudyDate,
              scale:
                defaultScale *
                (Math.min(planning.Rows, planning.Columns) /
                  Math.min(roi.Rows, roi.Columns)),
              translation: {
                x: roi.Rows / 2 - roi.maxAreaCentroid.x,
                y: roi.Columns / 2 - roi.maxAreaCentroid.y,
              },
            }))
            .filter(roi => roi.id !== currentId),
        };
      })
      .filter(i => i);
    const distribution = { overall: 0 };
    Object.keys(locationMap).forEach(key => {
      distribution[key] = 0;
    });
    reportLesions.forEach(l => {
      distribution.overall += 1;
      Object.entries(locationMap).forEach(([key, value]) => {
        if (value === l.currentTimepoint.location) distribution[key] += 1;
      });
    });
    reportLesions.sort(
      (a, b) => b.currentTimepoint.longAxis - a.currentTimepoint.longAxis
    );
    const numberOfROI = this.links.reduce((count, l) => {
      count += l.RTMappings.length;
      return count;
    }, 0);
    const maxNumber = Math.max(5, Math.floor(30 / this.timepoints.length));
    reportLesions.forEach((l, index) => {
      l.displayImage = numberOfROI <= 30 || index < maxNumber;
    });
    return {
      PatientName: planning.PatientName,
      PatientID: planning.PatientID,
      StudyDate: planning.StudyDate,
      Lesions: reportLesions,
      sumOfVolumes: measurements,
      volumes: getVolumes(this.getStats(), this.links),
      diameters: getAxes(this.getStats(), this.links),
      matchings: getMatchings(this.getStats(), this.links),
      distribution: distribution,
    };
  }
  getLocation(name) {
    let location = 'Unknown';
    Object.entries(locationMap).forEach(([key, value]) => {
      if (name.includes(key)) location = value;
    });
    return location;
  }
  getStats() {
    return getStats(this.timepoints, this.links, this.seriesStructureMap);
  }
  async exportTrackingReport(filename) {
    const volumeContent = getVolumeContent(this.getStats(), this.links);
    const axisContent = getAxisContent(this.getStats(), this.links);
    const axis3DContent = getAxis3DContent(this.getStats(), this.links);
    const matchingContent = getMatchingContent(this.getStats(), this.links);
    const zip = new JSZip();
    zip.file(`volume.csv`, volumeContent);
    zip.file(`major_axis.csv`, axisContent);
    zip.file(`major_axis_3D.csv`, axis3DContent);
    zip.file(`matching.csv`, matchingContent);
    zip.generateAsync({ type: 'blob' }).then(blob => saveAs(blob, filename));
  }
}
