import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { generateUuid } from '../utils/generator';
import { writeLog } from './logs';
import _ from 'lodash';

/**
 * @param {String} email
 * @returns {Array} the list of sites
 */
export async function getSites(email) {
  const sites = [];
  const getQuerySnapshotByRole = async role => {
    return await firebase
      .firestore()
      .collection('sites')
      .where(role, 'array-contains', email)
      .get();
  };
  const managerSnapshot = await getQuerySnapshotByRole('managers');
  managerSnapshot.forEach(doc => {
    sites.push({ id: doc.id, ...doc.data(), role: 'site-manager' });
  });
  const userSnapshot = await getQuerySnapshotByRole('users');
  userSnapshot.forEach(doc => {
    sites.push({ id: doc.id, ...doc.data(), role: 'site-user' });
  });
  return sites;
}

/**
 * @param {String} siteId
 * @returns {Object} site
 */
export async function getSite(siteId) {
  const doc = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .get();
  return { id: doc.id, ...doc.data() };
}

/**
 * @param {String} email
 * @param {Object} site
 * @param {Object} trial
 * @returns {String} role
 */
export function getTrialRole(email, site, trial) {
  return site.managers?.includes(email)
    ? 'site-manager'
    : trial.owners?.includes(email)
    ? 'trial-owner'
    : trial.reviewers?.includes(email)
    ? 'trial-reviewer'
    : trial.readers?.includes(email)
    ? 'trial-reader'
    : '';
}

/**
 * @param {String} email
 * @param {String} siteId
 * @returns {Array} the list of trials
 */
export async function getTrials(email, siteId) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const getQuerySnapshotByRole = async role => {
    return await db.where(role, 'array-contains', email).get();
  };
  const trials = [];
  const site = await getSite(siteId);
  if (site.managers?.includes(email)) {
    const querySnapshot = await db.get();
    querySnapshot.forEach(doc => {
      trials.push({ id: doc.id, ...doc.data(), role: 'site-manager' });
    });
  } else {
    const ownerSnapshot = await getQuerySnapshotByRole('owners', email);
    ownerSnapshot.forEach(doc => {
      trials.push({ id: doc.id, ...doc.data(), role: 'trial-owner' });
    });
    const reviewerSnapshot = await getQuerySnapshotByRole('reviewers', email);
    reviewerSnapshot.forEach(doc => {
      trials.push({ id: doc.id, ...doc.data(), role: 'trial-reviewer' });
    });
    const readerSnapshot = await getQuerySnapshotByRole('readers', email);
    readerSnapshot.forEach(doc => {
      trials.push({ id: doc.id, ...doc.data(), role: 'trial-reader' });
    });
  }
  return trials;
}

/**
 * @param {String} email
 * @param {String} siteId
 * @param {String} trialId
 * @returns {Object} trial
 */
export async function getTrial(email, siteId, trialId) {
  const site = await getSite(siteId);
  const doc = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .get();
  const trial = { id: doc.id, ...doc.data() };
  return { ...trial, role: getTrialRole(email, site, trial) };
}

export async function getTrialCriteria({ site: siteId, trial: trialId }) {
  if (!siteId || !trialId) return {};
  const user = firebase.auth().currentUser;
  const trial = await getTrial(user.email, siteId, trialId);
  return trial.criteria;
}

/**
 * @param {String} siteId
 * @param {String} data.name
 * @param {String} data.description
 * @returns {Boolean}
 */
export async function createTrial(siteId, data) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');

  /** check repeated name */
  const trials = [];
  const querySnapshot = await db.get();
  querySnapshot.forEach(doc => {
    trials.push({ id: doc.id, ...doc.data() });
  });
  if (trials.find(trial => trial.name === data.name)) {
    window.alert('The name already exists');
    return false;
  }

  /** create trial */
  const id = generateUuid(data.name);
  await db.doc(id).set({
    name: data.name,
    location: data.location,
    dataset: data.datasetId,
    datastore: data.dicomStoreId,
    bucketName: data.bucketName,
    criteria: data.criteria,
    description: data.description,
    owners: [],
    reviewers: [],
    readers: [],
  });
  await db
    .doc(id)
    .collection('cohorts')
    .doc('default')
    .set({ name: 'Default', description: '' });

  const logDb = firebase.firestore().collection('sites');
  const logParam = { action: 'creates trial' };
  await writeLog(logDb, siteId, data.name, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {Object} data
 * @returns {Boolean}
 */
export async function updateTrial(siteId, trialId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .update(data);

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'updates trial', details: data };
  await writeLog(logDb, trialId, trialId, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} email
 * @param {String} role
 * @param {String} method
 * @returns {Boolean}
 */
export async function updateTrialMembers(siteId, trialId, email, role, method) {
  const data = {
    owner: {
      add: { owners: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { owners: firebase.firestore.FieldValue.arrayRemove(email) },
    },
    reviewer: {
      add: { reviewers: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { reviewers: firebase.firestore.FieldValue.arrayRemove(email) },
    },
    reader: {
      add: { readers: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { readers: firebase.firestore.FieldValue.arrayRemove(email) },
    },
  };
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .update(data[role][method]);

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'updates trial members' };
  const logSubject = `: ${method} ${role} ${email}`;
  await writeLog(logDb, trialId, logSubject, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @returns {Boolean}
 */
export async function deleteTrial(siteId, trialId) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .delete();

  const logDb = firebase.firestore().collection('sites');
  const logParam = { action: 'deletes trial' };
  await writeLog(logDb, siteId, trialId, logParam);
  return true;
}

/**
 * @param {String} email
 * @param {String} siteId
 * @param {String} trialId
 * @returns {Array} the list of cohorts
 */
export async function getCohorts(email, siteId, trialId) {
  const cohorts = [];
  const trial = await getTrial(email, siteId, trialId);
  if (['site-manager', 'trial-owner', 'trial-reviewer'].includes(trial.role)) {
    const querySnapshot = await firebase
      .firestore()
      .collection('sites')
      .doc(siteId)
      .collection('trials')
      .doc(trialId)
      .collection('cohorts')
      .get();
    querySnapshot.forEach(doc => {
      if (doc.id === 'default') {
        cohorts.unshift({ id: doc.id, ...doc.data() });
      } else {
        cohorts.push({ id: doc.id, ...doc.data() });
      }
    });
  }
  return cohorts;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} cohortId
 * @returns {Object} cohort
 */
export async function getCohort(siteId, trialId, cohortId) {
  const doc = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('cohorts')
    .doc(cohortId)
    .get();
  return { id: doc.id, ...doc.data() };
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} data.name
 * @param {String} data.description
 * @returns {Boolean}
 */
export async function createCohort(siteId, trialId, data) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('cohorts');

  /** check repeated name */
  const cohorts = [];
  const querySnapshot = await db.get();
  querySnapshot.forEach(doc => {
    cohorts.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  if (cohorts.find(cohort => cohort.name === data.name)) {
    window.alert('The name already exists');
    return false;
  }

  /** create cohort */
  const id = generateUuid(data.name);
  await db.doc(id).set(data);

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'creates cohort' };
  await writeLog(logDb, trialId, data.name, logParam);

  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} cohortId
 * @param {String} data.name
 * @param {String} data.description
 * @returns {Boolean}
 */
export async function updateCohort(siteId, trialId, cohortId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('cohorts')
    .doc(cohortId)
    .update(data);

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('cohorts');
  const logParam = { action: 'updates cohort', details: data };
  await writeLog(logDb, cohortId, cohortId, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} cohortId
 * @returns {Boolean}
 */
export async function deleteCohort(siteId, trialId, cohortId) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('cohorts')
    .doc(cohortId)
    .delete();

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'deletes cohort' };
  await writeLog(logDb, trialId, cohortId, logParam);
  return true;
}

/**
 * @param {String} email
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} cohortId
 * @returns {Array} the list of subjects
 */
export async function getSubjects(email, siteId, trialId, cohortId = 'all') {
  const subjects = [];
  const trial = await getTrial(email, siteId, trialId);
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects');

  let querySnapshot;
  let role = trial.role;
  if (['site-manager', 'trial-owner', 'trial-reviewer'].includes(trial.role)) {
    if (cohortId === 'all') {
      querySnapshot = await db.get();
    } else if (cohortId === 'default') {
      querySnapshot = await db.where('cohorts', '==', []).get();
    } else {
      querySnapshot = await db
        .where('cohorts', 'array-contains', cohortId)
        .get();
    }
  } else {
    querySnapshot = await db.where('readers', 'array-contains', email).get();
    role = 'reader';
  }
  querySnapshot.forEach(doc => {
    subjects.push({ id: doc.id, ...doc.data(), role });
  });
  return subjects;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @returns {Object} subject
 */
export async function getSubject(siteId, trialId, subjectId) {
  const doc = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .get();
  return { id: doc.id, ...doc.data() };
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} data.patientID
 * @param {String} data.patientName
 * @param {String} data.site
 * @param {String} data.cohortId
 * @returns {Boolean}
 */
export async function createSubject(siteId, trialId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc()
    .set({
      cohorts: data.cohortId === 'default' ? [] : [data.cohortId],
      patientID: data.patientID,
      patientName: data.patientName,
      patientSex: data.patientSex || '',
      patientBirthDate: data.patientBirthDate || '',
      patientAge: data.patientAge || '',
      readers: [],
      status: data.status || '',
      time: firebase.firestore.FieldValue.serverTimestamp(),
    });

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'creates subject' };
  const logSubject = `${data.patientID} for cohort ${data.cohortId}`;
  await writeLog(logDb, trialId, logSubject, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {Array} cohorts
 * @param {String} method
 * @returns {Boolean}
 */
export async function updateSubjectCohorts(
  siteId,
  trialId,
  subjectId,
  cohorts,
  method
) {
  const data = {
    add: { cohorts: firebase.firestore.FieldValue.arrayUnion(...cohorts) },
    remove: { cohorts: firebase.firestore.FieldValue.arrayRemove(...cohorts) },
  };
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .update(data[method]);

  const subject = await getSubject(siteId, trialId, subjectId);
  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects');
  const logParam = {
    action: 'updates subject cohorts',
    details: { [method]: cohorts },
  };
  await writeLog(logDb, subjectId, subject.patientID, logParam);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} email
 * @param {String} role
 * @param {String} method
 * @returns {Boolean}
 */
export async function updateSubjectMembers(
  siteId,
  trialId,
  subjectId,
  email,
  role,
  method
) {
  const data = {
    reader: {
      add: { readers: firebase.firestore.FieldValue.arrayUnion(email) },
      remove: { readers: firebase.firestore.FieldValue.arrayRemove(email) },
    },
  };
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId);
  await db
    .collection('subjects')
    .doc(subjectId)
    .update(data[role][method]);
  if (method === 'add') {
    await db.update(data[role][method]);
  }

  const subject = await getSubject(siteId, trialId, subjectId);
  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects');
  const logParam = { action: 'updates subject members' };
  const logSubject = `for ${subject.patientID}: ${method} ${role} ${email}`;
  await writeLog(logDb, subjectId, logSubject, logParam);
  return true;
}

export async function updateSubjectStatus(
  siteId,
  trialId,
  subjectId,
  status,
  workflow = {}
) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId);
  await db.update({
    status,
    workflow,
    time: firebase.firestore.FieldValue.serverTimestamp(),
  });
  return true;
}

export async function updateSubjectEndpoints(siteId, trialId, subjectId, data) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId);
  const endpoints = _.pick(data, [
    'bestOverallResponse',
    'changeFromBaseline',
    'durationOfResponse',
    'timeToResponse',
    'timeToProgression',
  ]);
  await db.update(endpoints);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @returns {Boolean}
 */
export async function deleteSubject(siteId, trialId, subjectId) {
  const subject = await getSubject(siteId, trialId, subjectId);
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .delete();

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'deletes subject' };
  await writeLog(logDb, trialId, subject.patientID, logParam);
  return true;
}

/**
 * @param {String} email
 * @param {String} siteId
 * @param {String} trialId
 * @returns {Array} the list of Tasks
 */
export async function getTasks(email, siteId, trialId) {
  const tasks = [];
  const trial = await getTrial(email, siteId, trialId);
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('tasks');
  if (['site-manager', 'trial-owner', 'trial-reviewer'].includes(trial.role)) {
    const querySnapshot = await db
      .orderBy('time', 'desc')
      .limit(200)
      .get();
    querySnapshot.forEach(doc => {
      tasks.push({ id: doc.id, ...doc.data() });
    });
  }
  return tasks;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} taskId
 * @param {Function} callback
 */
export function onTaskSnapshot(siteId, trialId, taskId, callback) {
  return firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('tasks')
    .doc(taskId)
    .onSnapshot(doc => {
      const task = { id: doc.id, ...doc.data() };
      callback(task);
    });
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {Object} data
 * @returns {Boolean}
 */
export async function createTask(siteId, trialId, data) {
  const task = {
    id: data.id,
    uid: data.uid,
    type: data.type,
    name: data.name,
    storagePath: data.storagePath,
    cohorts: data.cohorts,
    status: data.status,
    code: data.code,
    time: firebase.firestore.FieldValue.serverTimestamp(),
  };
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('tasks')
    .doc(task.id)
    .set(task);
  return task;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @returns {Boolean}
 */
export async function uploadImages(siteId, trialId, cohortId) {
  // TODO

  const logDb = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials');
  const logParam = { action: 'upload images' };
  const logSubject = `for cohort ${cohortId}`;
  await writeLog(logDb, trialId, logSubject, logParam);
  return true;
}

/**
 * @param {String} email
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @returns {Array} the list of Series(MR, CT, PET)
 */
export async function getSeries(email, siteId, trialId, subjectId) {
  const series = [];
  const trial = await getTrial(email, siteId, trialId);
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('series');
  if (['site-manager', 'trial-owner', 'trial-reviewer'].includes(trial.role)) {
    let querySnapshot;
    if (subjectId) {
      querySnapshot = await db.where('subjectID', '==', subjectId).get();
    } else {
      querySnapshot = await db.get();
    }
    querySnapshot.forEach(doc => {
      series.push({ id: doc.id, ...doc.data() });
    });
  }
  return series;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} data.studyInstanceUID
 * @param {String} data.seriesInstanceUID
 * @returns {Boolean}
 */
export async function createSeries(siteId, trialId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('series')
    .doc(data.seriesInstanceUID)
    .set({ ...data, isSelected: false });
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @returns {Array} the list of DICOMobjects (RTSTRUCT)
 */
export async function getObjects(siteId, trialId, subjectId, selected = false) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('objects');
  const objects = [];
  let querySnapshot;
  if (selected) {
    querySnapshot = await db.where('isSelected', '==', true).get();
  } else {
    querySnapshot = await db.get();
  }
  querySnapshot.forEach(doc => {
    objects.push({ id: doc.id, ...doc.data() });
  });
  return objects;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} data.studyInstanceUID
 * @param {String} data.seriesInstanceUID
 * @returns {Boolean}
 */
export async function createObject(siteId, trialId, subjectId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('objects')
    .doc(data.seriesInstanceUID)
    .set({ ...data, isSelected: false });
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} objectId
 * @param {Object} data
 * @returns {Boolean}
 */
export async function updateObject(siteId, trialId, subjectId, objectId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('objects')
    .doc(objectId)
    .update(data);
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @returns {Array} the list of subject series(MR registration)
 */
export async function getSubjectSeries(siteId, trialId, subjectId) {
  const endpoints = [];
  const querySnapshot = await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('subjectSeries')
    .get();
  querySnapshot.forEach(doc => {
    endpoints.push({ id: doc.id, ...doc.data() });
  });
  return endpoints;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} data.studyInstanceUID
 * @param {String} data.seriesInstanceUID
 * @returns {Boolean}
 */
export async function createSubjectSeries(siteId, trialId, subjectId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('subjectSeries')
    .doc(data.seriesInstanceUID)
    .set({ ...data, isSelected: false });
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} readId
 * @returns {Object} the latest read doc
 */
export async function getRead(siteId, trialId, subjectId, readId) {
  const db = firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('reads');
  if (readId) {
    const doc = await db.doc(readId).get();
    return { id: doc.id, ...doc.data() };
  }
  const reads = [];
  const querySnapshot = await db
    .orderBy('updateTime', 'desc')
    .limit(1)
    .get();
  querySnapshot.forEach(doc => {
    reads.push({ id: doc.id, ...doc.data() });
  });
  return reads[0] || null;
}

export async function getReadAssessments({
  site: siteId,
  trial: trialId,
  subject: subjectId,
  read: readId,
}) {
  if (!siteId || !trialId || !subjectId || !readId) return [];
  const read = await getRead(siteId, trialId, subjectId, readId);
  return read.assessments;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} readId
 * @returns {Boolean}
 */
export async function createRead(siteId, trialId, subjectId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('reads')
    .doc()
    .set({
      ...data,
      createTime: firebase.firestore.FieldValue.serverTimestamp(),
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  return true;
}

/**
 * @param {String} siteId
 * @param {String} trialId
 * @param {String} subjectId
 * @param {String} readId
 * @returns {Boolean}
 */
export async function updateRead(siteId, trialId, subjectId, readId, data) {
  await firebase
    .firestore()
    .collection('sites')
    .doc(siteId)
    .collection('trials')
    .doc(trialId)
    .collection('subjects')
    .doc(subjectId)
    .collection('reads')
    .doc(readId)
    .update({
      ...data,
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  return true;
}

export async function checkAndUpdateSubjectRead(
  structureSets,
  assessments,
  { site: siteId, trial: trialId, subject: subjectId, read: readId }
) {
  if (!siteId || !trialId || !subjectId || !readId) return false;
  const sets = structureSets.map(set => {
    const { StudyInstanceUID, SeriesInstanceUID } = set;
    return { StudyInstanceUID, SeriesInstanceUID };
  });
  const result = await updateRead(siteId, trialId, subjectId, readId, {
    structureSets: sets,
    assessments,
  });
  return result;
}

export async function checkAndUpdateSubjectEndpoints(
  endpoints,
  { site: siteId, trial: trialId, subject: subjectId }
) {
  if (!siteId || !trialId || !subjectId) return false;
  const result = await updateSubjectEndpoints(
    siteId,
    trialId,
    subjectId,
    endpoints
  );
  return result;
}
