// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dicom-tag-browser-table {
  margin-right: auto;
  margin-left: auto;
}

.dicom-tag-browser-table-wrapper {
  height: 500px;
  overflow-y: scroll;
}

.dicom-tag-browser-table tr {
  padding-left: 10px;
  padding-right: 10px;
  color: var(--table-text-primary-color);
  border-top: 1px solid #ddd;
  white-space: nowrap;
}

.dicom-tag-browser-content {
  overflow: hidden;
  width: 100%;
  padding-bottom: 50px;
}

.dicom-tag-browser-instance-range .range {
  height: 20px;
}

.dicom-tag-browser-instance-range {
  padding: 20px 0 20px 0;
}

.dicom-tag-browser-table td.dicom-tag-browser-table-center {
  text-align: center;
}

.dicom-tag-browser-table th {
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: var(--active-color);
}

.dicom-tag-browser-table th.dicom-tag-browser-table-left {
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./../../../extensions/dicom-tag-browser/src/components/DicomTagBrowser.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,sCAAsC;EACtC,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dicom-tag-browser-table {\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.dicom-tag-browser-table-wrapper {\n  height: 500px;\n  overflow-y: scroll;\n}\n\n.dicom-tag-browser-table tr {\n  padding-left: 10px;\n  padding-right: 10px;\n  color: var(--table-text-primary-color);\n  border-top: 1px solid #ddd;\n  white-space: nowrap;\n}\n\n.dicom-tag-browser-content {\n  overflow: hidden;\n  width: 100%;\n  padding-bottom: 50px;\n}\n\n.dicom-tag-browser-instance-range .range {\n  height: 20px;\n}\n\n.dicom-tag-browser-instance-range {\n  padding: 20px 0 20px 0;\n}\n\n.dicom-tag-browser-table td.dicom-tag-browser-table-center {\n  text-align: center;\n}\n\n.dicom-tag-browser-table th {\n  padding-left: 10px;\n  padding-right: 10px;\n  text-align: center;\n  color: var(--active-color);\n}\n\n.dicom-tag-browser-table th.dicom-tag-browser-table-left {\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
