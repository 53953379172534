import getAttribute from './getAttribute.js';
import getAuthorizationHeader from './getAuthorizationHeader.js';
import getModalities from './getModalities.js';
import getName from './getName.js';
import getNumber from './getNumber.js';
import getString from './getString.js';
import { QIDO, WADO } from './services';

const DICOMWeb = {
  getAttribute,
  getAuthorizationHeader,
  getModalities,
  getName,
  getNumber,
  getString,
  services: { QIDO, WADO },
};

export default DICOMWeb;
