import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';
import { ThemeProvider } from '@material-ui/core/styles';

import { RoundedButtonGroup } from '@platform/ui';
import { MeasurementSection } from './sections/MeasurementSection';
import { LinkSection } from './sections/LinkSection';
import { FindingSection } from './sections/FindingSection';
import { AnalysisSection } from './sections/AnalysisSection';
import { ReportSection } from './sections/ReportSection';
import theme from '../theme';

export const Tracking = ({
  imageSets,
  PatientID,
  defaultStructureSetUID: uid,
  defaultSectionName,
}) => {
  const [sectionName, setSectionName] = useState(defaultSectionName);
  const [selectedStructureSetUID, setSelectedStructureSetUID] = useState(uid);
  const [selectedROINumber, setSelectedROINumber] = useState(-1);
  const [selectedLinkID, setSelectedLinkID] = useState(-1);
  const [filteredTrackingType, setFilteredTrackingType] = useState('all');
  const [filteredInterpretedType, setFilteredInterpretedType] = useState('all');
  const [measurementTech, setMeasurementTech] = useState('volume');

  const getROIName = (structureSetUID, ROINumber) => {
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    const { ROIContours } = editModule.getters.structureSet(structureSetUID);
    const roi = ROIContours.find(roi => roi.ROINumber === ROINumber);
    return roi?.ROIName || '';
  };

  const getLinkROINames = (link, seriesUID) => {
    const mapping = link.RTMappings.find(
      mapping => mapping.SeriesInstanceUID === seriesUID
    );
    const names =
      mapping?.ROINumbers?.map(number =>
        getROIName(mapping.SeriesInstanceUID, number)
      )
        .filter(i => i)
        .join(', ') || '';
    return names;
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ margin: '0 0 10px 0' }}>
        <RoundedButtonGroup
          options={[
            { value: 'measurement', label: 'Measurements' },
            { value: 'link', label: 'Links' },
            { value: 'finding', label: 'Findings' },
            { value: 'analysis', label: 'Analysis' },
            { value: 'report', label: 'Report' },
          ]}
          value={sectionName}
          onValueChanged={value => setSectionName(value)}
        />
      </div>
      <div style={{ height: '70vh', minHeight: '300px', maxHeight: '70vh' }}>
        {sectionName === 'measurement' && (
          <MeasurementSection
            imageSets={imageSets}
            selectedStructureSetUID={selectedStructureSetUID}
            setSelectedStructureSetUID={setSelectedStructureSetUID}
            selectedROINumber={selectedROINumber}
            setSelectedROINumber={setSelectedROINumber}
            filteredTrackingType={filteredTrackingType}
            setFilteredTrackingType={setFilteredTrackingType}
            filteredInterpretedType={filteredInterpretedType}
            setFilteredInterpretedType={setFilteredInterpretedType}
          />
        )}
        {sectionName === 'link' && (
          <LinkSection
            selectedLinkID={selectedLinkID}
            setSelectedLinkID={setSelectedLinkID}
            getROIName={getROIName}
            getLinkROINames={getLinkROINames}
          />
        )}
        {sectionName === 'finding' && (
          <FindingSection
            imageSets={imageSets}
            selectedLinkID={selectedLinkID}
            setSelectedLinkID={setSelectedLinkID}
            getLinkROINames={getLinkROINames}
            measurementTech={measurementTech}
            setMeasurementTech={setMeasurementTech}
          />
        )}
        {sectionName === 'analysis' && (
          <AnalysisSection
            imageSets={imageSets}
            selectedLinkID={selectedLinkID}
            setSelectedLinkID={setSelectedLinkID}
            getLinkROINames={getLinkROINames}
            measurementTech={measurementTech}
            setMeasurementTech={setMeasurementTech}
          />
        )}
        {sectionName === 'report' && (
          <ReportSection imageSets={imageSets} PatientID={PatientID} />
        )}
      </div>
    </ThemeProvider>
  );
};
Tracking.propTypes = {
  imageSets: PropTypes.arrayOf(
    PropTypes.shape({ SeriesInstanceUID: PropTypes.string })
  ),
  PatientID: PropTypes.string,
  defaultStructureSetUID: PropTypes.string,
  defaultSectionName: PropTypes.string,
};
