import { useSelector } from 'react-redux';
import { getSeriesUIDByStructureSetUID } from '../modules/dicom-measurement/src';

export default function useLogStudy(structure_set_series_uid) {
  const state = useSelector(state => state);
  return getLogStudy(state, structure_set_series_uid);
}

export function getLogStudy(state, structure_set_series_uid) {
  if (!state) return {};
  const uid = structure_set_series_uid;
  if (!structure_set_series_uid) return {};
  const { studyData } = state.studies;
  if (Object.keys(studyData).length === 0) return {};
  const { seriesStructureMap } = state.extensions.dicomRT;
  const series_uid = getSeriesUIDByStructureSetUID(uid, seriesStructureMap);
  const [study_uid] = Object.entries(studyData).find(([, _study]) => {
    return !!_study.series.find(s => s.SeriesInstanceUID === series_uid);
  });
  return { study_uid, series_uid };
}
