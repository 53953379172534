import FindContours from './findContours';

export default function findContoursOfImage(image) {
  if (!image) return [];
  if (image.length === 0) return [];
  const fc = new FindContours();
  const F = [];
  for (let j = 0; j < image.length; j++) {
    for (let i = 0; i < image[j].length; i++) {
      const n = image[j][i] > 0 ? 1 : 0;
      F.push(n);
    }
  }
  const h = image.length;
  const w = image[0].length;
  const contours = fc.findContours(F, w, h);
  if (contours.length === 0) return [];
  return contours.map(contour => contour.points.map(([x, y]) => ({ x, y })));
}
