import React from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import dicomParser from 'dicom-parser';

import core, {
  CommandsManager,
  ExtensionManager,
  ServicesManager,
  HotkeysManager,
  UINotificationService,
  UIModalService,
  UIDialogService,
  LoggerService,
  utils,
  Firebase,
} from '@platform/core';
import {
  SnackbarProvider,
  ModalProvider,
  DialogProvider,
  LoggerProvider,
  BaseModal,
} from '@platform/ui';
import i18n from '@platform/i18n';
/** Extensions */
import CornerstoneExtension from '@platform/extension-cornerstone';
import DicomRTExtension from '@platform/extension-dicom-rt';
import DicomTagBrowserExtension from '@platform/extension-dicom-tag-browser';
/** Platform */
import { Auth as FirebaseAuth } from './cloud/auth/FirebaseAuth';
import { AuthRouting as FirebaseAuthRouting } from './cloud/auth/FirebaseAuthRouting';
import {
  GoogleHealthcareAPI,
  APIContext as GoogleAPIContext,
} from './cloud/context/GoogleHealthcareAPIContext';
import {
  ServiceAPI,
  ServiceAPIContext,
} from './cloud/context/ServiceAPIContext';
/** Utils */
import { initWebWorkers } from './utils/index.js';
/** Viewer */
import Routes from './routes/Routes';
import Auth from './routes/Auth';
/** Store */
import { getActiveContexts } from './store/selectors.js';
import store from './store';
/** Contexts */
import { AppProvider, useAppContext, CONTEXTS } from './context/AppContext';
import { API, APIContext } from './context/APIContext';
import { PACS, PACSContext } from './context/PACSContext';

/** Managers */
const commandsManagerConfig = {
  getAppState: () => store.getState(),
  getActiveContexts: () => getActiveContexts(store.getState()),
};
const commandsManager = new CommandsManager(commandsManagerConfig);
const servicesManager = new ServicesManager();
const hotkeysManager = new HotkeysManager(commandsManager, servicesManager);
let extensionManager;

function App({ config }) {
  const appConfig = initConfig(config);
  setConfiguration(appConfig);
  _initFirebase(appConfig);
  _initServices();
  _initExtensions(appConfig);
  _initHotkeys(appConfig.hotkeys);
  _initServers(appConfig.servers);
  const isOnPremiseSolution = appConfig.dicomWebServer === 'orthanc';
  const isFirebaseAuthentication = appConfig.firebaseConfig.apiKey;
  const reviewId = Firebase._uuid();
  initWebWorkers();

  const Viewer = () => {
    const {
      UINotificationService,
      UIDialogService,
      UIModalService,
      LoggerService,
    } = servicesManager.services;
    return (
      <Provider store={store}>
        <AppProvider config={appConfig}>
          <I18nextProvider i18n={i18n}>
            <LoggerProvider service={LoggerService} id={reviewId}>
              <SnackbarProvider service={UINotificationService}>
                <DialogProvider service={UIDialogService}>
                  <ModalProvider modal={BaseModal} service={UIModalService}>
                    <Routes />
                  </ModalProvider>
                </DialogProvider>
              </SnackbarProvider>
            </LoggerProvider>
          </I18nextProvider>
        </AppProvider>
      </Provider>
    );
  };

  /** Firebase: add authentication and platform routes */
  if (isFirebaseAuthentication) {
    return (
      <Router basename={appConfig.routerBasename}>
        <FirebaseAuth config={appConfig.firebaseConfig}>
          {({
            firebaseUser: user,
            firebaseToken: token,
            firebaseTokenExpiredAt: tokenExpiredAt,
            isAuthStateChanged,
          }) => {
            const googleHealthcareAPI = new GoogleHealthcareAPI(
              { token, tokenExpiredAt },
              appConfig
            );
            return user && token && tokenExpiredAt ? (
              <GoogleAPIContext.Provider value={googleHealthcareAPI}>
                <ServiceAPIContext.Provider value={new ServiceAPI(appConfig)}>
                  {Viewer()}
                </ServiceAPIContext.Provider>
              </GoogleAPIContext.Provider>
            ) : (
              <FirebaseAuthRouting isAuthStateChanged={isAuthStateChanged} />
            );
          }}
        </FirebaseAuth>
      </Router>
    );
  }

  /** On-premise */
  if (isOnPremiseSolution) {
    const { origin } = window.location;
    return (
      <Router basename={appConfig.routerBasename}>
        <APIContext.Provider value={new API(origin, appConfig)}>
          <PACSContext.Provider value={new PACS(origin, appConfig)}>
            <Auth>{Viewer()}</Auth>
          </PACSContext.Provider>
        </APIContext.Provider>
      </Router>
    );
  }

  return <Router basename={appConfig.routerBasename}>{Viewer()}</Router>;
}
App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
    }),
  ]).isRequired,
};
App.defaultProps = { config: { routerBasename: '/' } };

function initConfig(config) {
  return typeof config === 'function' ? config({ servicesManager }) : config;
}

function setConfiguration(appConfig) {
  /** add window variables for e2e testing */
  window.cornerstone = cornerstone;

  core.errorHandler.getHTTPErrorHandler = () => appConfig.httpErrorHandler;
  cornerstone.imageCache.setMaximumSizeBytes(appConfig.maxCacheSizeInBytes);
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  cornerstoneWADOImageLoader.configure({
    beforeSend: function(xhr) {
      const headers = core.DICOMWeb.getAuthorizationHeader();
      if (headers.Authorization) {
        xhr.setRequestHeader('Authorization', headers.Authorization);
      }
    },
    errorInterceptor: error => {
      if (typeof appConfig.httpErrorHandler === 'function') {
        appConfig.httpErrorHandler(error);
      }
    },
  });
}

function _initFirebase(appConfig) {
  if (appConfig.firebaseConfig?.apiKey) Firebase.init(appConfig.firebaseConfig);
}

function _initServices() {
  const services = [
    UINotificationService,
    UIModalService,
    UIDialogService,
    LoggerService,
  ];
  servicesManager.registerServices(services);
}

function _initExtensions(appConfig) {
  const extensions = [
    { enabled: true, extension: CornerstoneExtension },
    { enabled: appConfig.enableRTExtension, extension: DicomRTExtension },
    { enabled: true, extension: DicomTagBrowserExtension },
  ].reduce((extensions, option) => {
    return option.enabled ? [...extensions, option.extension] : extensions;
  }, []);
  extensionManager = new ExtensionManager({
    commandsManager,
    servicesManager,
    appConfig,
    api: { contexts: CONTEXTS, hooks: { useAppContext } },
  });
  extensionManager.registerExtensions(extensions);
}

function _initHotkeys(appConfigHotkeys) {
  /*
   * Must run after extension commands are registered
   * if there is no hotkeys from localStorage set up from config.
   */
  hotkeysManager.setHotkeys(appConfigHotkeys);
  hotkeysManager.setDefaultHotKeys(appConfigHotkeys);
}

function _initServers(servers) {
  if (servers) utils.addServers(servers, store);
}

export default App;
export { commandsManager, extensionManager, hotkeysManager, servicesManager };
