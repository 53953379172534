import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { utils } from '@platform/core';

import {
  math,
  transformPointsToPhysicalById,
} from '../../modules/dicom-measurement/src';
const { getDistance3D } = math;
const scrollToIndex = cornerstoneTools.import('util/scrollToIndex');
const { getViewTypeFromOrientation } = utils;

function refreshViewport() {
  cornerstone.getEnabledElements().forEach(enabledElement => {
    if (enabledElement.image) {
      cornerstone.updateImage(enabledElement.element);
    }
  });
}

const getMaxAreaSliceWithTool = (imageIds, SeriesInstanceUID, ROINumber) => {
  const slices = imageIds.map((imageId, index) => {
    const editModule = cornerstoneTools.getModule('rtstruct-edit');
    const painter = editModule.getters.peekPainter(imageId);
    if (!painter) return { index: -1 };
    const { area, mainAreaCentroid } = painter
      .getInfo()
      .filter(
        c =>
          c.structureSetSeriesInstanceUid === SeriesInstanceUID &&
          c.ROINumber === ROINumber
      )
      .reduce(
        (acc, cur) => {
          return cur.area > acc.area
            ? {
                area: acc.area + cur.area,
                mainArea: cur.area,
                mainAreaCentroid: cur.mainAreaCentroid,
              }
            : { ...acc, area: acc.area + cur.area };
        },
        { area: 0, mainArea: 0, mainAreaCentroid: { x: null, y: null } }
      );
    return { area: area, centroid: mainAreaCentroid, index: index };
  });
  return getMaxAreaSlice(slices);
};

const getMaxAreaSlice = slices => {
  const maxAreaSlice = slices.reduce(
    (acc, cur) => {
      return cur.area > acc.area ? cur : acc;
    },
    { index: -1, area: 0, centroid: null }
  );
  return maxAreaSlice;
};

const checkExistenceOfROIContour = (SeriesInstanceUID, ROINumber) => {
  const axials = cornerstone
    .getEnabledElements()
    .map(e => {
      const { imageId } = e.image;
      const meta = cornerstone.metaData.get('imagePlaneModule', imageId);
      const viewType = getViewTypeFromOrientation(meta.imageOrientationPatient);
      return viewType === 'axial' ? e : null;
    })
    .filter(i => i !== null);
  const elementWithROI = axials.find(enabledElement => {
    const { element } = enabledElement;
    const toolState = cornerstoneTools.getToolState(element, 'stack');
    const { imageIds } = toolState.data[0];
    const maxAreaSlice = getMaxAreaSliceWithTool(
      imageIds,
      SeriesInstanceUID,
      ROINumber
    );
    return maxAreaSlice.index !== -1;
  });

  return !!elementWithROI;
};

function checkAndNavigate(
  SeriesInstanceUID,
  ROINumber,
  selectedSeriesInstanceUID
) {
  /** retrieve enabled elements */
  const axials = [];
  const sagittals = [];
  const coronals = [];
  cornerstone.getEnabledElements().forEach(enabledElement => {
    const { imageId } = enabledElement.image;
    const meta = cornerstone.metaData.get('imagePlaneModule', imageId);
    const viewType = getViewTypeFromOrientation(meta.imageOrientationPatient);
    switch (viewType) {
      case 'axial':
        axials.push(enabledElement);
        break;
      case 'sagittal':
        sagittals.push(enabledElement);
        break;
      case 'coronal':
        coronals.push(enabledElement);
        break;
    }
  });

  /** initialize and sort references */
  const refs = [];
  axials.forEach(enabledElement => {
    const { imageId } = enabledElement.image;
    const { element } = enabledElement;
    const instanceMetaData = cornerstone.metaData.get('instance', imageId);
    const toolState = cornerstoneTools.getToolState(element, 'stack');
    const { imageIds } = toolState.data[0];
    const maxAreaSlice = getMaxAreaSliceWithTool(
      imageIds,
      SeriesInstanceUID,
      ROINumber
    );
    const getNonAxialElement = (
      nonAxialEnabledElements,
      axialInstanceMetaData
    ) => {
      const enabledElement = nonAxialEnabledElements.find(e => {
        const _metaData = cornerstone.metaData.get('instance', e.image.imageId);
        const _uid = _metaData?.SeriesInstanceUID;
        return _uid === axialInstanceMetaData?.SeriesInstanceUID;
      });
      return enabledElement?.element;
    };
    refs.push({
      SeriesInstanceUID: instanceMetaData.SeriesInstanceUID,
      StudyDate: instanceMetaData.StudyDate,
      SeriesDate: instanceMetaData.SeriesDate,
      ContentDate: instanceMetaData.ContentDate,
      AcquisitionDate: instanceMetaData.AcquisitionDate,
      element: enabledElement.element,
      sagittalElement: getNonAxialElement(sagittals, instanceMetaData),
      coronalElement: getNonAxialElement(coronals, instanceMetaData),
      index: maxAreaSlice.index,
      imageId: imageIds[maxAreaSlice.index],
      imageIds: imageIds,
      imagesTotalNumber: imageIds.length,
      centroid: maxAreaSlice.centroid,
    });
  });
  refs.sort(function(a, b) {
    const aSeriesDate = a.SeriesDate || a.ContentDate || a.AcquisitionDate;
    const bSeriesDate = b.SeriesDate || b.ContentDate || b.AcquisitionDate;
    return Number(aSeriesDate) - Number(bSeriesDate);
  });

  /** scroll to index in each viewport */
  let existing = false;
  refs.forEach(ref => {
    if (selectedSeriesInstanceUID) {
      if (ref.SeriesInstanceUID !== selectedSeriesInstanceUID) return;
    }
    if (ref.index === -1) return;
    existing = true;
    if (ref.imageId) {
      /**
       * find centroids of sagittal/coronal views and scroll to index
       * will skip this step if no sagittal/coronal view displayed
       * */
      const points = transformPointsToPhysicalById([ref.centroid], ref.imageId);
      const axialPhysicalCentroid = points[0];
      const getCentroidImageIndex = element => {
        if (!element) return -1;
        const toolState = cornerstoneTools.getToolState(element, 'stack');
        const { imageIds } = toolState.data[0];
        return findNearestImageIndexFromPhysicalPoint(
          imageIds,
          axialPhysicalCentroid
        );
      };
      const findNearestImageIndexFromPhysicalPoint = (imageIds, point) => {
        const { minIndex } = imageIds.reduce(
          ({ minId, minDistance, minIndex }, id, index) => {
            const imagePlane = cornerstone.metaData.get('imagePlaneModule', id);
            const imagePosition = imagePlane.imagePositionPatient;
            const [x, y, z] = imagePosition;
            const distance = getDistance3D(point, { x, y, z });
            if (minDistance > distance) {
              return { minId: id, minDistance: distance, minIndex: index };
            }
            return { minId, minDistance, minIndex };
          },
          { minId: '', minDistance: Infinity, minIndex: 0 }
        );
        return minIndex;
      };
      const sagittalIdx = getCentroidImageIndex(ref.sagittalElement);
      if (sagittalIdx !== -1) scrollToIndex(ref.sagittalElement, sagittalIdx);
      const coronalIdx = getCentroidImageIndex(ref.coronalElement);
      if (sagittalIdx !== -1) scrollToIndex(ref.coronalElement, coronalIdx);
    }
    /** scroll to index in axial views */
    scrollToIndex(ref.element, ref.index);
  });
  return existing;
}

export {
  refreshViewport,
  checkExistenceOfROIContour,
  checkAndNavigate,
  getMaxAreaSlice,
};
