// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  padding-bottom: 20px;
  padding-top: 10px;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding-right: 16px;
  padding-left: 4px;
  margin-right: -16px;
  display: flex;
  flex-direction: column;
}
.study-browser .scrollable-study-thumbnails::-webkit-scrollbar {
  display: none;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 1.5rem;
}
.study-browser .scrollable-study-thumbnails .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/studyBrowser/StudyBrowser.styl"],"names":[],"mappings":"AAAA;EACE,WAAO;EACP,YAAQ;EACR,WAAO;EACP,gBAAU;EACV,sBAAkB;EAClB,oBAAgB;EAChB,iBAAa;AACf;AACE;EACE,YAAQ;EACR,gBAAY;EACZ,kBAAY;EACZ,oBAAgB;EAChB,mBAAe;EACf,iBAAc;EACd,mBAAc;EAEd,aAAS;EACT,sBAAgB;AAApB;AAEI;EACE,aAAS;AAAf;AAEI;EACE,cAAQ;EACR,sBAAgB;AAAtB;AAEM;EACE,sBAAkB,EAAlB,YAAA;EAAwB,qBAAA,EAAA,sBAAA;EACxB,wBAAA,EAAA,oCAAA;EAAuB,yBAAA,EAAA,8BAAA;EACvB,2BAAA,EAAA,oCAAA;AAA0B;AAGlC;EAFmC,YAAA;EAC3B,oBAAA;EAA6B,iBAAA;AAKrC","sourcesContent":[".study-browser\n  float: left\n  height: 100%\n  width: 100%\n  overflow: hidden\n  background-color: black\n  padding-bottom: 20px\n  padding-top: 10px\n\n  .scrollable-study-thumbnails\n    height: 100%\n    overflow-y: auto\n    overflow-x: hidden\n    padding-bottom: 50px\n    padding-right: 16px\n    padding-left: 4px\n    margin-right: -16px\n\n    display: flex;\n    flex-direction: column;\n\n    &::-webkit-scrollbar\n      display: none\n\n    .thumbnail-container\n      margin: 0 auto;\n      padding-bottom: 1.5rem;\n\n      .noselect\n        -moz-user-select: none; /* Firefox */\n        -ms-user-select: none; /* Internet Explorer */\n        -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */\n        -webkit-user-select: none; /* Chrome, Safari, and Opera */\n        -webkit-touch-callout: none; /* Disable Android and iOS callouts*/\n\n\n.draggable\n  cursor: copy\n  cursor: -webkit-grab\n  cursor: -moz-grab\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
