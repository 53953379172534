import React from 'react';
import PropTypes from 'prop-types';

import { Body, Grid, Select } from '@platform/ui';
import { math } from '../../../modules/dicom-measurement/src';
import { AllLesionsLinePlot } from '../plots/LinePlot';
import { BODY_PARTS, TRACKING_TYPES } from '../constants/guideline';
import { getTimepoints } from '../models/getTimepoints';
const { round } = math;

export const AllLesionsSection = ({
  imageSets,
  ROIContours,
  plotFeature,
  setPlotFeature,
  getFilteredROIContours,
  filteredBodyPart,
  setFilteredBodyPart,
  measurementTechnique,
  setMeasurementTechnique,
}) => {
  const techMap = {
    BIDIMENSIONAL: 'maxDiameterValue',
    VOLUMETRIC: 'volume',
  };
  const keyMap = {
    BIDIMENSIONAL: 'sumDiameter',
    VOLUMETRIC: 'sumVolume',
  };
  const rois = getFilteredROIContours(ROIContours);
  const timepoints = getTimepoints(imageSets, rois);
  const names = {
    brain: 'Brain',
    liver: 'Liver',
    lung: 'Lung',
    'lymph-node': 'Lymph Node',
  };
  const data =
    plotFeature === 'byBodyPart'
      ? timepoints.map(t => {
          const obj = t.Lesions.reduce((acc, cur) => {
            const sum =
              (acc[cur.bodyPart] || 0) +
              Number(cur[techMap[measurementTechnique]]);
            return { ...acc, [cur.bodyPart]: round(sum, -2) };
          }, {});
          return { ...obj, name: t.SeriesDate };
        })
      : timepoints.map(t => {
          const obj = t.Lesions.reduce((acc, cur) => {
            names[cur.ROINumber] = cur.ROIName;
            return {
              ...acc,
              [cur.ROINumber]: round(cur[techMap[measurementTechnique]], -2),
            };
          }, {});
          return { ...obj, name: t.SeriesDate };
        });
  const targets =
    plotFeature === 'byLesion'
      ? rois
          .filter(roi => roi.trackingType === TRACKING_TYPES.TARGET)
          .map(l => l.ROINumber)
      : [];
  const others =
    plotFeature === 'byLesion'
      ? rois
          .filter(roi => roi.trackingType !== TRACKING_TYPES.TARGET)
          .map(l => l.ROINumber)
      : plotFeature === 'byBodyPart'
      ? ['brain', 'liver', 'lung', 'lymph-node']
      : [];
  const brains =
    plotFeature === 'byLesionCategorized'
      ? rois
          .filter(roi => roi.bodyPart === BODY_PARTS.BRAIN)
          .map(l => l.ROINumber)
      : [];
  const lungs =
    plotFeature === 'byLesionCategorized'
      ? rois
          .filter(roi => roi.bodyPart === BODY_PARTS.LUNG)
          .map(l => l.ROINumber)
      : [];
  const livers =
    plotFeature === 'byLesionCategorized'
      ? rois
          .filter(roi => roi.bodyPart === BODY_PARTS.LIVER)
          .map(l => l.ROINumber)
      : [];
  const lymphnodes =
    plotFeature === 'byLesionCategorized'
      ? rois
          .filter(roi => roi.bodyPart === BODY_PARTS.LYMPHNODE)
          .map(l => l.ROINumber)
      : [];

  const legends =
    plotFeature === 'byLesion'
      ? [
          ['Target lesions', 'var(--active-color)'],
          ['Others', 'var(--default-color)'],
        ]
      : [
          ['Brain', '#f6d55c'],
          ['Liver', '#ed553b'],
          ['Lung', '#3caea3'],
          ['Lymph Node', '#e4f1ee'],
        ];

  return (
    <Body>
      <AllLesionsLinePlot
        data={data}
        names={names}
        targets={targets}
        others={others}
        brains={brains}
        lungs={lungs}
        livers={livers}
        lymphnodes={lymphnodes}
        legends={legends}
        xAxisKey={'name'}
        ySumAxisKey={keyMap[measurementTechnique]}
      />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            value={filteredBodyPart}
            onChange={evt => setFilteredBodyPart(evt.target.value)}
          >
            {[
              ['all', 'Whole Body'],
              ['brain', 'Brain'],
              ['liver', 'Liver'],
              ['lung', 'Lung'],
              ['lymph-node', 'Lymph Node'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            value={plotFeature}
            onChange={evt => setPlotFeature(evt.target.value)}
          >
            {[
              ['byLesion', 'By Lesion'],
              ['byLesionCategorized', 'By Lesion(categorized)'],
              ['byBodyPart', 'By Body Part'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={measurementTechnique}
            onChange={evt => setMeasurementTechnique(evt.target.value)}
          >
            {[
              ['BIDIMENSIONAL', 'Bidimensional'],
              ['VOLUMETRIC', 'Volumetric'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Body>
  );
};
AllLesionsSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  plotFeature: PropTypes.string,
  setPlotFeature: PropTypes.func,
  getFilteredROIContours: PropTypes.func,
  filteredBodyPart: PropTypes.string,
  setFilteredBodyPart: PropTypes.func,
  measurementTechnique: PropTypes.string,
  setMeasurementTechnique: PropTypes.func,
};
