import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Body, Grid, Select } from '@platform/ui';
import { LinePlot } from '../plots/LinePlot';
import { WaterfallPlot } from '../plots/WaterfallPlot';
import { TRACKING_TYPES } from '../constants/guideline';
import {
  getTimepointStats,
  getTargetResponses,
  getNonTargetResponses,
  getNewLesionResponses,
} from '../models/responseAssessments';
import { getTimepoints, getAssessedTimepoints } from '../models/getTimepoints';

export const AnalysisSection = ({
  imageSets,
  ROIContours,
  criteria,
  assessments,
  plotType,
  getFilteredROIContours,
  filteredBodyPart,
  setFilteredBodyPart,
  filteredTrackingType,
  setFilteredTrackingType,
  selectedLesionNumber,
  setSelectedLesionNumber,
  measurementTechnique,
  setMeasurementTechnique,
}) => {
  const [xAxisKey] = useState('name');
  const [yAxisKey, setYAxisKey] = useState('comparedSumDiameter');
  const [ySumAxisKey, setYSumAxisKey] = useState('sumDiameter');

  const timepoints = getTimepoints(imageSets, ROIContours);

  /** target lesion responses */
  const targetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.TARGET
  );
  const targetStats = getTimepointStats(
    timepoints,
    targetROIContours,
    [],
    criteria,
    assessments
  );
  const targetResponses = getTargetResponses(targetStats, criteria);

  /** non-target lesion responses */
  const nonTargetROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NONTARGET
  );
  const nonTargetStats = getTimepointStats(
    timepoints,
    nonTargetROIContours,
    [],
    criteria,
    assessments
  );
  const nonTargetResponses = getNonTargetResponses(nonTargetStats, criteria);

  /** new lesion responses */
  const newLesionROIContours = ROIContours.filter(
    roi => roi.trackingType === TRACKING_TYPES.NEW
  );
  const newLesionResponses = getNewLesionResponses(
    timepoints,
    newLesionROIContours,
    criteria
  );

  /** overall responses */
  const assessedTimepoints = getAssessedTimepoints(
    timepoints,
    targetResponses,
    nonTargetResponses,
    newLesionResponses,
    assessments
  );

  const filteredROIContours = getFilteredROIContours(ROIContours);
  const selectedROIContour = ROIContours.find(
    roi => roi.ROINumber === selectedLesionNumber
  );
  const selectedStats = getTimepointStats(
    timepoints,
    filteredROIContours,
    selectedROIContour ? [selectedROIContour] : [],
    criteria,
    assessments
  );
  const measurements = selectedStats.map((series, index) => ({
    ...series.summation,
    date: series.date,
  }));
  const changesFromBaseline = selectedStats.map((series, index) => ({
    ...series.fromBaseline,
    date: series.date,
    result: assessedTimepoints[index].overallResponse.result,
  }));

  const onFilteredBodyPartChanged = evt => {
    const bodyPart = evt.target.value;
    setFilteredBodyPart(bodyPart);
  };

  const onFilteredTrackingTypeChanged = evt => {
    const type = evt.target.value;
    setFilteredTrackingType(type);
    if (type !== 'selected') {
      setSelectedLesionNumber(-1);
    }
  };

  const onSelectedLesionNumberChanged = evt => {
    setSelectedLesionNumber(Number(evt.target.value));
  };

  const onMeasurementTechniqueChanged = evt => {
    setMeasurementTechnique(evt.target.value);
  };

  useEffect(() => {
    switch (measurementTechnique) {
      case 'BIDIMENSIONAL':
        setYAxisKey('comparedSumDiameter');
        setYSumAxisKey('sumDiameter');
        break;
      case 'VOLUMETRIC':
        setYAxisKey('comparedSumVolume');
        setYSumAxisKey('sumVolume');
        break;
    }
  }, [measurementTechnique]);

  return (
    <Body>
      {plotType === 'measurement' && (
        <LinePlot
          data={measurements}
          xAxisKey={xAxisKey}
          yAxisKey={yAxisKey}
          ySumAxisKey={ySumAxisKey}
        />
      )}
      {plotType === 'changeFromBaseline' && (
        <WaterfallPlot
          data={changesFromBaseline}
          xAxisKey={xAxisKey}
          yAxisKey={yAxisKey}
          ySumAxisKey={ySumAxisKey}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            value={filteredBodyPart}
            onChange={evt => onFilteredBodyPartChanged(evt)}
          >
            {[
              ['all', 'Whole Body'],
              ['brain', 'Brain'],
              ['liver', 'Liver'],
              ['lung', 'Lung'],
              ['lymph-node', 'Lymph Node'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={filteredTrackingType}
            onChange={evt => onFilteredTrackingTypeChanged(evt)}
          >
            {[
              ['all', 'All Lesions'],
              ['measurable', 'All Measurables'],
              ['target', 'Target Lesions'],
              ['non-target', 'Non-target Lesions'],
              ['new', 'New Lesions'],
              ['selected', 'Selected Lesion'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={selectedLesionNumber}
            onChange={evt => onSelectedLesionNumberChanged(evt)}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option key={-1} value={-1}>
              ------
            </option>
            {(filteredTrackingType === 'selected'
              ? ROIContours
              : filteredROIContours
            ).map(roi => (
              <option key={roi.ROINumber} value={roi.ROINumber}>
                {roi.ROIName}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={measurementTechnique}
            onChange={evt => onMeasurementTechniqueChanged(evt)}
          >
            {[
              ['BIDIMENSIONAL', 'Bidimensional'],
              ['VOLUMETRIC', 'Volumetric'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Body>
  );
};
AnalysisSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  criteria: PropTypes.any,
  assessments: PropTypes.array,
  plotType: PropTypes.string,
  getFilteredROIContours: PropTypes.func,
  filteredBodyPart: PropTypes.string,
  setFilteredBodyPart: PropTypes.func,
  filteredTrackingType: PropTypes.string,
  setFilteredTrackingType: PropTypes.func,
  selectedLesionNumber: PropTypes.number,
  setSelectedLesionNumber: PropTypes.func,
  measurementTechnique: PropTypes.string,
  setMeasurementTechnique: PropTypes.func,
};
