import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import { AppContext, APIContext, PACSContext } from '@platform/viewer';
import { redux, utils, Firebase } from '@platform/core';
import {
  RoundedButtonGroup,
  useModal,
  useDialog,
  useSnackbar,
  useLogger,
} from '@platform/ui';
import usePrimary from '../../hooks/usePrimary';
import RTCompute from './computes/RTCompute';
import { ViewportSection } from './sections/ViewportSection';
import { ToolSettingsSection } from './sections/ToolSettingsSection';
import { ROIContourSection } from './sections/ROIContourSection';
import FooterButton from './sections/FooterButton';
import { SubmitRTStructForm } from './forms/SubmitRTStructForm';
import { ConfirmDialog } from './forms/ConfirmDialog';
import { Measurement } from '../Measurement';
import { Tracking } from '../Tracking/Tracking';
import { LongitudinalStudy } from '../LongitudinalStudy/LongitudinalStudy';
import * as ls from '../LongitudinalStudy';
import dicom from '../../modules/sopClassHandlerModule/DicomRTStructSopClassHandler';
import * as commands from '../../tools/commands';
import useLogStudy from '../../hooks/useLogStudy';

const { actions } = redux;

export default function RTEdit({
  studies,
  viewports,
  activeIndex,
  commandsManager,
  isLoaded,
}) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageSets = utils.getImageSetsFromStudies(studies);
  const axialImageSets = imageSets
    .filter(set => set.viewType === 'axial')
    .filter(set => set.Modality !== 'PT');
  const defaultTab = isLoaded ? 'roi-contours' : 'viewports';
  const tabOptions = isLoaded
    ? [
        { value: 'roi-contours', label: 'ROI', labelFontSize: 12 },
        { value: 'settings', label: 'Settings', labelFontSize: 12 },
        { value: 'viewports', label: 'Viewports', labelFontSize: 12 },
      ]
    : [{ value: 'viewports', label: 'Viewports', labelFontSize: 12 }];
  /** contexts */
  const { appConfig: config, mode } = useContext(AppContext);
  const api = useContext(APIContext);
  const pacs = useContext(PACSContext);
  const apiConfig = api?.isEnabled() ? api.getConfig() : {};
  /** states */
  const [interactings, setInteractings] = useState([]);
  const [isSubmitOpen, setIsSubmitOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAllDisplayed, setIsAllDisplayed] = useState(true);
  const [tabValue, setTabValue] = useState(defaultTab);
  const [ROIChecks, setROIChecks] = useState({});
  const [ROIContours, setROIContours] = useState([]);
  const [selectedROIContour, setSelectedROIContour] = useState(null);
  const { isScrollSync } = useSelector(state => state.extensions.cornerstone);
  const {
    selectedStructureSetUID: SeriesInstanceUID,
    isComputing,
    isLinking,
  } = useSelector(state => state.extensions.dicomRT);
  const user = useSelector(state => state.user);
  const linkage = useSelector(state => state.linkage);
  const { Links: links, PrimaryRTSS: primary } = linkage;
  const SeriesDescription =
    getDisplaySet(studies, SeriesInstanceUID)?.SeriesDescription || '';
  /** hooks */
  const dispatch = useDispatch();
  const isPrimary = usePrimary();
  /** queries */
  const { params } = useRouteMatch();
  const { pathname } = useLocation();
  const isLocal = pathname.split('/')[1] === 'local';
  /** services */
  const modal = useModal();
  const dialog = useDialog();
  const snackbar = useSnackbar();
  const logger = useLogger();
  const logStudy = useLogStudy(SeriesInstanceUID);
  const logPrimaryStudy = useLogStudy(primary.SeriesInstanceUID);

  useEffect(() => {
    const newPainterCallback = function(evt) {
      const uid = evt.detail.uid;
      const newInteractings = interactings.filter(i => i !== uid);
      newInteractings.push(uid);
      setInteractings(newInteractings);
    };
    editModule.addEventListener('new_painter', newPainterCallback);
    return () => {
      window.onbeforeunload = null;
      editModule.removeEventListener('new_painter', newPainterCallback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTabValue(SeriesInstanceUID ? 'roi-contours' : 'viewports');
    const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
    const length = structureSet?.ROIContours?.length;
    if (length || length === 0) {
      refreshROIContours();
      refreshSelectedROIContour();
      setIsAllDisplayed(structureSet.visible);
    }
    /** editable */
    if (mode === 'tracking') {
      const isEditable = SeriesInstanceUID === primary?.SeriesInstanceUID;
      editModule.setters.isEditable(isEditable);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SeriesInstanceUID]);

  const onCommand = function(name, opt) {
    commandsManager.runCommand(name, opt);
  };

  const onConfirm = ({ title, content, callback }) => {
    if (dialog) {
      let dialogId = dialog.create({
        content: ConfirmDialog,
        contentProps: {
          title: title,
          content: content,
          onClose: () => dialog.dismiss({ id: dialogId }),
          onSubmit: () => {
            callback();
            dialog.dismiss({ id: dialogId });
          },
        },
      });
    }
  };

  const refreshROIContours = () => {
    const { ROIContours } = editModule.getters.structureSet(SeriesInstanceUID);
    setROIContours([...ROIContours]);
  };

  const refreshSelectedROIContour = () => {
    const roi = editModule.getters.selectedROIContour();
    setSelectedROIContour(roi);
  };

  const createROI = (idx, roi) => {
    let colorArray = _.range(3).map(x => Math.random());
    const colorMax = _.max(colorArray);
    colorArray = colorArray.map(x => Math.ceil((x / colorMax) * 255));
    const ROINumber = new Date().getTime() * 10 + idx;
    return {
      ROIDescription: null,
      ROIGenerationAlgorithm: 'AUTOMATIC',
      ROINumber,
      ROIName: roi.ROIName,
      RTROIObservations: {
        ObservationNumber: ROINumber,
        ROIInterpreter: null,
        ROIObservationDescription: null,
        RTROIInterpretedType: roi.RTROIObservations.RTROIInterpretedType,
      },
      colorArray,
      visible: true,
      bodyPart: roi.bodyPart,
      measurable: roi.measurable,
      trackingType: roi.trackingType,
    };
  };

  const copyROIContour = (ROIContour, idx) => {
    const roi = { ...ROIContour, ROIName: `${ROIContour.ROIName} copy` };
    const created = createROI(idx, roi);
    editModule.setters.pushROI(SeriesInstanceUID, created);
    editModule.setters.selectedROIContour(SeriesInstanceUID, created.ROINumber);
    for (const imageSet of axialImageSets) {
      for (const image of imageSet.images) {
        const imageId = image.getImageId();
        const painter = editModule.setters.createPainter(
          'copy',
          imageId,
          SeriesInstanceUID,
          null,
          [ROIContour.ROINumber, created.ROINumber]
        );
        painter.commit();
      }
    }
    refreshROIContours();
    refreshSelectedROIContour();
  };

  const addNewROIContour = rois => {
    const created = [];
    for (let idx = 0; idx < rois.length; idx++) {
      const { ROIName } = rois[idx];
      const validated = validateROIName(ROIName);
      if (!validated) continue;
      const newROI = createROI(idx, rois[idx]);
      editModule.setters.pushROI(SeriesInstanceUID, newROI);
      created.push(newROI);
    }
    refreshROIContours();
    if (created[0]) {
      const number = created[0].ROINumber;
      editModule.setters.selectedROIContour(SeriesInstanceUID, number);
      refreshSelectedROIContour();
    }
    if (mode === 'tracking') {
      const id = getLinkMaxNum() + 1;
      dispatch(actions.addROILink(SeriesInstanceUID, created[0].ROINumber, id));
      logger.info({
        event_type: 'Viewer:link',
        message: 'Add link',
        details: {
          ...logPrimaryStudy,
          structure_set_study_uid: primary.StudyInstanceUID,
          structure_set_series_uid: primary.SeriesInstanceUID,
          structure_set_instance_uid: primary.SOPInstanceUID,
          link_id: id,
        },
      });
    }
    return created[0];
  };

  const addNewROIContourWithName = name => {
    const roi = {
      ROIName: name,
      RTROIObservations: { RTROIInterpretedType: 'GTV' },
    };
    const newROIContour = addNewROIContour([roi]);
    if (!newROIContour) return -1;
    return newROIContour.ROINumber;
  };

  const validateROIName = name => {
    const isExisting = _.some(ROIContours, x => x.ROIName === name);
    if (isExisting) {
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: `ROI ${name} already exists`,
        type: 'error',
        autoClose: false,
      });
      return false;
    }
    return true;
  };

  const navigateToROIContour = async ROIContour => {
    if (links.length > 0 && !isScrollSync) {
      links.forEach(link => {
        const mapping = link.RTMappings.find(
          m =>
            m.SeriesInstanceUID === SeriesInstanceUID &&
            m.ROINumbers.includes(ROIContour.ROINumber)
        );
        if (mapping) {
          link.RTMappings.forEach(m => {
            if (m.SeriesInstanceUID !== SeriesInstanceUID)
              /** navigate to the first linked roi */
              commands.checkAndNavigate(m.SeriesInstanceUID, m.ROINumbers[0]);
          });
        }
      });
    }
    const existing = commands.checkAndNavigate(
      SeriesInstanceUID,
      ROIContour.ROINumber,
      isScrollSync ? viewports[activeIndex].SeriesInstanceUID : ''
    );
    if (!existing) {
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: "Can't find contours in current viewports",
        type: 'error',
        autoClose: false,
      });
      onROIUpdate(ROIContour, 'existing', false);
    }
    dispatch(actions.setViewportActive(activeIndex));
  };

  const onROIClick = ROIContour => {
    const deselect = ROIContour.ROINumber === selectedROIContour?.ROINumber;
    if (!deselect) {
      const existing = commands.checkExistenceOfROIContour(
        SeriesInstanceUID,
        ROIContour.ROINumber
      );
      onROIUpdate(ROIContour, 'existing', existing);
    }
    onROISelect(ROIContour, !deselect);
  };

  const onROISelect = (ROIContour, select = true) => {
    const number = select ? ROIContour?.ROINumber : null;
    editModule.setters.selectedROIContour(SeriesInstanceUID, number);
    refreshSelectedROIContour();
    if (select) {
      const highlights = getROIHighlights(ROIContour);
      editModule.setters.highlights(highlights);
    } else {
      editModule.setters.highlights([]);
    }
  };

  const onROIUpdate = (ROIContour, key, value) => {
    if (key === 'ROIName') {
      const validated = validateROIName(value);
      if (!validated) return;
    }
    const newROIContour = { ...ROIContour, [key]: value };
    editModule.setters.updateROI(SeriesInstanceUID, newROIContour);
    commands.refreshViewport();
    refreshROIContours();
  };

  const onROIDelete = ROIContour => {
    editModule.setters.deleteROI(SeriesInstanceUID, ROIContour);
    commands.refreshViewport();
    refreshROIContours();
    if (mode === 'tracking') {
      dispatch(actions.removeROILink(SeriesInstanceUID, ROIContour.ROINumber));
      logger.info({
        event_type: 'Viewer:link',
        message: 'Remove link',
        details: {
          ...logPrimaryStudy,
          structure_set_study_uid: primary.StudyInstanceUID,
          structure_set_series_uid: primary.SeriesInstanceUID,
          structure_set_instance_uid: primary.SOPInstanceUID,
        },
      });
    }
  };

  const onDisplayAllStatusChange = () => {
    ROIContours.forEach(roi => {
      const _roi = _.cloneDeep(roi);
      _roi.visible = !isAllDisplayed;
      editModule.setters.updateROI(SeriesInstanceUID, _roi);
    });
    editModule.setters.structureSetVisible(SeriesInstanceUID, !isAllDisplayed);
    commands.refreshViewport();
    setIsAllDisplayed(!isAllDisplayed);
    refreshROIContours();
  };

  const onComputingStatusChange = isComputing => {
    if (isComputing) {
      editModule.setters.highlights([]);
      editModule.setters.selectedROIContour(null, null);
      refreshSelectedROIContour();
    }
    dispatch(actions.setIsComputing(isComputing));
  };

  /** linkage */
  const onLinkingStatusChange = isLinking => {
    if (isLinking) onCommand('setToolActive', { toolName: 'Pan' });
    dispatch(actions.setIsLinking(isLinking));
  };

  const getLink = (SeriesInstanceUID, ROINumber) => {
    return links.find(link => {
      return link.RTMappings.some(
        mapping =>
          mapping.SeriesInstanceUID === SeriesInstanceUID &&
          mapping.ROINumbers.includes(ROINumber)
      );
    });
  };

  const getROILink = ROIContour => {
    if (mode === 'tracking') {
      const ROINumber = ROIContour.ROINumber;
      const link = getLink(SeriesInstanceUID, ROINumber);
      return link;
    }
    return null;
  };

  const onROILink = confirmed => {
    if (confirmed) {
      const allLinks = [];
      const linkIds = [];
      Object.entries(ROIChecks).forEach(([key, numbers]) => {
        numbers.forEach(number => {
          const link = getLink(key, number);
          if (!link) return;
          if (!linkIds.includes(link.LinkID)) {
            allLinks.push(link);
            linkIds.push(link.LinkID);
          }
        });
      });
      if (linkIds.length > 0) {
        linkIds.sort((a, b) => a - b);
        dispatch(actions.mergeLinks(linkIds));
        logger.info({
          event_type: 'Viewer:link',
          message: 'Merge links',
          details: {
            ...logPrimaryStudy,
            structure_set_study_uid: primary.StudyInstanceUID,
            structure_set_series_uid: primary.SeriesInstanceUID,
            structure_set_instance_uid: primary.SOPInstanceUID,
            link_ids: linkIds,
          },
        });
      }
      onROISelect({}, false);
      setROIChecks({});

      /** callback */
      const checks = [];
      allLinks.forEach(link => {
        link.RTMappings.forEach(m => {
          m.ROINumbers.forEach(n => {
            checks.push({
              SeriesInstanceUID: m.SeriesInstanceUID,
              ROINumber: n,
            });
          });
        });
      });
      updateTrackingTypeBySelection(checks);
    }
    onLinkingStatusChange(false);
  };

  const getLinkMaxNum = () => {
    const nums = [0, ...links.map(link => link.LinkID)];
    const maxNum = Math.max(...nums);
    return maxNum;
  };

  const getROIHighlights = roi => {
    if (mode === 'tracking') {
      const highlights = links.reduce((highlights, link) => {
        const mapping = link.RTMappings.find(
          m =>
            m.SeriesInstanceUID === SeriesInstanceUID &&
            m.ROINumbers.includes(roi.ROINumber)
        );
        const _highlights = [];
        if (mapping) {
          link.RTMappings.forEach(m => {
            m.ROINumbers.forEach(number => {
              _highlights.push({
                SeriesInstanceUID: m.SeriesInstanceUID,
                ROINumber: number,
              });
            });
          });
        }
        return [...highlights, ..._highlights];
      }, []);
      if (highlights.length > 0) return highlights;
    }
    return [{ SeriesInstanceUID: SeriesInstanceUID, ROINumber: roi.ROINumber }];
  };

  const getROILinked = roi => {
    const link = getROILink(roi);
    const isLinked =
      link?.RTMappings.length > 1 ||
      (link?.RTMappings.length === 1 &&
        link?.RTMappings[0]?.ROINumbers.length > 1);
    return isLinked;
  };

  const updateTrackingTypeBySelection = (checks, removes = []) => {
    const updateTrackingType = (uid, number, trackingType) => {
      const roi = editModule.getters.ROIContour(uid, number);
      const newROI = { ...roi, trackingType };
      editModule.setters.updateROI(uid, newROI);
    };
    const candidates = [];
    const tracks = [];
    checks.forEach(check => {
      if (check.SeriesInstanceUID === primary.SeriesInstanceUID) {
        candidates.push(check);
      } else {
        tracks.push(check);
      }
    });
    candidates.forEach(c => {
      if (tracks.length > 0) {
        updateTrackingType(c.SeriesInstanceUID, c.ROINumber, 'tracked');
      } else {
        updateTrackingType(c.SeriesInstanceUID, c.ROINumber, 'new');
      }
    });
    removes.forEach(c => {
      if (c.SeriesInstanceUID === primary.SeriesInstanceUID) {
        updateTrackingType(c.SeriesInstanceUID, c.ROINumber, 'new');
      }
    });
    refreshROIContours();
  };

  const onROIUnlink = ROIContour => {
    if (mode === 'tracking') {
      onConfirm({
        title: 'Warning',
        content: `Are you sure to unlink ${ROIContour.ROIName}?`,
        callback: () => {
          const uid = SeriesInstanceUID;
          const { ROINumber: number } = ROIContour;
          const prevLink = getROILink(ROIContour);
          const prevId = prevLink.LinkID;
          const id = getLinkMaxNum() + 1;
          dispatch(actions.addLink(id));
          dispatch(actions.updateROILink(uid, number, prevId, id));
          logger.info({
            event_type: 'Viewer:link',
            message: 'Reassign link',
            details: {
              ...logPrimaryStudy,
              structure_set_study_uid: primary.StudyInstanceUID,
              structure_set_series_uid: primary.SeriesInstanceUID,
              structure_set_instance_uid: primary.SOPInstanceUID,
              origin_link_id: prevId,
              new_link_id: id,
              roi: { name: ROIContour.ROIName },
            },
          });
          onROISelect(ROIContour, false);

          /** callback */
          const checks = [];
          const removes = [{ SeriesInstanceUID, ROINumber: number }];
          prevLink.RTMappings.forEach(m => {
            m.ROINumbers.forEach(n => {
              if (m.SeriesInstanceUID === uid && n === number) return;
              checks.push({
                SeriesInstanceUID: m.SeriesInstanceUID,
                ROINumber: n,
              });
            });
          });
          updateTrackingTypeBySelection(checks, removes);
        },
      });
    }
  };

  const getROIChecked = roi => {
    return (
      ROIChecks[SeriesInstanceUID]?.find(number => number === roi.ROINumber) !==
      undefined
    );
  };

  const onROICheck = (roi, checked) => {
    const newValue =
      ROIChecks[SeriesInstanceUID]?.filter(
        number => number !== roi.ROINumber
      ) || [];
    if (checked) newValue.push(roi.ROINumber);
    const newChecks = { ...ROIChecks, [SeriesInstanceUID]: newValue };
    setROIChecks(newChecks);
  };
  /** end linkage */

  /** modals */
  const onMeasurementClick = () => {
    if (modal) {
      modal.show({
        content: Measurement,
        title: 'ROI Measurements',
        fullscreen: true,
        contentProps: {
          imageSets: axialImageSets,
          defaultStructureSetUID: SeriesInstanceUID,
        },
        onClose: () => refreshROIContours(),
      });
    }
  };

  const onTrackingClick = () => {
    commandsManager.runCommand('removePanZoomSync');
    const study = getStudy(studies, SeriesInstanceUID);
    const { PatientID } = study;
    if (modal) {
      modal.show({
        content: Tracking,
        title: 'Tracking Dashboard',
        fullscreen: true,
        contentProps: {
          imageSets: axialImageSets,
          PatientID,
          defaultStructureSetUID: SeriesInstanceUID,
          defaultSectionName: 'link',
        },
        onClose: () => refreshROIContours(),
      });
    }
  };

  const onLongitudinalStudyClick = sectionName => {
    const study = getStudy(studies, SeriesInstanceUID);
    const { PatientID } = study;
    if (modal) {
      modal.show({
        content: LongitudinalStudy,
        title: 'Longitudinal Study',
        fullscreen: true,
        contentProps: {
          imageSets: axialImageSets,
          PatientID,
          SeriesInstanceUID,
          defaultSectionName: sectionName,
        },
        onClose: () => refreshROIContours(),
      });
    }
  };
  /** end modals */

  /** outputs */
  const onExportClick = async () => {
    const study = getStudy(studies, SeriesInstanceUID);
    const { PatientID } = study;
    const filename = `rtss_${PatientID}`;
    const structureSet = editModule.getters.structureSet(SeriesInstanceUID);
    const _structureSet = makeObservationLabel(structureSet);
    const dataset = dicom.getRTStructDataset(
      study,
      _structureSet,
      axialImageSets,
      apiConfig
    );
    await dicom.exportRTStruct(filename, dataset);
  };

  const onSubmitClick = async () => {
    if (api?.isEnabled()) {
      const isMe = await api.checkMe();
      if (!isMe) {
        onConfirm({
          title: 'Warning',
          content: "You've logged out. Please log back in.",
          callback: () =>
            window.open(api.host, '_blank', 'noopener,noreferrer'),
        });
        return;
      }
    }
    if (mode === 'longitudinal') {
      try {
        /** validation */
        const criteria = editModule.getters.criteria();
        const numberCheck = ls.checkNumberOfTargets(ROIContours, criteria);
        const checked = numberCheck.result;
        if (!checked) {
          onConfirm({
            title: 'Warning',
            content: `${numberCheck.message} Are you sure to continue?`,
            callback: () => setIsSubmitOpen(true),
          });
          return;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        return;
      }
    }
    if (api?.isEnabled()) {
      setIsSubmitOpen(true);
    } else {
      await submit({
        StructureSetName: '',
        SeriesDescription: 'Vysioneer DICOMRT',
        ContentCreatorName: user.email,
        isForwarding: false,
        modalityId: '',
      });
    }
  };

  const handleSubmitClose = async (ok, input) => {
    if (ok) await submit(input);
    setIsSubmitOpen(false);
  };

  class SubmitError extends Error {
    constructor(message) {
      super(message);
      this.name = 'SubmitError';
      this.stack = new Error().stack;
    }
  }

  const submit = async input => {
    try {
      /** save */
      setIsSubmitting(true);
      const onbeforeunload = window.onbeforeunload;
      const study = getStudy(studies, SeriesInstanceUID);
      const structureSet = {
        ...editModule.getters.structureSet(SeriesInstanceUID),
        StructureSetName: input.StructureSetName,
        StructureSetLabel: mode === 'tracking' ? 'VBRAIN_TRACKING' : 'VBRAIN',
        SeriesDescription: input.SeriesDescription,
        ContentCreatorName: input.ContentCreatorName,
      };
      const _structureSet = makeObservationLabel(structureSet);
      let newSet;
      try {
        const url = axialImageSets[0].images[0].getData().wadoRoot;
        const dataset = dicom.getRTStructDataset(
          study,
          _structureSet,
          axialImageSets,
          apiConfig
        );
        newSet = await dicom.submitRTStruct(url, dataset);
      } catch (err) {
        throw new SubmitError('Failed to save DICOM RT structure set');
      }
      editModule.setters.rebaseStructureSets([newSet]);
      if (newSet) {
        logger.info({
          event_type: 'Viewer:save',
          message: 'Save DICOM RT structure set',
          details: {
            ...logStudy,
            origin_structure_set_study_uid: _structureSet.StudyInstanceUID,
            origin_structure_set_series_uid: _structureSet.SeriesInstanceUID,
            origin_structure_set_instance_uid: _structureSet.SOPInstanceUID,
            new_structure_set_study_uid: newSet.StudyInstanceUID,
            new_structure_set_series_uid: newSet.SeriesInstanceUID,
            new_structure_set_instance_uid: newSet.SOPInstanceUID,
          },
        });
        if (api?.isEnabled()) {
          api.analyze({
            study_id: newSet.StudyInstanceUID,
            series_id: newSet.SeriesInstanceUID,
            instance_id: newSet.SOPInstanceUID,
          });
        }
      }
      /** save callback */
      if (input.isForwarding && pacs) {
        const { StudyInstanceUID, SeriesInstanceUID } = newSet;
        try {
          const forwarded = await pacs.forwardSeries(input.modalityId, {
            StudyInstanceUID,
            SeriesInstanceUID,
          });
          if (!forwarded) throw new Error('Failed to forward');
        } catch (err) {
          throw new SubmitError('Failed to forward DICOM RT structure set');
        }
      }
      if (mode === 'tracking' && api) {
        try {
          const newLinkage = api.getUpdatedLinkage(
            linkage,
            structureSet,
            newSet
          );
          await api.submitLinkage(
            {
              patient_id: newSet.PatientID,
              study_id: newSet.StudyInstanceUID,
              series_id: newSet.SeriesInstanceUID,
              instance_id: newSet.SOPInstanceUID,
            },
            newLinkage
          );
        } catch (err) {
          throw new SubmitError('Failed to save linkage file');
        }
      }
      if (mode === 'longitudinal') {
        try {
          const { site, trial, subject } = params;
          if (site && trial && subject) {
            const criteria = editModule.getters.criteria();
            const assmnts = editModule.getters.assessments();
            const endpnts = ls.getEndpoints(
              axialImageSets,
              ROIContours,
              criteria,
              assmnts
            );
            const promises = config.firebaseConfig?.enableUpdateEndpoints
              ? [
                  Firebase.checkAndUpdateSubjectRead([newSet], assmnts, params),
                  Firebase.checkAndUpdateSubjectEndpoints(endpnts, params),
                ]
              : [Firebase.checkAndUpdateSubjectRead([newSet], assmnts, params)];
            await Promise.all(promises);
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      }
      if (onbeforeunload === window.onbeforeunload) {
        const newInteractings = interactings.filter(
          i => i !== SeriesInstanceUID
        );
        if (newInteractings.length === 0) window.onbeforeunload = null;
        setInteractings(newInteractings);
      }
      snackbar.show({
        title: 'DICOM RTSTRUCT',
        message: 'The file has been saved successfully',
        type: 'success',
      });
    } catch (e) {
      switch (e.name) {
        case 'TokenError': {
          logger.error({
            event_type: 'Viewer:save',
            message: 'Session expired',
            details: {},
          });
          snackbar.show({
            title: 'AUTHENTICATION',
            message:
              'Session expired. Please close this session and open the case again. Sorry for the inconvenience.',
            type: 'error',
            autoClose: false,
          });
          dispatch(actions.setEmail(''));
          dispatch(actions.setIsReadOnly(true));
          onCommand('setToolActive', { toolName: 'StackScroll' });
          break;
        }
        case 'SubmitError': {
          const s = editModule.getters.structureSet(SeriesInstanceUID);
          const details = {
            ...logStudy,
            structure_set_study_uid: s.StudyInstanceUID,
            structure_set_series_uid: SeriesInstanceUID,
            structure_set_instance_uid: s.SOPInstanceUID,
          };
          if (input.modalityId) details.modality_id = input.modalityId;
          logger.error({
            event_type: 'Viewer:save',
            errorMessage: e.message,
            details,
          });
          snackbar.show({
            title: 'DICOM RTSTRUCT',
            message: e.message,
            type: 'error',
            autoClose: false,
          });
          break;
        }
        default: {
          logger.error({
            event_type: 'Viewer:save',
            errorMessage: e.message,
            details: {},
          });
          snackbar.show({
            title: 'DICOM RTSTRUCT',
            message: 'Internal server error',
            type: 'error',
            autoClose: false,
          });
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const makeObservationLabel = structureSet => {
    if (mode === 'tracking') {
      const TYPES = { TRACKED: 'tracked', NEW: 'new', EX: 'ex' };
      structureSet.ROIContours.forEach(roi => {
        const label = utils.getReverseMap(TYPES)[roi.trackingType] || '';
        roi.RTROIObservations.ROIObservationLabel = label;
      });
    }
    if (mode === 'longitudinal') {
      const { BODY_PARTS, TRACKING_TYPES } = ls.guideline;
      structureSet.ROIContours.forEach(roi => {
        const bodyPart = utils.getReverseMap(BODY_PARTS)[roi.bodyPart] || '';
        const t = utils.getReverseMap(TRACKING_TYPES)[roi.trackingType] || '';
        const label = `${bodyPart}_${t}`;
        roi.RTROIObservations.ROIObservationLabel = label;
      });
    }
    return structureSet;
  };
  /** end outputs */

  return (
    <>
      <Header>
        <RoundedButtonGroup
          id={'rt-edit'}
          options={tabOptions}
          value={tabValue}
          onValueChanged={setTabValue}
        />
      </Header>
      <TabPanel value={tabValue} index={'viewports'}>
        {axialImageSets.length > 0 && (
          <ViewportSection viewports={viewports} activeIndex={activeIndex} />
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={'settings'}>
        <ToolSettingsSection mode={mode} />
      </TabPanel>
      <TabPanel value={tabValue} index={'roi-contours'}>
        {isComputing ? (
          <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            <RTCompute
              axialImageSets={axialImageSets}
              SeriesInstanceUID={SeriesInstanceUID}
              ROIContours={ROIContours}
              addNewROIContourWithName={addNewROIContourWithName}
              onROISelect={onROISelect}
              onROIDelete={onROIDelete}
              onCommand={onCommand}
              onFinished={() => onComputingStatusChange(false)}
            />
          </div>
        ) : (
          <ROIContourSection
            mode={mode}
            SeriesInstanceUID={SeriesInstanceUID}
            SeriesDescription={SeriesDescription}
            onConfirm={onConfirm}
            ROIContours={ROIContours}
            selectedROIContour={selectedROIContour}
            addNewROIContour={addNewROIContour}
            copyROIContour={copyROIContour}
            navigateToROIContour={navigateToROIContour}
            onROIClick={onROIClick}
            onROIUpdate={onROIUpdate}
            onROIDelete={onROIDelete}
            isAllDisplayed={isAllDisplayed}
            onDisplayAllStatusChange={onDisplayAllStatusChange}
            onComputingStatusChange={onComputingStatusChange}
            isLinking={isLinking}
            onLinkingStatusChange={onLinkingStatusChange}
            getROILinked={getROILinked}
            onROILink={onROILink}
            onROIUnlink={onROIUnlink}
            getROIChecked={getROIChecked}
            onROICheck={onROICheck}
          />
        )}
        {!isComputing && !isLinking && (
          <Footer>
            {!['tracking', 'longitudinal'].includes(mode) && (
              <FooterButton
                onClick={onMeasurementClick}
                disabled={axialImageSets.length === 0}
              >
                Measurements
              </FooterButton>
            )}
            {mode === 'tracking' && (
              <FooterButton
                onClick={onTrackingClick}
                disabled={axialImageSets.length === 0}
              >
                Tracking
              </FooterButton>
            )}
            {mode === 'longitudinal' && (
              <FooterButton
                onClick={() => onLongitudinalStudyClick('response')}
                disabled={axialImageSets.length === 0}
              >
                Longitudinal Study
              </FooterButton>
            )}
            {(config.enableDownloadRtstructButton || isLocal) && (
              <FooterButton onClick={onExportClick}>
                Export RTSTRUCT
              </FooterButton>
            )}
            {config.enableSubmitRtstructButton && !isLocal && (
              <FooterButton
                onClick={onSubmitClick}
                disabled={
                  !user.email ||
                  isSubmitting ||
                  (mode === 'tracking' && !isPrimary)
                }
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </FooterButton>
            )}
          </Footer>
        )}
      </TabPanel>
      <SubmitRTStructForm
        open={isSubmitOpen}
        onClose={handleSubmitClose}
        defaultCreator={user.email}
      />
    </>
  );
}
RTEdit.propTypes = {
  studies: PropTypes.array,
  viewports: PropTypes.object,
  activeIndex: PropTypes.number,
  commandsManager: PropTypes.object,
  isLoaded: PropTypes.bool,
};

const TabPanel = props => {
  const { children, value, index } = props;
  return value === index && children;
};
const Header = styled.div`
  margin: 0 0 10px 0;
`;
const Footer = styled.div`
  margin: auto 0 0 0;
  padding: 5px 0 0;
`;

function getStudy(studies, SeriesInstanceUID) {
  const checkStudyDisplaySet = study =>
    study.displaySets.find(d => d.SeriesInstanceUID === SeriesInstanceUID);
  const study = studies.find(study => checkStudyDisplaySet(study)) || {};
  return study;
}

function getDisplaySet(studies, SeriesInstanceUID) {
  const displaySet = studies
    .reduce((displaySets, study) => [...displaySets, ...study.displaySets], [])
    .find(set => SeriesInstanceUID === set.SeriesInstanceUID);
  return displaySet;
}
