import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Select, Input, P, Alert } from '@platform/ui';

export const BooleanForm = ({
  ROIContours,
  onSelectChange,
  onSubmit,
  onDelete,
  errorMessage = null,
}) => {
  const [subjectROIContour, setSubjectROIContour] = useState('');
  const [objectROIContour, setObjectROIContour] = useState('');
  const [method, setMethod] = useState('');
  const [name, setName] = useState('');

  return (
    <div
      style={{
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      <div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Name:</P>
          <Input
            value={name}
            onChange={evt => setName(evt.target.value)}
            size="small"
            variant="outlined"
            fullWidth
          />
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Subject ROI Contour:</P>
          <Select
            defaultValue={-1}
            onChange={evt => {
              const subjectROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setSubjectROIContour(subjectROIContour);
              onSelectChange({ subjectROIContour, objectROIContour, method });
            }}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option value={-1}>------</option>
            {ROIContours.filter(
              c => c.ROINumber !== objectROIContour?.ROINumber
            ).map(c => (
              <option key={c.ROINumber} value={c.ROINumber}>
                {c.ROIName}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Object ROI Contour:</P>
          <Select
            defaultValue={-1}
            onChange={evt => {
              const objectROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setObjectROIContour(objectROIContour);
              onSelectChange({ subjectROIContour, objectROIContour, method });
            }}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option value={-1}>------</option>
            {ROIContours.filter(
              c => c.ROINumber !== subjectROIContour?.ROINumber
            ).map(c => (
              <option key={c.ROINumber} value={c.ROINumber}>
                {c.ROIName}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>Method:</P>
          <Select
            defaultValue={'none'}
            onChange={evt => {
              const method = evt.target.value;
              setMethod(method);
              onSelectChange({ subjectROIContour, objectROIContour, method });
            }}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option value="none">------</option>
            <option value="union">Union</option>
            <option value="intersect">Intersect</option>
            <option value="subtract">Subtract</option>
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </div>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={() => onDelete()}
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() =>
                onSubmit({ name, subjectROIContour, objectROIContour, method })
              }
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
BooleanForm.propTypes = {
  ROIContours: PropTypes.array,
  onSelectChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};
