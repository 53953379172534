import _ from 'lodash';

class ROI {
  constructor(roi) {
    this.ROIName = roi.ROIName;
    this.ROINumber = roi.ROINumber;
    this.data = [];
  }
}

/**
 * @param {Object} structureSet
 * @param {Object[]} structureSet.StructureSetROISequence
 * @param {Object[]} structureSet.RTROIObservationsSequence
 * @param {Object[]} structureSet.ROIContourSequence
 */
export default function getROIContours(structureSet) {
  const {
    StructureSetROISequence,
    RTROIObservationsSequence,
    ROIContourSequence,
  } = structureSet;
  const ROIContours = StructureSetROISequence.map(roi => new ROI(roi));

  RTROIObservationsSequence.forEach(ob => {
    const ROI = ROIContours.find(
      roi => ob.ReferencedROINumber === roi.ROINumber
    );
    if (ROI) ROI.RTROIInterpretedType = ob.RTROIInterpretedType;
  });

  ROIContourSequence.forEach(ob => {
    const ROI = ROIContours.find(
      roi => ob.ReferencedROINumber === roi.ROINumber
    );
    if (ROI) {
      ROI.ROIDisplayColor = ob.ROIDisplayColor;
      let ContourSequence = ob.ContourSequence;
      if (!ContourSequence) return;
      if (!_.isArray(ContourSequence)) ContourSequence = [ContourSequence];
      const data = [];
      ContourSequence.forEach(c => {
        const {
          ContourImageSequence,
          ContourData,
          NumberOfContourPoints,
          ContourGeometricType,
        } = c;
        if (ContourGeometricType !== 'CLOSED_PLANAR') return;
        const ReferencedSOPInstanceUID =
          ContourImageSequence.ReferencedSOPInstanceUID;
        const points = [];
        for (let p = 0; p < NumberOfContourPoints * 3; p += 3) {
          points.push({
            x: ContourData[p],
            y: ContourData[p + 1],
            z: ContourData[p + 2],
          });
        }
        data.push({ points, ReferencedSOPInstanceUID });
      });
      ROI.data = data;
    }
  });

  return ROIContours;
}
