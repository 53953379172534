import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve Series metadata from a DICOM server
 * @param server
 * @param StudyInstanceUID
 * @param SeriesInstanceUID
 * @throws ECONNREFUSED
 * @returns {Array} The list of instance object
 */
export default async function retrieveSeriesMetadata(
  server,
  StudyInstanceUID,
  SeriesInstanceUID,
  naturalized = true
) {
  const dicomWebClient = getDICOMwebClient(server.wadoRoot, server);
  const instances = await dicomWebClient.retrieveSeriesMetadata({
    studyInstanceUID: StudyInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
  });
  if (naturalized) {
    return instances.map(instance => {
      return dcmjs.data.DicomMetaDictionary.naturalizeDataset(instance);
    });
  }
  return instances;
}
