import cornerstoneTools, { importInternal } from 'cornerstone-tools';

import BasePainterTool from './BasePainterTool';
import TOOL_NAMES from './constants/toolNames';
import { refreshViewport } from './commands';

export const draw = importInternal('drawing/draw');
export const getNewContext = importInternal('drawing/getNewContext');

export default class BBox3DTool extends BasePainterTool {
  constructor(props = {}) {
    const defaultProps = { name: TOOL_NAMES.BOUNDING_BOX_3D_TOOL };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps, 'bbox');
    this.stack = [];
  }

  renderToolData(evt) {
    const { element, canvasContext, image } = evt.detail;
    const painter = this._getPainter(element);
    const context = getNewContext(canvasContext.canvas);

    const _painter = this.stack[0];
    const { imageId } = image;
    if (_painter && _painter.imageId === imageId) {
      _painter.cursor(null, context, null, true);
    }

    if (!painter || painter._mode !== 'bbox') return;
    painter.cursor(evt, context, this._cursorCanvasPosition, this._isDrawing);
  }

  disabledCallback(element) {
    const painters = this._getPainters(element);
    for (const p of painters) {
      if (p?._mode === 'bbox') p.clear();
    }
    refreshViewport();
  }

  _drawingUpCallback(evt, shouldUpdate = true) {
    const eventData = evt.detail;
    const element = eventData.element;

    this._isDrawing = false;
    delete this._imageId;
    delete this._cursorCanvasPosition;

    if (shouldUpdate) {
      const selectedROINumber = this._module.getters.selectedROINumber();
      if (_.isNumber(selectedROINumber)) {
        const painter = this._getPainter(element);
        this.stack.push(painter);
      }
    }

    if (this.stack.length >= 2) {
      const toolState = cornerstoneTools.getToolState(element, 'stack');
      if (!toolState) return;

      const { imageIds } = toolState.data[0];
      const painters = [...this.stack];
      const top = this.stack[0].getState();
      const bottom = this.stack[1].getState();
      const topIndex = imageIds.indexOf(top.imageId);
      const bottomIndex = imageIds.indexOf(bottom.imageId);
      const max = Math.max(topIndex, bottomIndex);
      const min = Math.min(topIndex, bottomIndex);

      imageIds.forEach((id, index) => {
        if (min < index && index < max) {
          const start = {
            detail: {
              image: { imageId: id },
              currentPoints: {
                canvas: getInterpolatePoint(
                  top.initial,
                  bottom.initial,
                  (topIndex - index) / (topIndex - bottomIndex)
                ),
              },
            },
          };
          const end = {
            detail: {
              image: { imageId: id },
              currentPoints: {
                canvas: getInterpolatePoint(
                  top.current,
                  bottom.current,
                  (topIndex - index) / (topIndex - bottomIndex)
                ),
              },
            },
          };
          const _painter = this._module.getters.peekPainter(id);
          const newPainter = this._module.setters.createPainter(
            'bbox',
            id,
            _painter._structureSetSeriesInstanceUid
          );
          newPainter.update(start);
          newPainter.update(end);
          painters.push(newPainter);
        }
      });
      painters.forEach(painter => {
        painter
          .commit(
            {
              detail: {
                ...evt.detail,
                image: {
                  ...evt.detail.image,
                  imageId: painter.imageId,
                },
              },
            },
            false
          )
          .then(() => refreshViewport());
      });
      this.stack = [];
    }
  }
}

function getInterpolatePoint(p1, p2, distanceRatio) {
  return {
    x: getInterpolation(p1.x, p2.x, distanceRatio),
    y: getInterpolation(p1.y, p2.y, distanceRatio),
  };
}

function getInterpolation(a, b, ratio) {
  return a * (1 - ratio) + b * ratio;
}
