import React from 'react';
import PropTypes from 'prop-types';

import {
  Body,
  Container,
  Grid,
  Select,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@platform/ui';
import { MEASUREMENT_TECHNIQUE } from '../constants/guideline';
import { getTimepoints } from '../models/getTimepoints';
import { getTimepointStats } from '../models/responseAssessments';

function addSign(num) {
  return num > 0 ? `+${num}` : num;
}

function getUnit(measurementTechnique) {
  const mapping = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: <span>Sum(mm)</span>,
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: (
      <span>
        Sum(mm<sup>3</sup>)
      </span>
    ),
  };
  return mapping[measurementTechnique];
}

export const FindingSection = ({
  imageSets,
  ROIContours,
  criteria,
  assessments,
  getFilteredROIContours,
  filteredBodyPart,
  setFilteredBodyPart,
  filteredTrackingType,
  setFilteredTrackingType,
  selectedLesionNumber,
  setSelectedLesionNumber,
  measurementTechnique,
  setMeasurementTechnique,
}) => {
  const filteredROIContours = getFilteredROIContours(ROIContours);
  const timepoints = getTimepoints(imageSets, filteredROIContours);
  const timepointStats = getTimepointStats(
    timepoints,
    filteredROIContours,
    [],
    criteria,
    assessments
  );
  const reports = timepoints.map((timepoint, index) => {
    const measurementTechniqueMapping = {
      [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: 'sumDiameter',
      [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: 'sumVolume',
    };
    const key = measurementTechniqueMapping[measurementTechnique];
    let sum = timepointStats[index]['summation'][key];
    let fromBaseline = timepointStats[index]['fromBaseline'][key];
    let fromNadir = timepointStats[index]['fromNadir'][key];
    let fromLatest = timepointStats[index]['fromLatest'][key];
    return {
      timepoint,
      sum,
      fromBaseline,
      fromNadir,
      fromLatest,
    };
  });

  const onFilteredBodyPartChanged = evt => {
    const bodyPart = evt.target.value;
    setFilteredBodyPart(bodyPart);
  };

  const onFilteredTrackingTypeChanged = evt => {
    const type = evt.target.value;
    setFilteredTrackingType(type);
    if (type !== 'selected') {
      setSelectedLesionNumber(-1);
    }
  };

  const onSelectedLesionNumberChanged = evt => {
    setSelectedLesionNumber(Number(evt.target.value));
  };

  const onMeasurementTechniqueChanged = evt => {
    setMeasurementTechnique(evt.target.value);
  };

  return (
    <Body>
      <Container>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow index={-1}>
              {[
                ['No.', '6%'],
                ['Date', '14%'],
                [getUnit(measurementTechnique), '20%'],
                ['from Baseline(%)', '20%'],
                ['from Nadir(%)', '20%'],
                ['from Latest(%)', '20%'],
              ].map(([text, width], i) => (
                <TableCell key={i} index={i} width={width} header={1}>
                  <H6>{text}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report, index) => {
              return (
                <TableRow
                  key={report.timepoint.SeriesDate + index}
                  index={index}
                >
                  {[
                    index,
                    report.timepoint.SeriesDate,
                    report.sum,
                    addSign(report.fromBaseline),
                    addSign(report.fromNadir),
                    addSign(report.fromLatest),
                  ].map((text, i) => (
                    <TableCell key={i} index={i}>
                      <H6>{text}</H6>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Container>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Select
            value={filteredBodyPart}
            onChange={evt => onFilteredBodyPartChanged(evt)}
          >
            {[
              ['all', 'Whole Body'],
              ['brain', 'Brain'],
              ['liver', 'Liver'],
              ['lung', 'Lung'],
              ['lymph-node', 'Lymph Node'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={filteredTrackingType}
            onChange={evt => onFilteredTrackingTypeChanged(evt)}
          >
            {[
              ['all', 'All Lesions'],
              ['measurable', 'All Measurables'],
              ['target', 'Target Lesions'],
              ['non-target', 'Non-target Lesions'],
              ['new', 'New Lesions'],
              ['selected', 'Selected Lesion'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={selectedLesionNumber}
            onChange={evt => onSelectedLesionNumberChanged(evt)}
          >
            <option key={-1} value={-1}>
              ------
            </option>
            {filteredTrackingType === 'selected' &&
              ROIContours.map(roi => (
                <option key={roi.ROINumber} value={roi.ROINumber}>
                  {roi.ROIName}
                </option>
              ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Select
            value={measurementTechnique}
            onChange={evt => onMeasurementTechniqueChanged(evt)}
          >
            {[
              ['BIDIMENSIONAL', 'Bidimensional'],
              ['VOLUMETRIC', 'Volumetric'],
            ].map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Select>
        </Grid>
      </Grid>
    </Body>
  );
};
FindingSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  criteria: PropTypes.any,
  assessments: PropTypes.array,
  getFilteredROIContours: PropTypes.func,
  filteredBodyPart: PropTypes.string,
  setFilteredBodyPart: PropTypes.func,
  filteredTrackingType: PropTypes.string,
  setFilteredTrackingType: PropTypes.func,
  selectedLesionNumber: PropTypes.number,
  setSelectedLesionNumber: PropTypes.func,
  measurementTechnique: PropTypes.string,
  setMeasurementTechnique: PropTypes.func,
};
