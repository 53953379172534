import transformStudyDateToDate from '../clinicalEndpoints/transformStudyDateToDate';
import getNumberOfDays from '../clinicalEndpoints/getNumberOfDays';
import _ from 'lodash';

/**
 * @param {Object[]} assessedTimepoints
 * @param {Array[]} assessedTimepoints[].Lesions
 */
export function getAllNewLesionAlert(assessedTimepoints) {
  const date = assessedTimepoints[assessedTimepoints.length - 1].SeriesDate;
  const alerts = getNewLesionAlerts(assessedTimepoints);
  const falsePositives = alerts.reduce((fps, a) => {
    return [...fps, ...a.lesions];
  }, []);
  if (falsePositives.length === 0) {
    return { date: date, isWarning: false };
  }
  const names = _.uniq(falsePositives.map(l => l.ROIName)).join(', ');
  return {
    date: date,
    isWarning: true,
    message: `Unexpected new lesions are detected and disappeared in 6 month: ${names}`,
    lesions: falsePositives,
  };
}

export function getNewLesionAlerts(assessedTimepoints) {
  const baselineROINumbers = assessedTimepoints[0].Lesions.filter(
    l => l.maxAreaValue > 0
  ).map(l => l.ROINumber);
  const { newLesionAlerts } = assessedTimepoints.reduce(
    ({ newLesionAlerts, trackingLesions }, timepoint) => {
      const Lesions = timepoint.Lesions.filter(l => l.maxAreaValue > 0);

      /** get new lesion alert */
      const alert = getNewLesionAlert(
        trackingLesions,
        Lesions,
        timepoint.SeriesDate
      );
      const _newLesionAlerts = [...newLesionAlerts, alert];

      /** add tracking lesions */
      const trackingROINumbers = trackingLesions.map(l => l.ROINumber);
      const _trackingLesions = [
        ...trackingLesions,
        ...Lesions.filter(
          l =>
            !baselineROINumbers.includes(l.ROINumber) &&
            !trackingROINumbers.includes(l.ROINumber)
        ).map(l => ({
          ...l,
          examinedDate: timepoint.SeriesDate,
        })),
      ];

      return {
        newLesionAlerts: _newLesionAlerts,
        trackingLesions: _trackingLesions,
      };
    },
    { newLesionAlerts: [], trackingLesions: [] }
  );

  return newLesionAlerts;
}

function getNewLesionAlert(prevLesions, lesions, date) {
  if (prevLesions.length === 0) {
    return { date: date, isWarning: false, message: '', lesions: [] };
  }
  const ROINumbers = lesions.map(lesion => lesion.ROINumber);
  const falsePositives = prevLesions
    .filter(lesion => !ROINumbers.includes(lesion.ROINumber))
    .filter(
      lesion =>
        getNumberOfDays(
          transformStudyDateToDate(lesion.examinedDate),
          transformStudyDateToDate(date)
        ) < 180
    );
  if (falsePositives.length > 0) {
    const names = falsePositives.map(l => l.ROIName).join(', ');
    return {
      date: date,
      isWarning: true,
      message: `Unexpected new lesions are detected and disappeared in 6 month: ${names}`,
      lesions: falsePositives,
    };
  } else {
    return { date: date, isWarning: false, message: '', lesions: [] };
  }
}
