import isHighPriorityModality from './isHighPriorityModality';
import transformStudyDateToDate from './transformStudyDateToDate';

function getImageSetsFromStudies(studies) {
  const imageSets = studies
    .reduce((displaySets, study) => {
      return [...displaySets, ...study.displaySets];
    }, [])
    .filter(displaySet => isHighPriorityModality(displaySet.Modality));
  imageSets.sort((a, b) => {
    const aSeriesDate =
      a.SeriesDate || a.ContentDate || a.AcquisitionDate || '99999999';
    const bSeriesDate =
      b.SeriesDate || b.ContentDate || b.AcquisitionDate || '99999999';
    return (
      transformStudyDateToDate(aSeriesDate) -
      transformStudyDateToDate(bSeriesDate)
    );
  });
  return imageSets;
}

export default getImageSetsFromStudies;
