import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { MergeForm } from '../forms/MergeForm';
import TOOL_NAMES from '../../../tools/constants/toolNames';
import * as commands from '../../../tools/commands';

export function MergeCompute({
  createPainters,
  popActivePainters,
  ROIContours,
  onROISelect,
  onROIDelete,
  onCommand,
  onFinished,
}) {
  const [activePainters, setActivePainters] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const isSubmitting = useRef(false);
  const isDeleting = useRef(false);

  const onFormCreate = () => {
    onCommand('setToolActive', { toolName: TOOL_NAMES.MERGE_TOOL });
    const painters = createPainters('merge');
    setActivePainters(painters);
  };

  const onFormSelectChange = ({ subjectROIContour, objectROIContour }) => {
    if (!subjectROIContour || !objectROIContour) return;
    commands.refreshViewport();
    for (let i = 0; i < activePainters.length; i++) {
      activePainters[i].update({
        subjectROINumber: subjectROIContour.ROINumber,
        objectROINumber: objectROIContour.ROINumber,
      });
    }
  };

  const onFormSubmit = ({ subjectROIContour, objectROIContour }) => {
    try {
      if (!subjectROIContour) {
        throw new Error('Please select a subject ROI Contour');
      }
      if (!objectROIContour) {
        throw new Error('Please select an object ROI Contour');
      }
      onROISelect(objectROIContour);
      for (let i = 0; i < activePainters.length; i++) {
        activePainters[i].commit();
      }
      commands.refreshViewport();
      isSubmitting.current = true;
      onROIDelete(subjectROIContour);
      onFinished();
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const onFormDelete = () => {
    isDeleting.current = true;
    popActivePainters();
    onFinished();
  };

  useEffect(() => {
    onFormCreate();
    return () => {
      if (!isSubmitting.current && !isDeleting.current) popActivePainters();
    };
  }, []);

  return (
    <MergeForm
      ROIContours={ROIContours}
      onSelectChange={onFormSelectChange}
      onSubmit={onFormSubmit}
      onDelete={onFormDelete}
      errorMessage={errorMessage}
    />
  );
}
MergeCompute.propTypes = {
  createPainters: PropTypes.func,
  popActivePainters: PropTypes.func,
  ROIContours: PropTypes.arrayOf(
    PropTypes.shape({ ROINumber: PropTypes.number })
  ),
  onROISelect: PropTypes.func,
  onROIDelete: PropTypes.func,
  onCommand: PropTypes.func,
  onFinished: PropTypes.func,
};
