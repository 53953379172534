import cornerstoneTools from 'cornerstone-tools';
import * as dcmjs from 'dcmjs';
import _ from 'lodash';
import moment from 'moment';
import { math, transformPointsToPhysicalById } from '../dicom-measurement/src';
const { calibrate } = math;

export const meta = {
  MediaStorageSOPClassUID: '1.2.840.10008.5.1.4.1.1.481.3',
  TransferSyntaxUID: '1.2.840.10008.1.2',
};

export function getRTStructDataset(study, rtss, axialImageSets, config) {
  const now = moment();
  const date = now.format('YYYYMMDD');
  const time = now.format('HHmmss.000000');
  const utcOffset = now.utcOffset();
  const tzoffsetSign = utcOffset >= 0 ? '+' : '-';
  const tzoffsetMinute = (100 + (Math.abs(utcOffset) % 60)).toString().slice(1);
  const tzoffsetHour = (100 + Math.floor(Math.abs(utcOffset) / 60))
    .toString()
    .slice(1);

  const dataset = {
    PatientName: study.PatientName,
    PatientID: study.PatientID,
    PatientBirthDate: study.PatientBirthDate,
    PatientBirthTime: study.PatientBirthTime,
    PatientSex: study.PatientSex,
    StudyDate: study.StudyDate,
    StudyTime: study.StudyTime,
    AccessionNumber: study.AccessionNumber,
    StudyDescription: study.StudyDescription,
    StudyInstanceUID: study.StudyInstanceUID,
    SeriesInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
    SeriesDescription: rtss.SeriesDescription,
    SeriesDate: date,
    SeriesTime: time,
    Modality: 'RTSTRUCT',
    SpecificCharacterSet: 'ISO_IR 192',
    SOPClassUID: '1.2.840.10008.5.1.4.1.1.481.3',
    SOPInstanceUID: dcmjs.data.DicomMetaDictionary.uid(),
    ContentCreatorName: rtss.ContentCreatorName,
    Manufacturer: 'Vysioneer',
    StructureSetName: rtss.StructureSetName,
    StructureSetLabel: rtss.StructureSetLabel,
    StructureSetDescription: rtss.SeriesDescription,
    StructureSetDate: date,
    StructureSetTime: time,
    TimezoneOffsetFromUTC: `${tzoffsetSign}${tzoffsetHour}${tzoffsetMinute}`,
    ReferencedFrameOfReferenceSequence: rtss.referencedFrameOfReferenceSequence,
  };

  const imageIdMap = axialImageSets
    .reduce((images, imageSet) => [...images, ...imageSet.images], [])
    .reduce((imageIdMap, image) => {
      const imageId = image.getImageId();
      const sopInstanceUID = image.getSOPInstanceUID();
      imageIdMap[sopInstanceUID] = imageId;
      return imageIdMap;
    }, {});

  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const imageIds = rtss.referencedSeriesSequence
    .reduce((acc, seriesSequence) => {
      return [...acc, ...seriesSequence.ReferencedInstanceSequence];
    }, [])
    .reduce((result, referencedInstance) => {
      const imageId = imageIdMap[referencedInstance.ReferencedSOPInstanceUID];
      if (!imageId) return result;
      const { data } = editModule.getters.toolState(imageId);
      const uid = rtss.SeriesInstanceUID;
      return {
        ...result,
        [imageId]: {
          referencedInstance,
          data: _.chain(data)
            .filter(d => d.structureSetSeriesInstanceUid === uid)
            .groupBy(d => d.ROINumber)
            .value(),
        },
      };
    }, {});

  const ROIIdx = rtss.ROIContours.reduce((ROIIdx, ROIContour, idx) => {
    ROIIdx[ROIContour.ROINumber] = idx;
    return ROIIdx;
  }, {});

  dataset.StructureSetROISequence = rtss.ROIContours.map(roi => ({
    ROINumber: ROIIdx[roi.ROINumber],
    ReferencedFrameOfReferenceUID: study.FrameOfReferenceUID,
    ROIName: roi.ROIName,
    ROIGenerationAlgorithm: roi.ROIGenerationAlgorithm,
  }));

  dataset.ROIContourSequence = _.chain(rtss.ROIContours)
    .map(roi => {
      const ContourSequence = _.flatMap(
        imageIds,
        ({ referencedInstance: instance, data }, imageId) => {
          data = data[roi.ROINumber] || [];
          return _.chain(data)
            .map(({ handles }) => {
              const { points } = handles;
              if (!points.length) return null;
              const _points = config.contour_calibration
                ? calibrate(points, { x: -0.5, y: -0.5 })
                : points;
              const physicalPoints = transformPointsToPhysicalById(
                _points,
                imageId
              );
              const _data = physicalPoints.reduce((acc, e) => {
                return acc.concat([e.x, e.y, e.z].map(x => _.round(x, 3)));
              }, []);
              return {
                ContourImageSequence: {
                  ReferencedSOPClassUID: instance.ReferencedSOPClassUID,
                  ReferencedSOPInstanceUID: instance.ReferencedSOPInstanceUID,
                },
                ContourGeometricType: 'CLOSED_PLANAR',
                NumberOfContourPoints: physicalPoints.length,
                ContourData: _data,
              };
            })
            .compact()
            .value();
        }
      );
      return {
        ContourSequence,
        ReferencedROINumber: ROIIdx[roi.ROINumber],
        ROIDisplayColor: roi.colorArray,
      };
    })
    .compact()
    .value();

  dataset.RTROIObservationsSequence = rtss.ROIContours.map(roi => ({
    ObservationNumber: ROIIdx[roi.ROINumber],
    ReferencedROINumber: ROIIdx[roi.ROINumber],
    ROIObservationLabel: roi.RTROIObservations.ROIObservationLabel,
    RTROIInterpretedType: roi.RTROIObservations.RTROIInterpretedType,
    ROIInterpreter: roi.RTROIObservations.ROIInterpreter,
  }));

  return dataset;
}
