import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { booleanOperation } from './booleanOperation';
import { math } from '../../modules/dicom-measurement/src';
const { getDistance, pointInPolygon } = math;

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');

export function repulserPainter(
  toolState,
  structureSetSeriesInstanceUid,
  config
) {
  const module = cornerstoneTools.getModule('rtstruct-edit');
  let ROINumber = module.getters.selectedROINumber();
  const { colorArray } = module.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );

  const resolution = 0.25;
  let state = _.cloneDeep(toolState);
  let isStarted = false;
  const physicalRadius = config.radius;

  return {
    getState: function() {
      return state;
    },
    commit: function() {},
    update: function(evt) {
      const { image, element } = evt.detail;
      const { columnPixelSpacing } = image;
      const pixelRadius = physicalRadius / columnPixelSpacing;
      const canvasRadius = getDistance(
        cornerstone.pixelToCanvas(element, { x: pixelRadius, y: 0 }),
        cornerstone.pixelToCanvas(element, { x: 0, y: 0 })
      );
      const canvasCenter = evt.detail.currentPoints.canvas;

      if (!isStarted && _.every(state.data, d => d.ROINumber !== ROINumber)) {
        state.data.push({
          ROINumber,
          structureSetSeriesInstanceUid,
          handles: {
            points: arc(0, Math.PI * 2, canvasRadius, canvasCenter).map(p =>
              cornerstone.canvasToPixel(element, p)
            ),
          },
        });
      } else {
        const center = canvasCenter;
        if (evt.detail.lastPoints) {
          const last = evt.detail.lastPoints.canvas;
          const d = distance(center, last);
          const n = Math.ceil((d / canvasRadius) * 1.5);
          const displacement = { x: center.x - last.x, y: center.y - last.y };
          const unitDisplacement = scale(displacement, 1 / n);
          for (let idx = 1; idx < n; idx++) {
            state.data = getRepulsedData(
              add(last, scale(unitDisplacement, idx)),
              canvasRadius,
              state.data,
              ROINumber,
              resolution,
              element
            );
          }
        }

        state.data = getRepulsedData(
          canvasCenter,
          canvasRadius,
          state.data,
          ROINumber,
          resolution,
          element
        );
      }

      isStarted = true;
      return true;
    },
    cursor: function(evt, context, cursorCanvasPosition, isDrawing) {
      if (!isDrawing) return;
      if (!cursorCanvasPosition) return false;
      const { element, image } = evt.detail;
      const { columnPixelSpacing } = image;
      const pixelRadius = physicalRadius / columnPixelSpacing;
      const canvasRadius = getDistance(
        cornerstone.pixelToCanvas(element, { x: pixelRadius, y: 0 }),
        cornerstone.pixelToCanvas(element, { x: 0, y: 0 })
      );
      draw(context, context => {
        const { x, y } = cursorCanvasPosition;
        context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
        context.beginPath();
        context.arc(x, y, canvasRadius, 0, Math.PI * 2);
        context.stroke();
      });
      return true;
    },
  };
}

function getRepulsedData(
  canvasCenter,
  canvasRadius,
  data,
  ROINumber,
  resolution,
  element
) {
  let result = data.reduce((data, d) => {
    if (d.ROINumber === ROINumber) {
      const groupsOfPoints = repulse(
        canvasCenter,
        canvasRadius,
        d.handles.points.map(p => cornerstone.pixelToCanvas(element, p)),
        resolution
      );
      for (const points of groupsOfPoints) {
        data.push({
          ...d,
          handles: {
            points: points.map(p => cornerstone.canvasToPixel(element, p)),
          },
        });
      }
    } else {
      data.push(d);
    }
    return data;
  }, []);
  return result;
}

export function repulse(center, radius, points, resolution) {
  const { refPoints, pointsRemained } = points.reduce(
    ({ refPoints, pointsRemained }, point, idx, points) => {
      refPoints.push(point);
      pointsRemained.push(distance(point, center) > radius - 0.001);
      let jdx = idx + 1;
      if (jdx >= points.length) jdx = 0;
      const nextPoint = points[jdx];
      const intersections = _circleIntersection(
        point,
        nextPoint,
        center,
        radius,
        resolution
      )
        .filter(x => x.alpha >= 0 && x.alpha <= 1)
        .map(x => x.point);
      const [first, ...rest] = intersections;
      if (first) {
        refPoints.push(first);
        pointsRemained.push(true);
      }
      const last = rest.pop();
      if (last) {
        refPoints.push({
          x: (first.x + last.x) / 2,
          y: (first.y + last.y) / 2,
        });
        pointsRemained.push(false);
        refPoints.push(last);
        pointsRemained.push(true);
      }
      return { refPoints, pointsRemained };
    },
    { refPoints: [], pointsRemained: [] }
  );
  if (_.every(pointsRemained)) {
    return [points];
  } else if (!_.some(pointsRemained)) {
    // eslint-disable-next-line no-console
    console.debug('contour cleared');
    return [];
  } else {
    if (pointsRemained[0]) {
      const firstSegmentEndIdx = pointsRemained.findIndex(x => !x) - 1;
      const firstSegment = refPoints.splice(0, firstSegmentEndIdx + 1);
      refPoints.push(...firstSegment);
      const firstSegmentPointsRemained = pointsRemained.splice(
        0,
        firstSegmentEndIdx + 1
      );
      pointsRemained.push(...firstSegmentPointsRemained);
    } else if (!pointsRemained[pointsRemained.length - 1]) {
      const lastOpeningStartIdx = _.findLastIndex(pointsRemained) + 1;
      const lastOpeningLength = pointsRemained.length - lastOpeningStartIdx;
      const lastOpening = refPoints.splice(
        lastOpeningStartIdx,
        lastOpeningLength
      );
      refPoints.unshift(...lastOpening);
      const lastOpeningPointsRemained = pointsRemained.splice(
        lastOpeningStartIdx,
        lastOpeningLength
      );
      pointsRemained.unshift(...lastOpeningPointsRemained);
    }
    const openingPairs = [];
    for (let idx = 0; idx < pointsRemained.length; idx++) {
      if (pointsRemained[idx]) continue;
      let jdx = idx;
      while (jdx + 1 < pointsRemained.length && !pointsRemained[jdx + 1]) {
        jdx++;
      }
      const openingStart = idx;
      const openingEnd = jdx;
      while (jdx + 1 < pointsRemained.length && pointsRemained[jdx + 1]) {
        jdx++;
      }
      const followingSegmentEnd = jdx;
      openingPairs.push({ openingStart, openingEnd, followingSegmentEnd });
      idx = jdx - 1;
    }
    const arcSegments = [];
    const modifiedPoints = openingPairs.reduce(
      (result, openingPair) => {
        const { openingStart, openingEnd, followingSegmentEnd } = openingPair;
        const arcSegment = closeWithArc(
          refPoints.slice(openingStart, openingEnd + 1),
          refPoints[(openingStart || pointsRemained.length) - 1],
          refPoints[openingEnd + 1],
          center,
          radius
        );
        const arcPoints = arcSegment.points;
        arcSegments.push({ ...arcSegment, openingStart, openingEnd });
        for (const arcPoint of arcPoints) {
          result.points.push(arcPoint);
        }
        result.points.push(
          ...refPoints.slice(openingEnd + 1, followingSegmentEnd + 1)
        );
        return result;
      },
      { points: [], idx: 0 }
    ).points;
    const isSpecialCase = checkSpecialCase(arcSegments);
    if (isSpecialCase) {
      const circlePoints = arc(0, Math.PI * 2, radius, center);
      const circlePolygon = circlePoints.map(v => [v.x, v.y]);
      const modifiedPolygon = modifiedPoints.map(v => [v.x, v.y]);
      const method = pointInPolygon(modifiedPolygon, [center.x, center.y])
        ? 'union'
        : 'subtract';
      const booleanPolygons = booleanOperation(
        method,
        [[modifiedPolygon]],
        [[circlePolygon]]
      );
      const groupsOfPoints = [];
      for (let i = 0; i < booleanPolygons.length; i++) {
        const booleanPoints = booleanPolygons[i][0].map(v => ({
          x: v[0],
          y: v[1],
        }));
        groupsOfPoints.push(booleanPoints);
      }
      return groupsOfPoints;
    } else {
      return [modifiedPoints];
    }
  }
}

function closeWithArc(openingPoints, from, to, center, radius) {
  const fromAngle = relativeDirectionAngle(center, from);
  const toAngle = relativeDirectionAngle(center, to);

  const midOpenPoint = scale(
    add(
      scale(add(from, to), 0.5),
      openingPoints[Math.floor(openingPoints.length / 2)]
    ),
    0.5
  );
  const rawAngleDiff = (toAngle - fromAngle + 2 * Math.PI) % (2 * Math.PI);
  const angleDiff = _.minBy(
    [rawAngleDiff, -(2 * Math.PI - rawAngleDiff)],
    angle => {
      const midPoint = {
        x: center.x + radius * Math.cos(angle / 2 + fromAngle),
        y: center.y + radius * Math.sin(angle / 2 + fromAngle),
      };
      return distance(midOpenPoint, midPoint);
    }
  );

  return {
    points: arc(fromAngle, angleDiff, radius, center),
    fromAngle: fromAngle,
    toAngle: toAngle,
    rawAngleDiff: rawAngleDiff,
    angleDiff: angleDiff,
  };
}

function arc(fromAngle, angleDiff, radius, center) {
  let number = Math.ceil(
    Math.min(
      (180 * Math.abs(angleDiff)) / Math.PI / 5,
      (radius * 2 * Math.abs(angleDiff)) / 0.5
    )
  );
  let deltaAngle;
  if (number === 0) {
    number = 1;
    deltaAngle = 0;
  } else {
    deltaAngle = angleDiff / number;
  }

  return _.range(1, number).map(idx => {
    const angle = fromAngle + deltaAngle * idx;
    const p = {
      x: center.x + radius * Math.cos(angle),
      y: center.y + radius * Math.sin(angle),
    };

    return p;
  });
}

function distance(p1, p2) {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

function add(v1, v2) {
  return { x: v1.x + v2.x, y: v1.y + v2.y };
}

function scale(vec, factor) {
  return { x: vec.x * factor, y: vec.y * factor };
}

function relativeDirectionAngle(center, point) {
  const yDiff = point.y - center.y;
  const xDiff = point.x - center.x;

  let result = Math.atan2(yDiff, xDiff);
  if (result < 0) result += 2 * Math.PI;
  return result;
}

function _circleIntersection(p1, p2, center, radius, resolution) {
  if (
    distance(p1, center) <= 0.001 ||
    distance(p2, center) <= 0.001 ||
    distance(p1, p2) <= 0.001
  ) {
    return [];
  }

  const a = p1.x - p2.x;
  const b = p2.x - center.x;
  const c = p1.y - p2.y;
  const d = p2.y - center.y;

  const alpha = [
    (-(a * b + c * d) +
      ((a * b + c * d) ** 2 -
        (a ** 2 + c ** 2) * (b ** 2 + d ** 2 - radius ** 2)) **
        0.5) /
      (a ** 2 + c ** 2),
    (-(a * b + c * d) -
      ((a * b + c * d) ** 2 -
        (a ** 2 + c ** 2) * (b ** 2 + d ** 2 - radius ** 2)) **
        0.5) /
      (a ** 2 + c ** 2),
  ];

  return alpha
    .filter(x => !isNaN(x) && Math.abs(x) >= 0 && Math.abs(x) <= 1)
    .map(alpha => {
      return {
        alpha,
        point: {
          x: alpha * p1.x + (1 - alpha) * p2.x,
          y: alpha * p1.y + (1 - alpha) * p2.y,
        },
      };
    });
}

function checkSpecialCase(arcSegments) {
  const validArcSegments = arcSegments.filter(
    s => s.openingStart !== s.openingEnd
  );
  if (validArcSegments.length < 2) return false;
  // check arc overlapping
  for (let i = 0; i < validArcSegments.length; i++) {
    for (let j = i + 1; j < validArcSegments.length; j++) {
      const segA = validArcSegments[i];
      const segB = validArcSegments[j];
      let segAStart = Math.min(segA.fromAngle, segA.fromAngle + segA.angleDiff);
      let segAEnd = Math.max(segA.fromAngle, segA.fromAngle + segA.angleDiff);
      if (segAEnd > Math.PI * 2) {
        segAStart = segAStart - Math.PI * 2;
        segAEnd = segAEnd - Math.PI * 2;
      }
      let segBStart = Math.min(segB.fromAngle, segB.fromAngle + segB.angleDiff);
      let segBEnd = Math.max(segB.fromAngle, segB.fromAngle + segB.angleDiff);
      if (segBEnd > Math.PI * 2) {
        segBStart = segBStart - Math.PI * 2;
        segBEnd = segBEnd - Math.PI * 2;
      }
      if (Math.max(segAStart, segBStart) < Math.min(segAEnd, segBEnd)) {
        return true;
      }
    }
  }
  return false;
}
