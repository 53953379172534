import {
  polygonArea,
  polygonAxis,
  getWeightedCentroid,
  getDistance3D,
} from '../utils/math';
import transformPointsToPhysicalById from '../utils/transformPointsToPhysicalById';

function getMeasurementFromContour(imagePoints, imageId, imagePlane) {
  const { rowPixelSpacing, columnPixelSpacing } = imagePlane;
  /** axes */
  const { longAxis, shortAxis } = polygonAxis(imagePoints);
  const longAxisPoints = transformPointsToPhysicalById(
    longAxis.points,
    imageId
  );
  const shortAxisPoints = transformPointsToPhysicalById(
    shortAxis.points,
    imageId
  );
  let longAxisValue = 0,
    shortAxisValue = 0;
  if (longAxisPoints.length >= 2 && shortAxisPoints.length >= 2) {
    longAxisValue = getDistance3D(longAxisPoints[0], longAxisPoints[1]);
    shortAxisValue = getDistance3D(shortAxisPoints[0], shortAxisPoints[1]);
  }
  /** area */
  const area = polygonArea(imagePoints) * rowPixelSpacing * columnPixelSpacing;
  /** centroid */
  const centroid = getWeightedCentroid(imagePoints);
  return {
    area,
    centroid,
    longAxis: { points: longAxis.points, value: longAxisValue },
    shortAxis: { points: shortAxis.points, value: shortAxisValue },
    product: longAxisValue * shortAxisValue,
  };
}

export default getMeasurementFromContour;
