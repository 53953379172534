/**
 * @param {Array} vertices
 * @returns {Array} vertices of the smoothened polygon
 */
export default function polygonSmooth(vertices) {
  const output = [];
  for (var i = 0; i < vertices.length - 1; i++) {
    const v1 = vertices[i];
    const v2 = vertices[i + 1];

    var Q = { x: 0.75 * v1.x + 0.25 * v2.x, y: 0.75 * v1.y + 0.25 * v2.y };
    var R = { x: 0.25 * v1.x + 0.75 * v2.x, y: 0.25 * v1.y + 0.75 * v2.y };
    output.push(Q);
    output.push(R);
  }
  return output;
}

export function downsize(vertices, scale) {
  const _vertices = [];
  for (let i = 0; i < vertices.length; i += scale) {
    _vertices.push(vertices[i]);
  }
  return _vertices;
}
