import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Select, P, Alert } from '@platform/ui';

export const MergeForm = ({
  ROIContours,
  onSelectChange,
  onSubmit,
  onDelete,
  errorMessage = null,
}) => {
  const [subjectROIContour, setSubjectROIContour] = useState('');
  const [objectROIContour, setObjectROIContour] = useState('');

  return (
    <div
      style={{
        minHeight: '100%',
        display: 'grid',
        gridTemplateRows: '1fr auto',
      }}
    >
      <div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>
            Merge (Subject ROI Contour):
          </P>
          <Select
            defaultValue={-1}
            onChange={evt => {
              const subjectROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setSubjectROIContour(subjectROIContour);
              onSelectChange({
                subjectROIContour,
                objectROIContour,
              });
            }}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option value={-1}>------</option>
            {ROIContours.filter(
              c => c.ROINumber !== objectROIContour?.ROINumber
            ).map(c => (
              <option key={c.ROINumber} value={c.ROINumber}>
                {c.ROIName}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          <P style={{ color: 'var(--default-color)' }}>
            Into (Object ROI Contour):
          </P>
          <Select
            defaultValue={-1}
            onChange={evt => {
              const objectROIContour = ROIContours.find(
                c => c.ROINumber === Number(evt.target.value)
              );
              setObjectROIContour(objectROIContour);
              onSelectChange({
                subjectROIContour,
                objectROIContour,
              });
            }}
            size="small"
            variant="outlined"
            fullWidth
          >
            <option value={-1}>------</option>
            {ROIContours.filter(
              c => c.ROINumber !== subjectROIContour?.ROINumber
            ).map(c => (
              <option key={c.ROINumber} value={c.ROINumber}>
                {c.ROIName}
              </option>
            ))}
          </Select>
        </div>
        <div style={{ margin: '10px 0 0' }}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </div>
      </div>
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              onClick={() => onDelete()}
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => onSubmit({ subjectROIContour, objectROIContour })}
              color="secondary"
              variant="contained"
              medium
              fullWidth
            >
              Merge
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
MergeForm.propTypes = {
  ROIContours: PropTypes.array,
  onSelectChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
};
