import cornerstone from 'cornerstone-core';
import { vec3 } from 'gl-matrix';

import { utils } from '@platform/core';
import { mprState, getMprImageId } from './mprImageLoader.js';

const { StackManager, studyMetadataManager } = utils;

export default async function(viewportSpecificData, activeViewportIndex) {
  const axialViewportData = viewportSpecificData[activeViewportIndex];
  const { StudyInstanceUID, SeriesInstanceUID, StackID } = axialViewportData;
  const study = studyMetadataManager.get(StudyInstanceUID);
  const displaySet = study
    .getDisplaySets()
    .find(displaySet =>
      StackID
        ? displaySet.StackID === StackID
        : displaySet.SeriesInstanceUID === SeriesInstanceUID
    );
  const _SeriesInstanceUID = StackID
    ? SeriesInstanceUID + StackID
    : SeriesInstanceUID;

  /** find image ids and add to mpr state */
  const imageIds = displaySet.images.map(image => image.getImageId());
  mprState.series[_SeriesInstanceUID] = imageIds;

  /** find iop(orientation), ipp(origin) of the first image */
  const {
    imagePositionPatient,
    imageOrientationPatient,
    rows,
    rowPixelSpacing,
    columns,
    columnPixelSpacing,
  } = cornerstone.metaData.get('imagePlaneModule', imageIds[0]);

  /** config */
  const stepSize = 3;
  const sagittalRange =
    Math.floor((0.8 * (rows * rowPixelSpacing)) / (stepSize * 2)) || 35;
  const coronalRange =
    Math.floor((0.8 * (columns * columnPixelSpacing)) / (stepSize * 2)) || 35;

  /** create mpr stacks for sagittal and coronal view */
  const { studyInstanceUID } = study;
  const sagittalImageIds = await loadAndGetMprImageIds(
    _SeriesInstanceUID,
    imageOrientationPatient,
    imagePositionPatient,
    [0, 1, 0, 0, 0, -1],
    sagittalRange,
    stepSize
  );
  createMprStack(
    studyInstanceUID,
    _SeriesInstanceUID,
    sagittalImageIds,
    'sagittal'
  );

  const coronalImageIds = await loadAndGetMprImageIds(
    _SeriesInstanceUID,
    imageOrientationPatient,
    imagePositionPatient,
    [1, 0, 0, 0, 0, -1],
    coronalRange,
    stepSize
  );
  createMprStack(
    studyInstanceUID,
    _SeriesInstanceUID,
    coronalImageIds,
    'coronal'
  );

  /** set up data of viewports */
  const viewportData = {
    ContentDate: displaySet.ContentDate,
    InstanceNumber: displaySet.InstanceNumber,
    Modality: displaySet.Modality,
    StudyInstanceUID: displaySet.StudyInstanceUID,
    SeriesInstanceUID: _SeriesInstanceUID,
    SeriesDescription: displaySet.SeriesDescription,
    SeriesNumber: displaySet.SeriesNumber,
    SeriesDate: displaySet.SeriesDate,
    SeriesTime: displaySet.SeriesTime,
    SOPInstanceUID: '',
    frameIndex: 0,
    frameRate: undefined,
    isMultiFrame: false,
    isReconstructable: false,
    isSOPClassUIDSupported: false,
    numImageFrames: 0,
    plugin: 'cornerstone-vtk',
    reconstructionIssues: [],
    sopClassUIDs: ['1.2.840.10008.5.1.4.1.1.4'],
  };
  return {
    0: {
      ...viewportData,
      displaySetInstanceUID: _SeriesInstanceUID + ':mpr:coronal',
      viewType: 'coronal',
      imageIds: coronalImageIds,
      isMpr: true,
    },
    1: axialViewportData,
    2: {
      ...viewportData,
      displaySetInstanceUID: _SeriesInstanceUID + ':mpr:sagittal',
      viewType: 'sagittal',
      imageIds: sagittalImageIds,
      isMpr: true,
    },
  };
}

async function loadAndGetMprImageIds(
  SeriesInstanceUID,
  orientation,
  origin,
  rotation,
  range,
  stepSize
) {
  const orientationAsString = orientation.join();
  const originAsString = origin.join();
  const rotationAsString = rotation.join();
  const rowCosines = vec3.fromValues(rotation[0], rotation[1], rotation[2]);
  const colCosines = vec3.fromValues(rotation[3], rotation[4], rotation[5]);
  let zedCosines = vec3.create();
  vec3.cross(zedCosines, colCosines, rowCosines);

  /** create vtk volume and get center image for reference */
  const centerImageId = getMprImageId(
    SeriesInstanceUID,
    '1,0,0,0,1,0',
    '0,0,0',
    rotationAsString,
    'center'
  );
  const centerImage = await cornerstone.loadAndCacheImage(centerImageId);
  const centerImagePlane = cornerstone.metaData.get(
    'imagePlaneModule',
    centerImage.imageId
  );

  /** create image ids for mpr stack */
  const imageIds = [];
  for (let i = -range; i < range; i++) {
    let position = [...centerImagePlane.centerPositionPatient]; //top, left
    const dx = zedCosines[0] * stepSize * i;
    const dy = zedCosines[1] * stepSize * i;
    const dz = zedCosines[2] * stepSize * i;
    position[0] += dx;
    position[1] += dy;
    position[2] += dz;
    const positionAsString = new Float32Array(position).join();

    const imageId = getMprImageId(
      SeriesInstanceUID,
      orientationAsString,
      originAsString,
      rotationAsString,
      positionAsString
    );
    imageIds.push(imageId);
    await cornerstone.loadAndCacheImage(imageId);
  }
  return imageIds;
}

function createMprStack(
  StudyInstanceUID,
  SeriesInstanceUID,
  imageIds,
  viewType
) {
  const stack = StackManager.findOrCreateStack(
    { StudyInstanceUID },
    {
      displaySetInstanceUID: SeriesInstanceUID + `:mpr:${viewType}`,
      images: imageIds.map(id => ({ _imageId: id, getImageId: () => id })),
      isMpr: true,
    }
  );
  return stack;
}
