import React from 'react';
import PropTypes from 'prop-types';

import './TextInput.css';

function TextInput(props) {
  return (
    <div className="input-ohif-container">
      {props.label && (
        <label className="input-ohif-label" htmlFor={props.id}>
          {props.label}
        </label>
      )}
      <input
        type={props.type}
        id={props.id}
        className="form-control input-ohif"
        {...props}
      />
    </div>
  );
}
TextInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
};
TextInput.defaultProps = {
  value: '',
  id: `TextInput-${new Date().toTimeString()}`,
  label: undefined,
  type: 'text',
};

export { TextInput };
