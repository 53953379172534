import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import _ from 'lodash';

import { store } from '@platform/viewer';
import { dummyPainter } from '../painters/dummyPainter';
import { edgePainter } from '../painters/edgePainter';
import { polygonPainter } from '../painters/polygonPainter';
import { deletePainter } from '../painters/deletePainter';
import { sphereThresholdPainter } from '../painters/sphereThresholdPainter';
import { regionGrowingPainter } from '../painters/regionGrowingPainter';
import { repulserPainter } from '../painters/repulserPainter';
import { copyPainter } from '../painters/copyPainter';
import { booleanPainter } from '../painters/booleanPainter';
import { mergePainter } from '../painters/mergePainter';
import {
  interpolationPainter,
  findFirstValidImageIdIdx,
  createReferenceToolState,
} from '../painters/interpolationPainter';
import { expansionPainter } from '../painters/expansionPainter';
import { pointPainter } from '../painters/pointPainter';
import { boundingBoxPainter } from '../painters/boundingBoxPainter';
import {
  math,
  getMeasurementOfSlice,
} from '../../modules/dicom-measurement/src';
import { getLogStudy } from '../../hooks/useLogStudy';
import * as commands from '../../tools/commands';

const { crossProduct, innerProduct } = math;
const Painter = {
  dummy: dummyPainter,
  /** freehand segmentation */
  edge: edgePainter,
  polygon: polygonPainter,
  delete: deletePainter,
  /** modification */
  repulser: repulserPainter,
  /** image segmentation */
  'sphere-threshold': sphereThresholdPainter,
  'region-growing': regionGrowingPainter,
  /** computation */
  copy: copyPainter,
  merge: mergePainter,
  'boolean-operation': booleanPainter,
  interpolation: interpolationPainter,
  expansion: expansionPainter,
  point: pointPainter,
  bbox: boundingBoxPainter,
};

const defaultState = {
  /** mode */
  mode: 'default',
  /** multi-view */
  isEditable: true,
  scrollingImageId: null,
  scrollingImageConfig: {},
  /** painters */
  painters: {},
  stashedPainters: {},
  painterConfigs: {
    repulser: { radius: 2.5 },
    'region-growing': { radius: 1.5, maxRadius: null, sigma: 1.5 },
    'sphere-threshold': { type: 'binary' },
    point: { label: 1 },
  },
  displayConfigs: {
    isInfoRendering: false,
    isSelectedInfoRendering: true,
    isAxesRendering: false,
    isContoursFilling: false,
    isInterViewRefRendering: true,
  },
  /** raw data */
  rawStructureSets: [],
  /** dicom data */
  structureSet: {},
  baseStructureSets: [],
  selectedStructureSetUID: null,
  selectedROIStructureSetUID: null,
  selectedROINumber: null,
  highlights: [],
  criteria: {},
  assessments: [],
};

function getModule() {
  const target = new EventTarget();
  return Object.assign(target, { getters: {}, setters: {} });
}

export default function(servicesManager) {
  let state = _.cloneDeep(defaultState);
  const module = getModule();

  /** log */
  let prevLog = {};
  const checkSameLog = log => {
    const eventType = prevLog?.event_type;
    switch (eventType) {
      case 'Viewer:contours': {
        if (!log?.details?.roi?.name) return true;
        const isSame =
          prevLog?.event_type === log?.event_type &&
          prevLog?.message === log?.message &&
          prevLog?.details?.structure_set_series_uid ===
            log?.details?.structure_set_series_uid &&
          prevLog?.details?.roi.name === log?.details?.roi.name &&
          prevLog?.details?.tool === log?.details?.tool;
        prevLog = log;
        return isSame;
      }
      default: {
        prevLog = log;
        return false;
      }
    }
  };
  const { LoggerService: logger } = servicesManager.services;
  const logInfo = newLog => {
    if (!checkSameLog(newLog)) {
      const { details } = newLog;
      const { structure_set_series_uid } = details;
      const state = store?.getState && store.getState();
      const logStudy = getLogStudy(state, structure_set_series_uid);
      logger.info({ ...newLog, details: { ...details, ...logStudy } });
    }
  };

  const mixin = {
    getters: {
      mode: () => state.mode,
      rawStructureSet: SeriesInstanceUID => {
        return state.rawStructureSets.find(
          structureSet => structureSet.SeriesInstanceUID === SeriesInstanceUID
        );
      },
      isEditable: () => state.isEditable,
      scrollingImageId: () => state.scrollingImageId,
      scrollingImageConfig: () => state.scrollingImageConfig,
      painterConfig: mode => state.painterConfigs[mode] || {},
      displayConfig: key => state.displayConfigs[key],
      criteria: () => _.cloneDeep(state.criteria),
      criterion: key => state.criteria[key],
      assessments: () => _.cloneDeep(state.assessments),
      peekPainter: function(imageId) {
        let painterStack = state.painters[imageId];
        if (painterStack) return painterStack[painterStack.length - 1];
        return null;
      },
      ROIContour: function(structureSetSeriesInstanceUid, ROINumber) {
        if (!structureSetSeriesInstanceUid) return {};
        return state.structureSet[
          structureSetSeriesInstanceUid
        ].ROIContours.find(r => r.ROINumber === ROINumber);
      },
      structureSets: function() {
        return Object.values(state.structureSet);
      },
      structureSet: function(structureSetSeriesInstanceUid) {
        return state.structureSet[structureSetSeriesInstanceUid];
      },
      baseStructureSets: function() {
        return state.baseStructureSets;
      },
      toolState: function(imageId) {
        const painter = this.peekPainter(imageId);
        if (painter) return painter.getState();
        return null;
      },
      selectedStructureSetUID: function() {
        return state.selectedStructureSetUID;
      },
      selectedROIContour: function() {
        const isValidNumber =
          !!state.selectedROINumber || state.selectedROINumber === 0;
        const isReviewing =
          state.selectedStructureSetUID === state.selectedROIStructureSetUID;
        if (!isValidNumber || !isReviewing) return {};
        const uid = state.selectedROIStructureSetUID;
        const number = state.selectedROINumber;
        const { ROIContours } = state.structureSet[uid];
        const roi = ROIContours.find(roi => roi.ROINumber === number);
        return roi;
      },
      selectedROINumber: function() {
        const isReviewing =
          state.selectedStructureSetUID === state.selectedROIStructureSetUID;
        if (!isReviewing) return null;
        return state.selectedROINumber;
      },
      highlights: function() {
        return state.highlights;
      },
    },
    setters: {
      mode: mode => {
        state.mode = mode;
        commands.refreshViewport();
      },
      rawStructureSet: structureSetData => {
        state.rawStructureSets.push(structureSetData);
      },
      isEditable: isEditable => {
        state.isEditable = isEditable;
      },
      scrollingImageId: function(imageId) {
        if (!imageId) {
          state.scrollingImageId = '';
          state.scrollingImageConfig = {};
          return;
        }
        state.scrollingImageId = imageId;
        const {
          imagePositionPatient,
          rowCosines,
          columnCosines,
        } = cornerstone.metaData.get('imagePlaneModule', imageId);
        const normalVector = crossProduct(columnCosines, rowCosines);
        state.scrollingImageConfig = {
          normalVector,
          offset: -innerProduct(normalVector, imagePositionPatient),
        };
      },
      interpolateContour: function({ viewports }) {
        const enabledElement = cornerstone.getEnabledElements()[
          viewports.activeViewportIndex
        ];
        if (!enabledElement.image) return;
        const toolState = cornerstoneTools.getToolState(
          enabledElement.element,
          'stack'
        );
        if (!toolState) return;
        const targetImageId = enabledElement.image.imageId;
        const imageIds = toolState.data[0].imageIds;
        const targetImageIdIdx = imageIds.indexOf(targetImageId);
        const indices = imageIds.map((_, index) => index);
        let topSourceImageIdx = findFirstValidImageIdIdx(
          state,
          imageIds,
          indices.slice(0, targetImageIdIdx).reverse()
        );
        let bottomSourceImageIdx = findFirstValidImageIdIdx(
          state,
          imageIds,
          indices.slice(targetImageIdIdx + 1, imageIds.length)
        );
        if (topSourceImageIdx === null) {
          topSourceImageIdx = bottomSourceImageIdx;
        } else if (bottomSourceImageIdx === null) {
          bottomSourceImageIdx = topSourceImageIdx;
        }

        if (topSourceImageIdx != null || bottomSourceImageIdx != null) {
          const topReferenceToolState = createReferenceToolState(
            state,
            imageIds,
            topSourceImageIdx
          );
          const bottomReferenceToolState = createReferenceToolState(
            state,
            imageIds,
            bottomSourceImageIdx
          );
          const originalPainter = _.last(state.painters[targetImageId]);
          const originalData = originalPainter
            ? originalPainter
                .getState()
                .data.filter(x => x.ROINumber != state.selectedROINumber)
            : [];
          const referenceToolState =
            topReferenceToolState || bottomReferenceToolState;
          const painter = mixin.setters.createPainter(
            'interpolation',
            targetImageId,
            referenceToolState.SeriesInstanceUID,
            { ...referenceToolState, data: originalData }
          );
          painter.update({
            topRefToolState: topReferenceToolState,
            bottomRefToolState: bottomReferenceToolState,
            targetImageIdx: targetImageIdIdx,
            topImageIdx: targetImageIdIdx,
            bottomImageIdx: targetImageIdIdx,
          });
          painter.commit();
        }
        for (const enabledElement of cornerstone.getEnabledElements()) {
          if (enabledElement.image) {
            cornerstone.updateImage(enabledElement.element);
          }
        }
      },
      commitPoint: async function({ viewports }) {
        const index = viewports.activeViewportIndex;
        const enabledElement = cornerstone.getEnabledElements()[index];
        if (!enabledElement.image) return;
        const imageId = enabledElement.image.imageId;
        const module = cornerstoneTools.getModule('rtstruct-edit');
        const painter = module.getters.peekPainter(imageId);
        await painter.commit({ detail: { image: enabledElement.image } });
        commands.refreshViewport();
        module.setters.createPainter(
          'point',
          imageId,
          painter._structureSetSeriesInstanceUid
        );
      },
      clearActiveViewportContours({ viewports }) {
        if (!state.selectedROINumber && state.selectedROINumber !== 0) return;
        const enabledElement = cornerstone.getEnabledElements()[
          viewports.activeViewportIndex
        ];
        if (!enabledElement.image) return;
        const toolState = cornerstoneTools.getToolState(
          enabledElement.element,
          'stack'
        );
        if (!toolState) return;
        toolState.data[0].imageIds.forEach(imageId => {
          const previousPainter = _.last(state.painters[imageId]);
          const previousData = previousPainter?.getState().data;
          const targetData = previousData.filter(
            x => x.ROINumber === state.selectedROINumber
          );
          if (previousData?.length === 0 || targetData?.length === 0) return;
          const SeriesInstanceUID = targetData[0].structureSetSeriesInstanceUid;
          const painter = mixin.setters.createPainter(
            'delete',
            imageId,
            SeriesInstanceUID,
            { data: previousData, SeriesInstanceUID }
          );
          painter.update(
            { detail: { currentPoints: { canvas: { x: 0, y: 0 } } } },
            imageId
          );
          const { width, height } = enabledElement.canvas;
          painter.update(
            { detail: { currentPoints: { canvas: { x: width, y: height } } } },
            imageId
          );
          painter.commit({ detail: { element: enabledElement.element } });
        });
        for (const enabledElement of cornerstone.getEnabledElements()) {
          if (enabledElement.image) {
            cornerstone.updateImage(enabledElement.element);
          }
        }
      },
      painterConfig: function(mode, config) {
        state.painterConfigs[mode] = config;
      },
      displayConfig: function(key, config) {
        state.displayConfigs[key] = config;
      },
      criteria: function(criteria) {
        state.criteria = criteria;
      },
      assessments: function(assessments) {
        state.assessments = assessments;
      },
      assessment: function(SeriesInstanceUID, assessment, reason) {
        const idx = state.assessments.findIndex(
          a => a.SeriesInstanceUID === SeriesInstanceUID
        );
        state.assessments.splice(idx, 1, assessment);
      },
      clear: function() {
        state = _.cloneDeep(defaultState);
      },
      popPainter: function(imageId) {
        let painterStack = state.painters[imageId];
        if (painterStack && painterStack.length > 1) {
          let stashedStack = state.stashedPainters[imageId];
          if (!stashedStack) {
            stashedStack = [];
            state.stashedPainters[imageId] = stashedStack;
          }
          const popped = painterStack.pop();
          if (popped) {
            stashedStack.push(popped);

            /** log: undos contours */
            if (
              !popped.structureSetSeriesInstanceUid ||
              !popped.selectedROINumber
            ) {
              return true;
            }
            const s = mixin.getters.structureSet(
              popped.structureSetSeriesInstanceUid
            );
            const roi = mixin.getters.ROIContour(
              popped.structureSetSeriesInstanceUid,
              popped.selectedROINumber
            );
            logInfo({
              event_type: 'Viewer:contours',
              message: 'Edit contours', //'Undo edit contours',
              details: {
                structure_set_study_uid: s.StudyInstanceUID,
                structure_set_series_uid: popped.structureSetSeriesInstanceUid,
                structure_set_instance_uid: s.SOPInstanceUID,
                roi: { name: roi.ROIName },
                tool: popped._mode,
              },
            });
            /** end log */
          }
          return true;
        }
        return false;
      },
      restorePainter: function(imageId) {
        const stashed =
          state.stashedPainters[imageId] &&
          state.stashedPainters[imageId].pop();
        if (stashed) {
          let painterStack = state.painters[imageId];
          painterStack.push(stashed);

          /** log: redos contours */
          if (
            !stashed.structureSetSeriesInstanceUid ||
            !stashed.selectedROINumber
          ) {
            return true;
          }
          const s = mixin.getters.structureSet(
            stashed.structureSetSeriesInstanceUid
          );
          const roi = mixin.getters.ROIContour(
            stashed.structureSetSeriesInstanceUid,
            stashed.selectedROINumber
          );
          logInfo({
            event_type: 'Viewer:contours',
            message: 'Edit contours', //'Redo edit contours',
            details: {
              structure_set_study_uid: s.StudyInstanceUID,
              structure_set_series_uid: stashed.structureSetSeriesInstanceUid,
              structure_set_instance_uid: s.SOPInstanceUID,
              roi: { name: roi.ROIName },
              tool: stashed._mode,
            },
          });
          /** end log */

          return true;
        }
        return false;
      },
      createPainter: function(
        mode,
        imageId,
        structureSetSeriesInstanceUid,
        toolState,
        painterArgs
      ) {
        if (mode !== 'dummy') {
          window.onbeforeunload = function() {
            return 'Data will be lost if you leave the page, are you sure?';
          };
          module.dispatchEvent(
            new CustomEvent('new_painter', {
              detail: { uid: structureSetSeriesInstanceUid },
            })
          );
        }
        const _painter = mixin.getters.peekPainter(imageId);
        toolState = toolState ||
          (_painter && _painter.getState()) || {
            SeriesInstanceUID: structureSetSeriesInstanceUid,
            data: [],
          };
        if (!Painter[mode]) {
          const { UINotificationService: snackbar } = servicesManager.services;
          snackbar.show({
            title: 'DICOM RTSTRUCT',
            message: `Unknown painter ${mode}`,
            type: 'error',
            autoClose: false,
          });
          return _painter;
        }
        const painter = Painter[mode](
          toolState,
          structureSetSeriesInstanceUid,
          mixin.getters.painterConfig(mode),
          painterArgs
        );
        painter._mode = mode;
        painter.imageId = imageId;
        painter.getInfo = function() {
          const previousInfo =
            previousPainter?.getInfo && previousPainter.getInfo();
          const info = this._info || previousInfo || [];
          return info;
        };

        function commitCallback(painter) {
          const ROINumber = mixin.getters.selectedROINumber();
          if (previousPainter._info) {
            // update painter info ( area, long axis, short axis, ppd )
            const previous = previousPainter._info.filter(
              a => ROINumber !== null && a.ROINumber !== ROINumber
            );
            const current = calculateInfo(
              painter,
              structureSetSeriesInstanceUid,
              ROINumber
            );
            painter._info = [...previous, ...current];
          }
        }

        const originalCommit = painter.commit;
        painter.commit = async function() {
          await originalCommit.apply(originalCommit, arguments);
          commitCallback(this, structureSetSeriesInstanceUid);
          painter.structureSetSeriesInstanceUid = structureSetSeriesInstanceUid;
          painter.selectedROINumber = state.selectedROINumber;

          /** log: edits contours */
          const { viewports } = store.getState();
          const { activeViewportIndex, viewportSpecificData } = viewports;
          const data = viewportSpecificData[activeViewportIndex];
          /** define series date */
          const SeriesDate =
            data?.SeriesDate ||
            data?.ContentDate ||
            data?.AcquisitionDate ||
            '';
          painter.timepointDate = SeriesDate;

          if (painter._mode === 'dummy') return;
          const roi = mixin.getters.ROIContour(
            structureSetSeriesInstanceUid,
            state.selectedROINumber
          );
          const subject =
            Number.isInteger(painter.subjectROINumber) &&
            mixin.getters.ROIContour(
              structureSetSeriesInstanceUid,
              painter.subjectROINumber
            );
          const object =
            Number.isInteger(painter.objectROINumber) &&
            mixin.getters.ROIContour(
              structureSetSeriesInstanceUid,
              painter.objectROINumber
            );
          let details;
          switch (painter._mode) {
            case 'merge':
              details = {
                roi: { name: object.ROIName },
                subject_roi: { name: subject.ROIName },
                object_roi: { name: object.ROIName },
              };
              break;
            case 'boolean-operation':
              details = {
                roi: { name: roi.ROIName },
                subject_roi: { name: subject.ROIName },
                object_roi: { name: object.ROIName },
              };
              break;
            case 'expansion':
              details = { roi: { name: roi.ROIName } };
              break;
            default:
              details = { roi: { name: roi?.ROIName } };
          }
          const s = mixin.getters.structureSet(structureSetSeriesInstanceUid);
          logInfo({
            event_type: 'Viewer:contours',
            message: 'Edit contours',
            details: {
              ...details,
              structure_set_study_uid: s.StudyInstanceUID,
              structure_set_series_uid: painter.structureSetSeriesInstanceUid,
              structure_set_instance_uid: s.SOPInstanceUID,
              tool: painter._mode,
            },
          });
          /** end log */
        };
        painter.commitCallback = function() {
          commitCallback(this, structureSetSeriesInstanceUid);
        };
        painter._structureSetSeriesInstanceUid = structureSetSeriesInstanceUid;
        let painterStack = state.painters[imageId];
        if (!painterStack) {
          painterStack = [];
          state.painters[imageId] = painterStack;
        }
        const previousPainter = painterStack[painterStack.length - 1] || {
          _info: [],
        };
        painterStack.push(painter);
        delete state.stashedPainters[imageId];
        return painter;
      },
      updateROI: function(SeriesInstanceUID, roi) {
        const idx = state.structureSet[SeriesInstanceUID].ROIContours.findIndex(
          r => r.ROINumber === roi.ROINumber
        );
        const prevRoi = state.structureSet[SeriesInstanceUID].ROIContours[idx];
        const rois = state.structureSet[SeriesInstanceUID].ROIContours;
        state.structureSet[SeriesInstanceUID].ROIContours = [
          ...rois.slice(0, idx),
          roi,
          ...rois.slice(idx + 1),
        ];

        /** log: updates ROI */
        const s = mixin.getters.structureSet(SeriesInstanceUID);
        switch (true) {
          case prevRoi.ROIName !== roi.ROIName:
            logInfo({
              event_type: 'Viewer:roi',
              message: `Update ROI name`,
              details: {
                structure_set_study_uid: s.StudyInstanceUID,
                structure_set_series_uid: SeriesInstanceUID,
                structure_set_instance_uid: s.SOPInstanceUID,
                origin_roi: { name: prevRoi.ROIName },
                new_roi: { name: roi.ROIName },
              },
            });
            break;
          case prevRoi.RTROIObservations.RTROIInterpretedType !==
            roi.RTROIObservations.RTROIInterpretedType:
            logInfo({
              event_type: 'Viewer:roi',
              message: `Update ROI interpreted type`,
              details: {
                structure_set_study_uid: s.StudyInstanceUID,
                structure_set_series_uid: SeriesInstanceUID,
                structure_set_instance_uid: s.SOPInstanceUID,
                origin_roi: {
                  name: prevRoi.ROIName,
                  interpreted_type:
                    prevRoi.RTROIObservations.RTROIInterpretedType,
                },
                new_roi: {
                  name: roi.ROIName,
                  interpreted_type: roi.RTROIObservations.RTROIInterpretedType,
                },
              },
            });
            break;
          case prevRoi.trackingType !== roi.trackingType:
            logInfo({
              event_type: 'Viewer:roi',
              message: `Update ROI tracking type`,
              details: {
                structure_set_study_uid: s.StudyInstanceUID,
                structure_set_series_uid: SeriesInstanceUID,
                structure_set_instance_uid: s.SOPInstanceUID,
                origin_roi: {
                  name: prevRoi.ROIName,
                  tracking_type: prevRoi.trackingType,
                },
                new_roi: {
                  name: roi.ROIName,
                  tracking_type: roi.trackingType,
                },
              },
            });
            break;
          default:
        }
        /** end log */
      },
      pushROI: function(SeriesInstanceUID, roi) {
        state.structureSet[SeriesInstanceUID].ROIContours.push(roi);

        /** log: creates ROI */
        const s = mixin.getters.structureSet(SeriesInstanceUID);
        logInfo({
          event_type: 'Viewer:roi',
          message: `Create ROI`,
          details: {
            structure_set_study_uid: s.StudyInstanceUID,
            structure_set_series_uid: SeriesInstanceUID,
            structure_set_instance_uid: s.SOPInstanceUID,
            roi: {
              name: roi.ROIName,
              interpreted_type: roi.RTROIObservations?.RTROIInterpretedType,
              tracking_type: roi.trackingType,
            },
          },
        });
        /** end log */
      },
      deleteROI: function(SeriesInstanceUID, roi) {
        const idx = state.structureSet[SeriesInstanceUID].ROIContours.findIndex(
          r => r.ROINumber === roi.ROINumber
        );
        if (idx === -1) return;
        state.structureSet[SeriesInstanceUID].ROIContours.splice(idx, 1);

        /** log: deletes ROI  */
        const s = mixin.getters.structureSet(SeriesInstanceUID);
        logInfo({
          event_type: 'Viewer:roi',
          message: `Delete ROI`,
          details: {
            structure_set_study_uid: s.StudyInstanceUID,
            structure_set_series_uid: SeriesInstanceUID,
            structure_set_instance_uid: s.SOPInstanceUID,
            roi: { name: roi.ROIName },
          },
        });
        /** end log */
      },
      ROIContours: function(SeriesInstanceUID, ROIContours) {
        state.structureSet[SeriesInstanceUID].ROIContours = ROIContours;
      },
      structureSet: function(structureSet) {
        const { StudyInstanceUID, SeriesInstanceUID } = structureSet;
        state.structureSet[structureSet.SeriesInstanceUID] = structureSet;
        state.baseStructureSets = [
          ...state.baseStructureSets,
          { StudyInstanceUID, SeriesInstanceUID },
        ];
      },
      structureSetVisible: function(SeriesInstanceUID, visible) {
        state.structureSet[SeriesInstanceUID].visible = visible;
      },
      selectedStructureSetUID: function(SeriesInstanceUID) {
        state.selectedStructureSetUID = SeriesInstanceUID;
      },
      selectedROIContour: function(SeriesInstanceUID, ROINumber) {
        state.selectedROIStructureSetUID = SeriesInstanceUID;
        mixin.setters.selectedROINumber(ROINumber);
      },
      selectedROINumber: function(ROINumber) {
        state.selectedROINumber = ROINumber;
        const evt = new CustomEvent('ROISelected', { detail: { ROINumber } });
        module.dispatchEvent(evt);
      },
      highlights: function(highlights) {
        state.highlights = highlights;
        const detail = { highlights };
        const evt = new CustomEvent('Highlighted', { detail });
        module.dispatchEvent(evt);
      },
      rebaseStructureSets: StructureSets => {
        /** update url */
        const url = `${window.location.origin}${window.location.pathname}`;
        const params = new URLSearchParams(window?.location?.search || '');
        let href = url + (params.toString() ? `?${params.toString()}` : '');
        StructureSets.forEach((newSet, index) => {
          href = href.replace(
            state.baseStructureSets[index].SeriesInstanceUID,
            newSet.SeriesInstanceUID
          );
        });
        window.history.replaceState(null, null, href);
        /** update state */
        const sets = StructureSets.map(set => {
          const { StudyInstanceUID, SeriesInstanceUID } = set;
          return { StudyInstanceUID, SeriesInstanceUID };
        });
        state.baseStructureSets = sets;
      },
    },
  };

  Object.assign(module.getters, mixin.getters);
  Object.assign(module.setters, mixin.setters);

  return module;
}

function calculateInfo(painter, structureSetSeriesInstanceUid, ROINumber) {
  const { imageId } = painter;
  const ps = cornerstone.metaData.get('PixelSpacing', imageId);
  if (!ps) return [];
  const imagePlane = { rowPixelSpacing: ps[0], columnPixelSpacing: ps[1] };
  const data = painter
    .getState()
    .data.filter(d => ROINumber === null || d.ROINumber === ROINumber);
  const dataGroups = data.reduce((groups, d) => {
    if (!groups[d.ROINumber]) groups[d.ROINumber] = [];
    groups[d.ROINumber].push(d);
    return groups;
  }, {});
  return Object.entries(dataGroups).map(([ROINumber, group]) => ({
    ...getMeasurementOfSlice(group, imageId, imagePlane),
    structureSetSeriesInstanceUid,
    ROINumber: Number(ROINumber),
  }));
}
