import React, { useState } from 'react';
import cornerstoneTools from 'cornerstone-tools';

import { Select, P } from '@platform/ui';
import * as commands from '../../../tools/commands';

export const SphereThresholdToolSettings = () => {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const [thresholdType, setThresholdType] = useState(
    editModule.getters.painterConfig('sphere-threshold').type
  );
  function onPainterConfigChange(key, value) {
    setThresholdType(value);
    editModule.setters.painterConfig('sphere-threshold', {
      [key]: value,
    });
    commands.refreshViewport();
  }

  return (
    <div style={{ margin: '5px 0' }}>
      <P style={{ color: 'var(--default-color)' }}>Lasso Tool Threshold:</P>
      <Select
        value={thresholdType}
        onChange={evt => {
          onPainterConfigChange('type', evt.target.value);
        }}
      >
        <option value={'default'}>Auto</option>
        <option value={'binary'}>High Intensity</option>
        <option value={'binary-inv'}>Low Intensity</option>
      </Select>
    </div>
  );
};
