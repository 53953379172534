const guideline = {
  guidelineId: 'CUSTOM4',
  guidelineName: 'CUSTOM 4',
  measurementTechnique: 'BIDIMENSIONAL',
  maxNumberOfTargets: 5,
  maxNumberOfTargetsPerOrgan: 2,
  measurableLongAxis: 10,
  measurableShortAxis: 0,
  measurableVolume: 0,
  measurableLongAxisOfLymphNode: 15,
  measurableShortAxisOfLymphNode: 15,
  measurableVolumeOfLymphNode: 0,
  measurableLongAxisOfNewLesion: 5,
  measurableLongAxisOfNewLesionHST: 5,
  highSliceThickness: 2,
  measurableShortAxisOfNewLesion: 0,
  measurableVolumeOfNewLesion: 0,
  newLesionConfirmation: 'REQUIRED',
  newLesionDateOfProgression: 'USE_INITIAL_DATE',
  newLesionAlert: 'ENABLED',
  overallResponseAlert: 'ENABLED',
  minDiameterIncrease: 0.2,
  minDiameterDecrease: 0.3,
  minVolumeIncrease: 0.65,
  minVolumeDecrease: 0.65,
  smallDiameterChange: 3,
  smallVolumeChange: 0,
  absoluteDiameterChange: 0,
  absoluteSumOfDiameterChange: 5,
  absoluteVolumeChange: 0,
  absoluteSumOfVolumeChange: 0,
  completeResponseConfirmation: 'REQUIRED',
  minStableDiseaseDuration: 42,
};

export default guideline;
