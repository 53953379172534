import BasePainterTool from './BasePainterTool';
import TOOL_NAMES from './constants/toolNames';

export default class MergeTool extends BasePainterTool {
  constructor(props = {}) {
    const defaultProps = { name: TOOL_NAMES.MERGE_TOOL };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps, 'merge');
  }
}
