import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve a list of studies using a QIDO call
 * @param server
 * @param PatientID
 * @throws ECONNREFUSED
 * @returns {Array} The list of study object
 */
export default async function searchForStudies(server, PatientID) {
  const dicomWebClient = getDICOMwebClient(server.qidoRoot, server);
  const options = PatientID ? { queryParams: { PatientID } } : {};
  const studies = await dicomWebClient.searchForStudies(options);
  return studies.map(study => {
    return dcmjs.data.DicomMetaDictionary.naturalizeDataset(study);
  });
}
