import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

export default function LoadingSpinner({ width, height }) {
  return (
    <Container height={height}>
      <Spinner width={width} height={height} />
    </Container>
  );
}
LoadingSpinner.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  width: ${props => props.width || '30px'};
  height: ${props => props.height || '30px'};
  border: 4px solid #4a5582;
  border-top: 4px solid #bfcbed;
  border-radius: 50%;
  animation: ${spinner} 1.5s linear infinite;
`;

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: ${props => props.height || '30px'};
`;
