import cornerstone from 'cornerstone-core';
import { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { booleanOperation } from './booleanOperation';

const draw = importInternal('drawing/draw');

export function mergePainter(toolState, structureSetSeriesInstanceUid) {
  let state = _.cloneDeep(toolState);
  let subjectROINumber = '';
  let objectROINumber = '';
  let booleanData;

  return {
    getState: function() {
      return state;
    },
    commit: function() {
      if (!booleanData) {
        return;
      }
      state.data = [
        ...state.data.filter(
          d =>
            d.ROINumber !== subjectROINumber && d.ROINumber !== objectROINumber
        ),
        ...booleanData.map(data => {
          return { ...data, ROINumber: objectROINumber };
        }),
      ];

      // clear preview line
      subjectROINumber = '';
      objectROINumber = '';
    },
    update: function(evt) {
      subjectROINumber = evt.subjectROINumber;
      objectROINumber = evt.objectROINumber;
      this.subjectROINumber = subjectROINumber;
      this.objectROINumber = objectROINumber;
      if (subjectROINumber !== '' && objectROINumber !== '') {
        booleanData = getBooleanData(
          state,
          structureSetSeriesInstanceUid,
          subjectROINumber,
          objectROINumber
        );
      }
    },
    cursor: function(evt, context) {
      if (subjectROINumber === '' || objectROINumber === '') {
        return;
      }
      if (!booleanData || booleanData.length === 0) {
        return;
      }
      const element = evt.detail.element;
      const pointsData = booleanData.map(data =>
        data.handles.points.map(point =>
          cornerstone.pixelToCanvas(element, point)
        )
      );
      pointsData.forEach(points => {
        draw(context, context => {
          context.strokeStyle = 'rgba(217, 83, 79, 1)';
          context.fillStyle = 'rgba(217, 83, 79, 0.2)';
          context.lineWidth = 3;
          context.beginPath();
          context.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            context.lineTo(points[i].x, points[i].y);
          }
          context.closePath();
          context.stroke();
          context.fill();
        });
      });
      return true;
    },
  };
}

function getBooleanData(
  state,
  structureSetSeriesInstanceUid,
  subjectROINumber,
  objectROINumber
) {
  const booleanData = [];
  const subjectPolygons = state.data
    .filter(d => d.ROINumber === subjectROINumber)
    .map(d => [d.handles.points.map(v => [v.x, v.y])]);
  const objectPolygons = state.data
    .filter(d => d.ROINumber === objectROINumber)
    .map(d => [d.handles.points.map(v => [v.x, v.y])]);
  const unionPolygons = booleanOperation(
    'union',
    subjectPolygons,
    objectPolygons
  );
  unionPolygons.forEach(polygon => {
    polygon.forEach(segment => {
      booleanData.push({
        ROINumber: objectROINumber,
        handles: {
          points: segment.map(v => ({ x: v[0], y: v[1] })),
        },
        structureSetSeriesInstanceUid: structureSetSeriesInstanceUid,
      });
    });
  });

  return booleanData;
}
