import { ExtensionManager, MODULE_TYPES } from './extensions';
import { ServicesManager } from './services';
import classes, { CommandsManager, HotkeysManager } from './classes/';

import DICOMWeb from './DICOMWeb';
import cornerstone from './cornerstone.js';
import log from './log.js';
import metadata from './classes/metadata/';
import redux from './redux/';
import user from './user.js';
import errorHandler from './errorHandler.js';
import utils, { hotkeys } from './utils/';
import str2ab from './utils/str2ab';
import Firebase from './firebase';
import guidelines from './guidelines';

import {
  UINotificationService,
  UIModalService,
  UIDialogService,
  LoggerService,
} from './services';

const core = {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  ServicesManager,
  //
  utils,
  hotkeys,
  redux,
  classes,
  metadata,
  cornerstone,
  user,
  errorHandler,
  log,
  DICOMWeb,
  /** services */
  UINotificationService,
  UIModalService,
  UIDialogService,
  LoggerService,
};

export {
  MODULE_TYPES,
  //
  CommandsManager,
  ExtensionManager,
  HotkeysManager,
  ServicesManager,
  //
  utils,
  hotkeys,
  redux,
  classes,
  metadata,
  cornerstone,
  user,
  errorHandler,
  log,
  DICOMWeb,
  //
  UINotificationService,
  UIModalService,
  UIDialogService,
  LoggerService,
  str2ab,
  Firebase,
  guidelines,
};

export default core;
