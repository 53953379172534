import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  LinearProgress,
  Grid,
  Box,
} from '@material-ui/core';

import Hero from '../../static/images/HeroTheme.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
  },
}));

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 8,
    borderRadius: 4,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[300],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export function Callback() {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const onOAuthCallback = async history => {
      // callback functions
      history.push('/');
    };
    (async () => await onOAuthCallback(history))();
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img src={Hero} alt="Vysioneer" style={{ width: '100%' }} />
                <div style={{ width: '80%', height: '60px' }}>
                  <BorderLinearProgress />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
