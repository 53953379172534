import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CompactPicker } from 'react-color';

import {
  TableListItem,
  Box,
  TextField,
  Button,
  IconButton,
  Icon,
  CheckBox,
} from '@platform/ui';
import usePrimary from '../../../hooks/usePrimary';

const validInterpretedTypes = ['GTV', 'CTV', 'PTV'];
const nonTrackingTypes = ['ex'];
const typesMapping = {
  contouring: [],
  tracking: [
    { id: 'tracked', label: 'Tracked' },
    { id: 'new', label: 'New' },
  ],
  longitudinal: [
    { id: 'target', label: 'Target' },
    { id: 'non-target', label: 'Non-Target' },
    { id: 'new', label: 'New' },
  ],
};
const emptyMapping = {
  contouring: '',
  tracking: 'none',
  longitudinal: 'not-included',
};

export const ROIContourItem = ({
  id,
  idx,
  mode,
  roi,
  selected,
  onConfirm,
  onClick,
  onNavigate,
  onCopy,
  onUpdate,
  onDelete,
  /** tracking */
  isLinking,
  getLinked,
  onUnlink,
  getChecked,
  onCheck,
}) => {
  const {
    colorArray: color,
    trackingType: type,
    ROIName: name,
    existing,
    visible,
  } = roi;
  const [isEditing, setIsEditing] = useState(false);
  const [label, setLabel] = useState(name);
  const isPrimary = usePrimary();
  const disabled = mode === 'readonly' || (mode === 'tracking' && !isPrimary);
  const types = typesMapping[mode] || typesMapping['contouring'];
  const emptyType = emptyMapping[mode] || emptyMapping['contouring'];

  useEffect(() => {
    setLabel(name);
  }, [name]);

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onClick(roi);
  };
  const handleBlur = () => {
    setIsEditing(false);
    if (roi.ROIName !== label) onUpdate(roi, 'ROIName', label);
  };
  const handleLabelClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleLabelChange = e => {
    setLabel(e.target.value);
  };
  const handleColorChange = color => {
    onUpdate(roi, 'colorArray', color);
  };
  const handleCheckClick = e => {
    e.stopPropagation();
    e.preventDefault();
    if (isEditing) handleBlur();
    setIsEditing(!isEditing);
  };
  const handleEyeClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onUpdate(roi, 'visible', !visible);
  };
  const handleCopyClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onCopy(roi, idx);
  };
  const handleDeleteClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onConfirm({
      title: 'Delete ROI',
      content: `Are you sure to delete ${name}? This is not recoverable.`,
      callback: () => onDelete(roi),
    });
  };
  const handleNavigationClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onNavigate(roi);
  };
  const handleTypeChange = (e, type) => {
    e.stopPropagation();
    e.preventDefault();
    onConfirm({
      title: 'Update ROI',
      content: `Are you sure to change ${name} to ${type} lesion?`,
      callback: () => onUpdate(roi, 'trackingType', type),
    });
  };

  /** tracking */
  const isLinked = getLinked(roi);
  const handleUnlinkClick = e => {
    e.stopPropagation();
    e.preventDefault();
    onUnlink(roi);
  };
  const isSelecting = isLinking;
  const isCheckable =
    roi.RTROIObservations.RTROIInterpretedType !== 'ORGAN' &&
    roi.trackingType !== 'ex';
  const isChecked = getChecked(roi);
  const handleCheckChange = e => onCheck(roi, e.target.checked);

  return (
    <>
      <TableListItem
        data-cy={`roi-contour-item-${idx}`}
        itemKey={id}
        itemIndex={idx}
        itemClass={''}
        itemMeta={<ColoredCircle color={color} onChange={handleColorChange} />}
        itemMetaClass="item-color-section"
        itemSelected={selected}
        onItemClick={handleClick}
        style={{ padding: '0 4px 0 0', cursor: 'pointer' }}
      >
        <div style={{ padding: '0 6px 0 0' }}>
          <Box>
            <Label
              name={name}
              label={label}
              isEditing={isEditing}
              handleClick={handleLabelClick}
              handleChange={handleLabelChange}
              handleBlur={handleBlur}
            />
            {isLinked && (
              <IconButton
                onClick={handleUnlinkClick}
                disabled={false}
                color="primary"
                size="small"
                style={{ margin: '0 2px' }}
              >
                <Icon name="link" width="22px" height="22px" />
              </IconButton>
            )}
          </Box>
          <Box style={{ margin: '12px 0 6px' }}>
            <div>
              {existing && selected && (
                <Button
                  data-cy="localize-button"
                  onClick={handleNavigationClick}
                  color="primary"
                  variant="outlined"
                  style={{ padding: '4px 2px' }}
                >
                  Localize
                  <Icon
                    name="crosshair"
                    width="24px"
                    height="24px"
                    style={{ background: 'transparent' }}
                  />
                </Button>
              )}
            </div>
            {isSelecting ? (
              isCheckable && (
                <Box>
                  <CheckBox
                    color="primary"
                    checked={isChecked}
                    onClick={e => e.stopPropagation()}
                    onChange={handleCheckChange}
                    style={{ padding: '2px 3px' }}
                  />
                </Box>
              )
            ) : (
              <Box>
                <IconButton
                  onClick={handleCheckClick}
                  disabled={disabled}
                  color="primary"
                  size="small"
                >
                  <Icon
                    name={isEditing ? 'check' : 'edit-light'}
                    width="24px"
                    height="24px"
                  />
                </IconButton>
                {selected ? (
                  <IconButton
                    onClick={e => e.stopPropagation()}
                    color="primary"
                    style={{
                      backgroundColor: 'transparent',
                      cursor: 'not-allowed',
                    }}
                  >
                    <Icon
                      name={'eye-on'}
                      width="24px"
                      height="24px"
                      style={{ color: 'var(--active-color)' }}
                    />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleEyeClick} color="primary">
                    <Icon
                      name={visible ? 'eye-on' : 'eye-off'}
                      width="24px"
                      height="24px"
                    />
                  </IconButton>
                )}
                <IconButton
                  onClick={handleCopyClick}
                  disabled={disabled}
                  color="primary"
                >
                  <Icon name="clipboard" width="24px" height="24px" />
                </IconButton>
                <IconButton
                  onClick={handleDeleteClick}
                  disabled={disabled}
                  color="primary"
                >
                  <Icon name="trash-light" width="24px" height="24px" />
                </IconButton>
              </Box>
            )}
          </Box>
        </div>
      </TableListItem>
      {selected &&
        !disabled &&
        types.length > 0 &&
        validInterpretedTypes.includes(
          roi.RTROIObservations.RTROIInterpretedType
        ) &&
        !nonTrackingTypes.includes(roi.trackingType) && (
          <TrackingTypes
            types={types}
            type={type}
            emptyType={emptyType}
            handleTypeChange={handleTypeChange}
          />
        )}
    </>
  );
};
ROIContourItem.propTypes = {
  id: PropTypes.string,
  idx: PropTypes.number,
  mode: PropTypes.string.isRequired,
  roi: PropTypes.object,
  selected: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClick: PropTypes.func,
  onNavigate: PropTypes.func,
  onCopy: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  isLinking: PropTypes.bool,
  getLinked: PropTypes.func,
  onUnlink: PropTypes.func,
  getChecked: PropTypes.func,
  onCheck: PropTypes.func,
};

const ColoredCircle = ({ color, onChange }) => {
  const [isSelected, setIsSelected] = useState(false);
  const onChangeComplete = ({ rgb }) => {
    onChange([rgb.r, rgb.g, rgb.b]);
  };

  return (
    <div
      className="item-color"
      style={{ backgroundColor: `rgba(${color.join(',')}, 1)`, zIndex: '10' }}
      onClick={() => setIsSelected(true)}
    >
      {isSelected && (
        <div
          style={{
            position: 'fixed',
            top: '0px',
            right: '0px',
            left: '0px',
            bottom: '0px',
          }}
          onClick={e => {
            e.stopPropagation();
            setIsSelected(false);
          }}
        />
      )}
      {isSelected && (
        <CompactPicker
          color={{ r: color[0], g: color[1], b: color[2] }}
          onChangeComplete={onChangeComplete}
        />
      )}
    </div>
  );
};
ColoredCircle.propTypes = {
  color: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

const Label = ({
  name,
  label,
  handleClick,
  handleChange,
  handleBlur,
  isEditing,
}) => {
  return (
    <div>
      {isEditing ? (
        <TextField
          value={label}
          onClick={handleClick}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
        />
      ) : (
        <p
          style={{
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            wordWrap: 'break-word',
            fontSize: '16px',
          }}
        >
          {name}
        </p>
      )}
    </div>
  );
};
Label.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  isEditing: PropTypes.bool,
};

const TrackingTypes = ({ types, type, emptyType, handleTypeChange }) => {
  return (
    <Box style={{ margin: '4px 0' }}>
      {types.map(t => (
        <Button
          key={t.id}
          onClick={e => handleTypeChange(e, type === t.id ? emptyType : t.id)}
          color="secondary"
          variant={type === t.id ? 'contained' : 'outlined'}
          style={{ padding: '6px 16px', whiteSpace: 'nowrap' }}
          fullWidth
        >
          {t.label}
        </Button>
      ))}
    </Box>
  );
};
TrackingTypes.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string })
  ),
  type: PropTypes.string,
  emptyType: PropTypes.string,
  handleTypeChange: PropTypes.func,
};
