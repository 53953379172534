// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableList {
  background-color: var(--primary-background-color);
  height: 100%;
  width: 100%;
}
.tableList .tableListHeader {
  background-color: var(--ui-gray-darker);
  color: var(--text-secondary-color);
  display: flex;
  height: 63px;
  line-height: 63px;
  margin-top: 2px;
  overflow: hidden;
  width: 100%;
}
.tableList .tableListHeader .tableListHeaderTitle {
  color: var(--text-secondary-color);
  font-size: 22px;
  font-weight: 300;
  line-height: 63px;
  padding: 0 10px;
  text-align: left;
  flex: 1;
}
.tableList .tableListHeader .tableListHeaderSelector {
  cursor: pointer;
  max-width: 30px;
  padding-left: 2px;
  align-items: stretch;
  flex: 1;
  justify-content: space-around;
  text-align: center;
}
.tableList .tableListHeader .tableListHeaderSelector svg {
  fill: #c1d8e3;
  height: 63px;
  max-width: 11px;
}
.tableList .tableListHeader .numberOfItems {
  color: var(--ui-sky-blue);
  float: right;
  font-weight: 300;
  font-size: 40px;
  max-width: 54px;
  height: 63px;
  line-height: 66px;
  flex: 1;
}
.tableList .tableListContent {
  padding: 0 0 40px;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/tableList/TableList.styl"],"names":[],"mappings":"AAGA;EACE,iDAAkB;EAClB,YAAQ;EACR,WAAO;AAFT;AAIE;EACE,uCAAkB;EAClB,kCAAO;EACP,aAAS;EACT,YAAQ;EACR,iBAAa;EACb,eAAY;EACZ,gBAAU;EACV,WAAO;AAFX;AAII;EACE,kCAAO;EACP,eAAW;EACX,gBAAa;EACb,iBAAa;EACb,eAAS;EACT,gBAAY;EACZ,OAAM;AAFZ;AAII;EACE,eAAQ;EACR,eAAW;EACX,iBAAc;EACd,oBAAa;EACb,OAAM;EACN,6BAAiB;EACjB,kBAAY;AAFlB;AAIM;EACE,aAAM;EACN,YAAQ;EACR,eAAW;AAFnB;AAII;EACE,yBAAO;EACP,YAAO;EACP,gBAAa;EACb,eAAW;EACX,eAAW;EACX,YAAQ;EACR,iBAAa;EACb,OAAM;AAFZ;AAIE;EACE,iBAAS;AAFb","sourcesContent":["\n$headerRowHeight = 63px\n\n.tableList\n  background-color: var(--primary-background-color)\n  height: 100%;\n  width: 100%;\n\n  .tableListHeader\n    background-color: var(--ui-gray-darker)\n    color: var(--text-secondary-color)\n    display: flex\n    height: $headerRowHeight\n    line-height: $headerRowHeight\n    margin-top: 2px\n    overflow: hidden\n    width: 100%\n\n    .tableListHeaderTitle\n      color: var(--text-secondary-color)\n      font-size: 22px\n      font-weight: 300\n      line-height: $headerRowHeight\n      padding: 0 10px\n      text-align: left\n      flex: 1;\n\n    .tableListHeaderSelector\n      cursor: pointer\n      max-width: 30px\n      padding-left: 2px\n      align-items: stretch\n      flex: 1\n      justify-content: space-around\n      text-align: center\n\n      svg\n        fill: #c1d8e3\n        height: 63px\n        max-width: 11px\n\n    .numberOfItems\n      color: var(--ui-sky-blue)\n      float: right\n      font-weight: 300\n      font-size: 40px\n      max-width: 54px\n      height: $headerRowHeight\n      line-height: 66px\n      flex: 1;\n\n  .tableListContent\n    padding: 0 0 40px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
