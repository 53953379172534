const theme = {
  primary: {
    contained: {
      color: '#000000',
      background: '#BFCBED',
      hover: '#4A5582',
    },
    outlined: {
      color: '#BFCBED',
      background: 'transparent',
      hover: '#1c2a36',
    },
  },
  secondary: {
    contained: {
      color: '#000000',
      background: '#8784EE',
      hover: '#4A5582',
    },
    outlined: {
      color: '#8784EE',
      background: 'transparent',
      hover: '#1c2a36',
    },
  },
};

export default theme;
