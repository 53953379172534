import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../context/AppContext';

export default function RedirectPage({
  message = 'Access Denied',
  showGoBackButton = true,
  additionalUrl = null,
  additionalMessage = null,
}) {
  const { appConfig: config } = useContext(AppContext);
  const redirectUrl = config.redirectUrl || '/';
  const style = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
  };
  return (
    <div style={style}>
      <div style={{ color: 'white' }}>
        <h3>{message}</h3>
      </div>
      {showGoBackButton && (
        <h3>
          <a href={redirectUrl}>Go Back to Platform</a>
        </h3>
      )}
      {additionalUrl && (
        <h3>
          <a href={additionalUrl}>{additionalMessage}</a>
        </h3>
      )}
    </div>
  );
}
RedirectPage.propTypes = {
  message: PropTypes.string,
  showGoBackButton: PropTypes.bool,
  additionalUrl: PropTypes.string,
  additionalMessage: PropTypes.string,
};
