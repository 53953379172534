// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-select {
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  color: #fff;
  background: none;
  margin: 0;
  padding: 8px 4px;
  width: 100%;
  height: auto;
  min-height: 1.2em;
  font: inherit;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  letter-spacing: inherit;
  outline: 0;
  border: 1px solid;
  border-color: rgba(255,255,255,0.23);
  border-radius: 4px;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  cursor: pointer;
}
.language-select :hover {
  border-color: #fff;
}
.language-select option {
  color: #fff !important;
  background-color: #424242 !important;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/languageSwitcher/LanguageSwitcher.styl"],"names":[],"mappings":"AAAA;EACE,cAAS;EACT,sBAAY;EACZ,gBAAU;EACV,WAAO;EACP,gBAAY;EACZ,SAAQ;EACR,gBAAS;EACT,WAAO;EACP,YAAQ;EACR,iBAAY;EACZ,aAAM;EACN,eAAW;EACX,mBAAa;EACb,uBAAe;EACf,uBAAgB;EAChB,UAAS;EACT,iBAAQ;EACR,oCAAc;EACd,kBAAe;EACf,wBAAoB;EACpB,wCAA6B;EAC7B,iBAAa;EACb,eAAQ;AACV;AACE;EACE,kBAAc;AAClB;AACE;EACE,sBAAO;EACP,oCAAkB;AACtB","sourcesContent":[".language-select\n  display: block;\n  box-sizing: border-box;\n  overflow: hidden;\n  color: #ffffff;\n  background: none;\n  margin: 0;\n  padding: 8px 4px;\n  width: 100%;\n  height: auto;\n  min-height: 1.2em;\n  font: inherit;\n  font-size: 16px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  letter-spacing: inherit;\n  outline: 0;\n  border: 1px solid;\n  border-color: rgba(255, 255, 255, 0.23);\n  border-radius: 4px;\n  -webkit-appearance: none;\n  -webkit-tap-highlight-color: transparent;\n  user-select: none;\n  cursor: pointer;\n\n  :hover {\n    border-color: white;\n  }\n  option {\n    color: #ffffff !important;\n    background-color: #424242 !important;\n  }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
