function findDuplicate(arr) {
  return !!arr.find((value, index) => arr.indexOf(value) !== index);
}

export function checkMultipleStackByPositionNumbers(numbers) {
  if (numbers.length === 0) return false;
  if (!numbers.includes(1)) return false;
  if (numbers[numbers.length - 1] === numbers.length) return false;
  if (numbers.some((_, i) => i !== 0 && numbers[i] === numbers[i - 1])) {
    return false;
  }
  return findDuplicate(numbers);
}

export default function checkMultipleStack(instances) {
  const numbers = instances
    .map(instance => instance.getTagValue('InStackPositionNumber'))
    .filter(num => !!num);
  return checkMultipleStackByPositionNumbers(numbers);
}
