import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  Grid,
  Body,
  Container,
  H6,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Select,
  InputBox,
} from '@platform/ui';
import ExportAlert from '../../common/ExportAlert';

export const LinkSection = ({
  selectedLinkID,
  setSelectedLinkID,
  getROIName,
  getLinkROINames,
}) => {
  const [displayMethod, setDisplayMethod] = useState('linked');
  const linkage = useSelector(state => state.linkage);
  const {
    Links: links,
    PrimaryRTSS: primary,
    ReferencedRTSS: timepoints,
  } = linkage;

  return (
    <Body>
      <Container>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow index={-1}>
              {timepoints.map(({ ReferencedSeriesDate }, i) => (
                <TableCell key={i} index={i} header={1}>
                  <H6>{ReferencedSeriesDate}</H6>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {links
              .filter(link => {
                if (displayMethod === 'all') return true;
                return link.RTMappings.length > 1;
              })
              .filter(link => [-1, link.LinkID].includes(selectedLinkID))
              .map((link, index) => (
                <TableRow key={link.LinkID} index={index}>
                  {[
                    ...timepoints.map(({ SeriesInstanceUID: uid }) => {
                      const mapping = link.RTMappings.find(
                        mapping => mapping.SeriesInstanceUID === uid
                      );
                      return (
                        mapping?.ROINumbers?.map(number =>
                          getROIName(mapping.SeriesInstanceUID, number)
                        )
                          .filter(i => i)
                          .join(', ') || ''
                      );
                    }),
                  ].map((text, i) => (
                    <TableCell key={i} index={i}>
                      <H6>{text}</H6>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Container>
      <div style={{ marginTop: '15px' }}>
        <ExportAlert />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputBox label={'ROI Name(s):'} ratio={0.5}>
              <Select
                value={selectedLinkID}
                onChange={evt => setSelectedLinkID(Number(evt.target.value))}
              >
                <option value={-1}>------</option>
                {links
                  .map(link => ({
                    value: link.LinkID,
                    label: getLinkROINames(link, primary.SeriesInstanceUID),
                  }))
                  .filter(({ label }) => !!label)
                  .map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
              </Select>
            </InputBox>
          </Grid>
          <Grid item xs={6}>
            <InputBox label={'Display:'} ratio={0.5}>
              <Select
                value={displayMethod}
                onChange={evt => setDisplayMethod(evt.target.value)}
              >
                <option value="all">All</option>
                <option value="linked">Linked Only</option>
              </Select>
            </InputBox>
          </Grid>
        </Grid>
      </div>
    </Body>
  );
};
LinkSection.propTypes = {
  selectedLinkID: PropTypes.number,
  setSelectedLinkID: PropTypes.func,
  getROIName: PropTypes.func,
  getLinkROINames: PropTypes.func,
  onExportClick: PropTypes.func,
  isExporting: PropTypes.bool,
};
