import {
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  OVERALL_RESPONSE,
  STEROIDS_USE,
  CLINICAL_STATUS,
} from '../../constants/guideline.js';

/**
 * @param {String} targetResponse.result
 * @param {String} nonTargetResponse.result
 * @param {String} newLesionResponse.result
 * @param {String} steroidsUse.result
 * @param {String} clinicalStatus.result
 */
function getOverallResponse({
  targetResponse,
  nonTargetResponse,
  newLesionResponse,
  steroidsUse,
  clinicalStatus,
}) {
  /** progressive disease */
  if (targetResponse.result === TARGET_RESPONSE.PROGRESSIVE_DISEASE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: targetResponse.message || '',
    };
  }
  if (nonTargetResponse.result === NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: nonTargetResponse.message || '',
    };
  }
  if (newLesionResponse.result === NEW_LESION_RESPONSE.PRESENT) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: newLesionResponse.message || '',
    };
  }
  if (clinicalStatus.result === CLINICAL_STATUS.WORSE) {
    return {
      result: OVERALL_RESPONSE.PROGRESSIVE_DISEASE,
      message: clinicalStatus.message || '',
    };
  }

  /** pseudo progressive disease */
  // if (newLesionResponse.result === NEW_LESION_RESPONSE.UNCONFIRMED) {
  //   return {
  //     result: OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE,
  //     message: newLesionResponse.message || '',
  //   };
  // }

  /** non-progressive response */
  if (
    [NEW_LESION_RESPONSE.UNCONFIRMED, NEW_LESION_RESPONSE.ABSENT].includes(
      newLesionResponse.result
    ) &&
    clinicalStatus.result !== CLINICAL_STATUS.WORSE &&
    steroidsUse.result !== STEROIDS_USE.INCREASED
  ) {
    /** complete response */
    if (
      [STEROIDS_USE.NONE, STEROIDS_USE.NOT_APPLICABLE].includes(
        steroidsUse.result
      )
    ) {
      if (
        targetResponse.result === TARGET_RESPONSE.COMPLETE_RESPONSE &&
        [
          NON_TARGET_RESPONSE.COMPLETE_RESPONSE,
          NON_TARGET_RESPONSE.NONE,
        ].includes(nonTargetResponse.result)
      ) {
        return {
          result: OVERALL_RESPONSE.COMPLETE_RESPONSE,
          message: targetResponse.message || '',
        };
      }
      if (
        targetResponse.result === TARGET_RESPONSE.NONE &&
        nonTargetResponse.result === NON_TARGET_RESPONSE.COMPLETE_RESPONSE
      ) {
        return {
          result: OVERALL_RESPONSE.COMPLETE_RESPONSE,
          message: nonTargetResponse.message || '',
        };
      }
    }

    /** partial response */
    if (
      [
        TARGET_RESPONSE.COMPLETE_RESPONSE,
        TARGET_RESPONSE.PARTIAL_RESPONSE,
      ].includes(targetResponse.result) &&
      [
        NON_TARGET_RESPONSE.COMPLETE_RESPONSE,
        NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE,
        NON_TARGET_RESPONSE.NOT_EVALUABLE,
        NON_TARGET_RESPONSE.NONE,
      ].includes(nonTargetResponse.result)
    ) {
      return {
        result: OVERALL_RESPONSE.PARTIAL_RESPONSE,
        message: targetResponse.message || '',
      };
    }

    /** stable disease */
    if (
      targetResponse.result === TARGET_RESPONSE.STABLE_DISEASE &&
      [
        NON_TARGET_RESPONSE.COMPLETE_RESPONSE,
        NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE,
        NON_TARGET_RESPONSE.NOT_EVALUABLE,
        NON_TARGET_RESPONSE.NONE,
      ].includes(nonTargetResponse.result)
    ) {
      return {
        result: OVERALL_RESPONSE.STABLE_DISEASE,
        message: targetResponse.message || '',
      };
    }
    if (
      targetResponse.result === TARGET_RESPONSE.NONE &&
      nonTargetResponse.result === NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE
    ) {
      return {
        result: OVERALL_RESPONSE.STABLE_DISEASE,
        message: nonTargetResponse.message || '',
      };
    }

    /** not evaluable */
    if (
      targetResponse.result === TARGET_RESPONSE.NOT_EVALUABLE &&
      nonTargetResponse.result !== NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE
    ) {
      return {
        result: OVERALL_RESPONSE.NOT_EVALUABLE,
        message: '',
      };
    }
    if (
      targetResponse.result === TARGET_RESPONSE.NONE &&
      nonTargetResponse.result === NON_TARGET_RESPONSE.NOT_EVALUABLE
    ) {
      return {
        result: OVERALL_RESPONSE.NOT_EVALUABLE,
        message: '',
      };
    }
  }

  /** none response */
  return {
    result: OVERALL_RESPONSE.NONE,
    message: '',
  };
}

export default getOverallResponse;
