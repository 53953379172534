import getNonTargetResponse from './getNonTargetResponse.js';

/**
 * @param {Object[]} nonTargetStats
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.minDiameterIncrease
 * @param {Number} criteria.minDiameterDecrease
 * @param {Number} criteria.minVolumeIncrease
 * @param {Number} criteria.minVolumeDecrease
 */
function getNonTargetResponses(nonTargetStats, criteria) {
  return nonTargetStats.map(stat => getNonTargetResponse(stat, criteria));
}

export default getNonTargetResponses;
