export default function getMedian(_values) {
  if (_values.length === 0) {
    return 0;
  }
  const values = [..._values];
  values.sort(function(a, b) {
    return a - b;
  });
  const half = Math.floor(values.length / 2);
  if (values.length % 2) {
    return values[half];
  }
  return (values[half - 1] + values[half]) / 2;
}
