import getOverallAlert from "./getOverallAlert";
import { OVERALL_RESPONSE } from "../../constants/guideline";

/**
 * @param {Object[]} assessedTimepoints
 * @param {String} assessedTimepoints[].targetResponse.result
 * @param {String} assessedTimepoints[].nonTargetResponse.result
 * @param {String} assessedTimepoints[].newLesionResponse.result
 * @param {String} assessedTimepoints[].overallResponse.result
 * @param {Boolean} assessedTimepoints[].isResponseConfirmed
 */
function getResponseAlerts(assessedTimepoints) {
  const { responseAlerts } = assessedTimepoints.reduce(
    ({ responseAlerts, skipAlert }, report) => {
      const _responseAlerts = skipAlert
        ? responseAlerts
        : [...responseAlerts, getOverallAlert(report)];
      const _skipAlerts =
        skipAlert ||
        report.overallResponse.result === OVERALL_RESPONSE.PROGRESSIVE_DISEASE;
      return {
        responseAlerts: _responseAlerts,
        skipAlert: _skipAlerts,
      };
    },
    { responseAlerts: [], skipAlert: false }
  );

  return responseAlerts;
}

export default getResponseAlerts
