import cornerstone from 'cornerstone-core';
import { getToolState } from 'cornerstone-tools';

/**
 * Synchronize the target zoom and pan to match the source
 * @param {Object} synchronizer - The Synchronizer instance that attaches this
 * handler to an event
 * @param {HTMLElement} sourceElement - The source element for the zoom and pan values
 * @param {HTMLElement} targetElement - The target element
 * @returns {void}
 */
export default function(synchronizer, sourceElement, targetElement) {
  // Ignore the case where the source and target are the same enabled element
  if (targetElement === sourceElement) return;

  // Get the source and target viewports
  const sourceViewport = cornerstone.getViewport(sourceElement);
  const targetViewport = cornerstone.getViewport(targetElement);

  let ratio = 1;
  let xRatio = 1;
  let yRatio = 1;
  const sourceData = getToolState(sourceElement, 'stack')?.data[0];
  const targetData = getToolState(targetElement, 'stack')?.data[0];
  if (sourceData && targetData) {
    const s = sourceData.imageIds[sourceData.currentImageIdIndex];
    const sourceImagePlane = cornerstone.metaData.get('imagePlaneModule', s);
    const { scale: sScale, xScale: sxScale, yScale: syScale } = getFitScale(
      sourceImagePlane
    );
    const t = targetData.imageIds[targetData.currentImageIdIndex];
    const targetImagePlane = cornerstone.metaData.get('imagePlaneModule', t);
    const { scale: tScale, xScale: txScale, yScale: tyScale } = getFitScale(
      targetImagePlane
    );
    ratio = tScale / sScale;
    xRatio = txScale / sxScale;
    yRatio = tyScale / syScale;
  }

  // Do nothing if the scale and translation are the same
  if (!targetViewport || !sourceViewport) return;
  if (
    targetViewport.scale === sourceViewport.scale * ratio &&
    targetViewport.translation.x === sourceViewport.translation.x / xRatio &&
    targetViewport.translation.y === sourceViewport.translation.y / yRatio
  ) {
    return;
  }

  // Scale and/or translation are different, sync them
  targetViewport.scale = sourceViewport.scale * ratio;
  targetViewport.translation.x = sourceViewport.translation.x / xRatio;
  targetViewport.translation.y = sourceViewport.translation.y / yRatio;
  synchronizer.setViewport(targetElement, targetViewport);
}

function getFitScale(image) {
  const rowPixelSpacing = image.rowPixelSpacing || 1;
  const columnPixelSpacing = image.columnPixelSpacing || 1;
  let verticalRatio = 1;
  let horizontalRatio = 1;

  if (rowPixelSpacing < columnPixelSpacing) {
    horizontalRatio = columnPixelSpacing / rowPixelSpacing;
  } else {
    verticalRatio = rowPixelSpacing / columnPixelSpacing;
  }

  const verticalScale = rowPixelSpacing / verticalRatio;
  const horizontalScale = columnPixelSpacing / horizontalRatio;
  const scale = Math.min(horizontalScale, verticalScale);
  const xScale = scale * horizontalRatio;
  const yScale = scale * verticalRatio;
  return { scale, xScale, yScale };
}
