import React from 'react';
import PropTypes from 'prop-types';
import cornerstoneTools from 'cornerstone-tools';
import {
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  XAxis,
  YAxis,
  Label,
  Tooltip,
  Bar,
  Cell,
} from 'recharts';
import { OVERALL_RESPONSE } from '../constants/guideline';

const getOverallResponseLabel = result => {
  const mapping = {
    [OVERALL_RESPONSE.COMPLETE_RESPONSE]: 'CR',
    [OVERALL_RESPONSE.PARTIAL_RESPONSE]: 'PR',
    [OVERALL_RESPONSE.STABLE_DISEASE]: 'SD',
    [OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE]: 'PPD',
    [OVERALL_RESPONSE.PROGRESSIVE_DISEASE]: 'PD',
    [OVERALL_RESPONSE.NONE]: 'N/A',
  };
  return mapping[result];
};

const getOverallResponseColor = result => {
  const mapping = {
    [OVERALL_RESPONSE.COMPLETE_RESPONSE]: 'var(--default-color)',
    [OVERALL_RESPONSE.PARTIAL_RESPONSE]: 'var(--active-color)',
    [OVERALL_RESPONSE.STABLE_DISEASE]: 'var(--ui-yellow)',
    [OVERALL_RESPONSE.PSEUDO_PROGRESSIVE_DISEASE]: 'var(--ui-yellow)',
    [OVERALL_RESPONSE.PROGRESSIVE_DISEASE]: 'var(--ui-state-error-border)',
    [OVERALL_RESPONSE.NONE]: '#FFFFFF',
  };
  return mapping[result];
};

/**
 * data: [
 *  {
 *    name: '20200101', sumDiameter: 5, sumVolume: 60,
 *    comparedSumDiameter: 3, comparedSumVolume: 20,
 *    result: 'stable'
 *  },
 *  {
 *    name: '20200201', sumDiameter: 10, sumVolume: 200,
 *    comparedSumDiameter: 6, comparedSumVolume: 60,
 *    result: 'progressive'
 *  },
 * ]
 */

export const WaterfallPlot = ({ data, xAxisKey, yAxisKey, ySumAxisKey }) => {
  data.forEach(d => {
    d.name = d.date;
  });
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const getReferenceLineY = (measurementType, changeType) => {
    const criteria = editModule.getters.criteria();
    const minDiameterIncrease = Math.round(criteria.minDiameterIncrease * 100);
    const minDiameterDecrease = -Math.round(criteria.minDiameterDecrease * 100);
    const minVolumeIncrease = Math.round(criteria.minVolumeIncrease * 100);
    const minVolumeDecrease = -Math.round(criteria.minVolumeDecrease * 100);
    const mapping = {
      sumDiameter: {
        increase: minDiameterIncrease,
        decrease: minDiameterDecrease,
      },
      sumVolume: {
        increase: minVolumeIncrease,
        decrease: minVolumeDecrease,
      },
    };
    return mapping[measurementType][changeType];
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={data}
          margin={{
            top: 40,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barCategoryGap={'15%'}
        >
          <XAxis dataKey={xAxisKey} xAxisId={0}></XAxis>
          <XAxis dataKey={xAxisKey} xAxisId={1} hide></XAxis>
          <YAxis domain={[-100, 100]}>
            <Label
              style={{ fill: 'lightgrey' }}
              value={'%'}
              offset={0}
              position="insideTopLeft"
            />
          </YAxis>
          <Tooltip
            itemStyle={{ color: 'grey' }}
            contentStyle={{ color: 'grey' }}
            cursor={{ fill: 'transparent' }}
          />
          <ReferenceLine
            y={getReferenceLineY(ySumAxisKey, 'increase')}
            stroke="grey"
            strokeDasharray="3 3"
          />
          <ReferenceLine y={0} stroke="grey" />
          <ReferenceLine
            y={getReferenceLineY(ySumAxisKey, 'decrease')}
            stroke="grey"
            strokeDasharray="3 3"
          />
          <Bar
            name="Lesions"
            dataKey={ySumAxisKey}
            fill="var(--active-color)"
            xAxisId={0}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={'var(--active-color)'}
                strokeWidth={1}
              />
            ))}
          </Bar>
          <Bar
            name="Selected Lesion"
            dataKey={yAxisKey}
            fill="var(--default-color)"
            xAxisId={1}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={'var(--default-color)'}
                strokeWidth={1}
              />
            ))}
          </Bar>
          {data.map(d => (
            <ReferenceLine key={d.name} x={d.name} stroke="none">
              <Label
                value={getOverallResponseLabel(d.result)}
                fill={getOverallResponseColor(d.result)}
                position="top"
                offset={15}
              />
            </ReferenceLine>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
WaterfallPlot.propTypes = {
  data: PropTypes.any,
  xAxisKey: PropTypes.string,
  yAxisKey: PropTypes.string,
  ySumAxisKey: PropTypes.string,
};
