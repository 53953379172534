import cornerstone from 'cornerstone-core';
import cornerstoneTools, { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import bigGaussianBlur from '../filters/bigGaussianBlur';
import growRegion from '../operations/growRegion';
import dilation from '../operations/dilation';
import findConcaveHull from '../operations/findConcaveHull';
import { math } from '../../modules/dicom-measurement/src';

const { maxmin, getAverage, getStandardDeviation, getDistance } = math;

// Cornerstone 3rd party dev kit imports
export const draw = importInternal('drawing/draw');

export function regionGrowingPainter(
  toolState,
  structureSetSeriesInstanceUid,
  config
) {
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const ROINumber = editModule.getters.selectedROINumber();
  const { colorArray } = editModule.getters.ROIContour(
    structureSetSeriesInstanceUid,
    ROINumber
  );
  let state = _.cloneDeep(toolState);
  const cursorRadius = config.radius;
  const regionRadius = config.regionRadius;
  const sigma = config.sigma;
  let processedImage, upper, lower, seedPixel, maxPixelRadius;

  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      /** check if ROI contours exist */
      if (state.data.find(x => x.ROINumber === ROINumber)) {
        const confirmed = window.confirm(
          'ROI contours exist. Are you sure to override the current ROI contours?'
        );
        if (!confirmed) return false;
      }

      const { image } = evt.detail;
      const { width, height, columnPixelSpacing, rowPixelSpacing } = image;
      const pixelData = image.getPixelData();
      const pixelCenter = evt.detail.currentPoints.image;
      const pixelRadius =
        cursorRadius / Math.max(columnPixelSpacing, rowPixelSpacing);
      maxPixelRadius =
        regionRadius / Math.max(columnPixelSpacing, rowPixelSpacing);

      /** define threshold using the current image */
      const sample = getSample(
        { pixelData, width, height },
        pixelCenter,
        pixelRadius
      );
      const avg = getAverage(sample);
      const sd = getStandardDeviation(sample);
      upper = avg + sigma * sd;
      lower = avg - sigma * sd;
      seedPixel = {
        x: Math.round(pixelCenter.x),
        y: Math.round(pixelCenter.y),
      };

      /** region growing on the current image */
      processedImage = runProcess(
        { ...image, pixelData },
        null,
        structureSetSeriesInstanceUid,
        ROINumber,
        upper,
        lower,
        seedPixel,
        maxPixelRadius
      );

      return true;
    },
    commit3D: async function(evt) {
      /** region growing on the following images */
      const stack = cornerstoneTools.getToolState(evt.detail.element, 'stack');
      const { currentImageIdIndex, imageIds } = stack.data[0];

      let refImage = processedImage;
      let prev = currentImageIdIndex - 1;
      while (prev >= 0) {
        const image = await cornerstone.loadImage(imageIds[prev]);
        const painter = editModule.setters.createPainter(
          'region-growing',
          image.imageId,
          structureSetSeriesInstanceUid
        );
        refImage = runProcess(
          { ...image, pixelData: image.getPixelData() },
          refImage,
          structureSetSeriesInstanceUid,
          ROINumber,
          upper,
          lower,
          null,
          maxPixelRadius
        );
        painter.commitCallback();
        if (!refImage) break;
        prev--;
      }

      refImage = processedImage;
      let next = currentImageIdIndex + 1;
      while (next <= imageIds.length - 1) {
        const image = await cornerstone.loadImage(imageIds[next]);
        const painter = editModule.setters.createPainter(
          'region-growing',
          image.imageId,
          structureSetSeriesInstanceUid
        );
        refImage = runProcess(
          { ...image, pixelData: image.getPixelData() },
          refImage,
          structureSetSeriesInstanceUid,
          ROINumber,
          upper,
          lower,
          null,
          maxPixelRadius
        );
        painter.commitCallback();
        if (!refImage) break;
        next++;
      }

      return true;
    },
    update: function() {
      return true;
    },
    cursor: function(evt, context, cursorCanvasPosition, isDrawing) {
      if (!cursorCanvasPosition || !isDrawing) return false;
      const { element, image } = evt.detail;
      const { columnPixelSpacing } = image;
      const pixelRadius = cursorRadius / columnPixelSpacing;
      const canvasRadius = getDistance(
        cornerstone.pixelToCanvas(element, { x: pixelRadius, y: 0 }),
        cornerstone.pixelToCanvas(element, { x: 0, y: 0 })
      );
      draw(context, context => {
        context.strokeStyle = `rgba(${colorArray.join(',')}, 1)`;
        context.beginPath();
        context.arc(
          cursorCanvasPosition.x,
          cursorCanvasPosition.y,
          canvasRadius,
          0,
          Math.PI * 2
        );
        context.stroke();
      });
      return true;
    },
  };
}

function getSample(image, pixelCenter, pixelRadius) {
  const { pixelData, width, height } = image;

  const sample = [];
  const center = { x: Math.round(pixelCenter.x), y: Math.round(pixelCenter.y) };
  const start = {
    x: Math.floor(
      maxmin({ number: center.x - pixelRadius, max: width, min: 0 })
    ),
    y: Math.floor(
      maxmin({ number: center.y - pixelRadius, max: height, min: 0 })
    ),
  };
  const end = {
    x: Math.ceil(
      maxmin({ number: center.x + pixelRadius, max: width, min: 0 })
    ),
    y: Math.ceil(
      maxmin({ number: center.y + pixelRadius, max: height, min: 0 })
    ),
  };
  for (let i = start.x; i < end.x; i++) {
    for (let j = start.y; j < end.y; j++) {
      if (
        Math.sqrt(Math.pow(center.x - i, 2) + Math.pow(center.y - j, 2)) <=
        pixelRadius
      ) {
        const k = i + j * width;
        sample.push(pixelData[k]);
      }
    }
  }
  return sample;
}

function runProcess(
  image,
  refImage,
  structureSetSeriesInstanceUid,
  ROINumber,
  upper,
  lower,
  seedPixel,
  maxPixelRadius
) {
  const processedImage = processImage(
    image,
    refImage,
    upper,
    lower,
    seedPixel,
    maxPixelRadius
  );
  if (processedImage.existForeground) {
    processData(processedImage, structureSetSeriesInstanceUid, ROINumber);
    return processedImage;
  }
  return null;
}

function processImage(
  image,
  refImage,
  upper,
  lower,
  seedPixel,
  maxPixelRadius
) {
  const blurredImage = bigGaussianBlur(image);
  const binaryImage = growRegion(
    blurredImage,
    refImage,
    upper,
    lower,
    seedPixel,
    maxPixelRadius
  );
  binaryImage.existForeground = false;
  for (let i = 0; i < binaryImage.pixelData.length; i++) {
    if (binaryImage.pixelData[i] === 1) {
      binaryImage.existForeground = true;
      break;
    }
  }
  return dilation(binaryImage);
}

function processData(image, structureSetSeriesInstanceUid, ROINumber) {
  const { pixelData, width, height, imageId } = image;
  const editModule = cornerstoneTools.getModule('rtstruct-edit');
  const toolState = editModule.getters.toolState(imageId);

  /** clear previous data and add new data */
  toolState.data = toolState.data.filter(x => x.ROINumber !== ROINumber);

  const hull = findConcaveHull({ pixelData, width, height });
  if (hull.length >= 3) {
    const newData = {
      ROINumber,
      handles: { points: hull },
      structureSetSeriesInstanceUid,
    };

    toolState.data = [...toolState.data, newData];
  }
}
