import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Icon } from '../../elements';
import './RoundedButtonGroup.css';

function RoundedButtonGroup(props) {
  let className = classnames('RoundedButtonGroup', 'clearfix center-table');
  const [badgeNumbers] = useState([]);

  const onClickOption = value => {
    let newValue = value;
    if (props.value === value) {
      newValue = null;
    }

    if (props.onValueChanged) {
      props.onValueChanged(newValue);
    }
  };

  const buttons = props.options.map((option, index) => {
    const className = classnames({
      roundedButtonWrapper: true,
      noselect: true,
      active: props.value === option.value,
    });
    const optionText = option.label && (
      <p style={{ fontSize: `${option.fontSize || 15}px` }}>{option.label}</p>
    );
    const iconProps =
      typeof option.icon === 'string' ? { name: option.icon } : option.icon;

    const bottomLabel = option.bottomLabel && (
      <div className="bottomLabel">{option.bottomLabel}</div>
    );

    let badgeNumber = badgeNumbers[index];
    const badgeNumberOverflow = String(badgeNumber).length > 2;
    badgeNumber = badgeNumber ? (badgeNumberOverflow ? 99 : badgeNumber) : null;

    return (
      <div
        key={index}
        className={className}
        onClick={() => onClickOption(option.value)}
      >
        <div className="roundedButton">
          {optionText}
          {badgeNumber && (
            <div className="badgeNumber-container">
              <span className="badgeNumber">
                {badgeNumber}
                {badgeNumberOverflow && '+'}
              </span>
            </div>
          )}
          {iconProps && <Icon {...iconProps} />}
        </div>
        {bottomLabel}
      </div>
    );
  });

  return (
    <div data-cy={`rounded-button-group-${props.id}`} className={className}>
      {buttons}
    </div>
  );
}
RoundedButtonGroup.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      labelFontSize: PropTypes.number,
      icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ]),
    })
  ),
  value: PropTypes.string,
  onValueChanged: PropTypes.func,
};
RoundedButtonGroup.defaultProps = {
  options: [],
  value: null,
};

export { RoundedButtonGroup };
