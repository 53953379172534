import { api } from 'dicomweb-client';

import DICOMWeb from '../../DICOMWeb';
import errorHandler from '../../errorHandler';

export default function getDICOMwebClient(url, server) {
  const config = {
    url: url,
    headers: DICOMWeb.getAuthorizationHeader(server),
    errorInterceptor: errorHandler.getHTTPErrorHandler(),
  };
  const dicomWebClient = new api.DICOMwebClient(config);
  return dicomWebClient;
}
