import { InstanceMetadata, SeriesMetadata, StudyMetadata } from './metadata';

import CommandsManager from './CommandsManager.js';
import { DICOMFileLoadingListener } from './StudyLoadingListener';
import HotkeysManager from './HotkeysManager.js';
import ImageSet from './ImageSet';
import MetadataProvider from './MetadataProvider';
import StackError from './StackError.js';
import { StackLoadingListener } from './StudyLoadingListener';
import { StudyLoadingListener } from './StudyLoadingListener';
import { StudyPrefetcher } from './StudyPrefetcher';
import { TypeSafeCollection } from './TypeSafeCollection';

export {
  MetadataProvider,
  CommandsManager,
  HotkeysManager,
  ImageSet,
  StudyPrefetcher,
  StudyLoadingListener,
  StackLoadingListener,
  DICOMFileLoadingListener,
  StudyMetadata,
  SeriesMetadata,
  InstanceMetadata,
  TypeSafeCollection,
  StackError,
};

const classes = {
  MetadataProvider,
  CommandsManager,
  HotkeysManager,
  ImageSet,
  StudyPrefetcher,
  StudyLoadingListener,
  StackLoadingListener,
  DICOMFileLoadingListener,
  StudyMetadata,
  SeriesMetadata,
  InstanceMetadata,
  TypeSafeCollection,
  StackError,
};

export default classes;
