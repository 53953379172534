import React from 'react';
import { Box, TextField, MenuItem, Typography } from '@material-ui/core';

import { guidelines } from '@platform/core';

function getInnerBox(criterionKey, criterionValue, handleChange, readOnly) {
  switch (criterionKey) {
    case 'ClinicalTrialSiteName':
    case 'ClinicalTrialSubjectID':
    case 'SeriesDescription':
    case 'SeriesDate':
      return (
        <Box
          display="flex"
          alignItems="center"
          style={{ width: '200px', margin: '5px 20px' }}
        >
          <TextField
            value={criterionValue}
            onChange={handleChange}
            margin="dense"
            fullWidth
          ></TextField>
        </Box>
      );
    case 'guidelineId':
    case 'measurementTechnique':
    case 'completeResponseConfirmation':
    case 'newLesionConfirmation':
    case 'newLesionDateOfProgression':
    case 'overallResponseAlert':
    case 'newLesionAlert':
      return (
        <Box
          display="flex"
          alignItems="center"
          style={{ width: '200px', margin: '5px 20px' }}
        >
          <TextField
            select
            value={criterionValue}
            onChange={handleChange}
            InputProps={{ readOnly }}
            margin="dense"
            fullWidth
          >
            {Object.entries(optionMap[criterionKey]).map(([value, label]) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      );
    default:
      return (
        <Box
          display="flex"
          alignItems="center"
          style={{ width: '80px', margin: '5px 20px' }}
        >
          <TextField
            value={criterionValue}
            onChange={handleChange}
            InputProps={{ readOnly }}
            margin="dense"
          />
          <Typography>{unitMap[criterionKey]}</Typography>
        </Box>
      );
  }
}

const CriterionBox = ({
  criterionKey,
  criterionValue,
  onCriterionChange,
  readOnly,
}) => {
  const handleChange = e => onCriterionChange(criterionKey, e.target.value);
  return criterionKey ? (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography color="primary">{criterionMap[criterionKey]}:</Typography>
      {getInnerBox(criterionKey, criterionValue, handleChange, readOnly)}
    </Box>
  ) : null;
};

const mm3 = (
  <p>
    mm<sup>3</sup>
  </p>
);

const criterionMap = {
  /** timepoint */
  ClinicalTrialSiteName: 'Clinical Trial Site Name',
  ClinicalTrialSubjectID: 'Clinical Trial Subject ID',
  SeriesDescription: 'Series Description',
  SeriesDate: 'Baseline Date',
  SliceThickness: 'Slice Thickness',
  SpacingBetweenSlices: 'Spacing Between Slices',
  /** guideline */
  guidelineId: 'Guideline',
  measurementTechnique: 'Measurement Technique',
  maxNumberOfTargets: 'Targets',
  maxNumberOfTargetsPerOrgan: 'Targets Per Organ',
  measurableLongAxis: 'Measurable Long Axis',
  measurableShortAxis: 'Measurable Short Axis',
  measurableVolume: 'Measurable Volume',
  measurableLongAxisOfLymphNode: 'Measurable Long Axis of Lymph Node',
  measurableShortAxisOfLymphNode: 'Measurable Short Axis of Lymph Node',
  measurableVolumeOfLymphNode: 'Measurable Volume of LymphNode',
  measurableLongAxisOfNewLesion: 'Measurable Long Axis of New Lesion',
  measurableShortAxisOfNewLesion: 'Measurable Short Axis of New Lesion',
  measurableLongAxisOfNewLesionHST:
    'Measurable Long Axis of New Lesion (High Slice Thickness)',
  highSliceThickness: 'High Slice Thickness',
  measurableVolumeOfNewLesion: 'Measurable Volume of New Lesion',
  newLesionConfirmation: 'New Lesion Confirmation',
  newLesionDateOfProgression: 'New Lesion Date of Progression',
  minDiameterIncrease: 'Minimum Diameter Increase',
  minDiameterDecrease: 'Minimum Diameter Decrease',
  minVolumeIncrease: 'Minimum Volume Increase',
  minVolumeDecrease: 'Minimum Volume Decrease',
  smallDiameterChange: 'Small Diameter Change',
  smallVolumeChange: 'Small Volume Change',
  absoluteDiameterChange: 'Absolute Diameter Change',
  absoluteSumOfDiameterChange: 'Absolute Sum of Diameter Change',
  absoluteVolumeChange: 'Absolute Volume Change',
  absoluteSumOfVolumeChange: 'Absolute Sum of Volume Change',
  completeResponseConfirmation: 'CR-PR Confirmation',
  minStableDiseaseDuration: 'Minimum SD Duration',
  /** alerts */
  overallResponseAlert: 'Overall Response Alert',
  newLesionAlert: 'New Lesion Alert',
};

const unitMap = {
  /** timepoint */
  SliceThickness: 'mm',
  SpacingBetweenSlices: 'mm',
  /** guideline */
  maxNumberOfTargets: '',
  maxNumberOfTargetsPerOrgan: '',
  measurableLongAxis: 'mm',
  measurableShortAxis: 'mm',
  measurableVolume: mm3,
  measurableLongAxisOfLymphNode: 'mm',
  measurableShortAxisOfLymphNode: 'mm',
  measurableVolumeOfLymphNode: mm3,
  measurableLongAxisOfNewLesion: 'mm',
  measurableShortAxisOfNewLesion: 'mm',
  measurableLongAxisOfNewLesionHST: 'mm',
  highSliceThickness: 'mm',
  measurableVolumeOfNewLesion: mm3,
  minDiameterIncrease: '%',
  minDiameterDecrease: '%',
  minVolumeIncrease: '%',
  minVolumeDecrease: '%',
  smallDiameterChange: 'mm',
  smallVolumeChange: mm3,
  absoluteDiameterChange: 'mm',
  absoluteSumOfDiameterChange: 'mm',
  absoluteVolumeChange: mm3,
  absoluteSumOfVolumeChange: mm3,
  minStableDiseaseDuration: 'days',
};

const optionMap = {
  guidelineId: Object.keys(guidelines).reduce((guidelineMap, guidelineId) => {
    guidelineMap[guidelineId] = guidelines[guidelineId].guidelineName;
    return guidelineMap;
  }, {}),
  measurementTechnique: {
    BIDIMENSIONAL: 'Bidimensional',
    VOLUMETRIC: 'Volumetric',
  },
  completeResponseConfirmation: {
    REQUIRED: 'Required',
    NOT_APPLICABLE: 'N/A',
  },
  newLesionConfirmation: {
    REQUIRED: 'Required',
    NOT_APPLICABLE: 'N/A',
  },
  newLesionDateOfProgression: {
    USE_INITIAL_DATE: 'Use Initial Date',
    USE_DETERIORATION_DATE: 'Use Deterioration Date',
  },
  newLesionAlert: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
  overallResponseAlert: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
  },
};

export default CriterionBox;
