import moment from 'moment';

export default function getTimestampFromMetadata(
  date,
  time,
  timezoneOffsetFromUTC
) {
  if (!date || !time || !timezoneOffsetFromUTC) {
    return 0;
  }
  const rawTZ = timezoneOffsetFromUTC;
  let hour;
  let minute;
  let sign;
  if (rawTZ.length === 5) {
    sign = rawTZ[0];
    hour = rawTZ.slice(1, 3);
    minute = rawTZ.slice(3, 5);
  } else {
    sign = '+';
    let rawTZUnsigned = rawTZ;
    if (rawTZ[0] === '-') {
      sign = '-';
      rawTZUnsigned = rawTZ.slice(1);
    }
    if (rawTZUnsigned.length === 4) {
      hour = rawTZUnsigned.slice(0, 2);
      minute = rawTZUnsigned.slice(2, 4);
    } else if (rawTZUnsigned.length === 3) {
      hour = rawTZUnsigned[0];
      minute = rawTZUnsigned.slice(1);
    } else {
      hour = rawTZUnsigned[0];
      minute = rawTZUnsigned[1];
    }
  }

  const t = moment(date + time.slice(0, 6), 'YYYYMMDDHHmmss');
  if (sign === '-') {
    t.add(parseInt(hour, 10), 'hour');
    t.add(parseInt(minute, 10), 'minute');
  } else {
    t.subtract(parseInt(hour, 10), 'hour');
    t.subtract(parseInt(minute, 10), 'minute');
  }
  return t.valueOf();
}
