import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ToggleSwitch = ({ id, isToggled, onToggle, disabled = false }) => {
  return (
    <Switch>
      <input
        type="checkbox"
        onChange={onToggle}
        checked={isToggled}
        disabled={disabled}
      />
      <span data-cy={`toggle-switch-${id}`}></span>
    </Switch>
  );
};
ToggleSwitch.propTypes = {
  id: PropTypes.string,
  isToggled: PropTypes.bool,
  onToggle: PropTypes.func,
  disabled: PropTypes.bool,
};

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  span:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    top: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: var(--active-color);
  }

  input:checked + span:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
`;

export default ToggleSwitch;
