import BasePainterTool from './BasePainterTool';
import TOOL_NAMES from './constants/toolNames';
import { refreshViewport } from './commands';

export default class RegionGrowingTool extends BasePainterTool {
  constructor(props = {}) {
    const defaultProps = { name: TOOL_NAMES.REGION_GROWING_TOOL };
    const initialProps = Object.assign(defaultProps, props);
    super(initialProps, 'region-growing');
  }
  _drawingUpCallback(evt, shouldUpdate = true) {
    const eventData = evt.detail;
    const element = eventData.element;

    this._isDrawing = false;
    delete this._imageId;
    delete this._cursorCanvasPosition;

    if (shouldUpdate) {
      const painter = this._getPainter(element);
      painter.commit(evt);
      // painter.commit3D(evt);
      refreshViewport();
    }
  }
}
