import cornerstone from 'cornerstone-core';
import cornerstoneTools, { Synchronizer } from 'cornerstone-tools';

import pixelSpacingPanZoomSynchronizer from './pixelSpacingPanZoomSynchronizer';

const scrollSynchronizer = new Synchronizer(
  cornerstone.EVENTS.NEW_IMAGE,
  cornerstoneTools.stackImagePositionOffsetSynchronizer
);

const panZoomSynchronizer = new Synchronizer(
  cornerstone.EVENTS.IMAGE_RENDERED,
  pixelSpacingPanZoomSynchronizer
);

const wwwcSynchronizer = new Synchronizer(
  cornerstone.EVENTS.IMAGE_RENDERED,
  cornerstoneTools.wwwcSynchronizer
);

export { scrollSynchronizer, panZoomSynchronizer, wwwcSynchronizer };
