import {
  MEASUREMENT_TECHNIQUE,
  TRACKING_TYPES,
  NON_TARGET_RESPONSE,
} from '../../constants/guideline.js';

/**
 * @param {String} stat.date
 * @param {String} stat.baselineDate
 * @param {String} stat.nadirDate
 * @param {Boolean} stat.existROIContours
 * @param {Number} stat.summation.sumDiameter
 * @param {Number} stat.summation.sumVolume
 * @param {Number} stat.fromBaseline.sumDiameter
 * @param {Number} stat.fromBaseline.sumVolume
 * @param {Number} stat.fromNadir.sumDiameter
 * @param {Number} stat.fromNadir.sumVolume
 * @param {String[]} stat.ROINames
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.minDiameterIncrease
 * @param {Number} criteria.minDiameterDecrease
 * @param {Number} criteria.minVolumeIncrease
 * @param {Number} criteria.minVolumeDecrease
 */
export function getNonTargetResponse(stat, criteria) {
  const measurementTechnique =
    criteria?.measurementTechnique || MEASUREMENT_TECHNIQUE.BIDIMENSIONAL;
  const keyMap = {
    [MEASUREMENT_TECHNIQUE.BIDIMENSIONAL]: {
      sum: 'sumDiameter',
      change: 'sumDiameter',
      increase: 'minDiameterIncrease',
      decrease: 'minDiameterDecrease',
    },
    [MEASUREMENT_TECHNIQUE.VOLUMETRIC]: {
      sum: 'sumVolume',
      change: 'sumVolume',
      increase: 'minVolumeIncrease',
      decrease: 'minVolumeDecrease',
    },
  };
  const key = keyMap[measurementTechnique];
  const minIncrease = criteria[key.increase] * 100;
  const changeFromBaseline = stat.fromBaseline[key.change];
  const changeFromNadir = stat.fromNadir[key.change];
  const existAbsoluteDiameterChange =
    stat.fromNadir.existAbsoluteDiameterChange;
  const existAbsoulteSODChange =
    stat.fromNadir.existAbsoluteSumOfDiameterChange;

  let result;
  let message;
  const noChange =
    // changeFromNadir < minIncrease
    changeFromBaseline < minIncrease
      ? ''
      : criteria.absoluteSumOfDiameterChange && !existAbsoulteSODChange
      ? `, sum of change in non-target lesions is not greater than ${criteria['absoluteSumOfDiameterChange']}mm from ${stat.nadirDate} to ${stat.date}`
      : criteria.absoluteDiameterChange && !existAbsoluteDiameterChange
      ? `, no change in non-target lesion greater than ${criteria['absoluteDiameterChange']}mm from ${stat.nadirDate} to ${stat.date}`
      : '';

  if (
    /** Vysioneer: use baseline instead of nadir */
    // changeFromNadir >= minIncrease &&
    changeFromBaseline >= minIncrease &&
    existAbsoulteSODChange &&
    existAbsoluteDiameterChange
  ) {
    /** progressive disease */
    result = NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE;
    // message = `Non-target Lesion Progressive Disease: ${changeFromNadir}% change from timepoint ${stat.nadirDate} to ${stat.date}`;
    message = `Non-target Lesion Progressive Disease: ${changeFromBaseline}% change from timepoint ${stat.baselineDate} to ${stat.date}`;
  } else if (changeFromBaseline <= -100) {
    /** complete response */
    result = NON_TARGET_RESPONSE.COMPLETE_RESPONSE;
    message = `Non-target Lesion Complete Response: ${changeFromBaseline}% change from timepoint ${stat.baselineDate} to ${stat.date}`;
  } else {
    /** non-progressive disease */
    result = NON_TARGET_RESPONSE.NON_PROGRESSIVE_RESPONSE;
    message = `Non-target Lesion Non-progressive Disease: ${changeFromBaseline}% change from timepoint ${stat.baselineDate} to ${stat.date}${noChange}`;
  }

  const sum = stat.summation[key.sum];
  if (stat.date !== stat.baselineDate && sum === 0) {
    /** complete response */
    result = NON_TARGET_RESPONSE.COMPLETE_RESPONSE;
    message = `Non-target Lesion Complete Response: no lesion found at timepoint ${stat.date}`;
  }

  if (!stat.existROIContours) {
    /** no lesion */
    result = NON_TARGET_RESPONSE.NONE;
    message = `Non-target Lesion None: no non-target lesion selected`;
  }

  return {
    date: stat.date,
    baselineDate: stat.baselineDate,
    nadirDate: stat.nadirDate,
    lesions: stat.ROINames,
    fromBaseline: changeFromBaseline,
    fromNadir: changeFromNadir,
    trackingType: TRACKING_TYPES.NONTARGET,
    result: result,
    message: message,
  };
}

export default getNonTargetResponse;
