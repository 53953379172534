/**
 * @param {*} bitsAllocated
 * @param {*} pixelRepresentation
 * @param {*} dimensions
 * @returns {Int16Array|Uint16Array} pixel array
 */
export default function getTypedPixelArray(
  bitsAllocated,
  pixelRepresentation,
  dimensions
) {
  const signed = pixelRepresentation === 1;
  if (bitsAllocated === 8) {
    if (signed) {
      throw new Error(
        '8 Bit signed images are not yet supported by this plugin.'
      );
    } else {
      throw new Error(
        '8 Bit unsigned images are not yet supported by this plugin.'
      );
    }
  }

  let typedPixelArray;
  if (bitsAllocated === 16) {
    typedPixelArray = signed
      ? new Int16Array(dimensions[0] * dimensions[1] * dimensions[2])
      : new Uint16Array(dimensions[0] * dimensions[1] * dimensions[2]);
  } else {
    throw new Error(`Unssuported bit: ${bitsAllocated}`);
  }
  return typedPixelArray;
}
