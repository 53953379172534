/**
 * Objects to be used to throw errors
 */
class StackError extends Error {
  constructor(message) {
    super();
    this.message = message;
    this.stack = new Error().stack;
    this.name = this.constructor.name;
  }
}

export default StackError;
