import React from 'react';
import PropTypes from 'prop-types';

import { H6 } from '@platform/ui';
import LesionSnapshot from './LesionSnapshot';
import EmptySquare from './EmptySquare';

export default function LesionItem({ lesion, updateLesionNote }) {
  const current = lesion.currentTimepoint;
  return (
    <>
      <div style={{ display: 'flex', gridGap: '10px' }}>
        <div style={{ margin: '10px' }}>
          {lesion.displayImage ? (
            <LesionSnapshot
              id={current.id}
              imageId={current.imageId}
              scale={current.scale}
              translation={current.translation}
              width={200}
              height={200}
            />
          ) : (
            <EmptySquare width={200} height={50} />
          )}
        </div>
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              margin: '10px 0',
            }}
          >
            {[
              { label: 'Name', value: current.ROIName },
              { label: 'Date', value: current.dateRange },
              { label: 'Long Axis', value: current.longAxis, unit: 'mm' },
              { label: 'Status', value: current.status },
              { label: 'Short Axis', value: current.shortAxis, unit: 'mm' },
              { label: 'Location', value: current.location },
              { label: 'Volume', value: current.volume, unit: 'cc' },
            ].map(({ label, value, unit }) => (
              <div key={label} style={{ display: 'flex' }}>
                <H6 style={{ color: 'var(--active-color)' }}>{`${label}:`}</H6>
                <H6 style={{ marginLeft: '10px' }}>{`${value}`}</H6>
                <H6
                  style={{ color: 'var(--active-color)', marginLeft: '10px' }}
                >
                  {`${unit || ''}`}
                </H6>
              </div>
            ))}
          </div>
          {lesion.displayImage && (
            <div>
              <div style={{ display: 'flex' }}>
                <H6 style={{ color: 'var(--active-color)' }}>{`Note:`}</H6>
                <textarea
                  rows="3"
                  cols="50"
                  style={{
                    width: '100%',
                    margin: '0 10px',
                    color: 'white',
                    background: 'transparent',
                  }}
                  value={current.note}
                  onChange={evt =>
                    updateLesionNote(current.ROINumber, evt.target.value)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {lesion.previousTimepoints.length > 0 && (
        <div style={{ display: 'flex', margin: '10px' }}>
          {lesion.previousTimepoints.map(timepoint => (
            <div key={timepoint.id} style={{ margin: '0 10px 0 0' }}>
              {lesion.displayImage ? (
                <LesionSnapshot
                  id={timepoint.id}
                  imageId={timepoint.imageId}
                  scale={timepoint.scale}
                  translation={timepoint.translation}
                  width={160}
                  height={160}
                />
              ) : (
                <EmptySquare width={160} height={160} />
              )}
              <div
                style={{
                  width: '160px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <H6>{timepoint.date}</H6>
              </div>
            </div>
          ))}
        </div>
      )}
      <hr style={{ border: `1px solid var(--ui-gray)` }} />
    </>
  );
}
LesionItem.propTypes = {
  lesion: PropTypes.shape({
    displayImage: PropTypes.bool,
    currentTimepoint: PropTypes.shape({
      id: PropTypes.string,
      imageId: PropTypes.string,
      scale: PropTypes.number,
      translation: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
      ROIName: PropTypes.string,
      ROINumber: PropTypes.number,
      dateRange: PropTypes.string,
      longAxis: PropTypes.number,
      shortAxis: PropTypes.number,
      volume: PropTypes.number,
      status: PropTypes.string,
      location: PropTypes.string,
      note: PropTypes.string,
    }),
    previousTimepoints: PropTypes.array,
  }),
  updateLesionNote: PropTypes.func,
};
