import searchForStudies from './qido/searchForStudies.js';
import searchForSeries from './qido/searchForSeries.js';
import searchForInstances from './qido/searchForInstances.js';
import retrieveStudyMetadata from './wado/retrieveStudyMetadata.js';
import retrieveSeriesMetadata from './wado/retrieveSeriesMetadata.js';
import retrieveInstanceMetadata from './wado/retrieveInstanceMetaData.js';
import { createStudyFromSOPInstanceList } from './wado/studyInstanceHelpers.js';
import naturalizeDataset from './wado/naturalizeDataset.js';

const QIDO = {
  searchForStudies,
  searchForSeries,
  searchForInstances,
};

const WADO = {
  retrieveStudyMetadata,
  retrieveSeriesMetadata,
  retrieveInstanceMetadata,
  createStudyFromSOPInstanceList,
  naturalizeDataset,
};

export { QIDO, WADO };
