import getMeasurementFromContour from './getMeasurementFromContour';
import transformPointsToPhysicalById from '../utils/transformPointsToPhysicalById';

function getMeasurementOfSlice(data, imageId, imagePlane) {
  const metadata = data
    .map(d => getMeasurementFromContour(d.handles.points, imageId, imagePlane))
    .reduce(
      (sum, { area, product, longAxis, shortAxis, centroid }) => {
        sum.area += area;
        sum.product += product;
        sum.mergedLongAxisValue += longAxis.value;
        sum.mergedShortAxisValue += shortAxis.value;
        sum.longAxes.push(longAxis);
        sum.shortAxes.push(shortAxis);
        sum.centroids.push(centroid);
        if (longAxis.value > sum.mainAreaLongAxis.value) {
          sum.mainAreaLongAxis = longAxis;
          sum.mainAreaShortAxis = shortAxis;
          sum.mainAreaCentroid = centroid;
        }
        return sum;
      },
      {
        area: 0,
        product: 0,
        mergedLongAxisValue: 0,
        mergedShortAxisValue: 0,
        longAxes: [],
        shortAxes: [],
        centroids: [],
        mainAreaLongAxis: { points: [], value: 0 },
        mainAreaShortAxis: { points: [], value: 0 },
        mainAreaCentroid: { x: null, y: null },
      }
    );
  const { centroids: cds, mainAreaCentroid: macd } = metadata;
  return {
    ...metadata,
    pCentroids: transformPointsToPhysicalById(cds, imageId),
    mainAreaPhysicalCentroid: transformPointsToPhysicalById([macd], imageId)[0],
  };
}

export default getMeasurementOfSlice;
