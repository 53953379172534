import getTargetResponse from './getTargetResponse.js';

/**
 * @param {Object[]} targetStats
 * @param {String} criteria.measurementTechnique
 * @param {Number} criteria.minDiameterIncrease
 * @param {Number} criteria.minDiameterDecrease
 * @param {Number} criteria.minVolumeIncrease
 * @param {Number} criteria.minVolumeDecrease
 */
function getTargetResponses(targetStats, criteria) {
  return targetStats.map(stat => getTargetResponse(stat, criteria));
}

export default getTargetResponses;
