const vector = {
  add: function(v1, v2) {
    return v1.map((v, i) => v + v2[i]);
  },
  multiply: function(v1, scale) {
    return v1.map((v, i) => v * scale);
  },
};

export default vector;
