import getNumberOfDays from './getNumberOfDays.js';
import transformStudyDateToDate from './transformStudyDateToDate.js';
import findTimepointIndex from './findTimepointIndex.js';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @returns {string}
 */
function getTimeToResponse(reports) {
  const responsiveIndex = findTimepointIndex(reports, 'responsive');
  const progressiveIndex = findTimepointIndex(reports, 'progressive');
  if (responsiveIndex < 0) {
    return 0;
  }
  if (progressiveIndex > 0 && progressiveIndex < responsiveIndex) {
    return 0;
  }
  const responsiveDate = transformStudyDateToDate(
    reports[responsiveIndex].date
  );
  const baselineIndex = findTimepointIndex(reports, 'baseline');
  const baselineDate = transformStudyDateToDate(reports[baselineIndex].date);
  return getNumberOfDays(baselineDate, responsiveDate);
}

export default getTimeToResponse;
