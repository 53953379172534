import { useSelector } from 'react-redux';

export default function usePrimary() {
  const { PrimaryRTSS } = useSelector(state => state.linkage);
  const { dicomRT } = useSelector(state => state.extensions);
  const { selectedStructureSetUID } = dicomRT;
  if (!PrimaryRTSS.SeriesInstanceUID || !selectedStructureSetUID) {
    return false;
  }
  const primary = selectedStructureSetUID === PrimaryRTSS.SeriesInstanceUID;
  return primary;
}
