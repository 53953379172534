import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  studyData: {},
  displayedSeries: [],
};

const studies = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STUDY_DATA': {
      const updatedStudyData = cloneDeep(state.studyData);
      updatedStudyData[action.StudyInstanceUID] = cloneDeep(action.data);
      return Object.assign({}, state, { studyData: updatedStudyData });
    }
    case 'SET_DISPLAYED_SERIES': {
      const { series } = action.payload;
      return { ...state, displayedSeries: series };
    }
    default:
      return state;
  }
};

export default studies;
