import { createContext } from 'react';

class TokenError extends Error {
  constructor(statusCode, message) {
    super(message);
    this.name = 'TokenError';
    this.statusCode = statusCode;
  }
}

export class API {
  constructor(host, config = {}) {
    this.host = host;
    this.apiConfig = {};
    this.enabled = config.enableOnPremiseAPI;
  }
  isEnabled() {
    return this.enabled;
  }
  handleError(res) {
    if (res.status >= 400 && res.status <= 500) {
      if (res.status === 401) {
        throw new TokenError(401, 'Unauthorized');
      } else if (res.status === 403) {
        throw new TokenError(403, 'Forbidden');
      } else {
        throw new Error(res.statusText);
      }
    }
  }
  handleRedirect(response) {
    if (
      response.redirected &&
      response.url.indexOf(
        `${this.host}/auth/realms/vbrain/protocol/openid-connect/auth`
      ) === 0
    ) {
      window.location.reload();
    }
  }
  async getResponseData(response) {
    const resp_text = await response.text();
    const contentType = response.headers.get('Content-Type');
    if (
      resp_text &&
      contentType &&
      contentType.indexOf('application/json') !== -1
    ) {
      return JSON.parse(resp_text);
    } else {
      return resp_text;
    }
  }

  /** auth */
  async checkMe() {
    const res = await fetch(`${this.host}/me`, { credentials: 'include' });
    if (res.redirected) return false;
    return true;
  }

  async me() {
    const res = await fetch(`${this.host}/me`, { credentials: 'include' });
    this.handleRedirect(res);
    return await res.json();
  }
  /** end auth */

  /** log */
  async log(log) {
    const body = JSON.stringify(log);
    await fetch(`${this.host}/api/v1/log`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: body,
    });
  }
  /** end log */

  /** analyze */
  async analyze({ study_id, series_id, instance_id }) {
    await fetch(
      `${this.host}/api/v1/analyze/studies/${study_id}/series/${series_id}/instances/${instance_id}`,
      {
        method: 'POST',
        mode: 'cors',
        headers: { accept: 'application/json' },
      }
    );
  }
  /** end analyze */

  /** compute */
  async computeMargin(input) {
    const res = await fetch(`${this.host}/api/v1/compute/margin`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(input),
    });
    return res;
  }
  /** end compute */

  /** config */
  async fetchConfig() {
    const res = await fetch(`${this.host}/api/v1/config`, {
      credentials: 'include',
    });
    this.handleRedirect(res);
    return await res.json();
  }
  setConfig(config) {
    this.apiConfig = config;
  }
  getConfig() {
    return this.apiConfig;
  }
  /** end config */

  /** linkage */
  async fetchLinkage({ study_id, series_id, instance_id }) {
    const url = `${this.host}/api/v1/linkage/studies/${study_id}/series/${series_id}/instances/${instance_id}`;
    const res = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: { accept: 'application/json' },
    });
    this.handleRedirect(res);
    this.handleError(res);
    const resData = await this.getResponseData(res);
    return resData.content;
  }
  async submitLinkage({ patient_id, study_id, series_id, instance_id }, data) {
    const url = `${this.host}/api/v1/linkage/studies/${study_id}/series/${series_id}/instances/${instance_id}`;
    const body = JSON.stringify({
      patient_id: patient_id,
      study_instance_uid: study_id,
      series_instance_uid: series_id,
      sop_instance_uid: instance_id,
      content: data,
    });
    const res = await fetch(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: body,
    });
    this.handleError(res);
    return true;
  }
  getUpdatedLinkage(linkage, set, newSet) {
    if (set.ROIContours.length !== newSet.StructureSetROISequence.length) {
      throw new Error('Structure sets unmatched');
    }
    const numbersMapping = {};
    set.ROIContours.forEach((roi, index) => {
      numbersMapping[roi.ROINumber] =
        newSet.StructureSetROISequence[index].ROINumber;
    });
    const newLinkage = {
      PrimaryRTSS: {
        ...linkage.PrimaryRTSS,
        StudyInstanceUID: newSet.StudyInstanceUID,
        SeriesInstanceUID: newSet.SeriesInstanceUID,
        SOPInstanceUID: newSet.SOPInstanceUID,
      },
      ReferencedRTSS: linkage.ReferencedRTSS.map(rtss => {
        if (rtss.SeriesInstanceUID === set.SeriesInstanceUID) {
          return {
            ...rtss,
            StudyInstanceUID: newSet.StudyInstanceUID,
            SeriesInstanceUID: newSet.SeriesInstanceUID,
            SOPInstanceUID: newSet.SOPInstanceUID,
          };
        }
        return rtss;
      }),
      Links: linkage.Links.map(link => {
        return {
          ...link,
          RTMappings: link.RTMappings.map(mapping => {
            if (mapping.SeriesInstanceUID === set.SeriesInstanceUID) {
              return {
                StudyInstanceUID: newSet.StudyInstanceUID,
                SeriesInstanceUID: newSet.SeriesInstanceUID,
                SOPInstanceUID: newSet.SOPInstanceUID,
                ROINumbers: mapping.ROINumbers.map(
                  number => numbersMapping[number]
                ),
              };
            }
            return mapping;
          }),
        };
      }),
    };
    return newLinkage;
  }
  /** end linkage */
}

export const APIContext = createContext();
