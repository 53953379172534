import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve a list of instances using a QIDO call
 * @param server
 * @param StudyInstanceUID
 * @param SeriesInstanceUID
 * @throws ECONNREFUSED
 * @returns {Array} The list of instance object
 */
export default async function searchForInstances(
  server,
  StudyInstanceUID,
  SeriesInstanceUID
) {
  const dicomWebClient = getDICOMwebClient(server.qidoRoot, server);
  const instances = await dicomWebClient.searchForInstances({
    studyInstanceUID: StudyInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
  });
  return instances.map(instance => {
    return {
      ...dcmjs.data.DicomMetaDictionary.naturalizeDataset(instance),
      StudyInstanceUID,
      SeriesInstanceUID,
    };
  });
}
