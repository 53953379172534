import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../elements';
import getScrollbarSize from '../utils/getScrollbarSize.js';
import throttled from '../utils/throttled.js';
import './ScrollableArea.styl';

export function ScrollableArea(props) {
  const ref = useRef(null);
  const [scrollAreaClasses, setScrollAreaClasses] = useState('');

  const scrollNavDown = () => {
    const {
      scrollTop: currentTop,
      offsetHeight: height,
      scrollHeight,
    } = ref.current;

    const limit = scrollHeight - height;
    let scrollTop = currentTop + props.scrollStep;
    scrollTop = scrollTop > limit ? limit : scrollTop;
    ref.current.scrollTop = scrollTop;
  };

  const scrollNavUp = () => {
    const { scrollTop: currentTop } = ref.current;
    let scrollTop = currentTop - props.scrollStep;
    scrollTop = scrollTop < 0 ? 0 : scrollTop;
    ref.current.scrollTop = scrollTop;
  };

  const adjustMargins = () => {
    if (props.hideScrollbar) {
      const x = props.scrollX ? 1 : 0;
      const y = props.scrollY ? 1 : 0;
      const scrollbarSize = getScrollbarSize();
      ref.current.style.marginRight = `${0 - scrollbarSize[0] * y}px`;
      ref.current.style.marginBottom = `${0 - scrollbarSize[1] * x}px`;
    }
  };

  const scrollHandler = () => {
    const {
      offsetHeight: height,
      scrollTop: scrollTop,
      scrollHeight,
    } = ref.current;
    let scrollAreaClasses = '';

    // Check if can scroll up
    if (scrollTop) {
      scrollAreaClasses += 'canScrollUp';
    }

    // Check if can scroll down
    if (scrollTop + height < scrollHeight) {
      scrollAreaClasses += ' canScrollDown';
    }

    setScrollAreaClasses(scrollAreaClasses);
  };

  useEffect(() => {
    adjustMargins();
    scrollHandler();
    window.addEventListener('resize', adjustMargins);
    return () => {
      window.removeEventListener('resize', adjustMargins);
    };
  }, []);

  let scrollableClass = 'scrollable';
  if (props.scrollableClass) scrollableClass += ` ${props.scrollableClass}`;
  if (props.scrollX) scrollableClass += ` scrollX`;
  if (props.scrollY) scrollableClass += ` scrollY`;

  const scrollHandlerThrottled = throttled(150, scrollHandler);

  return (
    <div className={`scrollArea ${props.class} ${scrollAreaClasses}`}>
      <div
        className={scrollableClass}
        ref={element => {
          ref.current = element;
        }}
        onScroll={scrollHandlerThrottled}
        onMouseEnter={scrollHandlerThrottled}
        onTransitionEnd={scrollHandlerThrottled}
      >
        {props.children}
      </div>
      <div className="scrollNav scrollNavUp" onClick={scrollNavUp}>
        {/* <svg className="scrollNavIcon"> */}
        <Icon name="angle-double-up" />
      </div>
      <div className="scrollNav scrollNavDown" onClick={scrollNavDown}>
        <Icon name="angle-double-down" />
      </div>
    </div>
  );
}
ScrollableArea.propTypes = {
  children: PropTypes.node.isRequired,
  class: PropTypes.string,
  scrollableClass: PropTypes.string,
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  scrollStep: PropTypes.number,
};
ScrollableArea.defaultProps = {
  hideScrollbar: true,
  class: 'flex-grow fit',
  scrollY: true,
  scrollX: false,
  scrollStep: 100,
};
