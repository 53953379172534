import cornerstone from 'cornerstone-core';
import { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { booleanOperation } from './booleanOperation';
const draw = importInternal('drawing/draw');

export function expansionPainter(toolState, structureSetSeriesInstanceUid) {
  let state = _.cloneDeep(toolState);
  let expansionData = [];
  let avoidROINumber = null;

  return {
    getState: function() {
      return state;
    },
    commit: function(evt) {
      if (!expansionData) return;
      state.data = [
        ...state.data,
        ...expansionData.map(data => {
          return {
            ...data,
            structureSetSeriesInstanceUid: structureSetSeriesInstanceUid,
            ROINumber: evt.ROINumber,
          };
        }),
      ];
      expansionData = [];
    },
    update: function(evt) {
      expansionData = evt.data;
      avoidROINumber = evt.avoidROINumber;
      this.avoidROINumber = avoidROINumber;
      if (avoidROINumber || avoidROINumber === 0) {
        const avoidData = state.data.filter(d => d.ROINumber == avoidROINumber);
        expansionData = getSubtractedData(expansionData, avoidData);
      }
    },
    cursor: function(evt, context) {
      if (expansionData.length === 0) return;
      const element = evt.detail.element;
      const pointsData = expansionData.map(data =>
        data.handles.points.map(point =>
          cornerstone.pixelToCanvas(element, point)
        )
      );
      pointsData.forEach(points => {
        if (points.length === 0) return;
        draw(context, context => {
          context.strokeStyle = 'rgba(217, 83, 79, 1)';
          context.fillStyle = 'rgba(217, 83, 79, 0.2)';
          context.lineWidth = 3;
          context.beginPath();
          context.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            context.lineTo(points[i].x, points[i].y);
          }
          context.closePath();
          context.stroke();
          context.fill();
        });
      });
      return true;
    },
  };
}

function getSubtractedData(subjectData, objectData) {
  const subtractedData = [];
  const sPolygons = subjectData.map(d => [
    d.handles.points.map(v => [v.x, v.y]),
  ]);
  const oPolygons = objectData.map(d => [
    d.handles.points.map(v => [v.x, v.y]),
  ]);
  const booleanPolygons = booleanOperation('subtract', sPolygons, oPolygons);
  booleanPolygons.forEach(polygon => {
    polygon.forEach(segment => {
      subtractedData.push({
        handles: { points: segment.map(v => ({ x: v[0], y: v[1] })) },
      });
    });
  });
  return subtractedData;
}
