import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { PACSContext } from '@platform/viewer';
import {
  Box,
  Button,
  Modal,
  CheckBox,
  InputBox,
  TextField,
  MenuItem,
  Typography,
  Divider,
  LoadingSpinner,
  Alert,
} from '@platform/ui';

export function SubmitRTStructForm({ open, onClose, defaultCreator }) {
  const pacs = useContext(PACSContext);
  const isForwardEnabled = pacs && pacs.isEnabled();
  const [modalities, setModalities] = useState([]);
  const [modalityId, setModalityId] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('Vysioneer DICOMRT');
  const [creator, setCreator] = useState(defaultCreator);
  const [inputError, setInputError] = useState(null);
  const [searchingError, setSearchingError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isForwarding, setIsForwarding] = useState(false);
  const warningMessage = (
    <p style={{ margin: 0 }}>
      The ROI contours of the DICOM RTSTRUCT should be reviewed by medical
      professional before being used for clinical purpose.<br></br>Redundant
      contours(e.g. OARs, EX GTVs) should be deleted before finalizing treatment
      plan.
    </p>
  );

  const getModalities = useCallback(async () => {
    const modalities = [];
    try {
      setIsSearching(true);
      const ids = await pacs.listDICOMmodalities();
      if (ids.length === 0) {
        setSearchingError('No application entities found');
        return [];
      }
      for (const id of ids) {
        const config = await pacs.getModalityConfig(id);
        modalities.push({ ...config, id });
      }
    } catch (err) {
      setSearchingError('Failed to load application entities');
    } finally {
      setIsSearching(false);
    }
    return modalities;
  }, [pacs]);

  const checkForm = () => {
    if (!description) {
      setInputError('Series Description is empty');
      return false;
    }
    if (isForwarding && !modalityId) {
      setInputError('Please select an Application Entity');
      return false;
    }
    return true;
  };

  const reset = () => {
    setName('');
    setDescription('Vysioneer DICOMRT');
    setIsForwarding(false);
    setModalityId('');
    setSearchingError(null);
    setInputError(null);
  };

  const handleSubmitClick = async () => {
    const confirmed = checkForm();
    if (!confirmed) return;
    try {
      setIsSubmitting(true);
      await onClose(true, {
        StructureSetName: name,
        SeriesDescription: description,
        ContentCreatorName: creator,
        isForwarding,
        modalityId,
      });
    } finally {
      setIsSubmitting(false);
      reset();
    }
  };

  useEffect(() => {
    (async function() {
      if (isForwarding) {
        const modalities = await getModalities();
        setModalities(modalities);
      }
    })();
  }, [isForwarding, getModalities]);

  return (
    <Modal open={open} onClose={() => onClose(false)}>
      <div>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {'Save RTSTRUCT'}
        </Typography>
        <Divider />
        <InputBox label={'Structure Set Name:'}>
          <TextField
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
          />
        </InputBox>
        <InputBox label={'Series Description:'}>
          <TextField
            value={description}
            onChange={e => {
              setInputError(null);
              setDescription(e.target.value);
            }}
            fullWidth
          />
        </InputBox>
        <InputBox label={'Content Creator Name:'}>
          <TextField
            value={creator}
            onChange={e => setCreator(e.target.value)}
            fullWidth
          />
        </InputBox>
        {isForwardEnabled && (
          <InputBox label={'Forward to PACS:'}>
            <Box style={{ display: 'flex' }}>
              {isForwarding ? (
                isSearching ? (
                  <LoadingSpinner />
                ) : modalities.length > 0 ? (
                  <TextField
                    select
                    value={modalityId}
                    onChange={e => {
                      setInputError(null);
                      setModalityId(e.target.value);
                    }}
                    fullWidth
                  >
                    {modalities.map(m => (
                      <MenuItem key={m.id} value={m.id}>
                        {m.AET}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : (
                  <div />
                )
              ) : (
                <div />
              )}
              <CheckBox
                color="primary"
                checked={isForwarding}
                onChange={e => {
                  setIsForwarding(e.target.checked);
                  if (!e.target.checked) {
                    setSearchingError(null);
                    setInputError(null);
                  }
                }}
              />
            </Box>
          </InputBox>
        )}
        <div style={{ margin: '10px 0' }}>
          {isForwarding && searchingError ? (
            <Alert severity="error">{searchingError}</Alert>
          ) : inputError ? (
            <Alert severity="error">{inputError}</Alert>
          ) : (
            <Alert severity="warning">{warningMessage}</Alert>
          )}
        </div>
      </div>
      <div style={{ marginTop: 'auto' }}>
        {isSubmitting ? (
          <LoadingSpinner />
        ) : (
          <Button
            disabled={searchingError}
            onClick={handleSubmitClick}
            color="primary"
            variant="outlined"
            medium
            fullWidth
          >
            Save
          </Button>
        )}
      </div>
    </Modal>
  );
}
SubmitRTStructForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  defaultCreator: PropTypes.string,
};
