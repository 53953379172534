const HIGH_PRIORITY_MODALITIES = Object.freeze([
  'MR',
  'CT',
  'single_slice_spiral_ct',
  'multi_slice_spiral_ct',
  'PT',
  'bone_scan',
]);

export default function isHighPriorityModality(Modality) {
  return HIGH_PRIORITY_MODALITIES.includes(Modality);
}
