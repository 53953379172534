import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: { main: '#BFCBED' },
    secondary: { main: '#8784EE' },
    warning: { main: '#E29E4A' },
    background: { paper: '#151A1F' },
  },
});

export default theme;
