import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@platform/ui';

const FooterButton = props => {
  const { onClick, disabled, children } = props;
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      style={{ margin: '0 0 5px' }}
      color="secondary"
      variant="contained"
      medium
      fullWidth
    >
      {children}
    </Button>
  );
};
FooterButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FooterButton;
