import getTimepoint from './getTimepoint';
import getSeriesUIDByStructureSetUID from './getSeriesUIDByStructureSetUID';

export default function getTimepoints(
  series,
  structureSets,
  seriesStructureMap
) {
  const timepoints = structureSets
    .map(set => {
      const referencedSeriesUID = getSeriesUIDByStructureSetUID(
        set.SeriesInstanceUID,
        seriesStructureMap
      );
      const s = series.find(
        set => set.SeriesInstanceUID === referencedSeriesUID
      );
      const timepoint = getTimepoint(s.instances, set);
      return timepoint;
    })
    .filter(t => !!t.SeriesDate);
  timepoints.sort((a, b) => Number(a.SeriesDate) - Number(b.SeriesDate));
  return timepoints;
}
