import React from 'react';
import PropTypes from 'prop-types';

import { ScrollableArea } from '@platform/ui';
import { ViewportController } from '@platform/extension-cornerstone';

export const ViewportSection = ({ viewports, activeIndex }) => {
  if (!viewports?.[activeIndex]) return null;
  return (
    <ScrollableArea>
      <div style={{ padding: '0 0 40px' }}>
        <ViewportController
          key={`${viewports[activeIndex].SeriesInstanceUID}+${activeIndex}+${viewports.length}`}
          viewports={viewports}
          activeIndex={activeIndex}
        />
      </div>
    </ScrollableArea>
  );
};
ViewportSection.propTypes = {
  viewports: PropTypes.object,
  activeIndex: PropTypes.number,
};
