/**
 * @param {String} StudyDate
 */
function transformStudyDateToDate(StudyDate) {
  const date = new Date(
    Number(StudyDate.slice(0, 4)),
    Number(StudyDate.slice(4, 6)) - 1,
    Number(StudyDate.slice(6, 8))
  );
  return date;
}

export default transformStudyDateToDate;
