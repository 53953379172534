import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { CriteriaForm } from '@platform/ui';
import { getTimepoints } from '../models/getTimepoints';

export const CriteriaSection = ({
  imageSets,
  ROIContours,
  criteria: defaultCriteria,
  onUpdateCriteriaClick,
}) => {
  const role = new URLSearchParams(window.location.search).get('role');
  const timepoints = useMemo(() => getTimepoints(imageSets, ROIContours), [
    imageSets,
    ROIContours,
  ]);

  return (
    <CriteriaForm
      defaultCriteria={defaultCriteria}
      onUpdateCriteriaClick={onUpdateCriteriaClick}
      timepoint={timepoints[0]}
      component={'Viewer'}
      readOnly={['trial-owner', 'trial-reviewer', 'reader'].includes(role)}
    />
  );
};
CriteriaSection.propTypes = {
  imageSets: PropTypes.array,
  ROIContours: PropTypes.array,
  criteria: PropTypes.any,
  onUpdateCriteriaClick: PropTypes.func,
};
