import cornerstone from 'cornerstone-core';

import mprMetaDataStore from './mprMetaDataStore.js';
import calculateDimensions from './lib/calculateDimensions.js';
import createVtkVolume from './vtk/createVtkVolume.js';
import createImageSlice from './vtk/createImageSlice.js';

/**
 * @param {String} imageId
 * @returns {Object} image load object
 */
export default function(imageId) {
  const imageLoadObject = {
    promise: undefined,
    cancelFn: undefined,
    decacheData: undefined,
  };
  imageLoadObject.promise = createImage(imageId);
  return imageLoadObject;
}

/**
 * cache
 */
export const mprState = { series: {}, vtkVolumes: {} };

/**
 * @param {String} SeriesInstanceUID
 * @param {String} orientation image orientation
 * @param {String} origin image origin
 * @param {String} rotation vtk orientation
 * @param {String} position vtk position
 * @returns {String} image id
 */
export function getMprImageId(
  SeriesInstanceUID,
  orientation = '1,0,0,0,1,0',
  origin = '0,0,0',
  rotation = '1,0,0,0,1,0',
  position = 'center'
) {
  const scheme = 'mpr';
  return `${scheme}:${SeriesInstanceUID}:${orientation}:${origin}:${rotation}:${position}`;
}

/**
 * @param {String} imageId
 * @returns {Object} image
 */
async function createImage(imageId) {
  const [
    ,
    SeriesInstanceUID,
    orientationAsString,
    originAsString,
    rotationAsString,
    positionAsString,
  ] = imageId.split(':');

  /** create or get vtk volume */
  let vtkVolume = mprState.vtkVolumes[SeriesInstanceUID];
  if (!vtkVolume) {
    const images = [];
    const imageIds = mprState.series[SeriesInstanceUID];
    const metaDataMap = new Map();
    for (let i = 0; i < imageIds.length; i++) {
      const imageId = imageIds[i];
      const image = await cornerstone.loadImage(imageId);
      const imagePlane = cornerstone.metaData.get('imagePlaneModule', imageId);
      const { imagePositionPatient } = imagePlane;
      metaDataMap.set(imageId, imagePlane);
      images.push({ ...image, imagePositionPatient });
    }
    const sampleId = imageIds[0];
    const imagePixel = cornerstone.metaData.get('imagePixelModule', sampleId);
    const { bitsAllocated, pixelRepresentation } = imagePixel;
    vtkVolume = createVtkVolume(images, {
      ...calculateDimensions(metaDataMap),
      bitsAllocated,
      pixelRepresentation,
    });
    mprState.vtkVolumes[SeriesInstanceUID] = vtkVolume;
  }

  /** create and map slice to cornerstone image */
  const result = createImageSlice(vtkVolume, {
    SeriesInstanceUID,
    orientation: orientationAsString,
    origin: originAsString,
    rotation: rotationAsString,
    position: positionAsString,
  });

  /** set metadata to store */
  mprMetaDataStore.set(imageId, result.metaData);

  const image = {
    ...result.slice,
    imageId,
    intercept: vtkVolume.intercept,
    slope: vtkVolume.slope,
    windowCenter: vtkVolume.windowCenter,
    windowWidth: vtkVolume.windowWidth,
    isMpr: true,
  };

  if (image.windowCenter === undefined || image.windowWidth === undefined) {
    const maxVoi = image.maxPixelValue * image.slope + image.intercept;
    const minVoi = image.minPixelValue * image.slope + image.intercept;

    image.windowWidth = maxVoi - minVoi;
    image.windowCenter = (maxVoi + minVoi) / 2;
  }
  return image;
}
