import {
  TRACKING_TYPES,
  NEW_LESION_RESPONSE,
} from '../../constants/guideline.js';

/**
 * @param {String} timepoint.SeriesDate
 * @param {Object[]} timepoint.Lesions
 * @param {Object[]} newLesionROIContours
 * @param {Number} criteria.measurableLongAxisOfNewLesion
 */
function getNewLesionResponse(timepoint, newLesionROIContours, criteria) {
  const measurableLongAxisOfNewLesion =
    timepoint?.SliceThickness >= criteria?.highSliceThickness
      ? criteria.measurableLongAxisOfNewLesionHST
      : criteria.measurableLongAxisOfNewLesion;
  const newLesionROINumbers = newLesionROIContours.map(roi => roi.ROINumber);
  const newLesions = timepoint.Lesions.filter(lesion =>
    newLesionROINumbers.includes(lesion.ROINumber)
  ).map(l => ({
    ...l,
    isUnequivocal: l.maxLongAxisValue > measurableLongAxisOfNewLesion,
  }));

  const confirmedLesions = newLesions.filter(
    lesion =>
      lesion.isConfirmed ||
      (criteria.newLesionConfirmation !== 'REQUIRED' && lesion.isUnequivocal)
  );
  const unconfirmedLesions = newLesions.filter(
    lesion =>
      criteria.newLesionConfirmation === 'REQUIRED' &&
      !lesion.isConfirmed &&
      lesion.isUnequivocal
  );

  let result;
  let message;
  if (confirmedLesions.length > 0) {
    const lesionNames = confirmedLesions
      .map(lesion => lesion.ROIName)
      .join(', ');
    result = NEW_LESION_RESPONSE.PRESENT;
    message = `New Lesion Present: ${lesionNames} found at timepoint ${timepoint.SeriesDate}`;
  } else if (unconfirmedLesions.length > 0) {
    const lesionNames = unconfirmedLesions
      .map(lesion => lesion.ROIName)
      .join(', ');
    result = NEW_LESION_RESPONSE.UNCONFIRMED;
    message = `New Lesion Unconfirmed: ${lesionNames} found at timepoint ${timepoint.SeriesDate}`;
  } else {
    result = NEW_LESION_RESPONSE.ABSENT;
    message = `New Lesion Absent`;
  }
  return {
    date: timepoint.SeriesDate,
    trackingType: TRACKING_TYPES.NEW,
    lesions: confirmedLesions.map(lesion => lesion.ROIName),
    result: result,
    message: message,
  };
}

export default getNewLesionResponse;
