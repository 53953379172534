import React, { useState } from 'react';
import propTypes from 'prop-types';
import GoogleButton from 'react-google-button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container,
  CssBaseline,
  LinearProgress,
  Grid,
  Box,
  Button,
  TextField,
} from '@material-ui/core';
import { Firebase } from '@platform/core';

import Hero from '../../static/images/HeroTheme.png';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    minHeight: `100%`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
  },
}));

const BorderLinearProgress = withStyles(theme => ({
  root: { height: 8, borderRadius: 4 },
  colorPrimary: { backgroundColor: theme.palette.grey[300] },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}))(LinearProgress);

export function Home({ isAuthStateChanged }) {
  const classes = useStyles();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSetEmail = e => setEmail(e.target.value);
  const handleSetPassword = e => setPassword(e.target.value);
  const emailSignIn = async (email, password) => {
    try {
      await Firebase.signInWithEmailAndPassword(email, password);
    } catch (e) {
      window.alert('Email / Password authentication failed');
    }
  };
  const resetPassword = async email => {
    try {
      await Firebase.resetPassword(email);
    } catch (e) {
      window.alert('Failed to send password reset email');
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <img src={Hero} alt="Vysioneer" style={{ width: '100%' }} />
                {!isAuthStateChanged ? (
                  <div style={{ width: '80%' }}>
                    <BorderLinearProgress />
                  </div>
                ) : (
                  <Box
                    style={{
                      width: '90%',
                      display: 'grid',
                      gridTemplateColumns: 'repeat(1, 1fr)',
                      gridGap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      fullWidth
                      id="email"
                      type="email"
                      label="Email"
                      margin="dense"
                      variant="outlined"
                      onChange={handleSetEmail}
                    />
                    <TextField
                      fullWidth
                      id="password"
                      type="password"
                      label="Password"
                      margin="dense"
                      variant="outlined"
                      onChange={handleSetPassword}
                      onKeyDown={e =>
                        e.key === 'Enter' && emailSignIn(email, password)
                      }
                    />
                    <Button
                      fullWidth
                      color="primary"
                      variant="contained"
                      onClick={() => emailSignIn(email, password)}
                    >
                      Sign In
                    </Button>
                    <Button onClick={() => resetPassword(email)}>
                      Reset Password
                    </Button>
                    <GoogleButton
                      type="light"
                      style={{
                        width: '100%',
                        margin: '20px 0 0',
                        borderRadius: '5px',
                      }}
                      onClick={() => Firebase.signInWithGooglePopup()}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
Home.propTypes = { isAuthStateChanged: propTypes.bool };
