import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../elements';
import { withTranslation } from '../../context';
import './CineDialog.styl';

function CineDialog(props) {
  const [isPlaying, setIsPlaying] = useState(props.isPlaying);
  const [cineFrameRate, setCineFrameRate] = useState(props.cineFrameRate);

  useEffect(() => {
    if (props.isPlaying !== isPlaying) {
      setIsPlaying(props.isPlaying);
    }
  }, [props.isPlaying]);

  useEffect(() => {
    if (props.cineFrameRate !== cineFrameRate) {
      setCineFrameRate(props.cineFrameRate);
    }
  }, [props.cineFrameRate]);

  const handleInputChange = event => {
    const target = event.target;
    let value = target.value;
    if (target.type === 'range') {
      value = parseFloat(target.value);
    }

    const name = target.name;
    if (name === 'cineFrameRate' && props.onFrameRateChanged) {
      props.onFrameRateChanged(parseFloat(value));
    }
  };

  const onClickPlayPause = () => {
    setIsPlaying(!isPlaying);
    if (props.onPlayPauseChanged) {
      props.onPlayPauseChanged(!isPlaying);
    }
  };

  const onClickNextButton = event => {
    if (props.onClickNextButton) {
      props.onClickNextButton(event);
    }
  };

  const onClickBackButton = event => {
    if (props.onClickBackButton) {
      props.onClickBackButton(event);
    }
  };

  const onClickSkipToStart = event => {
    if (props.onClickSkipToStart) {
      props.onClickSkipToStart(event);
    }
  };

  const onClickSkipToEnd = event => {
    if (props.onClickSkipToEnd) {
      props.onClickSkipToEnd(event);
    }
  };

  const { t } = props;
  return (
    <div className="CineDialog">
      <div className="noselect double-row-style">
        <div className="cine-controls">
          <div className="btn-group">
            <button
              title={t('Skip to first image')}
              className="btn"
              data-toggle="tooltip"
              onClick={onClickSkipToStart}
            >
              <Icon name="fast-backward" />
            </button>
            <button
              title={t('Previous image')}
              className="btn"
              data-toggle="tooltip"
              onClick={onClickBackButton}
            >
              <Icon name="step-backward" />
            </button>
            <button
              title={t('Play / Stop')}
              className="btn"
              data-toggle="tooltip"
              onClick={onClickPlayPause}
            >
              <Icon name={isPlaying ? 'stop' : 'play'} />
            </button>
            <button
              title={t('Next image')}
              className="btn"
              data-toggle="tooltip"
              onClick={onClickNextButton}
            >
              <Icon name="step-forward" />
            </button>
            <button
              title={t('Skip to last image')}
              className="btn"
              data-toggle="tooltip"
              onClick={onClickSkipToEnd}
            >
              <Icon name="fast-forward" />
            </button>
          </div>
        </div>
        <div className="cine-options">
          <div className="fps-section">
            <input
              type="range"
              name="cineFrameRate"
              min={props.cineMinFrameRate}
              max={props.cineMaxFrameRate}
              step={props.cineStepFrameRate}
              value={cineFrameRate}
              onChange={handleInputChange}
            />
          </div>
          <span className="fps">
            {cineFrameRate.toFixed(1)} {t('fps')}
          </span>
        </div>
      </div>
    </div>
  );
}
CineDialog.propTypes = {
  /** Minimum value for range slider */
  cineMinFrameRate: PropTypes.number.isRequired,
  /** Maximum value for range slider */
  cineMaxFrameRate: PropTypes.number.isRequired,
  /** Increment range slider can "step" in either direction. */
  cineStepFrameRate: PropTypes.number.isRequired,
  cineFrameRate: PropTypes.number.isRequired,
  /** 'True' if playing, 'False' if paused. */
  isPlaying: PropTypes.bool.isRequired,
  onPlayPauseChanged: PropTypes.func,
  onFrameRateChanged: PropTypes.func,
  onClickNextButton: PropTypes.func,
  onClickBackButton: PropTypes.func,
  onClickSkipToStart: PropTypes.func,
  onClickSkipToEnd: PropTypes.func,
  /** i18next translation function */
  t: PropTypes.func.isRequired,
};
CineDialog.defaultProps = {
  cineMinFrameRate: 1,
  cineMaxFrameRate: 90,
  cineStepFrameRate: 1,
  cineFrameRate: 24,
  isPlaying: false,
};

const connectedComponent = withTranslation('CineDialog')(CineDialog);
export { connectedComponent as CineDialog };
export default connectedComponent;
