import React from 'react';
import MuiTableRow from '@material-ui/core/TableRow';

const TableRow = props => {
  const { children, backgroundColor, index } = props;
  return (
    <MuiTableRow
      {...props}
      style={{
        backgroundColor:
          backgroundColor || (index % 2 === 0 ? `#16202b` : `#151a1f`),
      }}
    >
      {children}
    </MuiTableRow>
  );
};

export default TableRow;
