import getNumberOfDays from './getNumberOfDays.js';
import transformStudyDateToDate from './transformStudyDateToDate.js';
import findTimepointIndex from './findTimepointIndex.js';

/**
 * @param {array} reports
 * @param {string[]} reports[].date
 * @param {string[]} reports[].result
 * @returns {string}
 */
function getTimeToProgression(reports) {
  const progressiveIndex = findTimepointIndex(reports, 'progressive');
  if (progressiveIndex < 0) {
    return null;
  }
  const progressiveDate = transformStudyDateToDate(
    reports[progressiveIndex].date
  );
  const baselineIndex = findTimepointIndex(reports, 'baseline');
  const baselineDate = transformStudyDateToDate(reports[baselineIndex].date);
  return getNumberOfDays(baselineDate, progressiveDate);
}

export default getTimeToProgression;
