export default function getAxis3DContent(stats, links) {
  let content = '';
  const newLine = '\r\n';

  /** header */
  const dates = stats.map(stat => `${stat.SeriesDate} (mm)`);
  content += 'ID,' + dates.join(',') + ',from latest (%),ROI Name' + newLine;

  /** rows */
  const sumValues = stats.map(stat => stat.sumOfLinks.longAxis3DValue);
  const sumChanges = stats.map(stat => stat.sumOfLinks.axis3DChange);
  let latestChange = sumChanges[sumChanges.length - 1];
  content += 'Sum,' + sumValues.join(',') + `,${latestChange}%` + ',' + newLine;

  links.forEach(link => {
    const names = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.names}`;
    });
    const latestName = names[names.length - 1];
    const nameCell = latestName.includes(',')
      ? JSON.stringify(latestName[latestName.length - 1]).replaceAll('"', "'")
      : latestName;
    const values = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      return `${_link.longAxis3DValue}`;
    });
    const changes = stats.map(stat => {
      const _link = stat.links.find(({ id }) => id === link.LinkID);
      if (!_link.axis3DChange) return '';
      return `${_link.axis3DChange}%`;
    });
    content +=
      `${link.LinkID},` +
      values.join(',') +
      `,${changes[changes.length - 1]}` +
      `,${nameCell}` +
      newLine;
  });

  return content;
}
