import React from 'react';
import { Alert } from '@platform/ui';

export default function ExportAlert() {
  const message =
    'The generated information and report should be reviewed by medical professional before being used for clinical purpose.';
  return (
    <div style={{ marginTop: '5px', marginBottom: '5px' }}>
      <Alert severity="warning">{message}</Alert>
    </div>
  );
}
