import { TRACKING_TYPES } from '../constants/guideline';
import { math } from '../../../modules/dicom-measurement/src';
const { round } = math;

export function getMeasurementSource(timepoints, ROIContours) {
  if (timepoints.length === 0) {
    return;
  }
  const dataSource = {
    data: [],
    columns: [
      {
        dataField: 'patientId',
        displayName: 'Patient ID',
      },
      {
        dataField: 'date',
        displayName: 'Scan Date',
      },
      {
        dataField: 'lesionName',
        displayName: 'Lesion',
      },
      {
        dataField: 'measurable',
        displayName: 'Measurable Lesion',
      },
      {
        dataField: 'trackingType',
        displayName: 'Target/Non-target Lesion',
      },
      {
        dataField: 'present',
        displayName: 'Present/Absent',
      },
      {
        dataField: 'volume',
        displayName: 'Volume (mm^3)',
      },
      {
        dataField: 'longAxis',
        displayName: 'Long Axis (mm)',
      },
      {
        dataField: 'shortAxis',
        displayName: 'Short Axis (mm)',
      },
      {
        dataField: 'locationX',
        displayName: 'Location X',
      },
      {
        dataField: 'locationY',
        displayName: 'Location Y',
      },
      {
        dataField: 'imageIdx',
        displayName: 'Image Index',
      },
      // {
      //   dataField: 'physicalCoordinate',
      //   displayName: 'Physical Coordinate',
      // },
    ],
  };

  timepoints.forEach(timepoint => {
    timepoint.Lesions.forEach((lesion, index) => {
      dataSource.data.push({
        patientId: timepoint.PatientID,
        date: timepoint.StudyDate,
        lesionName: lesion.ROIName,
        measurable: ROIContours[index].measurable
          ? 'measurable'
          : 'non-measurable',
        trackingType: ROIContours[index].trackingType,
        present:
          ROIContours[index].trackingType === TRACKING_TYPES.NONTARGET
            ? lesion.volume > 0
              ? 'present'
              : 'absent'
            : '',
        volume: round(lesion.volume, -2),
        longAxis: round(lesion.maxLongAxisValue, -2),
        shortAxis: round(lesion.maxShortAxisValue, -2),
        locationX: round(lesion.maxAreaCentroid.x, -2),
        locationY: round(lesion.maxAreaCentroid.y, -2),
        imageIdx: lesion.InstanceNumber,
        physicalCoordinate: `${lesion.maxAreaPhysicalCentroid.x}\\${lesion.maxAreaPhysicalCentroid.y}\\${lesion.maxAreaPhysicalCentroid.z}`,
      });
    });
  });
  return dataSource;
}
