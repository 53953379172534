import dcmjs from 'dcmjs';
import getDICOMwebClient from '../getDICOMwebClient';

/**
 * Retrieve Instance metadata from a DICOM server
 * @param server
 * @param StudyInstanceUID
 * @param SeriesInstanceUID
 * @param SOPInstanceUID
 * @throws ECONNREFUSED
 * @returns {Object} The instance object
 */
export default async function retrieveInstanceMetadata(
  server,
  StudyInstanceUID,
  SeriesInstanceUID,
  SOPInstanceUID
) {
  const dicomWebClient = getDICOMwebClient(server.wadoRoot, server);
  const instances = await dicomWebClient.retrieveInstanceMetadata({
    studyInstanceUID: StudyInstanceUID,
    seriesInstanceUID: SeriesInstanceUID,
    sopInstanceUID: SOPInstanceUID,
  });
  return dcmjs.data.DicomMetaDictionary.naturalizeDataset(instances[0]);
}
