import {
  TARGET_RESPONSE,
  NON_TARGET_RESPONSE,
  NEW_LESION_RESPONSE,
  OVERALL_RESPONSE,
} from '../../constants/guideline';

/**
 * @param {Object} assessedTimepoint
 * @param {String} assessedTimepoint.SeriesDate
 * @param {String} assessedTimepoint.targetResponse.result
 * @param {String} assessedTimepoint.nonTargetResponse.result
 * @param {String} assessedTimepoint.newLesionResponse.result
 * @param {String} assessedTimepoint.overallResponse.result
 * @param {Boolean} assessedTimepoint.isResponseConfirmed
 */
function getOverallAlert({
  SeriesDate,
  targetResponse,
  nonTargetResponse,
  newLesionResponse,
  overallResponse,
  isResponseConfirmed,
}) {
  if (isResponseConfirmed) {
    return { date: SeriesDate, isWarning: false };
  }
  if (overallResponse.result !== OVERALL_RESPONSE.PROGRESSIVE_DISEASE) {
    return { date: SeriesDate, isWarning: false };
  }
  if (
    targetResponse.result === TARGET_RESPONSE.PROGRESSIVE_DISEASE &&
    nonTargetResponse.result === NON_TARGET_RESPONSE.COMPLETE_RESPONSE
  ) {
    return {
      date: SeriesDate,
      message: `Unexpected target lesion progressive disease at timepoint ${SeriesDate}`,
      isWarning: true,
    };
  }
  if (
    [
      TARGET_RESPONSE.COMPLETE_RESPONSE,
      TARGET_RESPONSE.PARTIAL_RESPONSE,
    ].includes(targetResponse.result) &&
    nonTargetResponse.result === NON_TARGET_RESPONSE.PROGRESSIVE_DISEASE
  ) {
    return {
      date: SeriesDate,
      message: `Unexpected non-target lesion progressive disease at timepoint ${SeriesDate}`,
      isWarning: true,
    };
  }
  if (newLesionResponse.result === NEW_LESION_RESPONSE.PRESENT) {
    if (
      [
        TARGET_RESPONSE.COMPLETE_RESPONSE,
        TARGET_RESPONSE.PARTIAL_RESPONSE,
      ].includes(targetResponse.result) ||
      nonTargetResponse.result === NON_TARGET_RESPONSE.COMPLETE_RESPONSE
    ) {
      return {
        date: SeriesDate,
        message: `Unexpected new lesion present at timepoint ${SeriesDate}`,
        isWarning: true,
      };
    }
  }
  return { date: SeriesDate, isWarning: false };
}

export default getOverallAlert;
