/**
 * @param {Int16Array|Uint16Array} scalarData
 * @param {Array} pixelData
 * @param {Array} extent
 * @param {Number} sliceIndex
 */
export default function(scalarData, pixelData, extent, sliceIndex) {
  const increments = computeImageDataIncrements(extent, 1);
  const indexXYZ = [0, 0, sliceIndex];
  let pixelIndex = 0;

  for (let row = 0; row <= extent[3]; row++) {
    indexXYZ[1] = row;
    for (let col = 0; col <= extent[1]; col++) {
      indexXYZ[0] = col;
      const destIdx = computeIndex(extent, increments, indexXYZ);
      scalarData[destIdx] = pixelData[pixelIndex++];
    }
  }
}

/**
 * Based on vtkImageData.cxx (vtkDataset)
 * @param {*} extent
 * @param {*} numberOfComponents
 * @returns {Array} inc=[x, y, z]
 */
function computeImageDataIncrements(extent, numberOfComponents) {
  const inc = [0, 0, 0];
  let incr = numberOfComponents;
  for (let idx = 0; idx < 3; ++idx) {
    inc[idx] = incr;
    incr *= extent[idx * 2 + 1] - extent[idx * 2] + 1;
  }
  return inc;
}

/**
 * Based on vtkImageData.cxx (vtkDataset)
 * @param {*} extent
 * @param {*} increments
 * @param {*} indexXYZ
 * @returns {Number} index
 */
function computeIndex(extent, incs, xyz) {
  const index =
    ((xyz[0] - extent[0]) * incs[0] +
      (xyz[1] - extent[2]) * incs[1] +
      (xyz[2] - extent[4]) * incs[2]) |
    0;
  return index;
}
