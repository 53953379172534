import cornerstone from 'cornerstone-core';
import { importInternal } from 'cornerstone-tools';
import _ from 'lodash';

import { booleanOperation } from './booleanOperation';
import { math } from '../../modules/dicom-measurement/src';

const draw = importInternal('drawing/draw');
const { getDistance, getWeightedCentroid, round } = math;

class ReferenceToolState {
  constructor(toolState, imageIdx) {
    this.SeriesInstanceUID = toolState.SeriesInstanceUID;
    this.data = toolState.data;
    this.imageIdx = imageIdx;
  }
}

export function interpolationPainter(toolState, structureSetSeriesInstanceUid) {
  let state = _.cloneDeep(toolState);
  let topRefToolState = null;
  let bottomRefToolState = null;
  let targetImageIdx = '';
  let topImageIdx = '';
  let bottomImageIdx = '';
  let interpolateData;

  return {
    getState: function() {
      return state;
    },
    commit: function() {
      if (!interpolateData) {
        return;
      }
      state.data = [...state.data, ...interpolateData];

      // clear preview line
      topRefToolState = null;
      bottomRefToolState = null;
      targetImageIdx = '';
      topImageIdx = '';
      bottomImageIdx = '';
    },
    update: function(evt) {
      interpolateData = null;
      topRefToolState = evt.topRefToolState;
      bottomRefToolState = evt.bottomRefToolState;
      targetImageIdx = evt.targetImageIdx;
      topImageIdx = evt.topImageIdx;
      bottomImageIdx = evt.bottomImageIdx;

      /** out of the range */
      if ((!topImageIdx && topImageIdx !== 0) || !bottomImageIdx) {
        return;
      }
      if (topImageIdx > targetImageIdx || bottomImageIdx < targetImageIdx) {
        return;
      }
      /** lack of reference image tool state */
      if (!topRefToolState || !bottomRefToolState) {
        return;
      }
      /** overlapping the reference image and current image */
      if (
        topRefToolState.imageIdx === targetImageIdx ||
        bottomRefToolState.imageIdx === targetImageIdx
      ) {
        return;
      }

      interpolateData = getInterpolateData({
        topRefToolState,
        bottomRefToolState,
        targetImageIdx,
      });
    },
    cursor: function(evt, context) {
      /** lack of reference image tool state */
      if ((!topImageIdx && topImageIdx !== 0) || !bottomImageIdx) {
        return;
      }
      if (!topRefToolState || !bottomRefToolState) {
        return;
      }
      /** overlapping the reference image and current image */
      if (
        topRefToolState.imageIdx === targetImageIdx ||
        bottomRefToolState.imageIdx === targetImageIdx
      ) {
        return;
      }
      /** no updated data */
      if (!interpolateData || interpolateData.length === 0) {
        return;
      }
      const element = evt.detail.element;
      const pointsData = interpolateData.map(data =>
        data.handles.points.map(point =>
          cornerstone.pixelToCanvas(element, point)
        )
      );
      pointsData.forEach(points => {
        draw(context, context => {
          context.strokeStyle = 'rgba(217, 83, 79, 1)';
          context.fillStyle = 'rgba(217, 83, 79, 0.2)';
          context.lineWidth = 3;
          context.beginPath();
          context.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            context.lineTo(points[i].x, points[i].y);
          }
          context.closePath();
          context.stroke();
          context.fill();
        });
      });
      return true;
    },
  };
}

export function findFirstValidImageIdIdx(state, imageIds, indices) {
  for (const index of indices) {
    const painterStack = state.painters[imageIds[index]];
    if (!painterStack) continue;
    const painter = _.last(painterStack);
    if (!painter) continue;
    const { data } = painter.getState();
    const roiData = _.filter(data, y => y.ROINumber == state.selectedROINumber);
    if (!roiData.length) continue;
    return index;
  }
  return null;
}

export function createReferenceToolState(state, imageIds, imageIdx) {
  if (imageIdx == null) return null;
  const sourceImageId = imageIds[imageIdx];
  const painter = _.last(state.painters[sourceImageId]);
  const toolState = painter.getState();
  const data = toolState.data.filter(
    x => x.ROINumber === state.selectedROINumber
  );
  if (!data.length) return null;
  return new ReferenceToolState({ ...toolState, data: data }, imageIdx);
}

function getInterpolateData({
  topRefToolState,
  bottomRefToolState,
  targetImageIdx,
}) {
  if (topRefToolState === null) {
    return bottomRefToolState.data;
  } else if (bottomRefToolState === null) {
    return topRefToolState.data;
  }

  /** Define target polygons */
  const topPolygons = topRefToolState.data.map(d => d.handles.points);
  const bottomPolygons = bottomRefToolState.data.map(d => d.handles.points);
  if (topPolygons.length == 0 || bottomPolygons.length == 0) return;

  /** Interpolate with every possible combination */
  const interpolatePolygons = [];
  for (let i = 0; i < topPolygons.length; i++) {
    for (let j = 0; j < bottomPolygons.length; j++) {
      if (
        targetImageIdx < topRefToolState.imageIdx ||
        targetImageIdx > bottomRefToolState.imageIdx
      ) {
        interpolatePolygons.push([
          getCopyPoints(
            topPolygons[i],
            bottomPolygons[j],
            topRefToolState.imageIdx,
            bottomRefToolState.imageIdx,
            targetImageIdx
          ).map(p => [p.x, p.y]),
        ]);
      } else {
        interpolatePolygons.push([
          getInterpolatePoints(
            topPolygons[i],
            bottomPolygons[j],
            topRefToolState.imageIdx,
            bottomRefToolState.imageIdx,
            targetImageIdx
          ).map(p => [p.x, p.y]),
        ]);
      }
    }
  }

  if (interpolatePolygons.length === 0) {
    return;
  }

  /**  Combine all the computed polygons with boolean union */
  let unionInterpolatePolygons = [interpolatePolygons[0]];
  for (let i = 1; i < interpolatePolygons.length; i++) {
    unionInterpolatePolygons = booleanOperation(
      'union',
      [...[interpolatePolygons[i]]],
      [...unionInterpolatePolygons]
    );
  }

  /**  Add polygons' points to state */
  const interpolateData = unionInterpolatePolygons.map(polygon => ({
    ROINumber: topRefToolState.data[0].ROINumber,
    structureSetSeriesInstanceUid:
      topRefToolState.data[0].structureSetSeriesInstanceUid,
    handles: {
      points: polygon[0].map(p => ({ x: p[0], y: p[1] })),
    },
  }));
  return interpolateData;
}

function getInterpolatePoints(
  topPoints,
  bottomPoints,
  topZIndex,
  bottomZIndex,
  targetZIndex
) {
  let basePoints, mappedPoints, distanceRatio;
  const distance = Math.abs(topZIndex - bottomZIndex);
  if (topPoints.length > bottomPoints.length) {
    basePoints = topPoints;
    mappedPoints = bottomPoints;
    distanceRatio = (targetZIndex - topZIndex) / distance;
  } else {
    basePoints = bottomPoints;
    mappedPoints = topPoints;
    distanceRatio = (bottomZIndex - targetZIndex) / distance;
  }

  const baseCentroid = getWeightedCentroid(basePoints);
  const mappedCentroid = getWeightedCentroid(mappedPoints);
  const centroidShift = {
    x: mappedCentroid.x - baseCentroid.x,
    y: mappedCentroid.y - baseCentroid.y,
  };
  const idxMappings = getPointsMapping(basePoints, mappedPoints, centroidShift);

  return basePoints.map((point, idx) => {
    const mappedIdx = idxMappings[idx];
    return getInterpolatePoint(point, mappedPoints[mappedIdx], distanceRatio);
  });
}

function getCopyPoints(
  topPoints,
  bottomPoints,
  topZIndex,
  bottomZIndex,
  targetZIndex
) {
  if (topZIndex > targetZIndex) {
    return topPoints;
  } else if (bottomZIndex < targetZIndex) {
    return bottomPoints;
  }
}

export function getExtrapolatePoints(
  topPoints,
  bottomPoints,
  topZIndex,
  bottomZIndex,
  targetZIndex
) {
  let basePoints, refPoints, shiftRatio;

  if (targetZIndex < topZIndex) {
    basePoints = topPoints;
    refPoints = bottomPoints;
    shiftRatio = (targetZIndex - topZIndex) / (topZIndex - bottomZIndex);
  } else if (targetZIndex > bottomZIndex) {
    basePoints = bottomPoints;
    refPoints = topPoints;
    shiftRatio = (targetZIndex - bottomZIndex) / (bottomZIndex - topZIndex);
  }

  const baseCentroid = getWeightedCentroid(basePoints);
  const baseRadius = Math.max(
    ...basePoints.map(point => getDistance(point, baseCentroid))
  );
  const refCentroid = getWeightedCentroid(refPoints);
  const refRadius = Math.max(
    ...refPoints.map(point => getDistance(point, refCentroid))
  );
  const centroidShift = {
    x: (baseCentroid.x - refCentroid.x) * shiftRatio,
    y: (baseCentroid.y - refCentroid.y) * shiftRatio,
  };
  const extraCentroid = {
    x: baseCentroid.x + centroidShift.x,
    y: baseCentroid.y + centroidShift.y,
  };
  const extraRadius = baseRadius + (baseRadius - refRadius) * shiftRatio;
  if (extraRadius < 0) {
    return [];
  }
  const extraScale = extraRadius / baseRadius;

  return basePoints.map(point => {
    const vector = {
      x: point.x - baseCentroid.x,
      y: point.y - baseCentroid.y,
    };
    const extraVector = {
      x: vector.x * extraScale,
      y: vector.y * extraScale,
    };
    return {
      x: round(extraCentroid.x + extraVector.x, -3),
      y: round(extraCentroid.y + extraVector.y, -3),
    };
  });
}

export function getPointsMapping(basePoints, mappedPoints, pointShift) {
  return basePoints.map(p1 => {
    let minCompareValue = Number.MAX_SAFE_INTEGER;
    let minPointIdx = 0;
    mappedPoints.forEach((p2, idx) => {
      let compareValue = getCompareValue(p1, p2, pointShift);
      if (compareValue < minCompareValue) {
        minPointIdx = idx;
        minCompareValue = compareValue;
      }
    });
    return minPointIdx;
  });
}

export function getCompareValue(p1, p2, pointShift) {
  return (
    (p1.x - (p2.x - pointShift.x)) ** 2 + (p1.y - (p2.y - pointShift.y)) ** 2
  );
}

export function getInterpolatePoint(p1, p2, distanceRatio) {
  return {
    x: getInterpolation(p1.x, p2.x, distanceRatio),
    y: getInterpolation(p1.y, p2.y, distanceRatio),
  };
}

export function getInterpolation(a, b, ratio) {
  return a * (1 - ratio) + b * ratio;
}
