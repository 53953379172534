import { round } from '../utils/math';

export default function getStats(timepoints, links, seriesStructureMap) {
  const stats = timepoints
    .map(timepoint => {
      const structureSetUID = seriesStructureMap[timepoint.SeriesInstanceUID];
      const linkMeasurements = links.map(link => {
        const { LinkID, RTMappings } = link;
        const mapping = RTMappings.find(
          mapping => mapping.SeriesInstanceUID === structureSetUID
        );
        let measurement = {
          names: [],
          maxLongAxisValue: 0,
          longAxis3DValue: 0,
          volume: 0,
        };
        if (mapping) {
          const { ROINumbers } = mapping;
          if (ROINumbers.length > 0) {
            measurement = timepoint.ROIMeasurements.filter(m =>
              ROINumbers.includes(m.ROINumber)
            ).reduce(
              (acc, cur) => ({
                names: [...acc.names, cur.ROIName],
                maxLongAxisValue: acc.maxLongAxisValue + cur.maxLongAxisValue,
                longAxis3DValue: acc.longAxis3DValue + cur.longAxis3DValue,
                volume: acc.volume + cur.volume,
              }),
              { names: [], maxLongAxisValue: 0, longAxis3DValue: 0, volume: 0 }
            );
          }
        }
        return {
          id: LinkID,
          names: measurement.names.join(', '),
          date: timepoint.SeriesDate,
          volume: measurement.volume,
          maxLongAxisValue: measurement.maxLongAxisValue,
          longAxis3DValue: measurement.longAxis3DValue,
        };
      });
      const sumOfLinks = linkMeasurements.reduce(
        (acc, cur) => ({
          ...acc,
          maxLongAxisValue: acc.maxLongAxisValue + cur.maxLongAxisValue,
          longAxis3DValue: acc.longAxis3DValue + cur.longAxis3DValue,
          volume: acc.volume + cur.volume,
        }),
        { maxLongAxisValue: 0, longAxis3DValue: 0, volume: 0 }
      );
      return { ...timepoint, links: linkMeasurements, sumOfLinks };
    })
    .map((timepoint, index, timepoints) => {
      const prevTimepoint = index > 0 ? timepoints[index - 1] : timepoint;
      const links = timepoint.links.map((l, index) => {
        const prevLink = prevTimepoint.links[index];
        return {
          ...l,
          volume: getRound(l.volume / 1000),
          maxLongAxisValue: getRound(l.maxLongAxisValue),
          longAxis3DValue: getRound(l.longAxis3DValue),
          volumeChange: getChange(prevLink.volume, l.volume),
          axisChange: getChange(prevLink.maxLongAxisValue, l.maxLongAxisValue),
          axis3DChange: getChange(prevLink.longAxis3DValue, l.longAxis3DValue),
        };
      });
      const sumOfLinks = {
        date: timepoint.SeriesDate,
        volume: getRound(timepoint.sumOfLinks.volume / 1000),
        maxLongAxisValue: getRound(timepoint.sumOfLinks.maxLongAxisValue),
        longAxis3DValue: getRound(timepoint.sumOfLinks.longAxis3DValue),
        volumeChange: getChange(
          prevTimepoint.sumOfLinks.volume,
          timepoint.sumOfLinks.volume
        ),
        axisChange: getChange(
          prevTimepoint.sumOfLinks.maxLongAxisValue,
          timepoint.sumOfLinks.maxLongAxisValue
        ),
        axis3DChange: getChange(
          prevTimepoint.sumOfLinks.longAxis3DValue,
          timepoint.sumOfLinks.longAxis3DValue
        ),
      };
      return { ...timepoint, links, sumOfLinks };
    });
  return stats;
}

export function getChange(prev, current) {
  const change = prev === 0 ? null : round((100 * (current - prev)) / prev, -2);
  return change;
}

export function getUnit(key) {
  const mapping = {
    volume: 'cc',
    axis: 'mm',
  };
  return `${mapping[key]}`;
}

export function getTechs() {
  return {
    volume: {
      label: 'Volume (cc)',
      measurement: 'volume',
      change: 'volumeChange',
    },
    axis: {
      label: 'Long Axis 2D (mm)',
      measurement: 'maxLongAxisValue',
      change: 'axisChange',
    },
  };
}

export function getRound(value) {
  return `${round(value, -2)}`;
}

export function getSign(value) {
  return value ? (value >= 0 ? `+${value}%` : `${value}%`) : '';
}
