export default function polygonToMask(mask, vertices) {
  if (mask.length === 0) return mask;
  if (mask[0].length === 0) return mask;
  const height = mask.length;
  const width = mask[0].length;
  // Loop through each row of the mask
  for (let y = 0; y < height; y++) {
    // Initialize arrays to store the x-coordinates of intersections
    const intersections = [];

    // Loop through each edge of the polygon
    for (let i = 0; i < vertices.length; i++) {
      const p1 = vertices[i];
      const p2 = vertices[(i + 1) % vertices.length];

      const x1 = p1[0];
      const y1 = p1[1];
      const x2 = p2[0];
      const y2 = p2[1];

      // Check if the edge crosses the scanline (row)
      if ((y1 < y && y2 >= y) || (y2 < y && y1 >= y)) {
        // Calculate the x-coordinate of the intersection
        const xIntersect = Math.round(x1 + ((y - y1) / (y2 - y1)) * (x2 - x1));
        intersections.push(xIntersect);
      }
    }

    // Sort the intersections in ascending order
    intersections.sort((a, b) => a - b);

    // Fill the pixels between pairs of intersections
    for (let i = 0; i < intersections.length; i += 2) {
      const startX = Math.max(intersections[i], 0);
      const endX = Math.min(intersections[i + 1], width - 1);
      for (let x = startX; x <= endX; x++) {
        mask[y][x] = 1;
      }
    }
  }
  return mask;
}
